import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { ReactEditor } from "cs-slate-react"
import { useDispatch, useSelector } from "react-redux"

import { focusAndSelect, onNotePaste, defineIndentation } from "../helpers"
import useNoteKeyDown from "../useNoteKeyDown"
import { liftLock, removeAutoFocus, setBlock, setFocusedEditor, unsetFocusedEditor, setCaretPosition } from "../actions"
import { useEditor } from "../../../components/TextEditor/withHistory"

import TextEditor from "../../../components/TextEditor"
import deserialize from "../../../components/TextEditor/deserialize"
import FloatingToolbar from "../partials/FloatingToolbar"
import { store } from "../../.."
import { INTENDATION_WIDTH } from '../constants'

const noop = () => {}

const headings = {
  1: 1.875,
  2: 1.5,
  3: 1.3
  //Em realtion unit to contianer font
}

function EditorBlock({
  blocksRef,
  blockSelectionRef,
  interactionMenu,
  setInteractionMenu,
  containerRef,
  block = {},
  blockId,
  blockRef,
  dragPreview,
  setValue,
  placeholder,
  autoScrollDisabled = false,
  value,
  lock,
  auto_focus = false,
  hideToolbar = false,
  ...props
}) {
  const uid = useSelector(state => state.firebase.auth.uid)
  const { payload = {} } = block
  if(!auto_focus)
    auto_focus = block.auto_focus
    
  const text = useMemo(() => deserialize(value || payload.text || ''), [payload.text, value])
  const dispatch = useDispatch()
  const isFocused = useSelector(state => state.notes.focusedEditor === blockId)
  const setIsFocused = newFocused => {
    if(newFocused)
      dispatch(setFocusedEditor(blockId))
    else if(isFocused)
      setTimeout(() => dispatch(unsetFocusedEditor(blockId)), 200)
  }

  if(!setValue)
    setValue = value => setBlock(blockId, { payload: { ...payload, text: value } })
    
  const onKeyDown = useNoteKeyDown({ blocksRef, setInteractionMenu, blockSelectionRef })

  const editor = useEditor(editor => blocksRef.current[blockId] = { editor })

  useEffect(() => {
    if(uid === auto_focus){
      focusAndSelect(editor)
      setTimeout(() => {
        focusAndSelect(editor)
        removeAutoFocus(blockId)
      })
    }
  }, [auto_focus])

  const handleKeyDown = (callback = noop) => event => {
    callback(event, editor)
    onKeyDown(event, blockId)
  }

  const handleChange = (callback = noop) => value => callback(value, editor)

  useEffect(() => {
    if(!isFocused && (lock || block.lock)?.by === uid)
      liftLock(blockId)
      
    if(!autoScrollDisabled && isFocused && containerRef.current && blockRef.current){
      //Scroll block into view when focused and out of view
      const bottomPadding = 80
      const { offsetHeight, scrollTop } = containerRef.current
      const { offsetTop: blockTop, offsetHeight: blockHeight } = blockRef.current

      const containerBottom = offsetHeight + scrollTop - bottomPadding
      const blockBottom = blockTop + blockHeight

      if(containerBottom < blockBottom)
        containerRef.current.scrollTop = blockBottom - offsetHeight + bottomPadding
      else if(blockTop - bottomPadding < scrollTop)
        containerRef.current.scrollTop = blockTop - bottomPadding
    }
  }, [isFocused])

  const isTitle = useMemo(() => block.type === 'title', [block.type])
  const handleClick = () => dispatch(setCaretPosition(null))

  const contentRef = useRef()
  placeholder = placeholder || (isTitle ? 'Thread Title' : payload.heading ? 'Heading ' + payload.heading : undefined)

  return (
    <div
      key={placeholder} // Slate seems to be memoizing placeholders - this overrides this behavior
      ref={el => { dragPreview?.(el); contentRef.current = el }}
      className='block-content'
      style={payload.heading ? { fontSize: headings[payload.heading] + 'em', fontWeight: 500, marginTop: payload.heading > 1 ? 12 : 20 } : undefined}
    >
      {(block?.list || block?.parent_id) && <EditorLeft {...{ block, blockId, editor }} />}
      {/*<div style={{fontSize:9}}>{blockId}</div>*/}
      <TextEditor
        className={!isTitle ? 'note-editor-block' : `note-title-block ${!payload.text && !value ? 'is-empty' : ''}`}
        toolbarMode='hide'
        value={text}
        setIsFocused={setIsFocused}
        isFocused={isFocused}
        {...{ editor, setValue, handleKeyDown, handleChange, placeholder, ...props }}
        plainText={isTitle}
        onClick={handleClick}
        onPaste={onNotePaste(editor, blockId, blocksRef)}
      />
      {!hideToolbar && !isTitle && !block?.type?.includes('chart') && <FloatingToolbar {...{ isFocused, block, blockId, editor, contentRef }}/>}
    </div>
  )
}

function EditorLeft({ block, blockId, editor }){
  const blocks = useSelector(state => state.notes.currentNote.blocks)

  const [listCount, setListCount] = useState(null)

  useLayoutEffect(() => {
    if(block.list === 'unordered')
      return
    
    const { currentNote: { blocks }, blockOrder } = store.getState().notes
    let count = 0
    let blockPointer = blockId
    const setPointer = () => blockPointer = blockOrder.find(id => blocks[id]?.next_block === blockPointer)
    while(blocks[blockPointer]?.list === 'ordered' && blocks[blockPointer].parent_id === block.parent_id){
      count++
      const parentId = blocks[blockPointer].parent_id
      setPointer()
      while(blockPointer !== parentId && blocks[blockPointer].parent_id !== parentId)
        setPointer()
    }
    if(listCount !== count)
      setListCount(count)
  }, [blocks])

  const parentsNumber = defineIndentation(blocks, blockId)
  
  return (
    <div
      className='editor-left'
      style={{ paddingLeft: 4 + (parentsNumber * INTENDATION_WIDTH) + (block.list ? 8 : 0) }}
    >
     
      {Boolean(block.list) && (block.list === 'unordered' ? '●' : `${listCount}.`)}
    </div>
  )
}

export default EditorBlock