import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Button, makeStyles, Snackbar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

import { ConfirmationDialog } from '../dialogs/Confirmation';
import { transformEntrys, copyToClipboard } from '../../../utils/utils';

import {fetchAPIKey, postAPIKey, rmApiKey} from '../actions'
import { useLayoutConfig } from '../../../components/PlanttLayout/LayoutConfig';

const APIStyle = makeStyles(() => ({
  row: {
    paddingLeft: 23,
    paddingRight: 23,
  },
  apiContainer: {
    minHeight: 71,
    borderTop: 'solid 1px #d8d8d8',
  },
  newButton: {
    borderRadius: 3,
    backgroundColor: '#fd6c4b',
    fontSize: 12,
    textAlign: 'center',
    color: '#ffffff',
    padding: 0,
    width: 68,
    height: 32,
    border: 0,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#fd532c',
    }
  },
  rowHeader: {
    color: '#9b9b9b',
    fontSize: 14,
    marginBottom: 10,
  },
  deleteLinkRow: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  deleteLink: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    color: '#4a90e2',
    textDecoration: 'underline'
  },
  descriptionPanel: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Helvetica',
    fontSize: 12,
    color: '#9b9b9b'
  },
  apiKey: {
    display: 'inline-block',
    padding: '4px 8px',
    marginRight: 12,
    marginTop: 8,
    marginBottom: 8,
    fontFamily: 'Helvetica',
    fontSize: 12,
    color: '#19270d',
    borderRadius: 4,
    backgroundColor: '#dddddd'
  },
  apiDate: {
    display: 'inline-block',
    fontFamily: 'Helvetica',
    fontSize: 12,
    color: '#9b9b9b'
  },
  visibilityAndFileCopyButtons: {
    padding: 0,
    width: 31,
    height: 31,
    borderRadius: 2,
    border: 'solid 1px #d8d8d8'
  },
  fileCopyButtons: {
    marginRight: 5
  },
  apiTitle: {
    fontFamily: 'Helvetica',
    fontSize: 18,
    color: '#19270d'
  },
  apiTitleMark: {
    color: '#fd6c4b'
  },
  closeIcon: {
    fontSize: 16,
    color: '#4a4a4a'
  },
  successIcon: {
    color: '#37a516'
  },
  toastWrapper: {
    borderRadius: '4px',
    backgroundColor: '#19270d',
    fontSize: 12,
    color: '#ffffff'
  }
}));

function API() {
  useLayoutConfig({ title: 'Settings / API' })

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [visibleApisKeys, setVisibleApisKeys] = useState({});
  const [copyToastVisible, setCopyToastVisibility] = useState(false);

  const [state, setState] = React.useState({
    currApiKey: ''
  });

  const dispatch = useDispatch();
  const apis = useSelector(state => state.setting.apiKeys);

  useEffect(() => {
    dispatch(fetchAPIKey());
  }, []);

  const apiStyle = APIStyle();
  const closeConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const toggleVisibilty = key => {
    setVisibleApisKeys({
      ...visibleApisKeys,
      [key]: !visibleApisKeys[key]
    });
  };

  return (
    <div>
      <div className="setting-section">
        <Grid container>
          <Grid container className={`${apiStyle.row} mb-3`}>
            <Grid item xs={6} className={apiStyle.apiTitle}>Secret Key <span className={apiStyle.apiTitleMark}>[?]</span></Grid>
            <Grid item xs={6} className="setLeftAlign">
              <Button className={apiStyle.newButton} 
              onClick={() => {
                dispatch(postAPIKey());
              }}
            >
                <span style={{ marginLeft: '6px' }}>New</span><AddIcon style={{ width: '12px', height: '20px' }} />
              </Button>
            </Grid>
          </Grid>
          <Grid container className={apiStyle.row}>
            <Grid item xs={8} className={apiStyle.rowHeader}>API Key</Grid>
            <Grid item xs={4} className={apiStyle.rowHeader}>Creation Date</Grid>
          </Grid>
          {apis && 
            apis.map((api, i) => (
              <Grid container alignItems="center" key={i} className={`${apiStyle.row} ${apiStyle.apiContainer}`}>
                <Grid item xs={6}>
                  <div className={apiStyle.apiKey}>{visibleApisKeys[i] ? api.id : transformEntrys(api.id, 'apiKey')}</div>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    className={`${apiStyle.visibilityAndFileCopyButtons} ${apiStyle.fileCopyButtons}`}
                    onClick={() => {
                      copyToClipboard(api.id);
                      setCopyToastVisibility(true);
                    }}
                  >
                    <FileCopyIcon style={{ width: '14px', height: '14px', color: '#d8d8d8' }} />
                  </IconButton>
                  <IconButton className={apiStyle.visibilityAndFileCopyButtons} onClick={() => toggleVisibilty(i)}>
                    <VisibilityIcon style={{ width: '14px', height: '14px', color: '#d8d8d8' }} />
                  </IconButton>
                </Grid>
                <Grid item xs={2}>
                  <div className={apiStyle.apiDate}>
                  {new Date(api.created_at).toLocaleDateString()} {new Date(api.created_at).toLocaleTimeString()}
                  </div>
                </Grid>
                <Grid item xs={2} className={apiStyle.deleteLinkRow}>
                  <Link
                    onClick={() => {
                      setConfirmationDialogOpen(true, api.id);
                      setState({ ...state, currApiKey: api.id });
                    }}
                    className={apiStyle.deleteLink}
                  >
                    Delete
                  </Link>
                </Grid>
              </Grid>
            ))
          }
          
          <Grid container className={`${apiStyle.row} ${apiStyle.apiContainer}`}>
            <Grid item xs={12} className={apiStyle.descriptionPanel}>
              Your API keys are like your passwords: make sure to alway keep them hidden! Share them only with services you trust.
            </Grid>
          </Grid>
        </Grid>
      </div>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={closeConfirmationDialog}
        title="Are you sure"
        continueText="Delete"
        callback={() => dispatch(rmApiKey(state.currApiKey))}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={copyToastVisible}
        autoHideDuration={3000}
        onClose={() => {
          setCopyToastVisibility(false);
        }}
        message={(
          <div className={apiStyle.toastWrapper}>
            <DoneIcon className={apiStyle.successIcon} fontSize="large" />
            copied
          </div>
        )}
        action={(
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => { setCopyToastVisibility(false); }}>
              <CloseIcon className={apiStyle.closeIcon} fontSize="small" />
            </IconButton>
          </React.Fragment>
        )}
      />
    </div>
  );
}


export default API;
