import React, { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import TopProgressBar from '../../../components/TopProgressBar'
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded'
import { updateLoading } from '../actions'
import { useHistory } from 'react-router-dom'
import { trackMixpanelEvents } from '../../../helpers/mixpanel'
import { useCurrentConnector } from '../helpers'

function Activate() {
    const { currentConnector, connectorSpec } = useCurrentConnector()
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        dispatch(updateLoading('updateConnectorStatus',0))
    }, [])

    const startSyncing = () =>{
        trackMixpanelEvents("connectors_started_mapping_connector")
        history.push(`/apps/view/${currentConnector.id}/mapping`)
    }

    return (
        <Fragment>
            <TopProgressBar progress="100" />
            {connectorSpec &&
            <div className="add-connector-stage-container">
                <div className="page-title">
                {connectorSpec.data_sync ? `Activated` : `Hurray!`}  
                </div>
                <div className="authenticate-block activate-block">
                    <div className="block-header">
                        <img src={connectorSpec.icon_url} className="connector-logo"/>
                        <span className="connector-name">
                        {currentConnector.name} connected successfully!
                        </span>
                    </div>
                    <div className="logos">
                        <div className="circle-box">
                            <img src="https://firebasestorage.googleapis.com/v0/b/plantt-ui-icons/o/plantt.png?alt=media" className="logo-icon" />
                        </div>
                        <div className="green-dots">
                            <span className="dot"><FiberManualRecordRoundedIcon fontSize="inherit" /></span>
                            <span className="dot"><FiberManualRecordRoundedIcon fontSize="inherit" /></span>
                            <span className="dot2"><FiberManualRecordRoundedIcon fontSize="inherit" /></span>
                        </div>
                        <div className="circle-box">
                            <img src={connectorSpec.icon_url} className="logo-icon" />
                        </div>
                    </div>
                    {(connectorSpec.data_sync && connectorSpec.need_mapping) ?
                        <span>
                            <div>
                                What can I do now? <br />                                
                                1. Start creating shortcuts with {connectorSpec.name}. <br />
                                2. Map {connectorSpec.name} Account fields and get all your data into Plantt. <br />
                            </div>
                            <div className="go-button-container">
                                <div className="dark-blue-button pointer start" onClick={startSyncing}>
                                    {`Map & Sync >`}
                                </div>
                                <div className="grey-button pointer" style={{fontSize:'14px'}} onClick={() => history.push(`/apps`)} >
                                    Sync later
                                </div>
                            </div>
                        </span> :
                            <span>
                                <div>
                                    <br />
                                What can I do now? <br />
                                You can start adding widgets and actions from {connectorSpec.name} inside your threads. <br />
                            </div>
                            <div className="go-button-container">
                                <div className="dark-blue-button pointer start" style={{width:'64px'}} onClick={() => history.push(`/apps`)} >
                                   OK   
                                </div>
                            </div>
                        </span>
                    }
                </div>                  
            </div>
        }
        </Fragment>
    )
}
export default Activate