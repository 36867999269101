import history from '../../utils/history';
import firebase from 'firebase/app';
import { getAccount, setLoginError, updateMember } from '../../containers/App/actions'
import { updateAccountMetadata } from '../MyProfile/actions'
import { trackMixpanelEvents } from '../../helpers/mixpanel'
import { NEW_sendPostRequest, sendPostRequest } from '../../apis/api-utils';
import { CREATE_ACCOUNT_URL, CREATE_ACCOUNT_HOOK_URL, BASE_URL } from '../../apis/constant';
import { setToastMessage } from '../App/actions';
import { getTrialMetadata } from '../../helpers';
import { parse } from 'query-string';

export const createAccount = account => async dispatch => {
  const response = await NEW_sendPostRequest(CREATE_ACCOUNT_URL, {}, JSON.stringify(account))
  await firebase.auth().currentUser.getIdToken(true)
  // Custom claims are added onto the user during account creation. getIdToken(true) forces
  // a token refresh to make sure the claims are not absent, otherwise threads fail.

  localStorage.setItem('PlanttAccountID', response.text.id)
  dispatch(getAccount())
  return response
}

export const finalizeRegistration = ({ name: contact_name, company: name }) => async (dispatch, getState) => {
  const { email, uid: firebase_owner_uid, displayName } = getState().firebase.auth
  const role = history.location.search.includes('chatbot') ? 'chat_bot' : 'lite'
  const accountData = { email, name, contact_name, metadata: getTrialMetadata(), firebase_owner_uid, role }

  try {
    if(contact_name !== displayName)
      await firebase.auth().currentUser.updateProfile({ displayName: contact_name })

    const response = await dispatch(createAccount(accountData))
    if(!response.ok)
      throw new Error(response.text)

    trackMixpanelEvents('workspace_created', accountData)
    //Something with Zapier
    try { await sendPostRequest(CREATE_ACCOUNT_HOOK_URL, {}, JSON.stringify(response.text)) } catch (err) { console.error(err) }

    if(!history.location.search.includes('chatbot')){
      const { redirect } = parse(window.location.search)
      return history.replace(redirect || '/')
    }

    await dispatch(updateAccountMetadata({ onboarding_status: 'welcome' }))
    return history.replace('/onboarding-chatbot/1')
  } catch (err) {
    console.error('Error creating user:', err)
  }
}

export const finalizeInvite = ({ email, invite_id, name }) => async (dispatch, getState) => {
  try {
    if(firebase.auth().currentUser)
      return console.warn('LOGGED IN')
    const fetched = await fetch(`${BASE_URL}account/invite/complete`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Connection': 'keep-alive', 'Keep-Alive': 'timeout=0, max=1000' },
      body: JSON.stringify({ email, invite_id, name })
    })

    if(!fetched.ok)
      throw new Error(await fetched.text())

    const { token } = await fetched.json()

    await firebase.auth().signInWithCustomToken(token)
    trackMixpanelEvents('member_added_to_workspace')
    history.replace('/')
  } catch (error) {
    console.error('Error completing Invite', error)
    if(!error.message.includes('email invalid'))
      dispatch(setToastMessage('We ran into some trouble. Please try again', 'error'))
    return { error }
  }
}
