
  import { produce } from "immer"
  import {
    FETCH_INTEGRATION_FOR_EDIT_SUCCESS,
    FETCH_INTEGRATION_ERROR,
    UPDATE_INTEGRATION_SUCCESS,
    UPDATE_INTEGRATION_ERROR,
    DELETE_INTEGRATION_SUCCESS,
    DELETE_INTEGRATION_ERROR,
    FETCH_AVAILABLE_INTEGRATIONS_SUCCESS,
    FETCH_AVAILABLE_INTEGRATIONS_ERROR,
    FETCH_CURRENT_INTEGRATIONS_SUCCESS,
    FETCH_CURRENT_INTEGRATIONS_ERROR,
    CREATE_INTEGRATION_SUCCESS,
    FETCHING_CURRENT_INTEGRATIONS,
    FETCHING_AVAILABLE_INTEGRATIONS,
    CLEAR_INTEGRATION_FOR_EDIT
  } from './constants';

  // The initial state of the App
  export const initialState = {
    integrationSpecs: {},
    specsFetched:false,
    availableIntegrations: {},
    currentIntegrations: [],
    channels: [],
    editing: null,
    loaded: { current: false, available: false }
  };
  
  const appReducer = produce((draft, action) => {
  
    switch (action.type) {
      case 'UPDATE_SPECS': {
        draft.integrationSpecs = action.data
        draft.specsFetched = true

      return
      }

      case FETCH_INTEGRATION_FOR_EDIT_SUCCESS: {
        draft.editing = action.integration;
        return
      }

      case CLEAR_INTEGRATION_FOR_EDIT: {
        draft.editing = null;
        return
      }
  
      case FETCH_INTEGRATION_ERROR: {
        draft.error = action.error;
        return
      }

      case CREATE_INTEGRATION_SUCCESS: {
        draft.loaded = { channels: false, current: false };
        return
      }

      case UPDATE_INTEGRATION_SUCCESS: {
        const type = action.integration.type === 'channel'
          ? 'channels'
          : 'currentIntegrations';
        draft[type] = draft[type].map(integration => {
          if(action.integration.id !== integration.id) return integration;
          return action.integration;
        })
        return
      }
  
      case UPDATE_INTEGRATION_ERROR: {
        draft.error = action.error;
        return
      }

      case DELETE_INTEGRATION_SUCCESS: {
        ['currentIntegrations','channels'].forEach(type => {
          draft[type] = draft[type].filter(integration => integration.id !== action.id);
        })
        return
      }
  
      case DELETE_INTEGRATION_ERROR: {
        draft.error = action.error;
        return
      }

      case FETCHING_AVAILABLE_INTEGRATIONS: {
        draft.loaded.available = false;
        return
      }
  
      case FETCH_AVAILABLE_INTEGRATIONS_SUCCESS: {
        const integrations = {};
        action.integrations.forEach(i => integrations[i.id] = i);
        draft.availableIntegrations = integrations;
        draft.loaded.available = true;
        return
      }
  
      case FETCH_AVAILABLE_INTEGRATIONS_ERROR: {
        return
      }
      
      case FETCHING_CURRENT_INTEGRATIONS: {
        Object.assign(draft.loaded, { channels: false, current: false });
        return
      }

      case FETCH_CURRENT_INTEGRATIONS_SUCCESS: {
        const filtered = type => action.integrations.filter(i => i.type === type);
        Object.assign(draft, { channels: filtered('channel'), currentIntegrations: filtered('integration') });
        draft.loaded.current = true;
        return
      }
  
      case FETCH_CURRENT_INTEGRATIONS_ERROR: {
        return
      }

    }
  }, initialState)
  
  export default appReducer;
  