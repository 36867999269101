import React from 'react';
import './style.scss';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import injectReducer from '../../utils/injectReducer';
import Install from './partials/Install'
import Settings from './partials/Settings'
import HandleUserDecision from './HandleUserDecision'
import reducer from './reducer';
import { withFirebase } from '../../components/Firebase';
import { Switch, Route, useHistory } from 'react-router-dom';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';

function Integrations() {
  const history = useHistory()
  const loading = useSelector(state => state.global.loading.connectors || state.global.loading.connectorSpecs)

  return (
    <div className="integrations-page-contianer">
      <Switch>
        <Route path="/inte/:integrationName/install/handle-user-decision">
          {!loading ? <HandleUserDecision /> : <LoadingIndicator standalone />}
        </Route>
        <Route path="/inte/:integrationName/install">
          <Install afterNonOAuth={() => history.push('/settings/integrations')}/>
        </Route>
        <Route path="/inte/:integrationId/settings">
          <Settings />
        </Route>
      </Switch>
    </div>
  )
}

const withReducer = injectReducer({ reducersList: [{ key: 'integrations', reducer }, ] })

export default compose(withReducer, withFirebase)(Integrations)
