import { compose } from 'redux';
import injectReducer from '../../../utils/injectReducer';
import reducer from '../reducer';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import RemoveDialog from './RemoveDialog';
import { fetchAvailableIntegrations, fetchCurrentIntegrations, deleteIntegration } from '../actions';
import { IntegrationItem, renderInput, renderOption } from './SettingsTabElems';
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator';
import { useHistory } from 'react-router-dom';
import { useLayoutConfig } from '../../../components/PlanttLayout/LayoutConfig';

const style = makeStyles(() => ({
  row: {
    paddingLeft: 23,
    paddingRight: 23,
  },
  channelTitle: {
    fontFamily: 'Helvetica',
    fontSize: 18,
    color: '#19270d'
  },
}));

function IntegrationSettings() {
  useLayoutConfig({ title: 'Settings / Integrations' })

  const dispatch = useDispatch()
  const history = useHistory()
  const { availableIntegrations, currentIntegrations, loaded, channels } = useSelector(state => state.integrations)
  const [nowEditing, setNowEditing] = useState()
  const integrationsStyle = style()

  useEffect(() => {
    dispatch(fetchAvailableIntegrations())
    dispatch(fetchCurrentIntegrations())
  }, []);

  const [nowRemoving, setNowRemoving] = useState();

  const confirmationDialogTitle = () => {
    if(nowRemoving){
      const { integration } = nowRemoving;
      const type = integration.type==='channel' ? 'channel' : 'integration';
      return `Are you sure you want to remove the ${type} ${integration.name}?`
    }
  }

  const removeIntegration = async () => {
    setNowRemoving({ ...nowRemoving, loading: true });
    await dispatch(deleteIntegration(nowRemoving.integration.id));
    setNowRemoving();
  }

  const renderIntegrations = () => {
    const types = {
      channel: channels,
      integration: currentIntegrations,
    }
    return Object.keys(types).map(type => {
        return types[type].length ? (
          <React.Fragment key={type}>
            <Grid container className='integrations-section'>
              <Grid item xs={12} className='section-title'>
                {type.charAt(0).toUpperCase() + type.slice(1) + 's'}
              </Grid>
            </Grid>
            {types[type].map((integration, index) => {
              return <IntegrationItem
                key={integration.id}
                integration={integration}
                nowEditing={nowEditing}
                setNowEditing={setNowEditing}
                remove={() => setNowRemoving({ integration, loading: false })}
                index={index}
              />;
            })}
          </React.Fragment>
        ) : null;
    });
  }

  const loading = !loaded.current || !loaded.available;
  
  return (
    <div className={`setting-section ${loading ? 'section-loading' : ''}`}>
      {loading && <LoadingIndicator />}
      <Grid container>
        <Grid container className={`${integrationsStyle.row} mb-3`}>
          <Grid item xs={12} className={integrationsStyle.channelTitle}>Integrations</Grid>
          <Autocomplete
            className='integration-search'
            fullWidth={true}
            id="combo-box-demo"
            options={Object.values(availableIntegrations)}
            getOptionLabel={(option) => option.name}
            style={{ width: '95%', padding: 0 }}
            renderOption={renderOption}
            onChange={(e,v) => history.push(`/inte/${v.id}/install`)}
            renderInput={renderInput}
          />
        </Grid>
        {!loading && renderIntegrations()}
      </Grid>
      <RemoveDialog
        loading={!nowRemoving || nowRemoving.loading}
        open={typeof nowRemoving !== 'undefined'}
        onClose={() => setNowRemoving()}
        title={confirmationDialogTitle()}
        onRemove={removeIntegration}
        continueText="Remove"
      />
    </div>
  );
}

const withReducer = injectReducer({ key: 'integrations', reducer });

export default compose(withReducer)(IntegrationSettings);