import { useState } from "react"
import { useSelector } from "react-redux"
import { updateNoteTag } from "../actions"

function RenderTags({ className, setInteractionMenu, readonly = false }){
  const focusedEditor = useSelector(state => state.notes.focusedEditor)
  const gist = useSelector(state => state.notes.currentNote)

  const [deleteHovered, setDeleteHovered] = useState(false)

  const openActionMenu = e => setInteractionMenu({
    interaction: 'tags',
    anchor: e.target,
    selection: null,
    editor: null,
    id: focusedEditor || null
  })

  return (
    <div className={className} id='notebook-note-tags'>
      {gist.metadata?.tags?.map(tag => (
        <div key={tag} className={`note-tag ${readonly ? 'no-delete' : ''}`}>
          <i className='fad fa-hashtag' />
          {tag}
          {!readonly &&
          <i
            className={`${deleteHovered ? 'fas' : 'fal'} fa-times-circle`}
            onMouseEnter={() => setDeleteHovered(true)}
            onMouseLeave={() => setDeleteHovered(false)}
            onClick={() => updateNoteTag(tag)}
          />}
        </div>
      ))}
      {!readonly &&
      <div id='note-add-tag' onClick={openActionMenu}>
        <i className='fad fa-hashtag' /><i className='fas fa-plus-circle' />
      </div>}
    </div>
  )
}

export default RenderTags