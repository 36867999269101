import { ReactEditor } from "cs-slate-react"
import { Node, Transforms } from "cs-slate"
import { Popper } from "@material-ui/core"
import { useEffect, useMemo, useState } from "react"

const findMatch = (editor, test) => {
  const { selection } = editor
  let selected = null
  if(selection !== null && selection.anchor !== null){
    selected = editor
    const { path } = selection.anchor
    for(const step of path){
      selected = selected.children[step]
      if(test(selected))
        return { node: selected, path }
    }
  }
  return {}
}

function LinkToolbar({ editor, setLinkModal }){
  const [linkToolbar, setLinkToolbar] = useState()
  const href = useMemo(() => {
    if(!linkToolbar)
      return

    const { href } = linkToolbar.anchorEl
    if(href[href.length - 1] === '/')
      return href.slice(0, href.length - 1)
    return href
  }, [linkToolbar])

  useEffect(() => {
    const { node, path } = findMatch(editor, node => node?.type === 'link')
    if(linkToolbar?.node !== node){
      if(node){
        try{
          setLinkToolbar({ anchorEl: ReactEditor.toDOMNode(editor, node), node, path: path.slice(0, path.length - 1) })
        } catch(error) {
          console.warn({ error })
        }
      }else if(linkToolbar){
        setLinkToolbar(null)
      }
    }
  }, [editor.selection])

  const remove = e => {
    setLinkToolbar(null)
    requestAnimationFrame(() => {
      const node = Node.get(editor, linkToolbar.path)
      Transforms.removeNodes(editor, { at: linkToolbar.path })
      // if(findMatch(editor, node => node.type.includes('-list')))
      //   Transforms.move(editor, { distance: 1 })
      if(node.children)
        Transforms.insertNodes(editor, node.children)
    })
  }

  const change = e => {
    Transforms.select(editor, linkToolbar.path)
    setLinkModal({ selection: null, text: linkToolbar.anchorEl.innerText, href, path: linkToolbar.path })
  }

  return linkToolbar ? (
    <Popper open={true} anchorEl={linkToolbar.anchorEl} placement='bottom-start'>
      <div className='link-toolbar card' onMouseDown={e => e.preventDefault()}>
        Go to link: <a href={href} target='_blank' rel='noreferrer'>{href}</a>
        {/* <span className='separator'></span>
        <a onClick={change}>Change</a> */}
        <span className='separator'></span>
        <a onClick={remove}>Remove</a>
      </div>
    </Popper>
  ) : null
}

export default LinkToolbar