import { useSelector } from 'react-redux'
import Lightbulb from '../images/discover/lightbulbAlert.svg'

function SourceIcon({ source, size, style = {} }){
  const connectorSpecs = useSelector(state => state.global.connectorSpecs)
  const exceptions = { google_calendar: 'google', zoom: 'zoom' }   // Exceptions are temp!!!
  const icon = Object.keys(exceptions).includes(source)
    ? `https://firebasestorage.googleapis.com/v0/b/plantt-ui-icons/o/${exceptions[source]}.svg?alt=media`
    : connectorSpecs && connectorSpecs.find(connector => connector.id === source)?.icon_url

  return source !== 'contact'
    ? (source !== 'loading'
        ? <img src={icon} className={`source-icon ${source}`} style={size ? { width: size, ...style } : undefined} />
        : <img src={Lightbulb} className='icon-placeholder' />
      )
    : null
}

export default SourceIcon