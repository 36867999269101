import { forwardRef, useState } from "react"
import TooltipComponent from "../Tooltip/new"

import './style.scss'

function HoverableIcon({ className, icon, type = 'fal', active, activeType = 'fas', activeIcon, size, tooltipProps, ...props }, ref){
  const [hovered, setHovered] = useState(false)
  const isActive = active || hovered

  return (
    <HoverableIconWrapper tooltipProps={tooltipProps}>
      <div style={{ width: size, height: size }} className='hoverable-icon-wrapper' onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} ref={ref} {...props}>
        <i
          className={` ${isActive ? `active ${activeType}` : `${type}`} fa-${(isActive && activeIcon) || icon} ${className || ''}`}
          style={{ fontSize: size && size / 2 }}
        />
      </div>
    </HoverableIconWrapper>
  )
}

function HoverableIconWrapper({ children, tooltipProps }) {
  if (!tooltipProps)
    return children
  
  return (
    <TooltipComponent placement='bottom' lean enterDelay={250} enterNextDelay={250} {...tooltipProps}>
      <div>{children}</div>
    </TooltipComponent>
  )
}


export default forwardRef(HoverableIcon)