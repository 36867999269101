import FontFaceObserver from 'fontfaceobserver';

/* istanbul ignore next */
// Observe loading of Lato (to remove lato, remove the <link> tag in
// the index.html file and this observer)
const latoObserver = new FontFaceObserver('Lato', {});

/* istanbul ignore next */
// When Lato is loaded, add a font-family using Lato to the body
const registerLato = () => latoObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
}, () => {
  document.body.classList.remove('fontLoaded');
});

export { registerLato };
