import { useState } from "react"
import { useSelector } from "react-redux"
import { store } from "../../../.."
/* eslint-disable react-hooks/rules-of-hooks */

import HoverableIcon from "../../../../components/HoverableIcon"
import serialize from "../../../../components/TextEditor/serialize"
import TooltipComponent from "../../../../components/Tooltip/new"
import UserAvatar from "../../../../components/UserAvatar"
import { applyUserReaction, setBlock } from "../../actions"
import { TO_DO_ITEM } from "../../constants"
import { contactDetailsSelector } from "../../helpers"
import EditorBlock from "../Editor"
import ItemizedBlockField from "./Field"

function PollBlock({ readonly } = {}) {
  return ({ dragPreview, contactDetails, ...props }) => {
    const { block: { payload, reactions }, blockId } = props
    
    const { id, name, type } = useSelector(contactDetailsSelector(contactDetails))
    const setQuestion = text => setBlock(blockId, { payload: { ...payload, question: { text: serialize(text) } } })
    const [mode, setMode] = useState(payload.question.auto_focus ? 'config' : 'view')

    const togglePollResponse = itemId => {
      const currentResponse = reactions?.response?.[id]
      applyUserReaction(
        { response: !currentResponse || currentResponse.id !== itemId ? { name, id: itemId, [type]: true } : null },
        blockId
      )
    }

    return (
      <div ref={dragPreview} className={`note-itemized-block poll${mode === 'view' ? ' view' : ' '}`}>
        {!readonly &&
        <div className='toggle-mode'>
          <HoverableIcon type='far' active={mode === 'view'} icon='eye' onClick={() => setMode('view')} />
          <HoverableIcon type='far' active={mode === 'config'} icon='cog' onClick={() => setMode('config')} />
        </div>}
        <div className='poll-question'>
          {mode !== 'view'
            ? <EditorBlock
                {...props}
                blockId={`${blockId}/question`}
                auto_focus={payload.question.auto_focus}
                value={payload.question.text}
                setValue={setQuestion}
                placeholder='What would you like to ask?'
              />
            : (payload.question.text || <div className='placeholder'>Poll question</div>)
          }
        </div>
        {payload.fields.map((item, index) => {
          const isLast = index === payload.fields.length - 1
          if(mode === 'view' && isLast)
            return null

          const responses = Object.values(reactions?.response || {}).filter(r => r.id === item.id)

          return mode !== 'view'
            ? <ItemizedBlockField
                key={item.id}
                type={TO_DO_ITEM}
                placeholder={isLast ? 'Type here to add an option' : `Poll option ${String.fromCharCode(97 + index).toUpperCase()}`}
                {...{ index, ItemLeft, isLast, ...item, ...props }}
              />
            : <div key={item.id} className={`poll-option ${!item.label ? 'placeholder' : ''}`} onClick={() => togglePollResponse(item.id)}>
                <ItemLeft index={index} />
                {item.label || `Poll option ${String.fromCharCode(97 + index).toUpperCase()}`}

                {responses.length > 0 &&
                <TooltipComponent
                  theme={{ color: '#155788', backgroundColor: '#fff', borderColor: '#155788' }}
                  title={`Reponse selected by ${responses.length} ${responses.length > 1 ? 'people' : 'person'}`}
                  description={<ResponseDisplay responses={responses} />}
                  placement='bottom'
                >
                  <div className='poll-option-responses'>
                    {responses.map(({ name }) => <UserAvatar name={name} size={28} />)}  
                  </div>
                </TooltipComponent>}
              </div>
        })}
      </div>
    )
  }
}

function ResponseDisplay({ responses }){
  return responses.map(({ name }) => (
    <div className='poll-response-display-item' key={name}>
      <UserAvatar name={name} size={28} />
      {name}
    </div>
  ))
}

function ItemLeft({ index, isLast }){
  return (
    <div className='poll-option-indicator'>
      {!isLast
        ? String.fromCharCode(97 + index)
        : <i className='fal fa-plus' />
      }
    </div>
  )
}

export default PollBlock