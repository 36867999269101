import { ReactEditor } from "cs-slate-react"
import cloneDeep from 'lodash/cloneDeep'
import { defineIndentation } from './helpers'
export const computeParagraphLines = (p, editorWidth, direction, destenationEditor) => {
    const lines = []
    const tempWidth = editorWidth
    let tempLineWidth = 0
    let tempstring = ''
    let offset = 0
    for(const node of p.children) {
        const arr = direction === 'up' ? node.text.split(" ") : node.text.split(" ").reverse()
        for(const [index, char] of arr.entries()) {
        const relevantChar = index === arr.length-1 ? char : `${char} `
        const charLength = computeNodeWidth(relevantChar, node, destenationEditor)
        if(tempLineWidth + charLength < editorWidth) {
            tempLineWidth += charLength
            tempstring += char + ' '
        }
        else {
            lines.push({
            offsetEnd:offset,
            lineWidth:tempLineWidth,
            tempstring
            })
            tempLineWidth = charLength
            tempstring = `${char} `
        }
        offset += char.length
        }
    }
    if(tempLineWidth !== 0)
        lines.push({
        offsetEnd:offset,
        lineWidth:tempLineWidth,
        text:tempstring 
        })
    return lines
}
export const computeEditorNodesWidth = (nodes, destenationEditor) => {
    const nodesCopy = cloneDeep(nodes)
    const newNodes = {}
    //add width property to every node
    for(const [index, node] of nodes.entries()) {
      if(node.children && node.children.length) {
        const newChildren = computeEditorNodesWidth(node.children, destenationEditor)
        nodesCopy[index]['children'] = newChildren
        let combinedWidth = 0
        newChildren.forEach(child => combinedWidth += child.width)
        nodesCopy[index]['width'] = combinedWidth
      }
      else {
        nodesCopy[index]['width'] = computeNodeWidth(node.text, node, destenationEditor)
      }
    }
    return nodesCopy
}
export const computeNodeWidth = (text, properties, editor) =>{
    let fontStyle = ''
    if(properties.italic) fontStyle += 'italic '
    if(properties.bold) fontStyle += 'bold '
    const newEditor = ReactEditor.toDOMNode(editor, editor.children[0])
    const editorStyles = getComputedStyle(newEditor)
    fontStyle += `${editorStyles.fontSize} ${editorStyles.fontFamily}`
    return getTextWidth(text, fontStyle)
} 
export const getTextWidth = (text, font) => {
    // if given, use cached canvas for better performance
    // else, create new canvas
    var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
    var context = canvas.getContext("2d");
    context.font = font;
    var metrics = context.measureText(text);
    //console.log(metrics)
    return metrics.width;
}
export const getCaretTopPoint =  () => {
    const sel = document.getSelection()
    const r = sel.getRangeAt(0)
    let rect
    let r2
    // supposed to be textNode in most cases
    // but div[contenteditable] when empty
    const node = r.startContainer
    const offset = r.startOffset
    if (offset > 0) {
      // new range, don't influence DOM state
      r2 = document.createRange()
      r2.setStart(node, (offset - 1))
      r2.setEnd(node, offset)
      // https://developer.mozilla.org/en-US/docs/Web/API/range.getBoundingClientRect
      // IE9, Safari?(but look good in Safari 8)
      rect = r2.getBoundingClientRect()
      return { left: rect.right, top: rect.top }
    } else if (offset < node.length) {
      r2 = document.createRange()
      // similar but select next on letter
      r2.setStart(node, offset)
      r2.setEnd(node, (offset + 1))
      rect = r2.getBoundingClientRect()
      return { left: rect.left, top: rect.top }
    } else { // textNode has length
      // https://developer.mozilla.org/en-US/docs/Web/API/Element.getBoundingClientRect
      rect = node.getBoundingClientRect()
      const styles = getComputedStyle(node)
      const lineHeight = parseInt(styles.lineHeight)
      const fontSize = parseInt(styles.fontSize)
      // roughly half the whitespace... but not exactly
      const delta = (lineHeight - fontSize) / 2
      return { left: rect.left, top: (rect.top + delta) }
    }
}

