import { useSelector } from "react-redux"
import { Route, Switch, useLocation, useParams } from "react-router-dom"
import { OrangeButton } from "../../components/Button"
import { useLayoutConfig } from "../../components/PlanttLayout/LayoutConfig"
import Scrollable from "../../components/Scrollable"
import injectReducer from "../../utils/injectReducer"
import accountsReducer from "../Account/reducer"
import GistList from "./GistList"
import ViewGist from "./ViewGist"

import PlanttFocus from '../../images/PlanttFocus.svg'

import './style.scss'
import { createNote } from "../GistEditor/actions"

function Gists(){
  const { noteId } = useParams()
  useLayoutConfig(!noteId ? { title: 'Threads' } : undefined)
  return (
    <Switch>
      <Route path={`/:workspaceId(.{36})/:noteId`} component={ViewGist} />
      <Route path=''>
        <div className='account-overview'>
          <Scrollable id='note-editor-content' className='is-overview'>
            <div className='notebook-note-block' id='gists-page-header'>
              <OrangeButton rounded padding='8px 12px 8px 8px' onClick={() => createNote()}><i className='fas fa-plus' /> New Thread</OrangeButton>
            </div>
            <GistList EmptyState={EmptyState} />
          </Scrollable>
        </div>
      </Route>
    </Switch>
  )
}

function EmptyState(){
  const location = useLocation()
  return (
    <div id='page-empty-state'>
      <h2>You have no {location.pathname === '/starred' && 'starred'} threads</h2>
      <h3 id='enjoy-your-day'><span onClick={() => createNote()}>Create a new thread</span> to get going</h3>
      <img src={PlanttFocus} />
    </div>
  )
}

export default injectReducer({ key: 'accounts', reducer: accountsReducer })(Gists)