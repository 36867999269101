import { format } from "date-fns"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

const useTasks = () => {
  const { view } = useParams()
  const memberTasks = useSelector(state => Object.entries(state.tasks.memberTasks).filter(task => view === 'completed' ? task[1].completed : !task[1].completed))

  if(view === 'by-date')
    return memberTasks
    .reduce((sorted, [id, task]) => {
      const date = task.datetime ? format(new Date(task.datetime), 'yyyy-MM-dd') : 'noDate'
      let group = sorted.find(item => item.date === date)
      if(!group){
        group = { date, tasks: [] }
        sorted.push(group)
      }
      group.tasks.push({ ...task, id })
      return sorted
    }, [])
    .sort((a, b) => a.date === 'noDate' ? 1 : b.date === 'noDate' ? -1 : new Date(a.date) - new Date(b.date))

  return memberTasks.length > 0 ? [{ tasks: memberTasks.map(([id, task]) => ({ ...task, id })) }] : []
}

export default useTasks