import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useDispatch } from "react-redux"
import { firebase } from "../.."
import GoogleSignIn from "./partials/GoogleSignIn"

import RenderPage from './partials/RenderPage'
import FinalizeRegistration from './partials/FinalizeRegistration'
import { setLoginError } from '../App/actions'
import LoginContext from './LoginContext'
import useLogin from './useLogin'
import { Helmet } from 'react-helmet'
import Scrollable from '../../components/Scrollable'

import './style.scss'
import { upperFirst } from '../../helpers'
import { finalizeSignIn } from './helpers'

import Logo from '../../images/logo.svg'
import LoadingBar from '../../components/LoadingBar'
import TooltipComponent from '../../components/Tooltip/new'
import EmailSent from './partials/EmailSent'

function LoginPage(){
  const [state, setState] = useState({ email: '' })
  const [loading, setLoading] = useState(false)
  const [resetStatus, setResetStatus] = useState(null)
  const [errors, setErrors] = useState({ email: false, failed: false })

  const { search, pathname } = useLocation()
  const { Component, title } = useMemo(() => {
    let Component
    if(search.includes('email=') || pathname.startsWith('/invite'))
      Component = FinalizeRegistration
    else switch(pathname){
      case '/email-sent':
        Component = EmailSent; break
      case '/signin':
        Component = SignIn; break
      case '/signup':
        Component = SignUp; break
      case '/login':
        Component = Login; break
      case '/password-reset':
        Component = Reset; break
    }
    return { Component, title: pathname.split('/')[1].split('-').map(s => upperFirst(s)).join(' ') }
  }, [search, pathname])

  if(!Component)
    return null

  return (
    <LoginContext.Provider value={{ state, setState, loading, setLoading, resetStatus, setResetStatus, errors, setErrors }}>
      <Scrollable id='new-login-page'>
        <Helmet defer={false}>
          <title>{title} - Plantt Console</title>
          <meta name="description" content="Plantt - Signup" />
        </Helmet>
        <Component />
      </Scrollable>
    </LoginContext.Provider>
  )
}

function SignIn(){
  const { state, setLoading } = useContext(LoginContext)
  const [askForEmail, setAskForEmail] = useState(false)
  useEffect(async () => {
    if((await finalizeSignIn()) === 'no_email')
      setAskForEmail(true)
  }, [])

  return (
    <>
      <div style={{ width: '100%' }}><LoadingBar loading={!askForEmail} /></div>
      {!askForEmail
        ? <div id='loading-logo'><img src={Logo} /></div>
        : <RenderPage
            heading={{ className: 'is-reset', text: 'Enter your email address' }}
            onSubmit={async e => {
              e.preventDefault()
              setLoading(true)
              await finalizeSignIn(state.email)
            }}
            bottom={(
              <>
                <div id='sign-in-alt'>
                  Why am I being asked for my email again?
                  <TooltipComponent placement='right' lean content='This can happen when a link was requested on another device'>
                    <div style={{ display: 'inline', marginLeft: 8, fontSize: 16 }}>
                      <i className='fad fa-info-circle' style={{ transform: 'translateY(1px)' }} />
                    </div>
                  </TooltipComponent>
                </div>
              </>
            )}
            continueText='Continue'
          />
      }
    </>
  )
}

function Login(){
  const dispatch = useDispatch()
  const handleLogin = useLogin()

  useEffect(() => {
    dispatch(setLoginError(0))
  }, [])

  return (
    <RenderPage
      heading={{ text: 'Sign in to Plantt' }}
      top={{ GoogleComponent: GoogleSignIn }}
      bottom={(
        <Fragment>
          <Link to='/password-reset' id='forgot-password'>Forgot password?</Link>
          <div id='sign-in-alt'>Don't have an account? <Link to='/signup'>Signup</Link></div>
        </Fragment>
      )}
      onSubmit={handleLogin}
      hasPassword
    />
  )
}

function Reset(){
  const { state, setLoading, resetStatus, setResetStatus } = useContext(LoginContext)

  return (
    <RenderPage
      heading={{ className: 'is-reset', text: resetStatus !== 'success' ? 'Forgot your password?' : 'Instructions sent!' }}
      onSubmit={async e => {
        e.preventDefault()
        setLoading(true)
        try {
          await firebase.auth.sendPasswordResetEmail(state.email)
          setResetStatus('success')
        } catch(error) {
          setResetStatus(error.code)
        }
        setLoading(false)
      }}
      continueText='Send me instructions'
      onUpdate={() => resetStatus ? setResetStatus(null) : null}
    />
  )
}

function SignUp(){
  const handleLogin = useLogin({ isSignup: true })

  return (
    <RenderPage
      heading={{ className: 'is-signup', text: 'First, enter your email' }}
      top={{ GoogleComponent: GoogleSignIn }}
      onSubmit={handleLogin}
      bottom={<div id='sign-in-alt' style={{ marginTop: 20 }}>Already have an account? <Link to='/login'>Login</Link></div>}
    />
  )
}

export default LoginPage
