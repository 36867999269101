import { DELETE_CONNECTOR, UPDATE_CONNECTOR } from '../Apps/constants';
import {
  SET_FULL_ACCOUNT_DATA,
  SET_APP_DIMENSIONS,
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
  SET_LOGIN_ERROR,
  SET_GLOBAL_LOADING,
  SET_WORKSPACE_MEMBERS,
  FETCH_ONBOARDING_STATUSES_SUCCESS,
  FETCH_CONNECTOR_SPECS_SUCCESS,
  FETCH_CONNECTORS_SUCCESS,
  SYNC_GOOGLE_COMPLETE,
  SYNC_GOOGLE_ERROR,
  FETCH_SIGNALS_SUCCESS,
  SET_SEGMENTS,
  UPDATE_SEGMENT_DISPLAYED_COLUMNS,
  FETCH_ALL_FIELDS_SUCCESS,
  SAVE_ACCOUNT_FIELD_CHANGES_SUCCESS,
  DELETE_FIELD_SUCCESS,
  FETCH_ACCOUNTS_SUCCESS,
  UPDATE_MEMBER,
  TOGGLE_SHOW_GOOGLE_BANNER,
  UPDATE_WATCHER_DISPLAYED_COLUMNS,
  FETCH_WATCHERS_SUCCESS,
  DELETE_WATCHER_SUCCESS,
  FETCH_ACTIONS_SUCCESS,
} from './constants';

// The initial state of the App
export const initialState = {
  allActions: [],
  allSignals: [],
  availableWorkspaces: [],
  connectors: [],
  connectorSpecs: [],
  currentMember: {},
  dimensions: {},
  error: false,
  fieldLists: { accounts: [], contacts: [] },
  loading: { user: true, page: false, segments: true, signals: true, menuAccounts: true, connectors: true, connectorSpecs: true, watchers: true },
  loginError:0,
  menuAccounts: [],
  notifications: [],
  onboardingStatuses: {},
  segments: [],
  watchers: [],
  showGoogleBanner: false,
  workspace: null,
  workspaceMembers: []
}

function appReducer(state = initialState, action) {
  const newLoading = loading => ({ loading: { ...state.loading, ...loading } })
  switch (action.type) {
    case SET_GLOBAL_LOADING: {
      return { ...state, ...newLoading(action.payload) }
    }

    case TOGGLE_SHOW_GOOGLE_BANNER: {
      return { ...state, showGoogleBanner: action.payload }
    }

    case SET_FULL_ACCOUNT_DATA: {
      const newState = { ...state, workspace: action.account, ...newLoading({ user: false }) };
      const { additionalData } = action;
      for(const item in additionalData)
        if(additionalData[item])
          newState[item] = additionalData[item];
      return newState;
    }

    case ENQUEUE_SNACKBAR: {
      const notifications = [ ...state.notifications ]
      notifications.push({ key: action.key, ...action.notification })
      return { ...state, notifications }
    }

    case REMOVE_SNACKBAR: {
      const notifications = state.notifications.filter(notification => notification.key !== action.key)
      return {...state, notifications }
    }

    case CLOSE_SNACKBAR: {
      const notifications = state.notifications.map(notification => {
        return action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
      })
      return { ...state, notifications }
    }

    case SET_LOGIN_ERROR: 
      return { ...state, loginError:action.errorId };
    
    case SET_WORKSPACE_MEMBERS: {
      return { ...state, workspaceMembers: action.members }
    }

    case FETCH_ONBOARDING_STATUSES_SUCCESS: {
      return { ...state, onboardingStatuses: action.statuses }
    }
    
    case FETCH_SIGNALS_SUCCESS: {
      return { ...state, allSignals: action.signals, ...newLoading({ signals: false }) }
    }

    case SET_SEGMENTS: {
      return { ...state, segments: action.segments, ...newLoading({ segments: false }) }
    }
    
    case UPDATE_SEGMENT_DISPLAYED_COLUMNS: {
      const segments = [ ...state.segments ]
      segments[action.segmentIndex] = { ...segments[action.segmentIndex], displayed_columns: action.columns }
      return { ...state, segments }
    }

    case FETCH_CONNECTORS_SUCCESS: {
      return { ...state, connectors: action.connectors, ...newLoading({ connectors: false }) }
    }

    case FETCH_CONNECTOR_SPECS_SUCCESS: {
      return { ...state, connectorSpecs: action.connectors, ...newLoading({ connectorSpecs: false }) }
    }

    

    case SYNC_GOOGLE_COMPLETE: {
      return { ...state, onboardingStatuses: { ...state.onboardingStatuses, sync_google: true }, showGoogleBanner: true }
    }
    case SYNC_GOOGLE_ERROR: {
      return { ...state, onboardingStatuses: { ...state.onboardingStatuses, sync_google: false } }
    }

    case FETCH_ALL_FIELDS_SUCCESS: {
      return { ...state, fieldLists: action.fieldLists }
    }

    case SAVE_ACCOUNT_FIELD_CHANGES_SUCCESS:
    case DELETE_FIELD_SUCCESS: {
      const fields = [ ...state.fieldLists.accounts ]
      const index = state.fieldLists.accounts.findIndex(field => field.key === action.field.key)
      if(action.delete)
        fields.splice(index, 1)
      else
        fields[index] = action.field
        
      return { ...state, fieldLists: { ...state.fieldLists, accounts: fields } }
    }

    case FETCH_ACCOUNTS_SUCCESS: {
      return { ...state, menuAccounts: action.records, ...newLoading({ menuAccounts: false }) }
    }

    case UPDATE_MEMBER: {
      const newMembers = [ ...state.workspaceMembers ]
      const memberIndex = newMembers.findIndex(m => m.id === action.member.id)
      newMembers[memberIndex] = action.member
      return { ...state, workspaceMembers: newMembers }
    }

    case FETCH_WATCHERS_SUCCESS: {
      return { ...state, watchers: action.watchers, ...newLoading({ watchers: false }) }
    }
    case DELETE_WATCHER_SUCCESS: {
      return { ...state, watchers: state.watchers.filter(watcher => watcher.id !== action.watcherId) }
    }
    case UPDATE_WATCHER_DISPLAYED_COLUMNS: {
      const watchers = [ ...state.watchers ]
      watchers[action.watcherIndex] = { ...watchers[action.watcherIndex], displayed_columns: action.columns }
      return { ...state, watchers }
    }
    case SET_APP_DIMENSIONS: {
      return { ...state, dimensions: action.dimensions }
    }

    case DELETE_CONNECTOR: {
      const connectors = [ ...state.connectors ]
      const index = connectors.findIndex(c => c.id === action.connectorId)
      const { spec_ref } = connectors[index]
      connectors.splice(index, 1)

      let allActions = state.allActions
      if(!connectors.find(c => c.spec_ref === spec_ref) && allActions.find(a => a.spec_ref === spec_ref))
        allActions = allActions.map(action => action.spec_ref === spec_ref && action.enabled ? { ...action, enabled: 0 } : action)

      return { ...state, connectors, allActions }
    }
    case UPDATE_CONNECTOR: {
      const connectors = [ ...state.connectors ]
      const index = connectors.findIndex(c => c.id === action.connector.id)
      action.connector.records = connectors[index]?.records // TEMP! Records arrive only when getting all connectors...
      if(index >= 0)
        connectors[index] = action.connector
      else
        connectors.push(action.connector)

      const { spec_ref } = action.connector
      let allActions = state.allActions
      if(allActions.find(a => a.spec_ref === spec_ref))
        allActions = allActions.map(action => action.spec_ref === spec_ref && !action.enabled ? { ...action, enabled: 1 } : action)

      return { ...state, connectors, allActions }
    }

    case FETCH_ACTIONS_SUCCESS: {
      return { ...state, allActions: action.actions }
    }

    default:
      return state
  }
}

export default appReducer;
