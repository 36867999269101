import history from '../../../utils/history'
import { NEW_sendPostRequest } from '../../../apis/api-utils';
import { BASE_URL2 } from '../../../apis/constant'
import { setUniqueIdentifierError, setImportData } from "."
import { COMMIT_IMPORT_ERROR } from '../constants'
import { setToastMessage } from '../../App/actions';

const commitImportError = error => ({ type: COMMIT_IMPORT_ERROR, error })

const importedFieldErrors = (error, indices) => async (dispatch, getState) => {
  const importData = getState().accounts.importData
  const fields = [ ...importData.fields ]
  for(const index of indices)
    fields[index] = { ...fields[index], errors: { ...fields[index].errors, [error]: true }}
  dispatch(setImportData({ ...importData, fields }))
}

const prepareForPost = (importData, merge) => {
  const { fields, uniqueIdentifier } = importData

  const data = [ ...importData.data ]
  const fieldsToPut = fields
    .filter(({ action, type }) => action === 'map' && !type.readonly)
    .map(({ planttName, type }) => ({ key: planttName, type: type.is, label: planttName, track_changes: false }))
  const identities = data.map(item => {
    const itemObj = {}
    for(const index in item){
      const { action, planttName } = fields[index]
      if(action === 'map')
        itemObj[planttName] = item[index]
    }
    return { fields: itemObj }
  })

  return {
    identities,
    fields: fieldsToPut,
    ...(merge ? { unique_field: uniqueIdentifier } : {})
  }
}

const validate = (importData, merge, dispatch) => {
  const { fields, uniqueIdentifier } = importData

  let validationFailed = false
  if(merge && !uniqueIdentifier){
      dispatch(setUniqueIdentifierError(true))
      validationFailed = true
  }

  const areEmpty = []
  for(const field of fields)
    if(field.action === 'map' && !field.planttName)
      areEmpty.push(field.index)
  if(areEmpty.length)
    dispatch(importedFieldErrors('planttNameEmpty', areEmpty))

  if(areEmpty.length)
    document.querySelector('#import-accounts-page').scrollTo({ top: 0, behavior: 'smooth'})

  return !validationFailed && !areEmpty.length
}

const commitImport = merge => async (dispatch, getState) => {
  const state = getState()
  const { importData } = state.accounts

  if(!validate(importData, merge, dispatch))
    return

  try {
    const data = prepareForPost(importData, merge)
    const response = await NEW_sendPostRequest(`${BASE_URL2}identities`, {}, JSON.stringify(data))
    
    if(response.ok){
      // if(!state.onboarding?.returnToOnboarding){
      //   // dispatch(applyAccountQuery({}, true))
        history.push('/accounts')
      // }else{
      //   history.push('/onboarding')
      // }
    }else{
      throw new Error(response.text)
    }
  } catch(error) {
    console.error('Failed to commit import: ', error)
    dispatch(commitImportError(error))
    dispatch(setToastMessage('Something went wrong while attempting to import your data. Please try again', 'error'))
  }
}

export default commitImport