import { store, firebase } from '../..'
import Hashes from 'jshashes'
import { NEW_sendGetRequest, NEW_sendPostRequest } from '../../apis/api-utils'
import { BASE_URL2 } from '../../apis/constant'
import {
  SET_FOCUSED_EDITOR,
  SET_CURRENT_NOTE_FROM_SNAPSHOT,
  SET_TASKS_FROM_SNAPSHOT,
  COMMIT_FIREBASE_UPDATE,
  COMMIT_FIREBASE_REMOVE,
  COMMIT_FIREBASE_SET,
  CLEAR_NOTES_AND_TASKS,
  PLANTT_FIELD,
  DOCK_WIDGET,
  SET_EXISTING_TAGS,
  ADD_TO_EXISTING_TAGS,
  CLEAR_DOCK_DATA,
  SET_DOCK_DATA,
  APPLY_OBJECT_UPDATE,
  SET_TEMPLATES_FROM_SNAPSHOT,
  FETCH_DEFAULT_TEMPLATES_SUCCESS,
  SET_NOTE_LIST_FROM_SNAPSHOT,
  SET_ACTION_IN_MODAL,
  UPDATE_CURRENT_NOTE_SELECTION,
  SET_CURRENT_VIEWER,
  SET_IN_OVERLAY,
  SET_IS_DRAGGING,
  TO_DO_ITEM,
  SET_NOTE_CARET_POSITION,
  SET_ACTIVE_COLLABORATORS,
} from './constants'

import serialize from '../../components/TextEditor/serialize'
import { matchPath } from 'react-router'
import { idGen } from '../../helpers'
import { getImageDimensions, getNestedBlockInfo, isNestedBlock } from './helpers'
import history from '../../utils/history'
import { setToastMessage } from '../App/actions'
import { createTask, makeTask } from '../Tasks/actions'
import { trackMixpanelEvents } from '../../helpers/mixpanel'
import { isEqual } from 'lodash'
import { clearcurrentAccount, fetchCurrentAccount } from '../Account/actions'
import { gistHistory, recordHistory } from './useGistHistory'
import { addDays, addWeeks, isAfter, isSameDay, setHours, setMinutes } from 'date-fns'
import { NativeTypes } from 'react-dnd-html5-backend'
import { checkListField, isItemizedBlock } from './Blocks/Itemized/helpers'

export const isNew = () => ({ auto_focus: store.getState().firebase.auth.uid })

export const applyUpdates = (ref, action, value, metadata) => {
  const getAction = () => {
    switch(action){
      case 'update': return COMMIT_FIREBASE_UPDATE
      case 'remove': return COMMIT_FIREBASE_REMOVE
      case 'set': return COMMIT_FIREBASE_SET
    }
  }

  const valKeys = typeof value === 'object' && value !== null && Object.keys(value)
  if(!ref?.includes('/live') && (!valKeys || valKeys.length > 1 || !valKeys[0].includes('/live')))
    store.dispatch({ type: getAction(), ref, value, metadata })

  if(action !== 'update' || !valKeys.join('').includes('/live')){
    const { currentNote, focusedEditor } = store.getState().notes
    if(focusedEditor)
      recordHistory(currentNote)
  }

  try {
    firebase.database.ref(ref)[action](value)
  } catch(error) {
    console.error(error)
  }
}

export const setNotePublic = pub => {
  const { notePath } = getNoteInfo()
  applyUpdates(`${notePath}/public`, 'set', pub || null)
}

export const setCollaborator = (event) => {
  const { global: { dimensions }, notes, firebase } = store.getState()
  const { uid, displayName, photoURL, email } = firebase.auth
  const { notePath, noteId, workspaceId } = getNoteInfo()

  const collaboratorPath = `${notePath}/collaborators/${uid}`
  const livePath = `/${workspaceId}/live/${noteId}/${uid}`

  const { collaborators } = notes.currentNote
  let current = collaborators?.[uid]
  if(!event && current)
    return applyUpdates(`${livePath}/timestamp`, 'set', Date.now())

  if(!current){
    const getGuestName = () => {
      let count = 0
      Object.values(collaborators).forEach(c => {
        if(c.name?.startsWith('Guest ')){
          const guestNum = c.name.replace('Guest ', '')
          count = guestNum > count ? guestNum : count
        }
      })
      return `Guest ${count + 1}`
    }

    let color
    const colors = collaborators ? Object.values(collaborators).map(collaborator => collaborator.color) : []
    while(!color || colors.includes(color)){
      color = Math.round(Math.random()*12)
      if(colors.length > 12)
        break
    }
    const name = displayName !== 'PublicUser' ? displayName || null : getGuestName()
    applyUpdates(collaboratorPath, 'set', { name, avatar_url: photoURL || null, email: email || null, color })
  }

  if(event){
    const container = document.querySelector('#note-editor-content')
    applyUpdates(livePath, 'set', {
      x: event.pageX - 220 - ((dimensions.width - 220) / 2), // Getting x relative to screen middle point
      y: event.pageY - 60 + (container?.scrollTop ?? 0),
      timestamp: Date.now()
    })
  }
}

export const getNoteParams = () => {
  const path = ['/:workspaceId(.{36})/:noteId?', '/settings/templates/:templateId?', '/account/:accountId']
  return matchPath(window.location.pathname, { path })?.params || {}
}
export const getNoteInfo = (data = {}) => {
  const params = getNoteParams()

  const computed = {}
  const keys = ['workspaceId', 'noteId', 'accountId', 'templateId']
  keys.forEach(key => computed[key] = data[key] || params[key])
  let { noteId, accountId, templateId, workspaceId } = computed
  if(workspaceId === 'threads' || !workspaceId)
    workspaceId = store.getState().global.workspace.id

  const { currentNote, templates } = store.getState().notes
  const notePath = !templateId ? `/${workspaceId}/notes/${noteId}` : `/${workspaceId}/templates/${templateId}`
  const note = !templateId ? currentNote : templates[templateId]

  return { notePath, noteId, note, blocks: note?.blocks, accountId, workspaceId }
}

export const setCheckListTask = (id, datetime) => {
  const [blockId, item] = id.split('/')
  const { blocks, accountId, noteId, workspaceId } = getNoteInfo()

  const { fields } = blocks[blockId].payload
  const index = fields.findIndex(i => i.id === item)
  const { label: description, task_id } = fields[index]
  const task = task_id ? store.getState().notes.tasks[task_id] : null

  let updates = { [`/tasks/${task_id}/datetime`]: datetime?.getTime() }
  if(!task){
    const { task, taskId } = makeTask({ accountId, ref: { note: noteId, block: blockId, item }, preset: { description, datetime: datetime?.getTime() || null } })
    updates = {
      [`/tasks/${taskId}`]: task,
      [`${accountId}/${noteId}/blocks/${blockId}/payload/fields/${index}/task_id`]: taskId 
    }
  }

  applyUpdates(`${workspaceId}`, 'update', updates, { origin: 'setCheckListTask' })
}

export const removeCheckListTask = id => {
  const [blockId, item] = id.split('/')
  const { blocks, accountId, noteId, workspaceId } = getNoteInfo()

  const { fields } = blocks[blockId].payload
  const index = fields.findIndex(i => i.id === item)
  const taskId = fields[index].task_id

  const updates = {
    [`/tasks/${taskId}`]: null,
    [`${accountId}/${noteId}/blocks/${blockId}/payload/fields/${index}/task_id`]: null 
  }
  applyUpdates(`${workspaceId}`, 'update', updates, { origin: 'removeCheckListTask' })
}

export const updateNoteMetadata = ({ id, ...changes }) => {
  const { notePath, note } = getNoteInfo({ noteId: id })
  const metadata = { ...(note.metadata || {}), ...changes }
  applyUpdates(`${notePath}/metadata`, 'set', metadata)
}

export const setNoteTimer = changes => {
  const { notePath, note } = getNoteInfo()
  applyUpdates(`${notePath}/timer`, 'set', { ...note.timer, ...changes })
}

export const setNoteChannel = changes => {
  const { notePath, note } = getNoteInfo()
  applyUpdates(`${notePath}/channel`, 'set', { ...note.channel, ...changes })
}

export const setNoteChannelParticipant = (uid, participant) => {
  const { notePath, note } = getNoteInfo()
  applyUpdates(`${notePath}/channel/participants/${uid}`, 'set', participant)
}

export const updateNoteTag = tag => {
  const { note } = getNoteInfo()
  const tags = [ ...(note.metadata?.tags || []) ]
  const index = tags.findIndex(t => t === tag)
  if(index < 0)
    tags.push(tag)
  else
    tags.splice(index, 1)

  updateNoteMetadata({ tags })
  store.dispatch({ type: ADD_TO_EXISTING_TAGS, tag })
}

export const createBlock = (blockId, newBlock, options = {}) => {
  let { autoFocus = true, newId } = options
  const { notePath, blocks } = getNoteInfo()
  if(!blockId){
    blockId = Object.keys(blocks).find(id => !blocks[id].next_block)
    if(blocks[blockId].type === 'initial')
      blockId = 'noteTitle'
  }
  
  const { next_block, type, list, payload, parent_id } = blocks[blockId]
  if(type === 'title' && next_block && blocks[next_block].type === 'initial')
      return replaceInitialBlock(next_block, newBlock)

  while(!newId || (blocks && blocks[newId]))
    newId = idGen()

  const now = new Date().getTime()
  const updates = blockId
    ? {
        [`/blocks/${newId}`]: { 
          next_block: next_block || null,
          updated_at: now,
          ...newBlock
        },
        [`/blocks/${blockId}/next_block`]: newId,
        [`/blocks/${blockId}/updated_at`]: now
      }
    : { [`/blocks/${newId}`]: { updated_at: now, ...newBlock } }

  if(newBlock.type === 'basic')
    Object.assign(updates[`/blocks/${newId}`], {
      list: (payload.text?.length && list) || null,
      parent_id: parent_id || null,
    })

  if(!payload.text?.length && list)
      updates[`/blocks/${blockId}/list`] = null
  if(autoFocus)
    Object.assign(updates[`/blocks/${newId}`], isNew())

  updates[`/updated_at`] = new Date().getTime()

  applyUpdates(notePath, 'update', updates)
  return newId
}

export const removeAutoFocus = blockId => {
  const { notePath } = getNoteInfo()
  if(isNestedBlock(blockId)){
    const blockInfo = getNestedBlockInfo(blockId) 
    blockId = isItemizedBlock(blockInfo.block) && blockInfo.childId !== 'question'
      ? `${blockInfo.blockId}/payload/fields/${blockInfo.fieldIndex}`
      : `${blockInfo.blockId}/payload/${blockInfo.childId}`
  }
  applyUpdates(`${notePath}/blocks/${blockId}/auto_focus`, 'remove', undefined, { origin: 'removeAutoFocus' })
}
export const updateCurrentNoteSelection = (selection) => ({ type: UPDATE_CURRENT_NOTE_SELECTION, selection })

export const clearNotesAndTasks = () => ({ type: CLEAR_NOTES_AND_TASKS })
export const clearSelection = () => store.dispatch(updateCurrentNoteSelection([]))
export const deleteNote = id => {
  const { global: { workspace }, notes: { currentNote } } = store.getState()
  const { noteId } = getNoteParams()
  const { notePath } = getNoteInfo({ noteId: id || noteId })

  if(!id){
    if(workspace.role !== 'lite')
      history.push(`/account/${currentNote.account_id}`)
    else
      history.push('/threads')
  }

  // The timeout is here to avoid removing the note before the firebase listener is
  // destroyed. Otherwise, an unnecessary error toast is thrown...
  setTimeout(() => applyUpdates(notePath, 'remove'))
}

export const setBlock = (blockId, changes, lock = true) => {
  const { blocks, notePath } = getNoteInfo({ blockId })
  if(!blocks)
    return

  const block = { ...blocks[blockId], ...changes }
  const isEditor = block.type === 'basic' || block.type === 'title'

  const text = isEditor && block.payload.text
  if(text && typeof text !== 'string')
    block.payload = { ...block.payload, text: serialize(text) }
  if(block.auto_focus === false)
    delete block.auto_focus
  
  block.updated_at = Date.now()

  const updates = {
    [`/updated_at`]: block.updated_at,
    [`/blocks/${blockId}`]: block
  }

  if(lock && !isItemizedBlock(block))
    block.lock = { at: block.updated_at, by: store.getState().firebase.auth.uid }

  applyUpdates(notePath, 'update', updates, { origin: 'setBlock' })
}

export const uploadFiles = async (files, { droppedAfter, blockId }) => {
  if(!files.length)
    return
  if(files.length > 1)
    return store.dispatch(setToastMessage('We currently support uploading only one file at a time', 'info'))
  
  const memberId = store.getState().global.currentMember.id
  const fileId = new Hashes.MD5().hex(memberId + Date.now())

  if(droppedAfter)
    blockId = createBlock(droppedAfter, { type: 'file_upload', payload: { fileId } }, { autoFocus: false })

  const { noteId, workspaceId } = getNoteInfo()
  const uploadTask = firebase.storage.ref(`${workspaceId}/${noteId}/${fileId}`).put(files[0])

  const { type, name } = files[0]
  let dimensions
  if(type.startsWith('image'))
    dimensions = getImageDimensions(files[0])


  let size
  uploadTask.on(
    'state_changed',
    ({ bytesTransferred, totalBytes, state }) => {
      size = totalBytes
      setBlock(blockId, { payload: { fileId, bytesTransferred, totalBytes, state, type } })
    },
    error => console.error(error.code, error), // A full list of error codes is available at https://firebase.google.com/docs/storage/web/handle-errors
    async () => {
      const url = await uploadTask.snapshot.ref.getDownloadURL()
      const changes = { type: type.startsWith('image') ? 'image' : 'file', payload: { fileId, type, url, name, size } }
      if(changes.type === 'image')
        changes.payload.dimensions = await dimensions
      setBlock(blockId, changes)
    }
  )
}
/*
  GIDON
    ALEX
      SINAI
      OREN



*/



export const getBlockOffspring = (blocks, blockId, children = []) => {
  Object.entries(blocks).filter(([id, props]) => props?.parent_id === blockId).forEach(([id]) => children.push(id))
  if(children.length) 
    return children.concat(children.map(id => getBlockOffspring(blocks, id)).flat())
  return children
}
const hasChildren = (blocks, blockId) => Object.values(blocks).filter(props => props?.parent_id === blockId).length > 0
const hasSiblings = (blocks, blockId) => Object.values(blocks).filter(props => props?.parent_id === blocks[blockId].parent_id).length > 0
const determineDesignatedParent = (blocks, blockId) => {
  if(hasChildren(blocks, blockId))
    return blockId

  const parentId = blocks[blockId].parent_id
  if(!parentId)
    return 'none'
  else if(hasSiblings(blocks, blockId)) //has parent & sibilings
    return parentId
  else
    return blockId // has parent, no sibilings
}


export const handleBlockDrop = (blockId, { id: droppedId, type: droppedType, payload, files, lastBlockIdInGroup }, top = false) => {
  const { notePath, blocks } = getNoteInfo()
  const getBlock = func => {
    const id = Object.keys(blocks).find(func)
    return id ? { id, ...blocks[id] } : null
  }

  if(top)
    blockId = getBlock(id => blocks[id].next_block === blockId).id
    // This makes sure that blockId will always reflect the block that is *before* the drop zone

  switch(droppedType){
    case PLANTT_FIELD:
      return createBlock(blockId, { type: 'plantt-widget', payload: { fields: [droppedId] } }, { autoFocus: false })
    case DOCK_WIDGET:
      return createBlock(blockId, { type: 'widget', payload }, { autoFocus: false })
    case TO_DO_ITEM:
      const [parentId, childId] = droppedId.split('/')
      const newPayload = { ...blocks[parentId].payload, fields: [...blocks[parentId].payload.fields] }
      const index = newPayload.fields.findIndex(field => field.id === childId)

      const newBlock = { type: 'to-do-list', payload: { fields: [newPayload.fields[index], checkListField()] } };
      newPayload.fields.splice(index, 1)
      setBlock(parentId, { payload: newPayload })
      return createBlock(blockId, newBlock, { autoFocus: false })
    case NativeTypes.FILE:
      return uploadFiles(files, { droppedAfter: blockId })
  }
    

  const previousBlock = getBlock(id => blocks[id].next_block === droppedId)
  if(blockId === droppedId || blockId === previousBlock.id)
    return

  const droppedAt = getBlock(id => id === blockId)
  const droppedBlock = getBlock(id => id === droppedId)
  const now = new Date().getTime()
  const blockUpdate = (id, value, newParent = null) => {
    const deafultUpdates =  {
      [`/blocks/${id}/next_block`]: value,
      [`/blocks/${id}/updated_at`]: now,
    }
    if(newParent)
      deafultUpdates[`/blocks/${value}/parent_id`]= newParent === 'none' ? null : newParent
    return deafultUpdates    
  }

  const newParent = determineDesignatedParent(blocks, blockId)
  const updates = {
    ...blockUpdate(blockId, droppedId, newParent),
    ...blockUpdate(lastBlockIdInGroup ? lastBlockIdInGroup : droppedId, droppedAt.next_block || null)
  }

  if(previousBlock)
    Object.assign(updates, blockUpdate(previousBlock.id, lastBlockIdInGroup ? getBlock(id => id === lastBlockIdInGroup).next_block || null : droppedBlock.next_block || null))
  applyUpdates(notePath, 'update', updates)
}

export const handleItemizedBlockDrop = (blockId, { id: droppedId }) => {
  const { notePath } = getNoteInfo()
  const [droppedBlock, droppedChild] = droppedId.split('/')
  const [receivingBlock, receivingChild] = blockId.split('/')
  if(blockId === droppedId)
    return
  
  const sameBlock = droppedBlock === receivingBlock

  const { blocks } = getNoteInfo()
  const receivingFields = [ ...blocks[receivingBlock].payload.fields ]
  const receivingIndex = receivingFields.findIndex(f => f.id === receivingChild)
  const updates = { [`/${receivingBlock}/payload/fields`]: receivingFields }
  
  let field
  let placementOffset = 0
  if(droppedChild){
    // Dropped item is an itemized block field
    const droppedFields = sameBlock ? receivingFields : [ ...blocks[droppedBlock].payload.fields ]
    const droppedIndex = droppedFields.findIndex(f => f.id === droppedChild)
    if(sameBlock){
      if(receivingIndex === droppedIndex + 1) return
      if(receivingIndex > droppedIndex) placementOffset = 1
    }
    
    field = droppedFields.splice(droppedIndex, 1)[0]
    if(droppedFields.length > 1)
      updates[`/${droppedBlock}/payload/fields`] = droppedFields
    else
      deleteBlock(droppedBlock)
  }else{
    // Dropped item is a text block
    field = checkListField({ label: blocks[droppedBlock].payload.text })
    deleteBlock(droppedBlock)
  }

  receivingFields.splice(receivingIndex - placementOffset, 0, field)
  applyUpdates(`${notePath}/blocks`, 'update', updates)
}

export const setCurrentNoteFromSnapshot = snapshot => (dispatch, getState) => {
  const note = snapshot.val()
  if(!note)
    return

  // The following code is an optimization to avoid inserting new objects where no change occured
  const currentNote = getState().notes.currentNote
  for(const key in note)
    if(isEqual(note[key], currentNote[key]))
      delete note[key]

  if(note.blocks && currentNote.blocks){
    for(const blockId in note.blocks){
      const currentBlock = currentNote.blocks[blockId]
      if(isEqual(currentBlock, note.blocks[blockId]))
        note.blocks[blockId] = currentBlock
    }
  }

  if(Object.keys(note).length)
    dispatch({ type: SET_CURRENT_NOTE_FROM_SNAPSHOT, note })
}

export const setNoteListFromSnapshot = (pathname, snapshot) => {
  const today = setMinutes(setHours(new Date(), 0), 1)
  const weekAgo = addWeeks(today, -1)
  const thirtyDaysAgo = addDays(today, -30)

  let items = Object.entries(snapshot.val() || {})
    .map(([id, note]) => {
      const { pinned, created_at, updated_at, metadata, collaborators } = note
      const name = note.blocks?.noteTitle?.payload?.text
      return { id, name, pinned, created_at: new Date(created_at), updated_at: new Date(updated_at), metadata, collaborators }
    })
    .sort((a, b) => b.created_at - a.created_at)
  
  if(window.location.pathname === '/starred')
    items = [items.filter(item => item.metadata?.favorite)]
  else
    items = items.reduce((arr, item) => {
      const getIndex = () => {
        switch(true){
          case isSameDay(today, item.updated_at):       return 0
          case isAfter(item.updated_at, weekAgo):       return 1
          case isAfter(item.updated_at, thirtyDaysAgo): return 2
          default:                                      return 3    
        }
      }
      arr[getIndex()].push(item)
      return arr
    }, new Array(4).fill(true).map(() => []))
    

  return { type: SET_NOTE_LIST_FROM_SNAPSHOT, items, pathname }
}

export const deleteTemplate = templateId => {
  const { notePath } = getNoteInfo({ templateId })
  applyUpdates(notePath, 'remove')
}

export const setTasksFromSnapshot = snapshot => {
  const tasks = snapshot.val() || {}
  return ({ type: SET_TASKS_FROM_SNAPSHOT, tasks })
}

export const deleteBlock = (blockId, sideEffects = false) => {
  const { blocks, notePath, workspaceId } = getNoteInfo({ blockId })

  const block = blocks[blockId]
  const previousBlock = Object.keys(blocks).find(b => blocks[b].next_block === blockId)

  const updates = { [`/blocks/${blockId}`]: null }
  if(previousBlock)
    updates[`/blocks/${previousBlock}/next_block`] = block.next_block || null

  applyUpdates(notePath, 'update', updates, { origin: 'deleteBlock' })

  if(sideEffects && block.type === 'task')
    applyUpdates(`${workspaceId}/tasks`, 'update', { [`/${block.payload.id}`]: null })
}

export const deleteMultipleBlocks = (blocksList) => {
  const blocksOrder = store.getState().notes.blockOrder
  const lastBlockLocation = blocksOrder.indexOf(blocksList[blocksList.length-1])
  const { blocks, notePath } = getNoteInfo()

  const lastBlock =  blocks[blocksOrder[lastBlockLocation]]

  const updates = {}
  const previousBlock = Object.keys(blocks).find(blockId => blocks[blockId].next_block === blocksList[0])

  blocksList.forEach(blockId => updates[`/blocks/${blockId}`] = null)

  if(previousBlock)
    updates[`/blocks/${previousBlock}/next_block`] = lastBlock.next_block || null

  applyUpdates(notePath, 'update', updates)

}







const initialBlocks = (title = '') => {
  const id = idGen()
  return {
    noteTitle: { type: 'title', payload: { text: title }, next_block: id, ...isNew(), updated_at: new Date().getTime() },
    [id]: { type: 'initial', updated_at: new Date().getTime() },
  }
}

export const createNote = (accountId, title) => {
  let noteInfo = getNoteInfo()
  let noteId

  const testAndContinue = () => {
    noteId = idGen()
    firebase.database.ref(`${noteInfo.workspaceId}/notes/${noteId}`).once('value', snapshot => {
      if(snapshot.val())
        return testAndContinue()
    
      noteInfo = getNoteInfo({ noteId, accountId })
      if(!accountId)
        accountId = noteInfo.accountId
      
      const memberId = store.getState().global.currentMember.id
    
      applyUpdates(noteInfo.notePath, 'set', {
        created_at: new Date().getTime(),
        updated_at: new Date().getTime(),
        created_by: memberId,
        blocks: initialBlocks(title),
        pinned: false,
        account_id: accountId ?? noteInfo.accountId ?? null
      })
      trackMixpanelEvents('Gist Created')
      history.push(`/${noteInfo.workspaceId}/${noteId}`)
    })
  }

  testAndContinue()
}

export const createTemplate = () => {
  const { notes: { templates }, global: { workspace } } = store.getState()
  let templateId
  while(!templateId || templates[templateId])
    templateId = idGen()

  applyUpdates(`${workspace.id}/templates/${templateId}`, 'set', { created_at: new Date().getTime(), blocks: initialBlocks() })
  trackMixpanelEvents('Gist template created')
  history.push(`/settings/templates/${templateId}`)
}

export const replaceInitialBlock = (blockId, newBlocks = { type: 'basic', payload: { text: '' } }, metadata = null) => {
  const { notePath } = getNoteInfo()
  if(Array.isArray(newBlocks))
    return applyUpdates(notePath, 'update', { blocks: blocksFromTemplate(metadata, newBlocks, blockId) })

  if(newBlocks.type){
    Object.assign(newBlocks, { ...isNew(), updated_at: new Date().getTime() })
    return applyUpdates(`${notePath}/blocks/${blockId}`, 'set', newBlocks)
  }

  const blocksObj = {}
  let ids = { current: 'noteTitle', new: 'noteTitle' }

  while(ids.current){
    const block = blocksObj[ids.new] = { ...newBlocks[ids.current] }
    delete block.auto_focus
    delete block.updated_at
    delete block.lock
    const { next_block } = block
    let newId
    if(next_block){
      while(!newId || newBlocks?.[newId])
        newId = idGen()
      block.next_block = newId
    }

    ids = { current: next_block, new: newId }
  }

  applyUpdates(notePath, 'update', { blocks: blocksObj, metadata })
}

export const setFocusedEditor = blockId => ({ type: SET_FOCUSED_EDITOR, blockId })
export const unsetFocusedEditor = blockId => (dispatch, getState) => {
  const { focusedEditor } = getState().notes
  if(focusedEditor === blockId)
    dispatch({ type: SET_FOCUSED_EDITOR, blockId: null })
}

export const fetchDynamicList = async (values, searchTerm, action, field) => {
  if(!field?.dropdown_settings)
    return
  
  const { fetcher_id, fetcher_data_input = [] } = field.dropdown_settings
  const { identity_id } = getIdentityId()

  try {
    const fetchData = { fetcher_id, searchTerm, fetcher_data_input: {}, identity_id }
    fetcher_data_input.forEach(inputFieldName => fetchData.fetcher_data_input[inputFieldName] = values[inputFieldName])

    const list = await NEW_sendPostRequest(`${BASE_URL2}shortcut/fetch/${action.spec_ref}`, {}, JSON.stringify(fetchData))
    if(!list.ok)
      throw new Error(list.text)

    return list.text
  } catch(error) {
    console.error(error)
    return { error }
  }
}

const getIdentityId = () => matchPath(window.location.pathname, { path: '/account/:identity_id' })?.params || {}

export const doAction = async (specRef, payload) => {
  const { identity_id } = getIdentityId()
  trackMixpanelEvents("triggered_action")
  try {
    const response = await NEW_sendPostRequest(`${BASE_URL2}action/${specRef}/test`, {}, JSON.stringify({ ...payload, identity_id }))
    if(!response.ok || response.text?.error || typeof response.text === 'string')
      throw new Error(response.text)

    return { block: response.text }
  } catch(error) {
    console.error(error)
    store.dispatch(setToastMessage(`Something went wrong while trying to do an action. Please contact us if this issue persists`, 'error'))
  }
}

export const getFieldDefault = field => {
  switch(field.type){
    case 'datetime': return new Date().getTime()
    case 'boolean': return false
    case 'email_list': return []
    default: return ''
  }
}

export const saveAsTemplate = noteId => {
  let { notes: { templates }, global: { workspace } } = store.getState()

  firebase.database.ref(`${workspace.id}/notes/${noteId}`).once('value', snapshot => {
    const { blocks, metadata = null } = snapshot.val()

    const template = { metadata, created_at: new Date().getTime(), blocks: {} }
    Object.keys(blocks).forEach(key => {
      const block = template.blocks[key] = { ...blocks[key] }
      delete block.auto_focus
      delete block.updated_at
      delete block.lock
    })
    
    let newId
    while(!newId || templates[newId])
      newId = idGen()

    applyUpdates(`/${workspace.id}/templates/${newId}`, 'set', template)

    store.dispatch(setToastMessage('Gist saved as template successfully', 'success'))
  })
}

export const liftLock = blockId => {
  const { notePath } = getNoteInfo()

  let lockPath = `${notePath}/blocks/${blockId}/lock`
  if(isNestedBlock(blockId)){
    const info = getNestedBlockInfo(blockId)
    const pathStart = `${notePath}/blocks/${info.blockId}/payload/`
    lockPath = pathStart + (!('fieldIndex' in info) ? info.childId : `fields/${info.fieldIndex}`) + '/lock'
  }
  
  applyUpdates(lockPath, 'remove')
}

export const getAllTags = () => async (dispatch, getState) => {
  const { notes, global } = getState()
  const existingTags = notes.existingTags
  if(existingTags !== null)
    return

  const workspaceId = global.workspace?.id
  if(!workspaceId)
    return //TEMP!!
  try {
    const snapshot = await firebase.database.ref(`${workspaceId}/notes`).once('value')
    const allNotes = snapshot.val()
    const allTags = {}
    Object.keys(allNotes).forEach(noteId => {
      allNotes[noteId].metadata?.tags?.forEach(tag => allTags[tag] = true)
    })
    dispatch({ type: SET_EXISTING_TAGS, tags: Object.keys(allTags) })
  }catch(error){
    console.error(error)
  }
}



export const clearDockData = () => ({ type: CLEAR_DOCK_DATA })
export const setDockData = (specRef, data) => ({ type: SET_DOCK_DATA, specRef, data })
export const fetchDock = ({ appId, specRef }, force = false) => async (dispatch, getState) => {
  const { accounts, notes, global } = getState()
  if(typeof appId === 'undefined')
    appId = global.connectors.find(connector => connector.spec_ref === specRef)?.id || null

  const currentDockData = ![appId, specRef].includes('plantt') ? notes?.dockData?.[specRef] : null
  const notFetchedYet = !currentDockData || (currentDockData.error && currentDockData.error.error !== 'not_found')
  const hasEmptyBlocks = currentDockData?.blocks && !currentDockData.blocks.length
  if(!force && (appId === null || !notFetchedYet || !accounts.currentAccount || hasEmptyBlocks))
    return

  dispatch(setDockData(specRef, { loading: true }))
  try {
    const payload = { ...accounts.currentAccount, ...((specRef === 'salesforce' || specRef === 'monday' || specRef === 'hubspot' || specRef === 'zendesk' || specRef === 'google_cloud') ? {version: 2} : null) }
    delete payload.highlights
    delete payload.predictions
    let dockData = await NEW_sendPostRequest(`${BASE_URL2}connector/${appId}/dock`, {}, JSON.stringify(payload))
    if(!dockData.ok)
      return dispatch(setDockData(specRef, { error: dockData.text }))

    dockData = dockData.text
    if(Array.isArray(dockData))
      dockData = { blocks: dockData }

    dockData.actions = dockData.actions.map(action => typeof action === 'string' ? { id: action, payload: {} } : action)

    dispatch(setDockData(specRef, dockData))
  } catch(error) {
    console.error('Failed to fetch dock:', error)
    dispatch(setDockData(specRef, { error }))
  }
}

export const updateThirdPartyObject = (updateData, updateFunction) => async dispatch => {
  const { object, changes, edit_action, specRef } = updateData
  let hasChanges = false
  for(const key in changes){
    if(changes[key] !== object[key]){
      hasChanges = true
      break
    }
  }
  if(!hasChanges)
    return

  updateFunction(updateData)
  try {
    const { identity_id } = matchPath(window.location.pathname, { path: '/account/:identity_id' })?.params || {}
    const payload = { ...object, ...changes, ...edit_action, fields_to_update: Object.keys(changes), identity_id }
    const response = await NEW_sendPostRequest(`${BASE_URL2}action/${specRef}/test`, {}, JSON.stringify(payload))
    if(!response.ok || response.text.error)
      throw new Error(response.text)

    return { success: true }
  } catch(error) {
    console.error(error)
    dispatch({ type: APPLY_OBJECT_UPDATE, updateData, rollback: true })
    dispatch(setToastMessage('Something went wrong while saving your update. Please give it another try', 'error'))
    return { error }
  }
}

export const shareGist = async (email, permissions) => {
  const { noteId: gist_id, blocks } = getNoteInfo()
  const gist_title = blocks.noteTitle.payload.text
  try {
    const user = await NEW_sendPostRequest(`${BASE_URL2}gist/share`, {}, JSON.stringify({ gist_id, gist_title, email, permissions }))
    return user.text[0]
  } catch(error) {
    console.error(error)
  }
}

export const getChannelToken = async () => {
  const { noteId: channel_id } = getNoteParams()
  try {
    const response = await NEW_sendPostRequest(`${BASE_URL2}thread/token`, {}, JSON.stringify({ channel_id }))
    return response.text.token
  } catch(error) {
    console.error(error)
  }
}

export const applyUserReaction = (changes, blockId) => {
  const { uid } = store.getState().firebase.auth
  const { notePath, blocks } = getNoteInfo()
  const { reactions = {} } = blocks[blockId]
  const updates = {}

  for(const c in changes)
    updates[`/${c}`] = { ...reactions[c], [uid]: changes[c] }

  applyUpdates(`${notePath}/blocks/${blockId}/reactions`, 'update', updates)
}

// export const deleteAllTemplates = async () => {
//   const query = firebase.database.ref()
//   const ordered = query.orderByKey()
//   let updates = {}
//   const snapshot = await ordered.once("value")
//   snapshot.forEach(childSnapshot => updates[`/${childSnapshot.key}/templates`] = null)
//   query.update(updates)
// }

const setTemplatesFromSnapshot = snapshot => {
  const templates = snapshot.val() || {}
  return ({ type: SET_TEMPLATES_FROM_SNAPSHOT, templates })
}

const fetchDefaultTemplatesSuccess = templates => ({ type: FETCH_DEFAULT_TEMPLATES_SUCCESS, templates })
export const fetchGistTemplates = workspaceId => async dispatch => {
  try {
    firebase.database
      .ref(`${workspaceId}/templates`)
      .on('value', snapshot => dispatch(setTemplatesFromSnapshot(snapshot)))

    const templates = await NEW_sendGetRequest(`${BASE_URL2}gist-templates`)
    if(!templates.ok)
      throw new Error(templates.text)

    dispatch(fetchDefaultTemplatesSuccess(templates.text))
  } catch(error) {
    console.error(error)
  }
}

const blocksFromTemplate = (name, blocks, blockId = idGen()) => {
  const updated_at = new Date().getTime()
  const ids = []
  const blocksObj = { noteTitle: { type: 'title', payload: { text: name }, next_block: blockId, updated_at } }
  blocks.forEach((block, index) => {
    let newId = !index ? blockId : undefined
    while(!newId || blocksObj[newId])
      newId = idGen()

    ids[index] = newId
    blocksObj[newId] = { ...block, updated_at }
    if(index)
      blocksObj[ids[index - 1]].next_block = newId
  })

  return blocksObj
}

export const getNote = () => (dispatch, getState) => {
  dispatch(getAllTags()) // Needs adjustment for non-workspace viewers
  trackMixpanelEvents("notebook_watched")
  Object.assign(gistHistory, { records: [], position: -1, stopChange: false, timeout: null })

  const { workspaceId, noteId, templateId } = getNoteParams()
  const { global: { workspace }, firebase: { auth } } = store.getState()

  const workspaceRef = firebase.database.ref(!templateId ? workspaceId : workspace.id) // comes workspaceId comes from params, workspace.id from redux
  const noteRef = workspaceRef.child(!templateId ? `notes/${noteId}` : `templates/${templateId}`)
  const tasksRef = workspaceRef.child('tasks').orderByChild('ref/note').equalTo(noteId || null)

  const attachListeners = () => {
    noteRef.on('value', snapshot => {
      const note = snapshot?.val()
      if(note){
        if(!templateId && note.account_id)
          dispatch(fetchCurrentAccount(note.account_id)) // Needs adjustment for non-workspace viewers
        return dispatch(setCurrentNoteFromSnapshot(snapshot))
      }
  
      const { notes: { currentNote }, global: { workspace } } = getState()
      const path = !currentNote.account_id
        ? (workspace?.role === 'lite' ? '/threads' : '/accounts')
        : `/account/${currentNote.account_id}`
      history.replace(!templateId ? path : '/settings/templates')
      dispatch(setToastMessage(`We could not find the ${!templateId ? 'thread' : 'template'} you were looking for`, 'error'))
    })
  
    tasksRef.on('value', snapshot => dispatch(setTasksFromSnapshot(snapshot)))
  }

  const getPermissions = async () => {
    const permissionsPath = `authorization/${auth.uid}/${noteId}/permissions`
    const snapshot = await firebase.database.ref(permissionsPath).once('value')
    const permissions = snapshot.val()
    if(permissions){
      dispatch(setCurrentViewer({ permissions }))
      attachListeners()
    }else{
      history.push('/')
      dispatch(setToastMessage('You are not authorized to view this thread', 'error'))
    }
  }

  if(workspace && workspaceId && workspaceId !== workspace.id)
    getPermissions()
  else
    attachListeners()

  return () => {
    noteRef.off()
    tasksRef.off()
    dispatch(clearcurrentAccount())
    dispatch(clearNotesAndTasks())
  }
}

export const setActionInModal = action => ({ type: SET_ACTION_IN_MODAL, action })
export const setCaretPosition = position => ({ type: SET_NOTE_CARET_POSITION, position })


export const createEmbeds = (blockId, embeds) => {
  embeds.reverse().forEach(embed => createBlock(blockId, { type: 'embed', payload: { ...embed } }))
}

export const setCurrentViewer = viewer => ({ type: SET_CURRENT_VIEWER, viewer })
export const setInOverlay = inOverlay => ({ type: SET_IN_OVERLAY, inOverlay })

export const setIsDragging = blockId => (dispatch, getState) => {
  const { isDragging } = getState().notes
  if((!isDragging && blockId) || (isDragging && blockId === null))
    dispatch({ type: SET_IS_DRAGGING, blockId })
}

export const setActiveCollborators = (action, id) => (dispatch, getState) => {
  const { activeCollaborators } = getState().notes
  if((action === 'add' && !activeCollaborators.includes(id)) || (action === 'delete' && activeCollaborators.includes(id)))
    dispatch({ type: SET_ACTIVE_COLLABORATORS, action, id })
}