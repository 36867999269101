import React, { Fragment,useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Divider from '@material-ui/core/Divider'
import { changeReplaceCreditCardStatus, getCheckOutToken, getCheckoutPlanInfo, resetCheckoutPlanInfo, setCheckoutToken, setPaymentMethod, addNewSubscription, addAccountData } from '../actions'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator'
import { useLayoutConfig } from '../../../components/PlanttLayout/LayoutConfig'
import FieldError from '../../../components/FormControls/FieldError'
import Select from '../../../components/FormControls/Select'
import { upperFirst } from '../../../helpers'
import { OrangeButton } from '../../../components/Button'

const isEmpty = obj => Object.keys(obj).length === 0

function Checkout() {
    useLayoutConfig({ title: 'Checkout' })

    const dispatch = useDispatch()
    const params = useParams()

    useEffect(() => {
        dispatch(getCheckOutToken())
        dispatch(changeReplaceCreditCardStatus(0))
        dispatch(getCheckoutPlanInfo(params.plan))
        return () => {
            dispatch(resetCheckoutPlanInfo())
            dispatch(setCheckoutToken(''))
        }
    }, [])
    const planDetails = useSelector(state => state.setting.checkoutPlan)
    const checkoutToken = useSelector(state => state.setting.checkoutToken)

    return  (
        <div className="checkout-container">
            <div className="checkout-title">Checkout</div>
            <div className="checkout-title-second">Get additional power and control to help your team more effectively</div>
            {!isEmpty(planDetails)
                ? (checkoutToken.length
                    ? <Checkout2 planDetails={planDetails} checkoutToken={checkoutToken} />
                    : <LoadingIndicator  />)
                : 'Plan Problem'
            }
        </div>
    )
}

function Checkout2({ planDetails, checkoutToken }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const subscription_id = useSelector(state => state.setting.subscription_id)

    const [focusedField, setFocusedField] = useState(null)
    const [state, setState] = useState({
        first_name: '',
        last_name: '',
        company_name: '',
        billing_email: '',
        billingPeriod: 'yearly',
        agentsNumber: 2,
        loading: false,
        isValid: { ccn: false, exp: false, cvv: false },
        showTooltips: { first_name: false, last_name: false, company_name: false, billing_email: false, ccn: false, exp: false, cvv: false }
    })

    useEffect(() => {
        const newSubscriptionData = planDetails
            ? { plan_id: planDetails.id, billing_period: "Yearly", price: planDetails.monthly_price, status: "Draft" }
            : {}

        if(planDetails.id && planDetails.monthly_price)
             dispatch(addNewSubscription(newSubscriptionData))

        const handleCardInfoValidation = (tagId, errorCode , errorDescription) => {
            if(['ccn', 'exp', 'cvv'].includes(tagId))
                showTooltip(tagId)
            /*errorCode returns:
                "10" --> invalidCcNumber, invalidExpDate, invalidCvv Dependent on the tagId;
                "22013" --> "CC type is not supported by the merchant"; 
                "14040" --> " Token is expired";
                "14041" --> " Could not find token";
                "14042" --> " Token is not associated with a payment method, please verify your client integration or contact BlueSnap support";
                "400" --> "Session expired please refresh page to continue";
                "403", "404", "500" --> "Internal server error please try again later"; 
            */
        }

        var bsObj = {
            token: checkoutToken,
            onFieldEventHandler: {
                // /*OPTIONAL*/ setupComplete: () => console.log("setupComplete"),
                // /*OPTIONAL*/ threeDsChallengeExecuted: () => console.log("threeDsChallengeExecuted"),
                onFocus: setFocusedField,
                onBlur: () => setFocusedField(null),
                onError: handleCardInfoValidation,
                onType: () => showTooltip('ccn', false),
                onValid: function (tagId) {
                    if(['ccn', 'exp', 'cvv'].includes(tagId)){
                        showTooltip(tagId, false)
                        setState(state => ({ ...state, isValid: { ...state.isValid, [tagId]: true } }))
                    }
                } // Handle a change in validation
            },
            style: {
                ":focus": {
                    "color": "orange"
                },
                "input": {
                    "color": "blue",
                    "padding-left": "14px"
                },
                ".invalid": {
                    "color": "red"
                },
                "#ccn": {
                    "color": "green",
                    "border-color": "green",
                    "border-width": "2px",
                },
            },
            ccnPlaceHolder: "1234 5678 9012 3456", //for example
            cvvPlaceHolder: "123", //for example
            expPlaceHolder: "MM/YY" //for example
        }
        window.bluesnap.hostedPaymentFieldsCreate(bsObj)
    }, [])

    const createSubscription = () => {
        if(state.loading)
            return

        const invalid = {}
        for(const key of ['first_name', 'last_name', 'company_name'])
            if(!state[key].length)
                invalid[key] = true

        for(const key in state.isValid)
            if(!state.isValid[key])
                invalid[key] = true

        if(!validateEmail())
            invalid.billing_email = true

        if(!isEmpty(invalid))
            return setState({ ...state, showTooltips: { ...state.showTooltips, ...invalid } })

        setState({ ...state, loading: true })
        //Submit the Card, then save it with the acccses token
        window.bluesnap.hostedPaymentFieldsSubmitData(callback => {
            if (callback.cardData === null){
                for (let i in callback.error)
                    console.error("Received error: ", callback.error[i])
                return
            }
            
            console.log(callback.cardData) //will output { ccType: cardType, last4Digits, exp, issuingCountry, isRegulatedCard, cardSubType, binCategory, ccBin }
            // submit the form
            //Create the new subscription
            const newSubscriptionData = {
                plan_id: planDetails.id,
                billing_period: state.billingPeriod,
                price: finalPrice,
                status: "Active",
                subscription_id
            }
            
            const { ccType, last4Digits } = callback.cardData
            const paymentMethodData = {
                cc_info: {
                      token: checkoutToken, last_digits: last4Digits, name: `${ccType} ${last4Digits}` 
                },
                first_name:state.first_name,
                last_name: state.last_name,
                company_name: state.company_name,
                email: state.billing_email,
            }
            
            const { billing_email, company_name, first_name, last_name } = state
            dispatch(changeReplaceCreditCardStatus(1))
            dispatch(setPaymentMethod(paymentMethodData))
            dispatch(addNewSubscription(newSubscriptionData))
            dispatch(addAccountData({ billing_email, company_name, first_name, last_name }))
            history.push(`/settings/paymentdone/${finalPrice}`)
        })
    }

    const finalPrice = useMemo(() => {
        if(planDetails.id === 'agents') {
            return state.billingPeriod === 'yearly'
                ? parseInt(state.agentsNumber)* 80 * 12 *0.8
                : parseInt(state.agentsNumber)* 80
        }
        else {
            return state.billingPeriod === 'yearly'
                ? (parseInt(planDetails.monthly_price)*12 - parseInt(planDetails.yearly_discount))
                : planDetails.monthly_price
        }
    }, [state.billingPeriod, planDetails, state.agentsNumber])

    const validateEmail = (setTooltip = true) => {
        // eslint-disable-next-line
        const emailRegEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const invalid = !emailRegEx.test(state.billing_email)

        if(setTooltip)
            showTooltip('billing_email', invalid)
        return !invalid
    }

    const showTooltip = (key, value = true) => setState(state => ({ ...state, showTooltips: { ...state.showTooltips, [key]: value } }))
    const changeState = key => e => {
        setState({ ...state, [key]: e.target.value })
        if(typeof state.showTooltips[key] !== undefined)
            showTooltip(key, false)
    }

    return (
        <div className="checkout-body">
            <div className="checkout-input-data">
                <div className="checkout-details-title">Payment details</div>
                <div className="checkhout-card-details-row">
                    <CheckoutCardDetails
                        id='ccn'
                        text='Credit card number'
                        errorText='Invalid Card number'
                        {...{ state, focusedField }}
                    />
                    <CheckoutCardDetails
                        id='exp'
                        text='Expiration'
                        errorText='Invalid expiration date'
                        {...{ state, focusedField }}
                    />
                    <CheckoutCardDetails
                        id='cvv'
                        text='CVV'
                        errorText='Invalid CVV number'
                        {...{ state, focusedField }}
                    />
                </div>
                <div className="checkout-details-title">Billing Info</div>
                <div className="checkout-additional-details">
                    <CheckoutAdditionalDetails id='first_name' text='First Name' {...{ state, changeState }} />
                    <CheckoutAdditionalDetails id='last_name' text='Last Name' {...{ state, changeState }} />
                </div>
                <div className="checkout-additional-details">
                    <CheckoutAdditionalDetails id='company_name' text='Company Name' {...{ state, changeState }} />
                    <CheckoutAdditionalDetails
                        id='billing_email'
                        text='Billing Email'
                        errorText='Please enter a valid email adress'
                        onBlur={validateEmail}
                        {...{ state, changeState }}
                    />
                </div>
            </div>
            <div className="checkout-middle"> </div>
            <div className="checkout-summary">
                <div className="checkout-little-header">Your plan</div>
                <div className="checkout-15text">{upperFirst(planDetails.name)}</div>
                <Divider />
                {planDetails.id === 'agents' &&
                    <Fragment>
                        <div className="checkout-little-header">Number of agents</div>
                        <Select
                            initialValue={2}
                            items={['3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25',]}
                            getItem={item => `${item}`}
                            onSelect={({ selectedItem }) => setState({ ...state, agentsNumber: selectedItem })}
                        />
                    </Fragment>
                }
                <div className="checkout-little-header">Billing</div>
                <Select
                    initialValue={state.billingPeriod}
                    items={['yearly', 'monthly']}
                    getItem={item => `${upperFirst(item)} plan`}
                    onSelect={({ selectedItem }) => setState({ ...state, billingPeriod: selectedItem })}
                    className='checkout-select-plan'
                />
                {/* <select value={state.billingPeriod}  className="checkout-select-plan">
                    <option value="yearly" defaultValue>Yearly plan</option>
                    <option value="monthly">Monthly plan</option>
                </select> */}
                <div className="checkout-plan-saving flex-row">
                    <span>{state.billingPeriod === 'yearly' ? 'Annual saving' : 'Switch to annual and save'}</span>
                    {planDetails.id !== 'agents' &&
                        <span>{planDetails.yearly_discount}$/yr</span>
                    }
                    {planDetails.id === 'agents' &&
                        <span>{12*state.agentsNumber*80*0.2}$/yr</span>
                    }
                </div>
                <Divider />
                <div className="checkout-little-header flex-row">
                    <span>Due Today</span>
                    <span>{finalPrice}$</span>
                </div>
                <div className="flex-row center">
                    <OrangeButton onClick={createSubscription} style={{ width: 180, height: 40, fontSize: 16 }} loading={state.loading}>
                        Subscribe Now
                    </OrangeButton>
                </div>
            </div>
        </div>        
  );
}

function CheckoutCardDetails({ id, text, state, errorText, focusedField }){
    const ref = useRef()
    return (
        <div className="checkhout-card-details">
            {text}
            <div ref={ref} id={id} className={`checkout-${id} ${focusedField === id ? 'is-focused' : ''} checkout-field-normal`} data-bluesnap={id}></div>
            <FieldError hasErrors={state.showTooltips[id]} anchorEl={ref.current}>{errorText}</FieldError>
        </div>
    )
}

function CheckoutAdditionalDetails({ id, text, state, errorText = 'Please fill out this field', changeState, onBlur }){
    const ref = useRef()
    return (
        <div className="checkout-additional-details-cell">
            {text}
            <input
                ref={ref}
                value={state[id]}
                onChange={changeState(id)}
                className="checkout-input-field checkout-field-normal"
                id={id}
                onBlur={onBlur}
            />
            <FieldError hasErrors={state.showTooltips[id]} anchorEl={ref.current}>{errorText}</FieldError>              
        </div>
    )
}

export default Checkout;

