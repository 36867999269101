import { LinearProgress } from "@material-ui/core"
import { useDrop } from "react-dnd"
import { NativeTypes } from "react-dnd-html5-backend"
import { useDispatch } from "react-redux"
import { setToastMessage } from "../../../App/actions"
import { uploadFiles } from "../../actions"

function FileUploadBlock({ block, blockId }){
  const dispatch = useDispatch()
  const [{ isActive }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    collect: monitor => ({ isActive: !!monitor.isOver() }),
    drop: dropped => {
      if(!dropped.files)
        return dispatch(setToastMessage('You tried to drop the wrong sort of element here', 'info'))

        uploadFiles(dropped.files, { blockId })
    }
  })
  const { fileId, totalBytes, bytesTransferred } = block.payload || {}
  return (
    <div className='note-file-upload-block'>
      {!fileId
        ? <label ref={drop} className={isActive ? 'is-active' : ''}>
            <div>Drop a file here or click to upload</div>
            <input type='file' onChange={e => uploadFiles(e.target.files, { blockId })} />
          </label>
        : <div className='uploading-file'>
            <div>Uploading...</div>
            <LinearProgress style={{ width: '60%' }} />
            <div>{Math.round(bytesTransferred / 100) / 10}kb / {Math.round(totalBytes / 100) / 10}kb</div>
          </div>
      }
    </div>
  )
}

export default FileUploadBlock