import { deleteCookie } from '../../utils/utils'
import { firebase } from '../..'
import { useContext } from "react"
import LoginContext from "./LoginContext"
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setToastMessage } from '../App/actions'

const useLogin = ({ isSignup = false, isResend = false } = {}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { state, setState, errors, setErrors, setLoading } = useContext(LoginContext)

  const handleLogin = async e => {
    e.preventDefault()
    const { email } = state
    const newErrors = {}
    if(!email.length)
      newErrors.email = true

    if(Object.keys(newErrors).length)
      return setErrors({ ...errors, ...newErrors })

    setLoading(true)

    try {
      const actionCodeSettings = { url: window.location.href.replace(/\/(login|signup)/i, '/signin'), handleCodeInApp: true }
      setLoading(true)
      await firebase.auth.sendSignInLinkToEmail(email, actionCodeSettings)
      window.localStorage.setItem('emailForSignIn', email)
      deleteCookie('schedule-demo-hidden') //chat_bot legacy...
      if(isResend)
        return dispatch(setToastMessage('Magic link resent successfully', 'success'))
      
      history.push(`/email-sent${isSignup ? '?signup' : ''}`)
    } catch(error) {
      setErrors({ ...errors, failed: true })
    }

    setLoading(false)
  }

  return handleLogin
}

export default useLogin