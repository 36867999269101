
import { OrangeButton } from '../Button'
import useCalendly from '../../helpers/useCalendly'
import ComingSoonImage from '../../images/Watchers/ComingSoon.svg'
import './style.scss'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export function WatchersBookDemo(){
  return (
    <BookADemo
      title='Get Proactive with Watchers'
      subtitle='Watchers monitor customer relationships and mine them for meaningful signals to predict potential business outcomes.'
      image={<img src={ComingSoonImage} />}
    />
  )
}

export function AppsBookDemo(){
  const premiumApps = useSelector(state => state.global.connectorSpecs.filter(c => c.is_premium))

  const image = useMemo(() => (
    <div className='book-a-demo-apps'> 
      {premiumApps.map(app => <div key={app.id} className='app-icon-wrapper card'><img src={app.icon_url} /></div>)}
    </div>
  ), [])

  return (
    <BookADemo
      title='Get More with Premium'
      subtitle='Use premium apps to make the most out of your Plantt workspace.'
      image={image}
    />
  )
}

function BookADemo({ title, subtitle, image }){
  const { openCalendly } = useCalendly()
  return (
    <div id='book-a-demo'>
      <div className='book-a-demo-title'>{title}</div>
      <div className='book-a-demo-subtitle'>{subtitle}</div>
      {image}
      <div className='book-a-demo-footer'>
        Book a demo to learn more
        <OrangeButton rounded onClick={openCalendly}>Set a call with us</OrangeButton>
      </div>
    </div>
  )
}

export default BookADemo