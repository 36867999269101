import firebase from 'firebase/app';

async function parseResponse(response){
  const text = await response.text(); // Parse it as text
  try {
    const jsonData = JSON.parse(text); // Try to parse it as json
    return await jsonData;
  } catch (err) {
    return text;
  }
}

async function NEW_parseResponse(response){
  let text = await response.text() // Parse it as text
  try {
    const jsonData = JSON.parse(text) // Try to parse it as json
    text = jsonData;
  } catch (err) {
    //
  }
  
  return { text, ok: response.ok, status: response.status }
}


async function getRequestOptions(options = {}){
  const PlanttAccountID = localStorage.getItem('PlanttAccountID')
  const authToken = await firebase.auth().currentUser.getIdToken().then(idToken => idToken)
  return {
    ...options,
    headers: {
      Authorization: `token ${authToken}`,
      'Content-Type': 'application/json',
      'X-PlanttAccountID':  PlanttAccountID,
      'Connection': 'keep-alive',
      'Keep-Alive': 'timeout=0, max=1000',
      ...(options.headers || {})
    }
  }
}

export async function NEW_sendPostRequest(url = '', options, data) {
  const requestOptions = await getRequestOptions(options);
  
  const response = await fetch(url, {
    method: 'POST',
    ...requestOptions,
    body: data
  })
  const responseText = await parseResponse(response)
  return {
    text: responseText,
    ok: response.ok
  }
}

export async function NEW_sendDeleteRequest(url = '', options) {
  const requestOptions = await getRequestOptions(options);
  const response = await fetch(url, {
    method: 'DELETE',
    ...requestOptions
  });
  
  return NEW_parseResponse(response);
}



export async function NEW_sendPutRequest(url = '', options, data) {
  const requestOptions = await getRequestOptions(options);
  const response = await fetch(url, {
    method: 'PUT',
    ...requestOptions,
    body: data
  })

  const responseText = await parseResponse(response)
  return {
    text: responseText,
    ok: response.ok
  }
}

export async function NEW_sendGetRequest(url = '', options, params = {}) {
  const requestOptions = await getRequestOptions(options);
  const requestUrl = new URL(url);
  Object.keys(params).forEach(key => requestUrl.searchParams.append(key, params[key]));
  const response = await fetch(requestUrl, {
    method: 'GET',
    ...requestOptions,
  });

  return NEW_parseResponse(response);
}

export async function sendPostRequest(url = '', options, data) {
  const requestOptions = await getRequestOptions(options);
  const response = await fetch(url, {
    method: 'POST',
    ...requestOptions,
    body: data
  });

  return parseResponse(response);
}

export async function sendPutRequest(url = '', options, data) {
  const requestOptions = await getRequestOptions(options);
  const response = await fetch(url, {
    method: 'PUT',
    ...requestOptions,
    body: data
  });
  return parseResponse(response);
}

export async function sendGetRequest(url = '', options = {}, params = {}) {
  const requestOptions = await getRequestOptions();
  const requestUrl = new URL(url);
  Object.keys(params).forEach(key => requestUrl.searchParams.append(key, params[key]));
  const response = await fetch(requestUrl, {
    method: 'GET',
    ...requestOptions,
  });
  return parseResponse(response);
}

export async function sendDeleteRequest(url = '', options) {
  const requestOptions = await getRequestOptions(options);
  const response = await fetch(url, {
    method: 'DELETE',
    ...requestOptions
  });
  
  return parseResponse(response);
}
