import RelationshipScoreToken from "../../../components/RelationshipScoreToken"
import ContactHeatMap from "./ContactHeatMap"
import { ContactAlerts } from "../../../components/Tooltip"
import { getContactName } from "../helpers"
import Tooltip from "../../../components/Tooltip/new"

const heatmapSquareSize = 12
const columns = [
  {
    name: 'Name',
    Content: ({ item }) => {
      const name = getContactName(item)
      const truncated = name.slice(0, 20)
      if(name !== truncated)
        return truncated + '...'
      return name
    }
  },
  {
    name: 'Title',
    Content: ({ item }) => {
      const title = item.fields?.title || null
      if(!title)
        return null

      const truncated = title.slice(0, 20)
      return title !== truncated ? `${truncated}...` : title
    }
  },
  { name: 'Activity', Content: ({ item }) => (
    <ContactHeatMap squareSize={heatmapSquareSize} activity={item.activity} />
  )},
  {
    id: 'tokens',
    Content: ({ item }) => {
      const relationshipScore = Math.round(item.relationship_score)
      const tooltipProps = { lean: true, placement: 'bottom', enterDelay: 400, enterNextDelay: 400, styles: { wrapper: { maxWidth: 140 } } }
      return (
        <div style={{ display: 'grid', gridTemplateRows: '1fr', gridGap: 20, gridTemplateColumns: '1fr 1fr 1fr', width: '100%' }}>
          {relationshipScore
            ? <Tooltip {...tooltipProps} title='Relationship Score' description='Based on the level of engagement with your user.'>
                <RelationshipScoreToken score={relationshipScore} />
              </Tooltip>
            : <div></div>
          }
          {item.usage_score
            ? <Tooltip {...tooltipProps} title='Usage Score' description={`Based on the passing month's average usage.`}>
                <div className='usage-score-token' style={{ width: '100%' }}>{Math.round(item.usage_score * 10) / 10}</div>
              </Tooltip>
            : <div></div>
          }
          <ContactAlerts contact={item} />
        </div>
      )
    }
  },
  // { Content: MakeCheckbox, width: '28px' },
  // { Content: MakeStar, width: '36px' },
  // { name: 'Email', Content: ({ item }) => item.fields.email || null },
  // { name: 'Label', Content: ({ item }) => item.label ? <div className='contact-label'>{item.label}</div> : null },
]

// function MakeCheckbox({ item }){
//   const checked = useSelector(state => Boolean(state.accounts.contacts.checked.find(id => id === item.id)))
//   return <Checkbox checked={checked} style={{ marginLeft: 'auto', marginRight: checked ? -2 : undefined }} />
// }

// function MakeStar({ item }) {
//   return (
//     <div className='contact-star-container' data-clicked-star>  
//       {item.starred ? <Star className='contact-star' /> : <StarBorder className='contact-star-border' />}
//     </div>
//   )
// }

export default columns