import { CheckBox } from "@material-ui/icons";

import './Checkbox.scss'

function Checkbox({ showUnchecked = true, checked, ...props }){
  const display = !showUnchecked ? 'none' : undefined
  
  return (
    <div data-checkbox-perimeter>
      {checked
        ? <CheckBox
            {...props}
            className={`checked ${props.className || ''}`}
          />
        : <div
            {...props}
            className={`unchecked ${props.className || ''}`}
            style={{ ...(props.style || {}), display }}
          />
      }
    </div>
  )
}

export default Checkbox