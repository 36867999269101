import { useLayoutEffect, useState } from 'react';
import { parse } from 'query-string';
import { useHistory } from 'react-router-dom';
import { firebase } from '../..';

const checkRegistration = Component => {
  return () => {
    const history = useHistory()
    const [showLogin, setShowLogin] = useState(false)

    useLayoutEffect(() => {
      const unsubscribe = firebase.auth.onAuthStateChanged(user => {
        if(!user)
          return setShowLogin(true)

        const { redirect = '/' } = parse(history.location.search)
        window.location = redirect // This is here instead of history.replace because of some loading animation issues...
      })

      return unsubscribe
    }, [])

    if(!showLogin)
      return null

    return <Component />;
  }
}

export { checkRegistration };
