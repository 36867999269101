import { useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoginContext from "../LoginContext";

function Message(){
  const { resetStatus, errors, state } = useContext(LoginContext)
  const loginError = useSelector(state => state.global.loginError)

  if(resetStatus){
    switch(resetStatus){
      case 'auth/user-not-found':
        return (
          <div className='sign-in-error'>
            The email address you entered is not registered with plantt.
            <Link to='/signup'>Would you like to sign up?</Link>
          </div>
        )
      case 'auth/too-many-requests':
        return <div className='sign-in-error'>You tried to reset the password for this account too many times.</div>
      case 'success':
        return (
          <div id='reset-password-success'>
            You will receive an email message with instructions for resetting your password within 5 minutes.
            Did not get it? Make sure to check your spam folder.
          </div>
        )
    }
  }else if(errors.email || errors.password || errors.failed){
    return (
      <div className='sign-in-error'>
        The username or password you entered are incorrect.
        <Link to='/password-reset'>Did you forget your password?</Link>
      </div>
    )
  }

  switch(loginError){
    case 1:
      return (
        <div className='sign-in-error'>
          The email address you tried to sign up with is already registered with Plantt.
          <Link to='/login'>Sign in with your registered credentials.</Link>
        </div>
      )
    case 2:
      return (
        <div className='sign-in-error'>
          An error occured while trying to connect you to Plantt through google. Please try again or contact support.
        </div>
      )
    case 3:
      return (
        <div className='sign-in-error'>
          You tried to log in with an email that is not registered with plantt.
          <Link to='/signup'>Would you like to sign up?</Link>
        </div>
      )
    default:
      return null
  }
}

export default Message