/* eslint-disable react-hooks/rules-of-hooks */

import { useSelector } from "react-redux"
import { blocksFromTemplate, replaceInitialBlock } from "../actions"
import TemplateIcon from "../partials/TemplateIcon"

function InitialBlock({ readonly }){
  return ({ blockId }) => {
    const templates = useSelector(state => Object.entries(state.notes.templates))
    const defaultTemplates = useSelector(state => state.notes.defaultTemplates)

    return (
      <div className='note-initial-block'>
        <div onClick={() => !readonly && replaceInitialBlock(blockId)}>
          Start typing your thread here, or type '/' to access the actions menu and '#' to add tags
        </div>
        <div id='note-templates'>
          {templates.length > 0 &&
          <>
            <div id='templates-title'>CUSTOM TEMPLATES</div>
            {templates.map(([id, template]) => <RenderTemplate key={id} id={id} {...template} blockId={blockId} />)}
          </>}
          <div id='templates-title'>GENERAL TEMPLATES</div>
          {defaultTemplates.map(template => <RenderTemplate key={template.id} {...template} blockId={blockId} />)}
        </div>
      </div>
    )
  }
}


function RenderTemplate({ id, name, blocks, metadata, blockId }){
  return (
    <div className='note-templates-item' onClick={() => replaceInitialBlock(blockId, blocks, metadata || name)}>
      <TemplateIcon id={id} />
      {name || blocks?.noteTitle?.payload.text}
    </div>
  )
}

export default InitialBlock