import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { setAuthenticationStatus, updateConnectorAuthenticationInfoNotOauth } from '../actions'
import AuthField from '../AddApp/AuthField'
import { setToastMessage } from '../../App/actions'
import PlanttModal from '../../../components/PlanttModal'
import { BlueButton, OrangeButton } from '../../../components/Button'

function ConnectButton({ connectorSpec, currentConnector }) {
    const { section } = useParams()

    const dispatch = useDispatch()
    const [updateClicked, setupdateClicked] = useState(false)
    const [showUpdateOtherFields, setshowUpdateOtherFields] = useState(false)
    const authenticationStatus = useSelector(state => state.connectors.authenticationStatus)
    const authenticationError = useSelector(state => state.connectors.errors.authenticationPageError)

    useEffect(() => {
        dispatch(setAuthenticationStatus(0))
    }, [])

    useEffect(() => {
        if(authenticationStatus === 3 && section !== 'mapping') { //Second condition here is a TEMP bypass to issue when first authorizing...
            dispatch(setToastMessage('Reauthorization succeeded!','success'))
            setupdateClicked(false)
            close()
        }
        if(authenticationStatus === 2)
            dispatch(setToastMessage(`Reauthorization failed: ${authenticationError}`,'error'))

    }, [authenticationStatus])

    const createAuthFieldsInitialValue = () => {
        const fields = connectorSpec.authentication.auth_fields
        const stateObject = {}
        Object.values(fields).forEach(field => {
                stateObject[field.key] = currentConnector?.authentication.auth_config[field.key] || ''
        })  
        return stateObject
    }

    const [authFieldsValues, setauthFieldsValues] = useState(createAuthFieldsInitialValue())
    const handleFieldChange = (key, value) => setauthFieldsValues({ ...authFieldsValues, [key]: value })

    const moveForward = () => {
        setupdateClicked(true)

        for(const value of Object.values(authFieldsValues)) {
            if(value === '')
                return console.error('Not all values were filled')
        }

        dispatch(updateConnectorAuthenticationInfoNotOauth(currentConnector.id, authFieldsValues))
    }
    const startReauthorize = () => {
        if(connectorSpec.authentication?.type !== 'oauth2'){
            if(connectorSpec.authentication?.type === 'other')
                setshowUpdateOtherFields(true)
            return
        }

        let qParams = `redirect_uri=${window.location.origin}/inte/${connectorSpec.authentication.payload.oauth_redirect_name}/install/handle-user-decision`
        Object.entries(connectorSpec?.authentication?.payload?.params).map(([key,value]) => qParams += `&${key}=${value}`);
        localStorage.setItem('isReauthorizingConnector',JSON.stringify({instanceId: currentConnector.id, timestamp: new Date().getTime()}))

        let target = connectorSpec.id === 'zendesk2'
            ? `https://${currentConnector.authentication.auth_config.zendesk_subdomain}.zendesk.com/oauth/authorizations/new?redirect_uri=https://app.plantt.io/zendesk-login/handle_user_decision&scope=read write&client_id=zdg-plantt-zendesk&response_type=code`
            : `${connectorSpec.authentication.payload.oauth_url}?${qParams}`

        window.location.assign(target)
    }

    const close = () => {
        setshowUpdateOtherFields(false)

        const empty = { ...authFieldsValues }
        Object.keys(empty).forEach(key => empty[key] = '')
        setauthFieldsValues(empty)
    }
    
    return (
        <>
            {currentConnector
                ? <BlueButton outline onClick={startReauthorize}>Reauthorize</BlueButton>
                : <OrangeButton>Connect</OrangeButton>
            }
            <PlanttModal
                title={`Update ${currentConnector.name}'s Authorization Details`}
                open={showUpdateOtherFields}
                onClose={close}
                buttons={<AuthModalButtons close={close} save={moveForward} loading={authenticationStatus === 1} />}
            >
                <div className="connector-fields" style={{ margin: '0 12px' }}>
                    {Object.values(connectorSpec.authentication.auth_fields).map((field, index)=>(
                        <AuthField {...{ field, moveForward, handleFieldChange, authFieldsValues }} nextClicked={updateClicked} key={index} />
                    ))}                                
                </div>
            </PlanttModal>
        </>
    )
}

function AuthModalButtons({ close, save, loading }){
    return (
        <>
            <BlueButton outline onClick={close}>Cancel</BlueButton>
            <BlueButton onClick={save} loading={loading}>Save</BlueButton>
        </>
    )
}
export default ConnectButton