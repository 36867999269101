const urlGen = version => process.env.REACT_APP_ENV === 'production' ? `https://api.cloud.plantt.io/api/${version}/` : `https://api.staging.plantt.io/api/${version}/`
// const urlGen = version => `https://78fe13027f90.ngrok.io/api/${version}/`
export const BASE_URL = urlGen('v1')
export const BASE_URL2 = urlGen('v2')

export const CREATE_ACCOUNT_URL = `${BASE_URL}account`
export const CREATE_ACCOUNT_HOOK_URL = 'https://hooks.zapier.com/hooks/catch/6101614/o92wkls/';
export const UPDATE_ACCOUNT_HOOK_URL = 'https://hooks.zapier.com/hooks/catch/6101614/o9jzn2k/';
export const GET_ACCOUNT_URL = `${BASE_URL}account`
export const GET_ACCOUNTS_URL = `${BASE_URL}accounts`
export const GET_USER_PROFILE = `${BASE_URL}user_profile`
export const UPDATE_ACCOUNT_URL = `${BASE_URL}account`
export const INTEGRATION_INSTALL_URL = `${BASE_URL}integrations/install`
export const DIALOGS_INTEGRATIONS_URL = `https://v2-api.sheety.co/56ee1b268fd3e52f3ca49611de7efb91/dialogs/integrations`
export const SUBMIT_TYPE_FORM_URL = `https://hooks.zapier.com/hooks/catch/6101614/o5wkf4n/`
export const AUTOMATIONS_URL = `${BASE_URL}automation`
export const GET_AUTOMATIONS_URL = `${BASE_URL}automations`
export const ACCOUNT_INVITE_COMPLETE = `${BASE_URL}account/invite/complete`
export const LOAD_INTENTS_URL = `${BASE_URL}intents`
export const ADD_INTENTS_URL = `${BASE_URL}automations/intent/`
export const GET_LIBRARY_URL = `${BASE_URL}automations/library`
export const GET_INTENT_URL  = `${BASE_URL}intent`