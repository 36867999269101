import { ReactEditor } from "cs-slate-react"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { store } from "../../.."
import UserAvatar from "../../../components/UserAvatar"
import { liftLock } from "../actions"
import { blocksRef } from "../useGistHistory"

function LockHandler({ blockId, block, lock }){
  if(!lock)
    lock = block?.lock

  const uid = useSelector(state => state.firebase.auth.uid)
  const lockingCollaborator = useSelector(state => state.notes.currentNote.collaborators?.[lock?.by])

  useEffect(() => {
    if(!lockingCollaborator && lock)
      liftLock(blockId)
  }, [lockingCollaborator])

  useEffect(() => {
    const focusedEditor = store.getState().notes.focusedEditor
    const { editor } = blocksRef.current[focusedEditor] || {}
    if(editor && lock && focusedEditor === blockId){
      if(lock.by !== uid){
        ReactEditor.blur(editor)
      }else{
        const blurTimeout = setTimeout(() => {
          const { editor } = blocksRef.current[focusedEditor]
          if(ReactEditor.isFocused(editor)){
            ReactEditor.blur(editor)
            liftLock(blockId)
          }
        }, 30000)
        return () => clearTimeout(blurTimeout)
      }
    }
  }, [lock])

  const { name, email, avatar_url } = lockingCollaborator || {}
  return lock?.by !== uid && lockingCollaborator ? <UserAvatar name={name || email} url={avatar_url} /> : null
}

export default LockHandler