import React from 'react';
import './style.scss';

function Scrollable({ className, children, ...props }, ref){
  return (
    <div 
      ref={ref}
      className={`layout-scrollbar scrollable-container ${className || ''}`}
      {...props}
    >
      {children}
    </div>
  )
}

export default React.forwardRef(Scrollable);