import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import { withStyles }from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { setSettingsPropertyByPath, updatingGeneralSettingsDismissed, updateGeneralSettings } from '../actions';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const rootColor = color => () => ({ root: {color }});

const SuccessIcon = withStyles(rootColor('green'))(CheckIcon);
const FailureIcon = withStyles(rootColor('red'))(CloseIcon);

const ActiveSwitch = withStyles(() => ({
  root: {
    marginTop: 2,
    width: 75,
    height: 28,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: '6px 5px',
    color: 'white',
    '&:after': {
      content: '"OFF"',
      width: '93px',
      position: 'absolute',
      fontSize: '13px',
      left: '4px',
    },
    '&$checked': {
      transform: 'translateX(50px)',
      color: 'white',
      '&:after': {
        content: 'none',
      },
      '&:before': {
        content: '"ON"',
        width: '93px',
        position: 'absolute',
        fontSize: '13px',
        right: '4px',
      },
      '& + $track': {
        opacity: 1,
        backgroundColor: 'green',
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',

  },
  track: {
    borderRadius: 15,
    opacity: 1,
    backgroundColor: '#cacfd2',
  },
  checked: { },
}))(Switch);

function FormInput({ name, value, saveToRedux, type, step }){
    const [localValue, setLocalValue] = useState(value);
  
    return (
      <input
        name={name}
        value={localValue}
        onChange={e => setLocalValue(e.target.value)}
        onBlur={() => saveToRedux(localValue)}
        type={type}
        step={step}
      />
    );
}

function FormElement({name, type, value, path}) {
    const dispatch = useDispatch();
    const saveToRedux = value => dispatch(setSettingsPropertyByPath(value, path));
    const inputProps = { name, value, saveToRedux };

    switch(type){
        case 'str':
          return <FormInput {...inputProps} step='1'/>;
        case 'float':
          return <FormInput {...inputProps} type='number' step='0.01'/>;
        case 'int':
          return <FormInput {...inputProps} type='number'/>;
        case 'bool':
          return <ActiveSwitch name={name} checked={value} onChange={e => saveToRedux(e.target.checked)} />;
    }
}

export function FormAlert() {
  const updating = useSelector(state => state.setting.updatingSettings);
  const open = updating!==true && updating!==false ? true : false;
  const dispatch = useDispatch();

  const dismiss = () => dispatch(updatingGeneralSettingsDismissed());

  return (
      <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}
        open={open} onClose={dismiss} autoHideDuration={4000}
        message={updating === 'failed'
         ? <div><FailureIcon/>Oops! An error occured while trying to update your settings.</div>
         : <div><SuccessIcon/>Settings updated succesfully!</div>
        }
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={dismiss}
          >
            <CloseIcon />
          </IconButton>
        }
      />
  );
}

export function SettingsTitle({title, description, onClick, open}) {
    const arrowElem = () => {
      if(open) return <KeyboardArrowUpIcon className='float-right'/>
      return <KeyboardArrowDownIcon className='float-right'/>
    }
    return (
      <div className='settings-row general' onClick={onClick}>
          <div className='settingImage'/>
          <div>
            <div className='title'>{title}</div>
            <div className='explanation'>{description || null}</div>
          </div>
          {arrowElem()}
      </div>
    );
  }
  
  export function SettingsItems({items, path}) {
    const settings = useSelector(state => state.setting.generalSettings);
    const updating = useSelector(state => state.setting.updatingSettings);
    const dispatch = useDispatch();

    const onSubmit = (event) => {
      event.preventDefault();
      dispatch(updateGeneralSettings(settings));
    }

    const renderItems = () => {
      return items.map(({ identifier, label, type, value }, index) => {
        let itemPath = `${path}.items.${index}`;
        return (
          <div className='item' key={identifier}>
            <label htmlFor={identifier}>{label}</label>
            <FormElement 
              name={identifier}
              type={type}
              value={value}
              path={itemPath}
            />
          </div>
        );
      });
    }
  
    return (
      <div>
        <div className='settingsItems'>
          {renderItems()}
        </div>
        <button
          className='settingsButton'
          onClick={onSubmit}
        >
          { updating === true ? '...' : 'Save' }
        </button>
      </div>
    )
  }