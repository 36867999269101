import { Fragment, useMemo } from "react"

import { Warning } from "@material-ui/icons"
import Tooltip from "./new"

function ContactAlerts({ contact, entity = 'Contact', tooltipProps, ...props }){
  const theme = { color: '#19270d', backgroundColor: '#fff', borderColor: '#fd6c4b'}
  const alerts = useMemo(() => contact.notifications || contact.alerts || [], [contact])
  // const alerts = ['Hi there', 'And hello to you too']
  const content = useMemo(() => (
    <Fragment>
      <div className='contact-alerts-tooltip-title'>
        {entity} Alerts
      </div>
      <div className='contact-alerts-tooltip-content'>
        {alerts.map(alert => (
          <div className='alerts-item' key={alert}>
            <div className='item-dot'></div> {alert}
          </div>
        ))}
      </div>
    </Fragment>
  ), [alerts])

  return alerts.length ? (
    <Tooltip {...{ content, theme }} styles={{ wrapper: { minWidth: 260 }}} {...tooltipProps}>
      <div className='account-contact-alert' {...props}><Warning /> {alerts.length}</div>
    </Tooltip>
  ) : null
}

export default ContactAlerts