import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { parse } from 'query-string'

import FieldError from '../../../components/FormControls/FieldError'
import { OrangeButton } from '../../../components/Button'
import { finalizeInvite, finalizeRegistration } from '../actions'

import Logo from '../../../images/logo.svg'
import PlanttBee from '../../../images/PlanttBee.svg'
import '../style.scss'
import { setGlobalLoading } from '../../App/actions'
import { firebase, store } from '../../..'

// export const goToRegistration = email => async dispatch => {
//   const data = await firebase.auth().fetchSignInMethodsForEmail(email)

//   if (data?.length)
//     return dispatch(setLoginError(1))

//   window.Intercom('boot', {
//     hide_default_launcher: true, 
//     app_id: 'wdoiy2sm',  
//     email: email
//   })
//   history.push(`/signup?email=${btoa(email)}`)
// }

function Registration() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { base64 } = useParams()
  const inviteData = useMemo(() => base64 ? JSON.parse(atob(base64)) : null, [base64])
  const displayName = useSelector(state => state.firebase.auth.displayName)
  const authLoading = useSelector(state => !state.firebase.auth.isLoaded)

  useLayoutEffect(() => {
    if(history.location.pathname.startsWith('/invite') && store.getState().firebase.auth.uid){
      firebase.auth.signOut()
      localStorage.removeItem('PlanttAccountID')
    }
  }, [history.location.pathname, authLoading])

  const email = useMemo(() => {
    let { email } = parse(history.location.search)
    if(!email)
      return null

    return atob(email)
  }, [history.location.search])

  const [state, setState] = useState({ company: '', name: inviteData?.name || '', email: '' })
  const [errors, setErrors] = useState({ company: false, name: false, email: false })
  const [loading, setLoading] = useState(false)

  const changeState = (changes) => {
    setState({ ...state, ...changes })

    const newErrors = { ...errors }
    Object.keys(changes).forEach(key => newErrors[key] = false)
    setErrors(newErrors)
  }

  const onSubmit = async e => {
    if(loading)
      return
    e.preventDefault()
    let hasErrors = false
    const newErrors = { ...errors }

    const minLen = {}
    if(!inviteData)
      minLen.company = 1
    else
      minLen.email = 1
    if(!displayName || (inviteData && !inviteData.name))
      minLen.name = 1
    for(const key in minLen)
      if((state[key].trim().length || 0) < minLen[key])
        newErrors[key] = hasErrors = true
    
    if(!state.email.includes('@'))
      newErrors.email = 'invalid'
    
    if(hasErrors)
      return setErrors(newErrors)
    
    setLoading(true)
    if(!inviteData){
      dispatch(finalizeRegistration({ ...state, name: state.name || displayName, email }))
    }else{
      const result = await dispatch(finalizeInvite({ ...inviteData, ...state }))
      if(result?.error){
        if(result.error.message.includes('email invalid'))
          setErrors({ ...errors, email: 'incorrect' })
        setLoading(false)
      }
    }
  }

  return !authLoading ? (
    <div>
      <img src={Logo} id='sign-in-logo' />
      <h1 className='is-signup'>Welcome to Plantt!</h1>
      <h2>Complete your account details</h2>
      <form onSubmit={onSubmit} id='registration-form'>
        {inviteData &&
        <InputWithError
          name='email'
          text='Confirm your email address'
          errorText={['invalid', 'incorrect'].includes(errors.email) ? 'Please enter a valid email address' : 'Please fill in your email address'}
          autoFocus
          {...{ errors, state, changeState }}
        />}
        {!displayName && !inviteData?.name &&
        <InputWithError
          name='name'
          text='Full Name'
          errorText='Please fill in a name'
          autoFocus={!inviteData}
          {...{ errors, state, changeState }}
        />}
        {!inviteData &&
        <InputWithError
          name='company'
          text={`What's your company name?`}
          errorText='Please enter your company name'
          {...{ errors, state, changeState }}
        />}
        <OrangeButton padding='6px 0' loading={loading} style={{ marginTop: 52 }}>Continue</OrangeButton>
      </form>
      <div id='sign-in-bottom'>
        <img src={PlanttBee} id='sign-in-plantt-bee' />
      </div>
    </div>
  ) : null
}

function InputWithError({ name, text, errorText, errors, state, changeState, autoFocus = false }){
  const ref = useRef()

  useEffect(() => {
    if(autoFocus)
      ref.current?.focus()
  }, [ref.current])
  return (
    <>
      <label ref={ref}>
        {text}
        <input style={errors[name] ? { borderColor: '#fd6c4b' } : undefined} value={state[name]} onChange={e => changeState({ [name]: e.target.value })} />
      </label>
      <FieldError hasErrors={errors[name]} anchorEl={ref.current} containerEl={ref.current}>{errorText}</FieldError>
    </>
  )
}

export default Registration