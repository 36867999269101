import { useDrag } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'

import WidgetBlock, { ToggleBlock, RenderBlock as RenderWidgetBlock } from '../../GistEditor/Blocks/Widget'
import { DOCK_WIDGET } from '../../GistEditor/constants'
import Scrollable from '../../../components/Scrollable'
import { setDisplayedInDock } from '../actions'
import { fetchDock, clearDockData } from '../../GistEditor/actions'

import Plantt from './Plantt'
import ActionButton from '../partials/ActionButton'
import { currentAppSelector, useCloseDock } from './helpers'
import LoadingText from '../../../components/LoadingIndicator/LoadingText'
import PlanttRedLeaves from '../../../images/PlanttRedLeaves.svg'
import PlanttSun from '../../../images/PlanttSun.svg'

import { Rnd } from 'react-rnd'
import { matchPath, useLocation } from 'react-router-dom'
import TableBlock, { TableCard } from '../../GistEditor/Blocks/Table'
import { BlueButton } from '../../../components/Button'
import { dockEmpty } from '../helpers'

function DockContent({ transitionLength, transitioning, setTransitioning, appsDockWidth, setAppsDockWidth, current, setCurrent }) {
  const location = useLocation()
  const dispatch = useDispatch()
  const { currentApp, currentAppId, currentDockData, displayedApp } = useSelector(currentAppSelector)

  useCloseDock()

  const previousAccountId = useRef() // This is in place mostly for trouble with hot reloading
  useEffect(() => {
    const { accountId } = matchPath(location.pathname, { path: '/account/:accountId' })?.params || {}
    if(previousAccountId.current !== accountId){
      dispatch(clearDockData())
      previousAccountId.current = accountId
    }
  }, [location.pathname])

  const doFetch = async () => dispatch(fetchDock({ appId: currentAppId, specRef: displayedApp }))

  useLayoutEffect(() => {
    if ((!current && currentApp) || (current && !currentApp)) {
      setTransitioning(true)
      setTimeout(() => setTransitioning(false), transitionLength)
    }

    if (!currentApp && current)
      setTimeout(() => setCurrent(currentApp), transitionLength)
    else
      setTimeout(setCurrent(currentApp))

    doFetch()
  }, [currentApp])
  // The "current" state is used here to create a delay in the disappearance of app content,
  // to make sure it will disappear only when dock is fully closed.

  const isPlantt = displayedApp === 'plantt'

  const titleRef = useRef()
  const [scrollableHeight, setScrollableHeight] = useState('100%')
  useEffect(() => {
    if (titleRef.current)
      setTimeout(() => setScrollableHeight(`calc(100% - ${titleRef.current?.offsetHeight || 0}px)`), 100)
  }, [titleRef.current, currentDockData?.actions])

  return (
    <Rnd
      id='apps-dock-content'
      onResize={(e, direction, ref) => setAppsDockWidth(ref.offsetWidth)}
      size={{ height: '100%', width: current && (currentApp || !transitioning) ? appsDockWidth : 0 }}
      style={{ position: 'relative', transition: transitioning ? `width ${transitionLength / 1000}s` : undefined }}
      disableDragging
      enableResizing={{ left: true }}
      minWidth={current && !transitioning ? 380 : 0}
      maxWidth={760}
    >
      <h2 style={{ minWidth: current ? appsDockWidth : undefined }} ref={titleRef}>
        {current?.name} <i className='fal fa-times' onClick={() => dispatch(setDisplayedInDock(null))} />
        {currentDockData?.actions?.length > 0 &&
          <div id='dock-action-buttons'>
            {currentDockData.actions.map(action => <ActionButton specRef={displayedApp} {...{ RenderButton, ...action }} key={action.id} />)}
          </div>}
      </h2>
      <Scrollable style={{ minWidth: current ? appsDockWidth : undefined, height: scrollableHeight }}>
        {!isPlantt && currentDockData && !currentDockData.loading && (!currentDockData.error && currentDockData.blocks?.length
          ? currentDockData.blocks.map((block, index) => (
              <RenderBlock key={displayedApp + index} block={block} isTop blockIndex={index} specRef={displayedApp} inDock />
            ))
          : <RenderError {...currentDockData} appName={current?.name} doFetch={doFetch} />
        )}
        {isPlantt && <Plantt />}
        {currentDockData?.loading && <LoadingText />}
      </Scrollable>
    </Rnd>
  )
}

const RenderButton = (props) => <BlueButton outline {...props} />

export function RenderError({ error, blocks, appName, doFetch }) {
  return (
    <div id='apps-dock-error'>
      {error?.error === 'not_found' || (blocks && dockEmpty({ error, blocks }))
        ? <>
          We couldn't find any data related to this account on {appName}
          <div id='no-data-images'>
            <img src={PlanttRedLeaves} />
            <img src={PlanttSun} />
          </div>
        </>
        : <>
          <div>Something went wrong while fetching from {appName}...</div>
          <div className='another-try'>
            Give it another try, and please let us know if the error persists
            <i className='far fa-redo-alt' onMouseUp={() => setTimeout(doFetch, 200)} />
          </div>
        </>
      }
    </div>
  )
}

function RenderDraggableBlock({ block }) {
  // eslint-disable-next-line
  const [_, drag] = useDrag({
    item: { type: DOCK_WIDGET, payload: block.dragPayload },
    // collect: monitor => ({ isDragging: !!monitor.isDragging() })
  })

  return (
    <div ref={drag}>
      <RenderBlock block={block} isDraggable />
    </div>
  )
}

export function RenderBlock({ block, isDraggable = false, isTop = false, inDock = false, ...props }) {
  if (block.dragPayload && !isDraggable)
    return <RenderDraggableBlock block={block} />

  switch (block.type) {
    case 'basic':
    case 'card':
      return <RenderWidgetBlock block={block} truncateCardValueAt={15} />
    case 'toggle':
      return <ToggleBlock {...block} BlockComponent={inDock ? RenderBlock : undefined} noSideLine={isTop} openByDefault={isTop} />
    case 'widget':
      return <WidgetBlock block={block} BlockComponent={inDock ? RenderBlock : undefined} />
    case 'table':
      return <TableBlock block={block} {...props} cardsOnly={inDock} RenderCard={inDock ? RenderCard : undefined} />
    default:
      return null
  }
}

function RenderCard(props){
    // eslint-disable-next-line
    const [_, drag] = useDrag({ item: { type: DOCK_WIDGET, payload: props.object.dragPayload } })
    return <div ref={drag}><TableCard {...props} /></div>
}

export default DockContent