import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import currency from 'currency.js'

import OverviewBlock from './OverviewBlock'
import Tooltip from '../../../components/Tooltip/new'
import Scrollable from '../../../components/Scrollable'
import { computeFieldValue } from '../../../helpers'

function Trackables(){
  const trackables = useSelector(state => state.accounts.trackables)

  return (
    <OverviewBlock title='Trends' tooltipProps={{ title: 'Trends', description: 'Plantt keeps track of important business data and outcomes' }}>
      <Scrollable className='trackables'>
        <div className='trackables-content'>
          {trackables.map(trackable => <RenderTrackable key={trackable.label} {...trackable} />)}
        </div>
      </Scrollable>
    </OverviewBlock>
  )
}

function RenderTrackable({ label, value, trend, field_type }){
  const tooltipText = useMemo(() => {
    const trendText = trend > 0 ? 'increased' : trend < 0 ? 'decreased' : 'not changed'
    return <div style={{ color: '#ccc' }}>{label} has <b style={{ color: '#fff' }}>{trendText}</b> in the last 14 days</div>
  }, [label, trend])

  const text = typeof value !== 'undefined' ? computeFieldValue(field_type, value) : value

  return (
    <Tooltip enterNextDelay={400} enterDelay={400} styles={{ wrapper: { maxWidth: 120, textAlign: 'center' } }} lean content={tooltipText} placement='bottom'>
      <div>
        <div>{label}</div>
        <div className='trackable-value'>
          <div className={`value-dot ${trend > 0 ? 'positive' : trend < 0 ? 'negative' : ''}`}></div>
          {text || 'N/A'}
        </div>
      </div>
    </Tooltip>
  )
}

export default Trackables