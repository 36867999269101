export const SET_HOVERED_ROW = 'PlanttTable/SET_HOVERED_ROW'
export const setHoveredRow = hovered => ({ type: SET_HOVERED_ROW, hovered })


export const initialState = () => ({ hoveredRow: null })

function tableReducer(state = initialState(), action) {
  switch (action.type) {
    case SET_HOVERED_ROW: {
      return { ...state, hoveredRow: action.hovered || null }
    }
    default:
      return state;
  }
}

export default tableReducer