import { DragIndicatorRounded } from "@material-ui/icons"
import { Fragment, useEffect, useRef } from "react"
import { useDrag } from "react-dnd"
import TextBlock from "../ReadOnlyBlock"
import { handleItemizedBlockDrop, setIsDragging } from "../../actions"
import DropZone from "../../partials/DropZone"
import EditorBlock from "../Editor"
import { setValue } from "./helpers"
import { useDispatch, useSelector } from "react-redux"
import { BASIC_BLOCK } from "../../constants"
import LockHandler from "../../partials/LockHandler"

function ItemizedBlockField({ type, label, ItemLeft, ItemRight, isLast, is_done, readonly, task_id, ...props }){
  const uid = useSelector(state => state.firebase.auth.uid)
  const isLocked = props.lock && uid !== props.lock.by

  const dispatch = useDispatch()
  const blockId = `${props.blockId}/${props.id}`
  //eslint-disable-next-line
  const [{ isDragging }, drag, dragPreview] = useDrag({
    item: { type, id: blockId },
    collect: monitor => ({ isDragging: !!monitor.isDragging() })
  })

  useEffect(() => {
    dispatch(setIsDragging(isDragging ? { blockId, type } : null))
  }, [isDragging])

  return (
    <Fragment>
      <DropZone blockId={blockId} types={[type, BASIC_BLOCK]} onDrop={handleItemizedBlockDrop} />
      <div className={`itemized-block-field ${is_done ? 'is-done' : ''} ${isLocked ? 'locked' : ''}`}>
        {!isLast && !readonly
          ? <div ref={drag} className='block-drag-handle'><DragIndicatorRounded /></div>
          : <div className='block-drag-handle'></div>
        }
        <div className='itemized-editor-wrapper' ref={dragPreview}>
          <ItemLeft {...{ isLast, is_done, readonly, ...props }} />
          {!readonly
            ? <EditorBlock
                {...props}
                blockId={blockId}
                value={label}
                setValue={setValue('label', props)}
                autoScrollDisabled
                hideToolbar={Boolean(props.placeholder)}
              />
            : <TextBlock value={label} />
          }
          {ItemRight && <ItemRight {...{ blockId, isLast, task_id, readonly }} />}
        </div>
        <LockHandler blockId={blockId} lock={props.lock} />
      </div>
    </Fragment>
  )
}

export default ItemizedBlockField