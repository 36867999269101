import { ClickAwayListener, Popper } from "@material-ui/core"
import { KeyboardArrowDownRounded } from "@material-ui/icons"
import { Fragment, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { setFullAccountData } from "../../containers/App/actions"
import Hoverable from "../Hoverable"
import HoverableIcon from "../HoverableIcon"
import useLogout from "./useLogout"

const separate = true

function WorkspaceMenu({ logoutOnly }){
  const [open, setOpen] = useState(false)
  const ref = useRef()
  const workspace = useSelector(state => state.global?.workspace)
  const isAdmin = useSelector(state => state.global.currentMember.type === 'admin')
  const hasWorkspaces = useSelector(state => state.global.availableWorkspaces.length > 1)

  const logout = useLogout()
  const items = useMemo(() => {
    const list = [
      { text: 'Workspace Settings', to: '/settings/team' },
      { text: 'My Account', to: '/my-profile' },
      { text: 'Contact Support', separate, onClick: () => window.Intercom('show') },
    ]

    if(hasWorkspaces)
      list.push({ text: 'Workspaces', separate, to: '/workspaces' })

    if(isAdmin)
      list.push({ text: 'Change Account Role', separate: !hasWorkspaces, bottom: <AccountRoles /> })

    list.push({ text: 'Sign Out', separate, onClick: logout })

    return list
  }, [logout, workspace, isAdmin, hasWorkspaces])

  return (
    <Fragment>
      <div id='workspace-name' ref={ref} onClick={!logoutOnly ? () => setOpen(true) : undefined}>
        {workspace?.name} {!logoutOnly && <KeyboardArrowDownRounded />}
      </div>
      {!logoutOnly &&
      <Popper id='workspace-menu' anchorEl={ref.current} open={open} placement='bottom-start'>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div className='card'>
            {items.map(({ separate, text, to, onClick, bottom }) => !bottom ? (
              <Hoverable key={text + to} className={separate ? 'separate' : undefined} onClick={() => setOpen(false)}>
                {to
                  ? <Link to={to}>{text}</Link>
                  : <div onClick={onClick}>{text}</div>
                }
              </Hoverable>
            ) : <Fragment key={text + to}><div><div>{text}{bottom}</div></div></Fragment> )}
          </div>
        </ClickAwayListener>
      </Popper>}
    </Fragment>
  )
}

const icons = { chat_bot: 'robot', beta: 'user-tie', lite: 'lights-holiday' }
function AccountRoles(){
  const dispatch = useDispatch()
  const workspace = useSelector(state => state.global.workspace)

  const changeRole = role => {
    localStorage.setItem(`role@${workspace.id}`, role)
    dispatch(setFullAccountData({ ...workspace, role }))
  }

  return (
    <div id='account-roles'>
      {Object.keys(icons).map(role => (
          <HoverableIcon key={role} onClick={() => changeRole(role)} active={role === workspace.role} icon={icons[role]} />
      ))}
    </div>
  )
}

export default WorkspaceMenu