import { makeStyles, Modal } from "@material-ui/core"
import { BlueButton } from "./Button"

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > [aria-hidden]': {
      backgroundColor: 'rgba(0, 0, 0, 0.2) !important'
    }
  },
  container: {
    backgroundColor: '#fff',
    outline: 'none',
    borderRadius: 3,
    width: 220,
    overflow: 'hidden'
  },
  header: {
    backgroundColor: '#155788',
    padding: '8px 12px',
    fontSize: 20,
    color: '#fff',
  },
  text: {
    padding: '8px 12px'
  },
  buttons: {
    padding: '8px 12px',
    borderTop: '1px solid #ddd',
    display: 'flex',
    justifyContent: 'space-between',

    '& button': {
      width: '47%',
      padding: '4px 8px',
      font: 'inherit',
      fontSize: 14,
    }
  }
})

function ConfirmModal({ open, onClose, onConfirm, text, loading = false }){
  const classes = useStyles()
  return (
    <Modal className={classes.modal} open={open} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.header}>
          Confirm
        </div>
        <div className={classes.text}>{text}</div>
        <div className={classes.buttons}>
          <BlueButton outline onClick={onClose}>
            Cancel
          </BlueButton>
          <BlueButton onClick={onConfirm} style={{ height: 31, paddingTop: loading ? 6 : undefined }} loading={loading}>
            Confirm
          </BlueButton>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmModal