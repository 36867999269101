import { useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import Picker from 'emoji-picker-react'
import Tooltip from "../../../components/Tooltip/new"
import { applyUserReaction } from "../actions"

import { ClickAwayListener, Popper } from "@material-ui/core"
import EmojiIcon from '../../../images/EmojiIcon.svg'


function EmojiDisplay({ blockId, emojiById }){
  const uid = useSelector(state => state.firebase.auth.uid)
  const allCollaborators = useSelector(state => state.notes.currentNote.collaborators)

  const collaboratorsByEmoji = useMemo(() => {
    const byEmoji = {}
    Object.entries(emojiById).forEach(([id, value]) => {
      if(!byEmoji[value])
        byEmoji[value] = []
      byEmoji[value].push(allCollaborators[id])
    })
    return Object.entries(byEmoji)
  }, [emojiById, allCollaborators])

  return (
    <div className='notebook-note-block block-emoji-display'>
      {collaboratorsByEmoji.map(([emoji, collaborators]) => (
        <EmojiToken key={emoji} isSelected={emojiById[uid] === emoji} {...{ blockId, emoji, collaborators }} />
      ))}
      <EmojiPicker blockId={blockId} />
    </div>
  )
}

function EmojiToken({ blockId, emoji, collaborators, isSelected }){
  const content = useMemo(() => (
    <>
      <span className='emoji-collaborator-names'>
        {collaborators.map(({ name, email }, index) => (
          (!index ? '' : (index === collaborators.length - 1 ? ', and ' : ', ')) + (name || email)
        ))}
      </span>
      <span className='emoji-reacted-with'>
        reacted with {emoji}
      </span>
    </>
  ), [collaborators])

  const toggleEmoji = () => applyUserReaction({ emoji: isSelected ? null : emoji }, blockId)

  return (
    <Tooltip
      styles={{ wrapper: { maxWidth: 300, textAlign: 'center', lineHeight: 1.8 }}}
      content={content}
      placement='top'
      enterDelay={500}
      enterNextDelay={500}
      lean
    >
      <div className={`emoji-token ${isSelected ? 'selected' : ''}`} onClick={toggleEmoji}>
        {emoji} {collaborators.length}
      </div>
    </Tooltip>
  )
}

const noop = () => {}

export function EmojiPicker({ blockId, setKeepAlive = noop }){
  const [pickerOpen, setPickerOpen] = useState(false)
  const ref = useRef()

  const openPicker = e => {     
    setPickerOpen(true)
    setKeepAlive(true)
  }

  const closePicker = (e, emojiObject) => {
    setPickerOpen(false)
    setKeepAlive(false)
    if(emojiObject?.emoji)
      applyUserReaction({ emoji: emojiObject.emoji }, blockId)
  }

  return (
    <div onMouseDown={e => setKeepAlive(true)} className='note-emoji-picker'>
      <img ref={ref} src={EmojiIcon} onMouseDown={openPicker} />
      <Popper open={pickerOpen} anchorEl={ref.current} className='emoji-picker-popper'>
        <ClickAwayListener onClickAway={closePicker}>
          <div>
            <Picker onEmojiClick={closePicker} />
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

export default EmojiDisplay