import { Fragment, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { BlueButton } from "../../components/Button"
import Select from "../../components/FormControls/Select"
import EmailBox from "../../components/FormControls/EmailBox"
import { createNewAccount } from "./actions"

import { Modal } from "@material-ui/core"
import { Add } from "@material-ui/icons"
import FieldError from "../../components/FormControls/FieldError"
import CustomSwitch from "../../components/FormControls/Switch"

const initialState = () => ({ open: false, emails: [], name: '', role: 'customer_success', sendInvites: true })
function CreateNewModal(){
  const dispatch = useDispatch()
  const [state, setState] = useState(initialState(false))
  const toggleEmail = email => {
    const emails = [ ...state.emails ]
    const index = emails.findIndex(item => item.email === email)

    if(index < 0)
      emails.push({ email, error: false })
    else
      emails.splice(index, 1)

    return setState({ ...state, emails })
  }

  const [errors, setErrors] = useState({ name: false, emailInvalid: false, noEmails: false })
  const [loading, setLoading] = useState(false)
  const [links, setLinks] = useState(null)

  const close = () => {
    setState(initialState())
    setLinks(null)
  }
  const create = async () => {
    let hasNewErrors = errors.emails
    const newErrors = { ...errors }
    if(!state.name.length){
      newErrors.name = hasNewErrors = true
    }

    if(hasNewErrors)
      return setErrors(newErrors)

    setLoading(true)
    const result = await dispatch(createNewAccount(state))
    setLoading(false)
    if(result.error)
      return
    else if(result.links)
      return setLinks(result.links)
    
    close()
  }

  const onNameChange = e => {
    setErrors({ ...errors, name: false })
    setState({ ...state, name: e.target.value })
  }

  const nameRef = useRef()
  const emailsRef = useRef()

  return (
    <Fragment>
      <BlueButton padding='6px 4px' className='create-new' onClick={() => setState({ ...state, open: true })}>
        <Add /> Create New
      </BlueButton>
      <Modal id='create-new-account' className='list-modal' open={state.open} onClose={close} disableEnforceFocus>
        <div className='modal-content'>
          <div className='content-header'>
            {!links ? 'Create a New Account' : 'Invite Links (By Email)'}
          </div>
          {links &&
          <div className='content-container'>
            {links.map(({ link, email }) => (
              <div className='invite-links-display'>
                <div>{email}</div>
                <a href={link} target='_blank'>{link}</a>
              </div>
            ))}
          </div>}
          {!links &&
          <div className='content-container'>
            <div ref={nameRef}>
              Account name
              <input value={state.name} onChange={onNameChange} className='account-name' />
              <FieldError containerEl={nameRef.current} anchorEl={nameRef.current} hasErrors={errors.name}>
                Accounts must have a name
              </FieldError>
            </div>
            <div>
              Account role
              <Select
                initialValue={state.role}
                onSelect={({ selectedItem }) => setState({ ...state, role: selectedItem })}
                items={['chat_bot', 'customer_success']}
                getItem={item => item === 'chat_bot' ? 'Chatbot' : 'Customer Success'}
                popperProps={{ id: 'create-new-account-popper'}}
              />
            </div>
            <div ref={emailsRef}>
              Team email addresses
              <EmailBox
                displayed={state.open}
                emails={state.emails}
                onRemove={toggleEmail}
                onSave={toggleEmail}
                onFocus={() => setErrors({ ...errors, noEmails: false })}
                error={errors.emailInvalid}
                setError={value => setErrors({ ...errors, emailInvalid: value })}
                containerEl={emailsRef.current}
              />
              <FieldError containerEl={emailsRef.current} anchorEl={emailsRef.current} hasErrors={errors.noEmails}>
                Enter at least one email to proceed
              </FieldError>
            </div>
            <div className='send-invites'>
              Send invite emails
              <CustomSwitch
                checked={state.sendInvites}
                onClick={() => setState({ ...state, sendInvites: !state.sendInvites })}
              />
            </div>
            <div>
              <BlueButton className='create-button' onClick={create} loading={loading}>
                Create
              </BlueButton>
            </div>
          </div>}
        </div>
      </Modal>
    </Fragment>
  )
}

export default CreateNewModal