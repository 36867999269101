/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from './utils/history';
import globalReducer from './containers/App/reducer';
import notesReducer from './containers/GistEditor/reducer';

import {
  firebaseReducer
} from 'react-redux-firebase'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    global: globalReducer,
    notes: notesReducer,
    router: connectRouter(history),
    firebase: firebaseReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
