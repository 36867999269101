import { useSelector } from "react-redux"
import { useParams } from "react-router"

export const useCurrentConnector = () => {
  const { connectorId } = useParams()
  return useSelector(state => {
    const { global: { connectorSpecs, connectors } } = state

    const isUUID = connectorId.length === 36
    const currentConnector = isUUID ? connectors.find(c => c.id === connectorId) : null
    const connectorSpec = connectorSpecs.find(spec => spec.id === (isUUID ? currentConnector?.spec_ref : connectorId))
    return { currentConnector, connectorSpec }
  })
}