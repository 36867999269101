import React from 'react'
import './style.scss'

function Hoverable({ children, isSelected, className, is, ...props }){
  const selectable = typeof isSelected !== 'undefined' ? 'selectable' : ''
  const selected = isSelected ? 'selected' : ''
  const Component = is || 'div'

  return (
    <Component className={`hoverable ${selectable} ${selected} ${className || ''}`} {...props}>
      {children}
    </Component>
  )
}

export default Hoverable