import { makeStyles, Popper } from '@material-ui/core'
import { Info, Warning } from '@material-ui/icons'

const color = info => !info ? '#fd6c4b' : '#155788'
const useStyles = makeStyles({
  tooltip: ({ info }) => ({
    color: '#fff',
    fontSize: 11,
    backgroundColor: color(info),
    padding: 6,
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0',

    '& .popper-arrow, & .popper-arrow::after': {
      position: 'absolute',
      width: 8,
      height: 8,
      backgroundColor: color(info),
      transform: 'rotate(45deg)',
    },
  }),
  icon: {
    fontSize: 13,
    marginRight: 4,
    transform: 'translateY(-1px)'
  }
})

function FieldError({ anchorEl, hasErrors, children, containerEl, info = false, ...props }){
  const classes = useStyles({ info })
  return (
    <Popper
      anchorEl={anchorEl}
      open={Boolean(anchorEl) && Boolean(hasErrors)}
      className={classes.tooltip}
      placement='bottom-end'
      container={containerEl}
      {...props}
    >
      {!info
        ? <Warning className={classes.icon} />
        : <Info className={classes.icon} />
      }
      {children}
      <div className='popper-arrow' data-popper-arrow></div>
    </Popper>
  )
}

export default FieldError