import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { sendPutRequest, NEW_sendPutRequest, sendGetRequest, NEW_sendPostRequest } from '../../apis/api-utils';
import { BASE_URL, BASE_URL2 } from '../../apis/constant';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import { setToastMessage } from '../App/actions';
import { updateAccountOnPostInstall } from '../MyProfile/actions';
import { setRedirectUri } from '../Apps/AddApp/HandleOauthRedirect'
function HandleUserDecision() {
    const dispatch = useDispatch()
    const { integrationName } = useParams();
    const workspace = useSelector(state => state.global.workspace)
    const history = useHistory();
    const newConnectorInstanceId = localStorage.getItem('isAddingConnector') ? JSON.parse(localStorage.getItem('isAddingConnector')).instanceId : false

    const connectors = useSelector(state => state.global.connectors)
    const connectorSpecs = useSelector(state => state.global.connectorSpecs)


    const isOnboardingChatbot = workspace.status === 'created' && workspace.role === 'chat_bot'

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search)
        let data = {}
        
        for (let p of searchParams){
            data = { ...data, [p[0]]: p[1] };

            /* This is relevant specifically for Zendesk deny scenario */
            if(p[0] === 'error'){
                localStorage.removeItem('integrationId');
                if(isOnboardingChatbot)
                    history.replace('/onboarding-chatbot/crmdeny');
                else
                    history.replace('/settings/integrations');
                return;
            }
        }
        async function finalizeApp() {
            const appName = integrationName
            console.log(`connectors`,connectors)
            const currentConnector = connectors.find(connector => connector.id === newConnectorInstanceId)
            console.log(`currentConnector`,currentConnector)
            try {
                const auth =  await NEW_sendPutRequest(`${BASE_URL2}connector/${newConnectorInstanceId}/authorize`, {}, JSON.stringify({
                    code: searchParams.get('code'),
                    redirect_uri:setRedirectUri(connectorSpecs.find(spec => spec.id === currentConnector.spec_ref))
                }))
                if(!auth.ok)
                    throw new Error(auth.text)
                const setActiveCall = await NEW_sendPutRequest(`${BASE_URL2}connector/${newConnectorInstanceId}`, {}, JSON.stringify( {...currentConnector,status:'active',authentication:{...currentConnector.authentication, status:'authenticated'}}))
                if(!setActiveCall.ok)
                    throw new Error(setActiveCall.text)
            } catch (error) {
                dispatch(setToastMessage(`failed in API request to finish App Oauth: ${error}`, 'error'))
            }

            if(appName === 'slack') {
                try {
                 await NEW_sendPostRequest(`${BASE_URL2}slack/send-welcome-moment`,{});
                } catch (error) {
                    console.log('failed in API request to initiate slack.',error)
                }
            }
            localStorage.removeItem('IntegrationFromOnboarding')
            localStorage.removeItem('isAddingConnector')

            history.replace('/apps/all')
        }
        async function finalizeIntegration() {
            try {
                const integrationId = localStorage.getItem('integrationId');
                const integration = await sendGetRequest(`${BASE_URL}integration/${integrationId}`);

                await sendPutRequest(`${BASE_URL}integration/${integrationId}`, {}, JSON.stringify({ 
                    ...integration,
                    data: { ...integration.data, ...data },
                    status: 'done'
                }))
            } catch (error) {
                console.error("failed to install integration", error)
                dispatch(setToastMessage('Something went wrong while installing your integration. Please try again', 'error'))
            }

            localStorage.removeItem('integrationId')

            if(localStorage.getItem('IntegrationFromOnboarding')){
                if(integrationName === 'slack-calender-app') {
                    try {
                     await NEW_sendPostRequest(`${BASE_URL2}slack/send-welcome-moment`,{});
                    } catch (error) {
                        console.log('failed in API request to initiate slack.',error)
                    }
                }
                localStorage.removeItem('IntegrationFromOnboarding')
                history.replace('/apps/all')
            }else if(isOnboardingChatbot){
                await dispatch(updateAccountOnPostInstall())
                history.replace('/processing')
            }else{
                history.replace('/settings/integrations')
            }
        }
        
        const isAddingConnector = localStorage.getItem('isAddingConnector')
        const isReauthorizingConnector = localStorage.getItem('isReauthorizingConnector')

        const testTimestamp = time => time && (new Date().getTime() - JSON.parse(time).timestamp < 1000 * 60 * 30) ? true : false
        const reallyAddingConnector = testTimestamp(isAddingConnector)
        const reallyReauthorizingConnector = testTimestamp(isReauthorizingConnector)
        if(localStorage.getItem('IntegrationFromOnboarding') && reallyAddingConnector) {
            finalizeApp()
        }
        else if(reallyAddingConnector) {
            history.push(`/apps/add/authenticate2/?code=${searchParams.get('code')}`)
        } else if(reallyReauthorizingConnector) {
            history.push(`/apps/view/${JSON.parse(isReauthorizingConnector).instanceId}/reauthorize?code=${searchParams.get('code')}`)
        } else if(localStorage.getItem('ConnectorFromOnboarding')) {

        }
        else if(integrationName) {
            finalizeIntegration()
        }
    }, [])

    return <LoadingIndicator standalone />
}
export default HandleUserDecision