import { useEffect, useState, useRef, useMemo, memo } from 'react'

import AppField from './AppField'
import TrackableField from './TrackableField'
import PlanttField from './PlanttField'

import './style.scss'
import IsItemGood from './IsItemGood'
import FieldType from './FieldType'
import NewTable from '../NewTable'

/*

Documentation:
--------
nameOfName:
  Name of the table's *name* field.

stateAddress:
  An array of keys describing where the import data can be found in redux.
  For example, import data located at state.accounts.importData will be described as ['identites', 'importData'].

setState:
  A function that will dispatch modified import data to redux, e.g. (data) => dispatch(setImportData(data))
  This function should place the data received at the same address receive on stateAddress.
  A reducer example for this: return { ...state, importData: action.data <= data received on setState }

*/

function ImportTable({ nameOfName, stateAddress, labelsAddress, setState, fieldList, ...props }){

  const contentRef = useRef()
  const [containerEl, setContainerEl] = useState()
  useEffect(() => {
    if(contentRef.current){
      setContainerEl(contentRef.current)
    }
  }, [contentRef])

  const columns = useMemo(() => {
    return [
      {
        name: '',
        Content: ({ item }) => <IsItemGood {...{  item }} />,
        width: 60,
        noSkeleton: true
      },
      {
        name: 'Plantt Field',
        Content: ({ item }) => <PlanttField {...{  item, stateAddress, setState, containerEl }} />,
        width: 212
      },
      {
        name: `${nameOfName} field`,
        Content:   ({ item }) => <AppField {...{ item, stateAddress, setState, labelsAddress, containerEl, fieldList }} />,
        width: 220
      }, 
      {
        name: 'Field Type',
        Content:  ({ item }) => <FieldType {...{ item, stateAddress, setState }} />,
        width: 160
      },
      {
        name: 'Trackable',
        Content:  ({ item }) => <TrackableField {...{ item, stateAddress, setState, containerEl }} />,
        width: 140
      },
    ]
  }, [stateAddress, labelsAddress, setState, containerEl, fieldList])

  return (
    <NewTable
      id='import-accounts-table'
      columns={columns}
      itemsAddress={[ ...stateAddress, 'fields']}
      {...props}
    />
  )
}

export default memo(ImportTable)