import { Fragment } from "react";
import HeatMap from "../../../components/HeatMap";

const hint = ['Between May 5th and May 19th:', 'Communication was Up.', 'Communication was 0.5 of typical user communication.']
function ContactHeatMap({ activity }){

  return activity ? (
    <HeatMap
      columns={Object.keys(activity)}
      datasets={[
        { data: activity.map(item => item.engagement), max: 1 },
        { data: activity.map(item => item.usage), color: [58, 175, 75], max: 1 }
      ]}
      RenderHint={RenderHint}
    />
  ) : null
}

function RenderHint({ hint }){
  return (
    <Fragment>
      {hint[0]}
      <ul style={{ padding: '0 12px 0 24px', margin: 4 }}>
        <li>{hint[1]}</li>
        <li>{hint[2]}</li>
      </ul>
    </Fragment>
  )
}

export default ContactHeatMap