/* eslint-disable radix */

export const cookieExpireConstants = {
  YEARS: 'years',
  DAYS: 'days',
  HOURS: 'hours',
  MINUTES: 'minutes',
  SECONDS: 'seconds',
  IMAGES_ALLOWED_EXTENSIONS: ['bmp', 'gif', 'jpg', 'jpeg', 'pjpeg', 'png'],
  CategoryFilterLabel: 'Related Categories',
};

export const setCookie = ({
  cname, cvalue, expires, domain, path, cookieExpireTimeType
}) => {
  if (!path) {
    path = '/';
  }
  if (expires) {
    // If it's a date
    if (expires instanceof Date) {
      // If it isn't a valid date
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(expires.getTime())) {
        expires = new Date();
      }
    } else {
      switch (cookieExpireTimeType) {
        case cookieExpireConstants.YEARS:
          expires = new Date(new Date().getTime() + parseInt(expires) * 1000 * 60 * 60 * 24 * 365);
          break;
        case cookieExpireConstants.DAYS:
          expires = new Date(new Date().getTime() + parseInt(expires) * 1000 * 60 * 60 * 24);
          break;
        case cookieExpireConstants.HOURS:
          expires = new Date(new Date().getTime() + parseInt(expires) * 1000 * 60 * 60);
          break;
        case cookieExpireConstants.MINUTES:
          expires = new Date(new Date().getTime() + parseInt(expires) * 1000 * 60);
          break;
        case cookieExpireConstants.SECONDS:
          expires = new Date(new Date().getTime() + parseInt(expires) * 1000);
          break;
        default:
          expires = new Date(new Date().getTime() + parseInt(expires));
      }
    }
    expires = `expires=${expires.toUTCString()}`;
  }
  document.cookie = `${cname}=${cvalue};${expires};path=${path};${domain ? `domain=${domain};` : ''}`;
};

export const getCookieValue = cookieName => {
  const result = document.cookie.replace(new RegExp(`(?:(?:^|.*;\\s*)${cookieName}\\s*\\=\\s*([^;]*).*$)|^.*$`), '$1');
  return result;
};

export const deleteCookie = cname => {
  document.cookie = `${cname}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const noop = () => {};

export const createAvatarText = (text = '') => text && text.split(' ').map(str => (str ? str[0].toUpperCase() : '')).join('');

export const transformEntrys = (text, type) => {
  if (type === 'apiKey' && text.length > 4) {
    return '*'.repeat(text.length - 4) + text.slice(-4);
  }

  return text;
};

export const copyToClipboard = code => {
  const textField = document.createElement('textarea');
  textField.innerText = code;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};
