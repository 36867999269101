import { Link, useParams } from "react-router-dom"
import TooltipComponent from "../../../components/Tooltip/new"

function AppIcons({ containerRef, availableApps, currentApp, ...props }){
  const { id } = useParams()

  return (
    <div id='overview-app-icons' {...props}>
      {availableApps?.map(({ spec }) => {
        const isSmall = spec.icon_url.includes('zendesk') || spec.icon_url.includes('salesforce')
        const isCurrent = spec === currentApp.spec
        return (
          <div key={spec.id} className={isCurrent ? 'is-current-app' : undefined}>
            <div className='filler' />
            <div>
              <Link to={`/account/${id}/${spec.id === 'plantt' ? 'overview' : spec.id}`}>
                <img src={spec.icon_url} style={{ width: isSmall ? 35 : 30 }} />
              </Link>
            </div>
            <div className='filler' />
          </div>
        )
      })}
      <div>
        <div className='filler' />
          <div>
            <TooltipComponent lean content='Add app' placement='bottom'>
              <Link to={`/apps/all`}><i className='fal fa-plus' /></Link>
            </TooltipComponent>
          </div>
        <div className='filler' />
      </div>
    </div>
  )
}

export default AppIcons