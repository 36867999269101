import React from 'react'
import './style.scss';

function ProgressBar({ progress }) {
    return (
        <div className="progress-bar-container">
            <div className="progress-bar"></div>
            <div className="progress-value" style={{ width: `${progress}%` }}></div>
        </div>
    )
}
export default ProgressBar;