import { Editor, Range, Path, Point, Transforms } from "cs-slate"
import isHotkey from "is-hotkey"
import { ReactEditor } from "cs-slate-react"

import { createBlock, replaceInitialBlock, deleteBlock, getNoteInfo, setBlock, updateCurrentNoteSelection, setCaretPosition } from "./actions"
import serialize from "../../components/TextEditor/serialize"
import { toggle } from "../../components/TextEditor/ToolbarFormats"
import { addFieldAfter, isItemizedBlock, removeField, setValue as setFieldValue } from './Blocks/Itemized/helpers'
import { focusAndSelect, getCurrentNode, getNestedBlockInfo, isNestedBlock, 
         mergeNodesOnRemove, useGist, defineIndentation, focusHiddenInput } from "./helpers"
import { computeParagraphLines, computeEditorNodesWidth, computeNodeWidth, getCaretTopPoint } from './arrowsNavigationHelpers'
import { useDispatch } from 'react-redux'
import { store } from "../.."
import { INTENDATION_WIDTH } from './constants'

const directions = {
  backward: ['ArrowUp', 'ArrowLeft', 'Backspace'],
  forward: ['ArrowDown', 'ArrowRight', 'Delete']
}






function getOffset( el ) {
  var _x = 0;
  var _y = 0;
  while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
  }
  return { top: _y, left: _x };
}





export const useNoteKeyDown = ({ blocksRef, setInteractionMenu, blockSelectionRef}) => {
  const dispatch = useDispatch()

  const onKeyDown = (event, id) => {
    const { blocks } = store.getState().notes.currentNote
    const { editor } = blocksRef.current[id]
    const selectUp = isHotkey('Shift+ArrowUp', event)
    const selectDown = isHotkey('Shift+ArrowDown', event)
    const isBackspace = event.key === 'Backspace'
    const isDelete = event.key === 'Delete'

    const getBlock = func => {
      const id = Object.keys(blocks).find(func)
      return id ? { id, ...blocks[id] } : null
    }
    const { path, offset } = editor.selection?.anchor || {}
    if(!path)
      return




    if(selectUp || selectDown) {
      const point = selectUp ? Editor.start(editor,[]) : Editor.end(editor,[])
      if(Point.equals(point, editor.selection.anchor) || Point.equals(point, editor.selection.focus)){
        dispatch(updateCurrentNoteSelection([{blockId:id, index:0}]))
        focusHiddenInput()
      }
    }
    if(handleTab(event, editor, id) === 'stop')
      return
    //Handle Select all
    if (isHotkey('mod+a', event)) {
      const editorStart = Editor.start(editor,[])
      const editorEnd = Editor.end(editor,[])

      const { anchor, focus } = editor.selection

      if((Point.equals(editorStart, anchor) && Point.equals(editorEnd, focus)) ||
         (Point.equals(editorStart, focus) && Point.equals(editorEnd, anchor))) {

      }
      else {
        console.log('popgation stoped')
        event.stopPropagation()
      }
      return
    }

    if(event.key === 'Enter')
      return handleEnter(event, editor, id)
    else if((event.key === ' ' && toggleList(event, editor, id)) || !Range.isCollapsed(editor.selection))
      return
   else if(!directions.backward.concat(directions.forward).includes(event.key) && !isNestedBlock(id))
     return activateInteraction(event, editor, id)

      const direction = (() => {
        if(directions.backward.includes(event.key))
          return -1
        else if(directions.forward.includes(event.key))
          return 1
        else
          return 0
      })()
  
      let nextBlockId = direction && getNextBlock(direction, id)
      while(nextBlockId && !isNestedBlock(nextBlockId) && !['to-do-list', 'basic', 'title'].includes(blocks[nextBlockId].type)){
        nextBlockId = getNextBlock(direction, nextBlockId)
        if((direction > 0 && isDelete) || (direction < 0 && isBackspace)) return
      }
      if(!nextBlockId) {
        return
      }
      const isNextBlockNested = isNestedBlock(nextBlockId)

      if(!isNextBlockNested)
        if(isItemizedBlock(blocks[nextBlockId])){
          console.log('nested')
          const { fields } = blocks[nextBlockId].payload
          const childId = fields[direction > 0 ? 0 : fields.length - 1].id
          nextBlockId = `${nextBlockId}/${childId}`
        }      
      const nextEditor = blocksRef.current[nextBlockId].editor


    //The next part of the code handles the moving beetwen editor block woth the arrows.
    const computeIndentationAddition = (blockId) => defineIndentation(blocks, blockId) * INTENDATION_WIDTH
    if(!isHotkey('ArrowUp', event) && !isHotkey('ArrowDown', event))
      if(store.getState().notes.caretPosition) {
        console.log('reset caret')
        dispatch(setCaretPosition(null))
      }

    if(id !== 'noteTitle' && (isHotkey('ArrowUp', event) || isHotkey('ArrowDown', event))) {
      console.log('code started')
      let direction 
      if(event.code === 'ArrowUp') direction = 'up'
      if(event.code === 'ArrowDown') direction = 'down'
      const currentSlateEditor = blocksRef.current[id].editor
      const currentDomEditor = event.target.closest('.note-editor-block')
      const editorPosition = getOffset(currentDomEditor)
      const caretPosition = getCaretTopPoint()
      //Check if we are at the first or last line of editor block
      const dif = direction === 'up' ? caretPosition.top - editorPosition.top : caretPosition.top - (editorPosition.top + currentDomEditor.offsetHeight -10) //+ 10 IS THE CARET HEIGHT
      const prevBlock = nextBlockId
      if(Math.abs(dif) > 0.7 *parseInt(getComputedStyle(currentDomEditor).lineHeight)) {
        console.log('not at top or bottom row. do nothing')
      }
      //Check if the next/previos block exists
      
      else if (direction === 'up' && (!prevBlock || prevBlock.id === 'noteTitle')) {}
      else if(direction === 'down' && (!nextBlockId || id === 'noteTitle')) {}
      else {        
        event.preventDefault()
        let wantedOffset
        let myNodeIndex

        const destenationEditor =  nextEditor 
        //Handle Caret Position             
        //Takes care of special nested blocks
        const nextEditorDomNode = ReactEditor.toDOMNode(nextEditor, nextEditor.children[0]).closest('.note-editor-block') //Maybe there is better solution
        const diffrence = Math.abs(getOffset(nextEditorDomNode).left - editorPosition.left)
        const nesteItemContainer = ReactEditor.toDOMNode(currentSlateEditor, currentSlateEditor.children[0]).closest('.itemized-block-field')
        const localEdiorDifference = Math.abs(editorPosition.left - getOffset(nesteItemContainer).left)
        //Take care of List blocks
        const relevantListSlateEditor = blocks[id]?.list ? currentSlateEditor : nextEditor
        const relevantListDomEditorConatainer =  ReactEditor.toDOMNode(relevantListSlateEditor, relevantListSlateEditor.children[0]).closest('.block-content')
        const relevantListDomEditor = blocks[id]?.list ? currentDomEditor : nextEditorDomNode
        const listBlockDiffrence = Math.abs(getOffset(relevantListDomEditor).left - getOffset(relevantListDomEditorConatainer).left) -computeIndentationAddition(blocks[id]?.list ? id : nextBlockId)   // minus 2 because of css border of .block-content

        const noteSavedCaretPosition = store.getState().notes.caretPosition
        let rawCaretTextWidth
        if(noteSavedCaretPosition) { 
          console.log('textWidthSave', noteSavedCaretPosition,nextBlockId)
          rawCaretTextWidth = noteSavedCaretPosition - computeIndentationAddition(id)
        }
        else {
          rawCaretTextWidth = caretPosition.left - (editorPosition.left + 4) //4 px of padding
          console.log({rawCaretTextWidth})
          let textWidthForRedux = rawCaretTextWidth + computeIndentationAddition(id)
          if(isNestedBlock(id)) textWidthForRedux += localEdiorDifference 
          if(blocks[id]?.list) textWidthForRedux += listBlockDiffrence
          dispatch(setCaretPosition(textWidthForRedux))
          console.log({textWidthForRedux})
        }

        if(isNestedBlock(nextBlockId) && !isNestedBlock(id))  rawCaretTextWidth -= diffrence
        if(!isNestedBlock(nextBlockId) && isNestedBlock(id) && !noteSavedCaretPosition)  rawCaretTextWidth += diffrence
        if(isNestedBlock(nextBlockId) && isNestedBlock(id) && noteSavedCaretPosition) {
          rawCaretTextWidth -= Math.abs(editorPosition.left - getOffset(nesteItemContainer).left)
        }
        console.log(listBlockDiffrence)
        if(blocks[nextBlockId]?.list && !blocks[id]?.list) { 
          rawCaretTextWidth -= listBlockDiffrence
          console.log(`added ${listBlockDiffrence}`)
        }
        if(!blocks[nextBlockId]?.list && blocks[id]?.list && !noteSavedCaretPosition) rawCaretTextWidth += listBlockDiffrence
        if(blocks[nextBlockId]?.list && blocks[id]?.list && noteSavedCaretPosition) rawCaretTextWidth -= listBlockDiffrence
        


        
        console.log('111',{rawCaretTextWidth})

        //handle indentation

        const destenationBlockId =  nextBlockId//direction === 'up' ? prevBlock.id : blocks[id].next_block
        const indentationDiffrence = computeIndentationAddition(id) - computeIndentationAddition(destenationBlockId)
        const caretTextWidth = rawCaretTextWidth + indentationDiffrence
        console.log('222',{caretTextWidth, indentationDiffrence})
        //Preperations for the computing of relevant location
        const destenationNodes =  computeEditorNodesWidth(destenationEditor.children, destenationEditor)
        const onlyParagraphs = destenationNodes.filter(node => node.type ==='paragraph')          
        const firstOrLastParagraphNode = direction === 'up' ?
                                         onlyParagraphs[onlyParagraphs.length-1] : 
                                         onlyParagraphs[0]  
        const destenationRelevantNodes = direction === 'up' ?
                     [...firstOrLastParagraphNode.children].reverse() :
                     firstOrLastParagraphNode.children

        const destFirstOrLastP = direction === 'up' ? 
                                 destenationEditor.children[destenationEditor.children.length-1] : 
                                 destenationEditor.children[0]

        const newEditor = ReactEditor.toDOMNode(destenationEditor, destFirstOrLastP)
        const editorStyles = getComputedStyle(newEditor)
        const newEditorWidth = newEditor.offsetWidth - parseInt(editorStyles.paddingRight) - parseInt(editorStyles.paddingLeft)
        const pLines = computeParagraphLines(firstOrLastParagraphNode, newEditorWidth, direction, destenationEditor)
        console.log({pLines})
        let rightPartOflastLineTempWidth = pLines[pLines.length-1]?.lineWidth 
        let leftPartOfFirstLineTempWidth = 0 

        //handle shorter text in last or first line
        if(direction === 'up' && (rightPartOflastLineTempWidth < caretTextWidth || !pLines.length)) {
          myNodeIndex = 0
          wantedOffset = 0
        }

        else if(direction === 'down' && ((pLines && pLines[0]?.lineWidth < caretTextWidth) || !pLines.length)) {
          myNodeIndex = 0
          wantedOffset = firstOrLastParagraphNode.children[firstOrLastParagraphNode.children.length-1].text.length
        }
        else { //Text in last or first is longer then at the origin. Compute!
          let x = 0
          for(const [index, node] of destenationRelevantNodes.entries()) {
            if(direction === 'up') {
              //lets find the right node
              if(node.width > rightPartOflastLineTempWidth - caretTextWidth || caretTextWidth < 0) {
                myNodeIndex = index
                //we have found the right node
                //now compute the right place inside
                wantedOffset = caretTextWidth > 0 ? (rightPartOflastLineTempWidth - caretTextWidth ) : rightPartOflastLineTempWidth
                const arr = node.text.split("").reverse()
                while(wantedOffset > 0) {
                  const charLength = computeNodeWidth(arr[x], node, destenationEditor) //getTextWidth(arr[x],'16px Roboto')
                  if(wantedOffset - charLength < 0) {
                    if(wantedOffset > 2) x++
                    break
                  }
                  wantedOffset -= charLength
                  x++
                }              
                wantedOffset = x
                break
              }
              else {
                rightPartOflastLineTempWidth -= node.width
              }
            }
            else {
              if(node.width >  caretTextWidth + leftPartOfFirstLineTempWidth) {
                myNodeIndex = index
                wantedOffset = (caretTextWidth - leftPartOfFirstLineTempWidth )
                const arr = node.text.split("")
                while(wantedOffset > 0) {
                  const charLength = computeNodeWidth(arr[x], node, destenationEditor) //getTextWidth(arr[x],'16px Roboto')
                  if(wantedOffset - charLength < 0) {
                    if(wantedOffset > 2) x++
                    break
                  }
                  wantedOffset -= charLength
                  x++
                }              
                wantedOffset = x
                break
              } else {
                leftPartOfFirstLineTempWidth +=  node.width
              }
            }
          }
        }
        if(!destenationRelevantNodes[myNodeIndex]) {
          console.log('EERRORRR')
          console.log({myNodeIndex})
          return
        }
        //Create the new point and give it to Slate editor
        const i = {}
        if(direction === 'up') {
          i['fatherNode']   = destenationNodes.length -1
          i['childNode']    = firstOrLastParagraphNode.children.length-1-myNodeIndex 
          i['wantedOffset'] = destenationRelevantNodes[myNodeIndex].text.length-wantedOffset
        }
        else {
          i['fatherNode']   = 0
          i['childNode']    = myNodeIndex
          i['wantedOffset'] = wantedOffset
        }
        const newPoint = {
          anchor: { path: [i.fatherNode, i.childNode], offset: i.wantedOffset },
          focus:  { path: [i.fatherNode, i.childNode], offset: i.wantedOffset },
        }
        setTimeout(() => {
          ReactEditor.focus(destenationEditor)
          Transforms.select(destenationEditor, newPoint)
        })
      }
    }
    // End of arrows navigation between blocks CODE

    if(isBackspace && editor?.selection?.focus?.offset === 0 && Range.isCollapsed(editor.selection)){
      if(blocks[id]?.list)
        return setBlock(id, { list: null })
      if(blocks[id]?.parent_id)
        return setBlock(id, { parent_id: blocks[blocks[id].parent_id]?.parent_id || null })
    }

    if(direction < 0){
      if(offset || !Path.equals(path, Editor.start(editor, []).path))
        return
        
      if(isBackspace){
        const stop = handleRemove({ editor, id, nextBlockId, nextEditor, direction })
        if(stop) return
      }

      const range = event.key !== 'ArrowUp' ? Editor.end(nextEditor, []) : undefined
      setTimeout(() => focusAndSelect(nextEditor, range))
    }else if(direction > 0){
      const editorEnd = Editor.end(editor, [])
      if(offset !== editorEnd.offset || !Path.equals(path, editorEnd.path))
        return

      if(isDelete){
        event.preventDefault()
        return handleRemove({ editor, id, nextBlockId, nextEditor, direction })
      }

      focusAndSelect(nextEditor)
    }
  }

  const toggleList = (event, editor, blockId) => {
    const { anchor } = editor.selection
    if(anchor.offset > 2)
      return

    const focus = { ...anchor, offset: anchor.offset - 2 }
    const text = Editor.string(editor, { anchor, focus })
    if(['1.', '*'].includes(text)){
      event.preventDefault()
      Editor.deleteBackward(editor, 1)
      Editor.deleteBackward(editor, 1)
      setBlock(blockId, { list: `${text === '*' ? 'un' : ''}ordered` })
      return true
    }
  }

  const activateInteraction = (event, editor, id) => {
    const { blocks } = store.getState().notes.currentNote
    const { anchor } = editor.selection
    const isTitle = blocks[id].type === 'title'
    const isAction = event.key === '/'

    setTimeout(() => {
      const focus = { ...anchor, offset: anchor.offset + (!isTitle || !isAction ? 1 : 0) }
      if(isAction || event.key === '#'){
        const { node } = getCurrentNode(editor)
        if(node)
          setInteractionMenu({
            interaction: isAction ? 'actions' : 'tags',
            anchor: ReactEditor.toDOMNode(editor, node),
            selection: { anchor: focus, focus },
            editor, id, isTitle
          })
      }
    })
  }

  const handleEnter = async (event, editor, blockId, doNotPrevent = false) => {

    const { blocks } = store.getState().notes.currentNote
    const { node, isList } = getCurrentNode(editor)
    console.log('eneter pressed')
    const isShift = isHotkey('Shift+Enter', event)
    if(!isList && isShift){
      return
    }else if(isList && !isShift){
      if(node.text)
        return

      toggle.block(editor, 'ordered-list', true)
    }
      
    if(!doNotPrevent)
      event.preventDefault()

    const getMarkdown = range => serialize(Editor.fragment(editor, range))
    const fragments = {
      before: getMarkdown({ anchor: Editor.start(editor, []), focus: Range.start(editor.selection) }),
      after: getMarkdown({ anchor: Range.end(editor.selection), focus: Editor.end(editor, []) })
    }
    ReactEditor.deselect(editor)

    if(!isNestedBlock(blockId)){
      const { heading } = blocks[blockId]?.payload || {}
      let parent_id = null
      if(defineIndentation(blocks, blockId) > 0) 
        parent_id = blocks[blockId].parent_id
      setBlock(blockId, { payload: { text: fragments.before, heading: (fragments.before.length && heading) || null } }, false) 
      createBlock(blockId, { type: 'basic', payload: { text: fragments.after, heading: (!fragments.before.length && heading) || null }, parent_id })
    }else{
      const { childId, blockId: parentId, block } = getNestedBlockInfo(blockId)
      setFieldValue('label', { block, blockId: parentId, id: childId }, false)(fragments.before)
      addFieldAfter(blockId, fragments.after)
    }
  }

  const handleTab = (event, editor, id) => {
    if(event.key !== 'Tab')
      return

    event.preventDefault()
    const { blocks } = store.getState().notes.currentNote

    if(blocks[id]?.type === 'title' && Object.keys(blocks).length === 2) {
      const initialBlock = Object.keys(blocks).find(blockId => blocks[blockId].type === 'initial')
      if(initialBlock){
        replaceInitialBlock(initialBlock)
        return 'stop'
      }
    }

    const { anchor } = editor.selection
    const previousBlock = Object.keys(blocks).find(blockId => blocks[blockId].next_block === id)
    if(anchor.offset !== 0 || !previousBlock || blocks[id].parent_id === previousBlock)
      return

    const upperBlockIntentaion = defineIndentation(blocks, previousBlock)
    const currentBlockIntentaion = blocks[id].parent_id ? defineIndentation(blocks, id) : 0

    const getGrandParent = (count, blockId) => {
      if(count !== 0)
        return getGrandParent(count - 1, blocks[blockId]?.parent_id)
      return blockId
    }
    
    const parent_id = upperBlockIntentaion > 0
      ? getGrandParent(upperBlockIntentaion - currentBlockIntentaion, previousBlock)
      : previousBlock

    setBlock(id, { parent_id })
    return 'stop'
  }

  const handleRemove = args => {
    const { blocks } = store.getState().notes.currentNote
    const { editor, id, nextBlockId, nextEditor, direction } = args
    if(isNestedBlock(id))
      return removeField(args)
    else if(isNestedBlock(nextBlockId) && blocks[nextBlockId.split('/')[0]].type === 'to-do-list')
      return true

    const nextBlock = { id: nextBlockId, ...blocks[nextBlockId] }
    const currentBlock = { id, ...blocks[id] }
    const removedBlock = direction > 0 ? nextBlock : currentBlock
    const receivingBlock = direction > 0 ? currentBlock : nextBlock

    const { mergeResult } = mergeNodesOnRemove({
      editor,
      nextEditor,
      removedText: removedBlock.payload.text,
      receivingText: receivingBlock.payload.text
    })
    if(mergeResult)
      setBlock(receivingBlock.id, { payload : { text: mergeResult, heading: receivingBlock.payload.heading || removedBlock.payload.heading || null } })

    deleteBlock(removedBlock.id)
  }

  return onKeyDown
}

const getNestedNextBlock = (direction, path) => {
  const { fields, fieldIndex, blockId, block } = getNestedBlockInfo(path)
  if(!isItemizedBlock(block) || fieldIndex < 0)
    return

  if((fieldIndex === fields.length - 1 && direction > 0) || (!fieldIndex && direction < 0))
    return getNextBlock(direction, blockId)

  return `${blockId}/${fields[fieldIndex + direction].id}`
}

const getNextBlock = (direction, blockId) => {
  const { blocks } = getNoteInfo()
  if(isNestedBlock(blockId))
    return getNestedNextBlock(direction, blockId)

  let nextId = blocks[blockId].next_block
  if(direction < 0)
    nextId = Object.keys(blocks).find(id => blocks[id].next_block === blockId)

  if(nextId)
    return nextId
}

export default useNoteKeyDown