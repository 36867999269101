import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import  { fetchPaymentMethod, changeReplaceCreditCardStatus, fetchAccountInvoices, getCheckOutToken, setCheckoutToken, setPaymentMethod, addAccountData } from '../actions'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useLayoutConfig } from '../../../components/PlanttLayout/LayoutConfig'

const InputTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: 0,
      margin: 0,
      padding: 0,
      paddingTop: 9,
    },
  }))(Tooltip)
const useStyles = makeStyles(theme => ({
    tooltiWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 14,
      fontWeight: 'bold',
      padding: '4px 8px',
      border: '1px solid #9b9b9b',
      position: 'relative',

      '&::before': {
        content: '""',
        position: 'absolute',
        top: '-8px',
        left: '8px',
        width: 0,
        height: 0,
        borderLeft: '8px solid transparent',
        borderRight: '8px solid transparent',
        borderBottom: '8px solid white',
        zIndex: 2,
      },
  
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '-8.5px',
        left: '8px',
        width: 0,
        height: 0,
        borderLeft: '8px solid transparent',
        borderRight: '8px solid transparent',
        borderBottom: '8px solid #9b9b9b',
        zIndex: 1,
      }
    },
    tooltipIcon: {
      backgroundColor: '#fd6c4b',
      color: 'white',
      padding: 2,
      borderRadius: 4,
      marginRight: 5,
    },
  }));

function ReplaceCreditCard({ handleCloseCreditCardDialog, firstName, lastName }) {
    const operationStatus = useSelector(state => state.setting.replaceCreditCardStatus)
    useEffect(() => {
        if(operationStatus === 2) {
            dispatch(fetchPaymentMethod())
            handleCloseCreditCardDialog()
        }
    }, [operationStatus])
    const dispatch = useDispatch();
    const classes = useStyles();
    const checkoutToken = useSelector(state => state.setting.checkoutToken)
    useEffect(() => {
            dispatch(getCheckOutToken())
        return () => {
            dispatch(setCheckoutToken(''))
        }
    }, [])
    useEffect(() => {
        if(checkoutToken !== '') {
            activateFields()
        }
    }, [checkoutToken])
    const activateFields = () => {
        const handleCardInfoValidation = (tagId, errorCode , errorDescription) => {
            console.log(errorDescription,tagId)
            if(tagId === 'ccn') {
                setshowccnTooltip(true)
            }
            if(tagId === 'exp') {
                setshowexpTooltip(true)
            }
            if(tagId === 'cvv') {
                setshowcvvTooltip(true)
            }
        /*errorCode returns:
            "10" --> invalidCcNumber, invalidExpDate, invalidCvv Dependent on the tagId;
            "22013" --> "CC type is not supported by the merchant"; 
            "14040" --> " Token is expired";
            "14041" --> " Could not find token";
            "14042" --> " Token is not associated with a payment method, please verify your client integration or contact BlueSnap support";
            "400" --> "Session expired please refresh page to continue";
            "403", "404", "500" --> "Internal server error please try again later"; 
        */
        }
        function addFocusClass(tagId) {
            var element = document.getElementById(tagId)
            element.classList.remove("checkout-field-normal");
            element.classList.add("checkout-field-focused");
        }
        function removeFocusClass(tagId) {
            var element = document.getElementById(tagId)
            element.classList.add("checkout-field-normal");
            element.classList.remove("checkout-field-focused");
        }
        var bsObj = {
            token: checkoutToken,
            onFieldEventHandler: {
                // tagId returns: "ccn", "cvv", "exp" 
                onFocus: function (tagId) { addFocusClass(tagId)}, // Handle focus
                onBlur: function (tagId) { removeFocusClass(tagId)}, // Handle blur 
                onError: function (tagId, errorCode , errorDescription) {
                    handleCardInfoValidation(tagId, errorCode , errorDescription)
                }, // Handle a change in validation
                onType: function (tagId, cardType  /*, cardData*/) {               
                    setshowccnTooltip(false)
                },
                onValid: function (tagId) {
                    if(tagId === 'ccn') {
                        setshowccnTooltip(false)
                        setisCnnValid(true)
                    }
                    if(tagId === 'exp') {
                        setshowexpTooltip(false)
                        setisExpValid(true)
                    }
                    if(tagId === 'cvv') {
                        setshowcvvTooltip(false)
                        setisCvvValid(true)
                    }
                    console.log('VALID',tagId)
                 }, // Handle a change in validation
            },
            style: {
                ":focus": {
                    "color": "orange"
                },
                "input": {
                    "color": "blue"
                },
                ".invalid": {
                    "color": "red"
                },
                "#ccn": {
                    "color": "green",
                    "border-color": "green",
                    "border-width": "2px",
                  },
            },
            ccnPlaceHolder: "1234 5678 9012 3456", //for example
            cvvPlaceHolder: "123", //for example
            expPlaceHolder: "MM/YY" //for example
        }
        //Run the following command after Document Object Model (DOM) is fully loaded 
        window.bluesnap.hostedPaymentFieldsCreate(bsObj)
    }
    //Set all the variables needed for the Form
    const [proceedButtonActive, setproceedButtonActive] = useState(true)
    const [showccnTooltip, setshowccnTooltip] = useState(false)
    const [showexpTooltip, setshowexpTooltip] = useState(false)
    const [showcvvTooltip, setshowcvvTooltip] = useState(false)
    const [isCnnValid, setisCnnValid] = useState(false)
    const [isExpValid, setisExpValid] = useState(false)
    const [isCvvValid, setisCvvValid] = useState(false)

    const handleUpdateCreditCard = () => {
        if(!isCnnValid) setshowccnTooltip(true)
        if(!isExpValid) setshowexpTooltip(true)
        if(!isCvvValid) setshowcvvTooltip(true)
        if(isCnnValid && isExpValid && isCvvValid) {
            //Disable "Subscribe Now" Buttun
            setproceedButtonActive(false)

            //Submit the Card, then save it with the acccses token
            window.bluesnap.hostedPaymentFieldsSubmitData( function(callback){
                if (null != callback.cardData) {
                    //Send Api call to replace the payment method
                    const paymentData = {
                        "cc_info": {
                            "token": checkoutToken,
                            "last_digits": callback.cardData.last4Digits,
                            "name": `${callback.cardData.ccType} ${callback.cardData.last4Digits}`
                            },
                    }
                    dispatch(changeReplaceCreditCardStatus(1))
                    dispatch(setPaymentMethod(paymentData))
                    handleCloseCreditCardDialog()
                } else {
                    //Handle Errors
                    var errorArray = callback.error;
                    for (let i in errorArray) {
                        console.log("Received error: tagId= " +
                        errorArray[i].tagId + ", errorCode= " +
                        errorArray[i].errorCode + ", errorDescription= " +
                        errorArray[i].errorDescription)
                    }
                }
            })
            //Close the Popup When API CALL is fnished
            //
        }
    }

    return (
        <div className="billing-info-change-dialog-container">
            <div className="dialog-title ">Replace credit card</div>
            {checkoutToken === '' && 
                <div className="devider-cell " >
                    <CircularProgress />
                </div>
            }
            {checkoutToken!== '' &&
                <div className="dialog-content">
                    <div className="checkhout-card-details-row">
                        <div className="checkhout-card-details">
                            Credit card number
                            <InputTooltip
                            placement="bottom-start"
                            open={showccnTooltip}
                            title={(
                                <React.Fragment>
                                    <div className={classes.tooltiWrapper}><PriorityHighIcon className={classes.tooltipIcon} fontSize="small" /> Invalid Card number</div>
                                </React.Fragment>
                                )}
                            >
                                <div id="ccn" className="checkout-ccn checkout-field-normal" data-bluesnap="ccn"></div>
                            </InputTooltip>
                        </div>
                        <div className="checkhout-card-details">
                            Month 
                            <InputTooltip
                                open={showexpTooltip}
                                title={(
                                    <React.Fragment>
                                        <div className={classes.tooltiWrapper}><PriorityHighIcon className={classes.tooltipIcon} fontSize="small" /> Invalid expiration date</div>
                                    </React.Fragment>
                                    )}
                            >
                                <div id="exp" className="checkout-exp checkout-field-normal" data-bluesnap="exp"></div>
                            </InputTooltip>
                        </div>
                        <div className="checkhout-card-details">
                            CVV
                            <InputTooltip
                                placement="right-end"
                                open={showcvvTooltip}
                                title={(
                                    <React.Fragment>
                                        <div className={classes.tooltiWrapper}><PriorityHighIcon className={classes.tooltipIcon} fontSize="small" /> Invalid CVV number</div>
                                    </React.Fragment>
                                    )}
                            >
                                <div id="cvv" className="checkout-cvv checkout-field-normal" data-bluesnap="cvv"></div>
                            </InputTooltip>
                        </div>
                    </div>
                    <div className="devider-cell">
                    <Divider />
                    </div>
                    <div className="flex-row">
                        <div className="dialog-button canceal-button pointer" onClick={handleCloseCreditCardDialog}>
                            CANCEL
                        </div>
                        <div className={`dialog-button ${proceedButtonActive ? 'save-button' : 'save-button-disabled'}`}  onClick={handleUpdateCreditCard}>
                            {proceedButtonActive ? 'SAVE' : 'Working...'}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
function Billing() {
    useLayoutConfig({ title: 'Billing' })

    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        dispatch(fetchAccountInvoices())
        dispatch(fetchPaymentMethod())
        //Fetch payment Method
    }, [])
    const invoices = useSelector(state => state.setting.invoices)
    const paymentMethodData = useSelector(state => state.setting.paymentMethodData)
    const billingInfo = useSelector(state => state.global.workspace.billing_info)

    const [isBillingInfoDialogOpen, setisBillingInfoDialogOpen] = useState(false)
    const [isReplaceCreditCardDialogOpen, setisReplaceCreditCardDialogOpen] = useState(false)
    const handleOpenCreditCardDialog = () => {
        dispatch(changeReplaceCreditCardStatus(0))
        setisReplaceCreditCardDialogOpen(true)

    }
    const handleCloseCreditCardDialog = () => {
        setisReplaceCreditCardDialogOpen(false)
    }
    
    const handleCloseIsBillingInfoDialogOpen = () => {
        setisBillingInfoDialogOpen(false)
    }
    const handleUpdateBillingInfo = () => {
        if(firstName === '') setshowFirstNameTooltip(true)
        if(lastName === '') setshowLastNameTooltip(true)
        if(companyName === '') setshowCompanyNameTooltip(true)
        emailValidation()
        if(firstName !== '' && lastName !== '' && companyName !== '' && emailValidation()) {
            //Validation done, Update data.
             const biilingInfo = {
                        "billing_email": billingEmail,
                        "company_name": companyName,
                        "first_name": firstName,
                        "last_name": lastName
                      }
            dispatch(addAccountData(biilingInfo))
            //Close Dialog
            setisBillingInfoDialogOpen(false)
        }
    }

    const [showFirstNameTooltip, setshowFirstNameTooltip] = useState(false)
    const [showLastNameTooltip, setshowLastNameTooltip] = useState(false)
    const [showCompanyNameTooltip, setshowCompanyNameTooltip] = useState(false)
    const [showBillingEmailTooltip, setshowBillingEmailTooltip] = useState(false)
    const [firstName, setFirstName] = useState(billingInfo.first_name)
    const [lastName, setLastName] = useState(billingInfo.last_name)
    const [companyName, setCompanyName] = useState(billingInfo.company_name)
    const [billingEmail, setBillingEmail] = useState(billingInfo.billing_email)
    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value)
        if(e.target.value !== '')
            setshowFirstNameTooltip(false)
    }
    const handleLastNameChange = (e) => {
        setLastName(e.target.value)
        if(e.target.value !== '')
            setshowLastNameTooltip(false)
    }
    const handleCompanyNameChange = (e) => {
        setCompanyName(e.target.value)
        if(e.target.value !== '')
            setshowCompanyNameTooltip(false)
    }
    const handleBillingEmailChange= (e) => {
        setBillingEmail(e.target.value)
        setshowBillingEmailTooltip(false)
    }
    const emailValidation = () => {
        //eslint-disable-next-line
        const emailRegEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailRegEx.test(billingEmail)) {
            setshowBillingEmailTooltip(true)
            return false
        }
        else {
            setshowBillingEmailTooltip(false)
            return true
        }
    }
  return (
    <div className="billing">
        <h2>Billing Info</h2>
        <Grid container spacing={0} >
            <Grid item md={6} className="billing-box">
                <div className="content">Payment Method</div>
                <div className="content">{billingInfo.first_name} {billingInfo.last_name} {paymentMethodData.name}</div>
                <div className="billing-box-button billing-box-button-left pointer" onClick={handleOpenCreditCardDialog}>Replace card</div>
            </Grid>
            <Grid item md={6} className="billing-box">
            <div className="content">Billing Info</div>
                <div className="content">{billingInfo.company_name}</div>
                <div className="content">{billingInfo.billing_email}</div>
                <div className="billing-box-button billing-box-button-right pointer" onClick={() => setisBillingInfoDialogOpen(true)}>Edit Info</div>
            </Grid>
        </Grid>
        <Dialog  open={isReplaceCreditCardDialogOpen} onClose={handleCloseCreditCardDialog} aria-labelledby="form-dialog-title">
            <ReplaceCreditCard handleCloseCreditCardDialog={handleCloseCreditCardDialog} firstName={firstName} lastName={lastName} />
        </Dialog>
        
        <Dialog  open={isBillingInfoDialogOpen} onClose={handleCloseIsBillingInfoDialogOpen} aria-labelledby="form-dialog-title">
            <div className="billing-info-change-dialog-container">
                <div className="dialog-title ">Edit billing information</div>
                <div className="dialog-content">
                <div className="checkout-additional-details">
                    <div  className="checkout-additional-details-cell">
                        First name
                        <InputTooltip
                            open={showFirstNameTooltip}
                            title={(
                                <React.Fragment>
                                    <div className={classes.tooltiWrapper}><PriorityHighIcon className={classes.tooltipIcon} fontSize="small" /> Please fill out this field</div>
                                </React.Fragment>
                                )}
                        >
                            <input value={firstName} onChange={handleFirstNameChange} type="text" className="checkout-input-field checkout-field-normal" id="firstName" />
                        </InputTooltip>                                
                    </div>
                    <div className="checkout-additional-details-cell">
                        Last Name
                        <InputTooltip
                            open={showLastNameTooltip}
                            title={(
                                <React.Fragment>
                                    <div className={classes.tooltiWrapper}><PriorityHighIcon className={classes.tooltipIcon} fontSize="small" /> Please fill out this field</div>
                                </React.Fragment>
                                )}
                        >
                            <input value={lastName} onChange={handleLastNameChange} type="text" className="checkout-input-field checkout-field-normal" id="lastName" />
                        </InputTooltip>
                    </div>
                </div>
                <div className="checkout-additional-details">
                    <div className="checkout-additional-details-cell">
                        Company Name
                        <InputTooltip
                            open={showCompanyNameTooltip}
                            title={(
                                <React.Fragment>
                                    <div  className={classes.tooltiWrapper}><PriorityHighIcon className={classes.tooltipIcon} fontSize="small" /> Please fill out this field</div>
                                </React.Fragment>
                                )}
                        >
                            <input value={companyName} onChange={handleCompanyNameChange} type="text" className="checkout-input-field checkout-field-normal" id="CompanyName" />
                        </InputTooltip>
                    </div>
                    
                    <div className="checkout-additional-details-cell">
                        Billing Email
                        <InputTooltip
                            open={showBillingEmailTooltip}
                            title={(
                                <React.Fragment>
                                    <div  className={classes.tooltiWrapper}><PriorityHighIcon className={classes.tooltipIcon} fontSize="small" /> Please enter valid Email adress</div>
                                </React.Fragment>
                                )}
                        >
                            <input value={billingEmail} onChange={handleBillingEmailChange} onBlur={emailValidation} type="text" className="checkout-input-field checkout-field-normal" id="billingEmail" />
                        </InputTooltip>
                    </div>
                </div>
                <div className="devider-cell">
                    <Divider />
                </div>
                <div className="flex-row">
                    <div className="dialog-button canceal-button pointer" onClick={handleCloseIsBillingInfoDialogOpen}>
                        CANCEL
                    </div>
                    <div className={`dialog-button ${(firstName !== '' && lastName !== '' && companyName !== '' && billingEmail !== '' )? 'save-button' : 'save-button-disabled'} `}  onClick={handleUpdateBillingInfo}>
                        SAVE
                    </div>
                </div>
            </div>
        </div>
    </Dialog>
    <div className="divider">
        <Divider />
    </div>
    <div className="billing-transactions">Transactions</div>
    {Array.isArray(invoices) && invoices.map((invoice) => (
        <span key={`invoiceRow_${invoice.id}`}>
        <div className="billing-invoice-row">
            <div className="billing-invoice-cell">{invoice.id}</div>
            <div className="billing-invoice-cell">{invoice.date}</div>
            <div className="billing-invoice-cell">{invoice.amount}</div>
            <div className="billing-invoice-cell">{invoice.plan_name}</div>
        </div>
        <div className="transactions-divider">
            <Divider />
        </div>
        </span>

    )) 
    }
    </div>
  );
}


export default Billing;
