import { FormatBold, FormatItalic, FormatListBulleted, FormatListNumbered, FormatUnderlined, Link, StrikethroughS } from '@material-ui/icons'

const formats = [
  {
    type: 'block',
    format: 'edge-block',
    Render: ({ attributes, children }) => <div data-edge-block style={{ height: 0, overflow: 'hidden' }}>{children}</div>,
  },
  { 
    type: 'mark',
    markdown: '**',
    format: 'bold',
    Icon: FormatBold,
    Render: ({ children }) => <strong>{children}</strong>
  },
  { 
    type: 'mark',
    markdown: '_',
    format: 'italic',
    Icon: FormatItalic,
    Render: ({ children }) => <em>{children}</em>
  },
  { 
    type: 'mark',
    markdown: '--',
    format: 'underline',
    Icon: FormatUnderlined,
    Render: ({ children }) => <u>{children}</u>
  },
  { 
    type: 'mark',
    markdown: '~~',
    format: 'strikethrough',
    Icon: StrikethroughS,
    Render: ({ children }) => <s>{children}</s>
  },
  { 
    type: 'mark',
    format: 'link',
    Render: ({ children, attributes = {}, element }) => {
      let href = element?.href
      if(href && !href.includes('http'))
        href = `http://${href}`

      return (
        <a
          style={{ textDecoration: 'underline', color: '#155788' }}
          href={href}
          target='_blank'
          {...attributes}
          // data-link-id={leaf.link}
        >
          {children}
        </a>
      )
    }
  },
  {
    type: 'block',
    format: 'unordered-list',
    list: true,
    Icon: FormatListBulleted,
    Render: ({ attributes, children }) => <ul {...attributes}>{children}</ul>,
    Child: 'li',
    prefix: () => '- '
  },
  {
    type: 'block',
    format: 'ordered-list',
    list: true,
    Icon: FormatListNumbered,
    Render: ({ attributes, children }) => <ol {...attributes}>{children}</ol>,
    Child: 'li',
    prefix: index => `${index + 1}. `
  },
  {
    format: 'list-item',
    Render: ({ attributes, children }) => <li {...attributes}>{children}</li>
  },
  {
    //This format is not used in the notes themselves, but rather in the note block "mrkdwn" type
    type: 'block',
    format: 'side-line',
    list: true,
    Render: ({ children }) => <div className='mrkdwn-side-line'><div></div><div>{children}</div></div>,
    Child: 'div',
    prefix: () => '>'
  }
]


export default formats