import { useEffect, useMemo, useRef, useState } from 'react'
import { BASE_URL2 } from '../../../../apis/constant';
import { getFieldDefault, getNoteParams } from '../../actions';
import { NEW_sendPostRequest } from '../../../../apis/api-utils';
import { isEqual } from 'lodash';
import { store } from '../../../..';

const useDynamicFields = ({ action, payload, setPayload, blockId }) => {
  const { values } = payload
  const [dynamicFields, setDynamicFields] = useState({})
  const [dynamicFieldsLoading, setDynamicFieldsLoading] = useState(false)

  const previousValues = useRef()

  const fetchTimestamp = useRef()
  const fetchFields = async () => {
    if(isEqual(values, previousValues.current))
      return
    previousValues.current = values
    const dynamicInputs = action?.input?.filter(i => i.type === 'dynamic_input') || []
    if(!dynamicInputs.length)
      return

    setDynamicFieldsLoading(true)
    try {
      const fetchedOn = fetchTimestamp.current = new Date()
      const formState = {}
      Object.keys(values).forEach(key => formState[key] = values[key].value ?? values[key])
      let fetchedFields = await Promise.all(dynamicInputs.map(i => {
        return NEW_sendPostRequest(`${BASE_URL2}action/${action.spec_ref}/dynamic-input`, {}, JSON.stringify({ action: action.id, dynamic_id: i.key, formState }))
      }))
      
      if(fetchedOn.getTime() !== fetchTimestamp.current.getTime())
        return

      if(typeof blockId !== 'undefined'){
        /* This is here to cover a scenario where block was deleted after fetch was triggered and before response arrived */
        const { templateId } = getNoteParams()
        const { currentNote, templates } = store.getState().notes
        const gist = !templateId ? currentNote : templates[templateId]
        const stillExists = Boolean(gist.blocks[blockId])
        if(!stillExists)
          return
        /* END DELETED SCENARIO */
      }
      
      const newDynamic = {}
      fetchedFields.forEach((fields, index) => {
        if(!fields.ok)
          throw new Error(fields.text)
        newDynamic[dynamicInputs[index].key] = fields.text
      })

      setDynamicFields(newDynamic)

      fetchedFields = fetchedFields.map(fields => fields.text).flat()
      const dynamicValues = {}
      fetchedFields.forEach(field => {
          dynamicValues[field.key] = payload.dynamicValues?.[field.key] || getFieldDefault(field)
      })
      setPayload({ ...payload, dynamicValues })
    } catch(error) {
      console.error(error)
    }
    setDynamicFieldsLoading(false)
  }

  useEffect(() => {
    fetchFields()
  }, [values, action])

  const fieldList = useMemo(() => {
    if(!action?.input)
      return []

    const list = [ ...action.input ]
    list.forEach((item, index) => {
      if(item.type === 'dynamic_input'){
        const replacement = dynamicFields[item.key]?.map(field => ({ ...field, is_dynamic: true })) || []
        Array.prototype.splice.apply(list, [index, 1].concat(replacement))
      }
    })
    return list
  }, [action?.input, dynamicFields])

  return { fieldList, dynamicFieldsLoading }
}

export default useDynamicFields