import { useDispatch } from "react-redux"
import Tooltip from "../../../components/Tooltip/new"
import { addNotes, modifyMomentMetadata } from "../actions"

import { Check } from "@material-ui/icons"
import useMomentsInfo from "../useMomentsInfo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import { CircularProgress } from "@material-ui/core"

function MomentHeader({ moment, animateActions }){
  const dispatch = useDispatch()
  const { completed, archive, liked } = moment.metadata || {}
  const { mode } = useMomentsInfo()

  const modify = key => dispatch(modifyMomentMetadata({ mode, moment, key, animateActions }))
  const tooltipProps = { placement: 'bottom', lean: true, enterDelay: 400, enterNextDelay: 400 }
  const hasAccountInParticipants = moment?.metadata?.participants.find(p => p.type === 'account')

  const [createGistLoading, setCreateGistLoading] = useState(false)

  const createGist = async () => {
    setCreateGistLoading(true)
    const { error } = await dispatch(addNotes({ momentId: moment.id }))
    if(error)
      setCreateGistLoading(false)
  }

  return (
    <div className='moment-header'>
      <button className={`toggle-completed ${completed ? 'is-completed' : ''}`} onClick={() => modify('completed')}>
        <Check /> {!completed ? 'Mark Completed' : 'Completed'}
      </button>
      <div className='moment-header-left'>
        {hasAccountInParticipants &&
        <Tooltip
          content='Create a gist'
          {...tooltipProps}
          className='create-gist'
          onClick={createGist}
        >
          <div>
            {!createGistLoading
              ? <FontAwesomeIcon icon={faPaperPlane} />
              : <CircularProgress size={14} />
            }
          </div>
        </Tooltip>}
        <Tooltip content='Archive moment' {...tooltipProps}>
          <span className={`material-icons${!archive ? '-outlined' : ''} archive-icon`} onClick={() => modify('archive')}>
            archive
          </span>
        </Tooltip>
        {liked
          ? <Tooltip content='You liked this' {...tooltipProps}>
              <span className='material-icons-round like-icon is-liked' onClick={() => modify('liked')}>
                thumb_down
              </span>
            </Tooltip>
          : <Tooltip
              content={<span style={{ textAlign: 'center' }}>Like this.<br/> It will help us improve for you</span>}
              {...tooltipProps}
            >
              <span className='material-icons-round like-icon' onClick={() => modify('liked')}>
                thumb_down_off_alt
              </span>
            </Tooltip>
        }
      </div>
    </div>
  )
}

export default MomentHeader