import React, { useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AutosizeInput from 'react-input-autosize'
import { format } from 'date-fns'
import EditIcon from '@material-ui/icons/Edit'
import ChangePasswordForm from './ChangePassword'

import { updadeAccountName, updateUserEmailAddress } from './actions';
import { useLayoutConfig } from '../../components/PlanttLayout/LayoutConfig';
import { BlueButton, OrangeButton } from '../../components/Button';
import { upperFirst } from '../../helpers';
import './style.scss';

function MyProfile() {
  useLayoutConfig({ title: 'My Profile' })

  const dispatch = useDispatch();

  const createdAt = useSelector(state => state.firebase.auth.createdAt)
  const currentMember = useSelector(state => state.global.workspaceMembers.find(m => m.id === state.global.currentMember.id))
  const [loading, setLoading] = useState(null)
  const [state, setState] = useState({ name: '', email: '', editName: false, editEmail: false })

  useLayoutEffect(() => {
    if(currentMember)
      setState({ ...state, name: currentMember.name || '', email: currentMember.email || '' })
  }, [currentMember])

  const update = (id, updateFunction) => async e => {
    e.preventDefault()
    setLoading(id)
    const response = await dispatch(updateFunction(state[id]))

    if(response === 'email-exists' || response?.includes('recent-login'))
      setState({ ...state, email: currentMember.email || '' })
    setLoading(null)
    inputsRef.current[id]?.input?.blur()
  }

  const inputsRef = useRef({ name: null, email: null })

  return (
    <div className='profile-page'>
      <div className='card'>
        <div>
          <HiddenEdit id='name' onSave={update('name', updadeAccountName)} {...{ state, setState, loading, inputsRef }} />
          <div className='member-created'>
            Member since {format(new Date(parseInt(createdAt || 0)), 'LLLL do, YYY')}
          </div>
          <HiddenEdit id='email' onSave={update('email', updateUserEmailAddress)} label='Email:' {...{ state, setState, loading, inputsRef }} />
        </div>
        {/* <hr /> */}
        {/* <div className='change-password-container'>
          <div className='password-header'>Password</div>
          <div className='password-description'>Change the password you use to login to your Plantt account.</div>
          <ChangePasswordForm />
        </div> */}
      </div>
    </div>
  );
};

function HiddenEdit({ id, label, state, setState, loading, onSave, inputsRef }){
  const editKey = `edit${upperFirst(id)}`
  const changeState = key => e => setState({ ...state, [key]: e.target.value })
  const setEditing = (key, value) => () => setState({ ...state, [key]: value })

  return (
    <div className={`hidden-edit ${id}`}>
      <label>
        {label}
        <AutosizeInput
          value={state[id]}
          placeholder={`Update ${id}`}
          className='edit-input'
          onChange={changeState(id)}
          onFocus={setEditing(editKey, true)}
          onBlur={setEditing(editKey, false)}
          onKeyDown={e => e.key === 'Enter' ? onSave(e) : e.key === 'Escape' ? e.target.blur() : null}
          ref={elem => inputsRef.current[id] = elem}
        />
        {!state[editKey] && <EditIcon />}
      </label>
      {state[editKey] &&
      <>
        <OrangeButton onMouseDown={onSave} loading={loading === id} spinnerProps={{ size: 12 }}>Save</OrangeButton>
        <BlueButton outline>Cancel</BlueButton>
      </>}
    </div>
  )
}

export default MyProfile;
