import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { ReactReduxContext } from 'react-redux';

import getInjectors from './reducerInjectors';

/**
 * Dynamically injects a reducer
 * reducersList: When the page need to control more than one reducer
 */
//eslint-disable-next-line
export default ({ key, reducer, reducersList }) => (WrappedComponent) => {
  class ReducerInjector extends React.Component {
    static WrappedComponent = WrappedComponent;

    static contextType = ReactReduxContext;

    static displayName = `withReducer(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

    constructor(props, context) {
      super(props, context);

      if (reducersList) {
        reducersList.forEach((reducerItem) => {
          getInjectors(context.store).injectReducer(reducerItem.key, reducerItem.reducer);
        })
      } else {
        getInjectors(context.store).injectReducer(key, reducer);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return hoistNonReactStatics(ReducerInjector, WrappedComponent);
};
