// function popupWindow(url, windowName, w, h) {
//   const y = window.top.outerHeight / 2 + window.top.screenY - ( h / 2);
//   const x = window.top.outerWidth / 2 + window.top.screenX - ( w / 2);
//   return window.open(url, windowName, `toolbar=no,directories=no,status=no,menubar=no,width=${w},height=${h},top=${y},left=${x}`);
// }

export const setRedirectUri = (connectorSpec) => {
    return `${window.location.origin}/inte/${connectorSpec.authentication.payload.oauth_redirect_name}/install/handle-user-decision`
}

export const handleOauthRedirect = (connectorSpec, connectorId, authFieldsValues = {}) => {
    let qParams = [`redirect_uri=${setRedirectUri(connectorSpec)}`]
    Object.entries(connectorSpec?.authentication?.payload?.params).map(([key,value]) => qParams.push(`${key}=${value}`));
    localStorage.setItem('isAddingConnector',JSON.stringify({instanceId: connectorId, timestamp: new Date().getTime()}))
    //handle zendesk exception
    if(connectorSpec.id === 'zendesk2') {
        const readyAuthFields = {}
        Object.entries(authFieldsValues).forEach(([key,value]) => {
          readyAuthFields[key] = value.value
        })
         const regex = new RegExp(`/(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})zendesk\.com`);
         const zendeskSubdomain = regex.test(readyAuthFields.zendesk_subdomain) ? readyAuthFields.zendesk_subdomain.match(regex)[1].slice(1) : readyAuthFields.zendesk_subdomain
         window.location.assign(`https://${zendeskSubdomain}.zendesk.com/oauth/authorizations/new?redirect_uri=https://app.plantt.io/zendesk-login/handle_user_decision&scope=read write&client_id=zdg-plantt-zendesk&response_type=code`);
    }
    else {
        window.location.assign(`${connectorSpec.authentication.payload.oauth_url}?${qParams.join('&')}`)
    }
}