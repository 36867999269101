
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';


function IsItemGood({ item,}){
  let flag = true
  Object.values(item.errors).forEach(error => {
      if(error) 
        flag = false
  })

  return (item.name && item.planttName && flag) ? <CheckCircleRoundedIcon style={{color:'#3aaf4b'}} /> : null
}

export default IsItemGood