import { Fragment, useContext } from 'react'
import Message from './Message'

import { OrangeButton } from '../../../components/Button'
import Logo from '../../../images/logo.svg'
import PlanttBee from '../../../images/PlanttBee.svg'
import LoginContext from '../LoginContext'

function RenderPage({ heading, top, bottom, onSubmit, hasPassword, continueText, onUpdate = () => {} } ){
  const { state, setState, loading, errors, setErrors } = useContext(LoginContext)

  const update = (e, field) => {
    setErrors({ ...errors, [field]: false, failed: false })
    setState({ ...state, [field]: e.target.value })
    onUpdate()
  }

  return (
    <div>
      <img src={Logo} id='sign-in-logo' />
      <h1 className={heading.className}>{heading.text}</h1>
      {top && <TopSection {...top} />}
      <form onSubmit={onSubmit}>
        <input placeholder='name@work-email.com' value={state.email} onChange={e => update(e, 'email')} />
        <Message />
        <OrangeButton padding='6px 0' loading={loading}>{continueText || 'Continue' }</OrangeButton>
      </form>
      <div id='sign-in-bottom'>
        {bottom}
        <img src={PlanttBee} id='sign-in-plantt-bee' />
      </div>
    </div>
  )
}

function TopSection({ GoogleComponent, subtitle = 'We suggest using your work email' }){
  return (
    <Fragment>
      <h2>{subtitle}</h2>
      {GoogleComponent &&
      <Fragment>
        <GoogleComponent />
        <div id='sign-in-or'><div /><div id='or-text'>OR</div><div /></div>
      </Fragment>}
    </Fragment>
  )
}

export default RenderPage
