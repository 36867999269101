import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CircularProgress, ClickAwayListener, Popper } from "@material-ui/core"
import { Help, MoreHoriz } from "@material-ui/icons"
import { useMemo, useRef, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import ConfirmModal from "../../ConfirmModal"
import Hoverable from "../../Hoverable"
import Scrollable from "../../Scrollable"
import Tooltip from '../../Tooltip/new'

import './style.scss'

function SubNav({ navSpec, bottomSection = null, ...props }){
  return (
    <Scrollable id='sub-nav'>
      <div id='sub-nav-main'>
        {navSpec.map((section, index) => <RenderSection key={section.name || index} {...props} section={section} />)}
      </div>
      {bottomSection && <RenderSection {...props} section={bottomSection} />}
    </Scrollable>
  )
}

function RenderSection({ section, targetPrefix = '', noTruncation = false }){
  const { name, add, addTooltip, items, info, Tail } = section
  return (
    <>
      {typeof name !== 'undefined' &&
      <div className='nav-section-header'>
        <div className='section-name'>
          {name}
          {info &&
          <Tooltip enterDelay={100} placement='bottom' content={info} lean styles={{ wrapper: { maxWidth: 132 } }}>
            <Help />
          </Tooltip>}
        </div>
        {add && 
        <Tooltip disableHoverListener={!addTooltip} enterDelay={200} placement='bottom' {...(addTooltip || {})}>
          <div className='add-icon' onClick={add}>
            <FontAwesomeIcon icon={faPlus} />
          </div>
        </Tooltip>}
      </div>}
      {items.length
        ? items.map((item, itemIndex) => !item.info
            ? <RenderItem key={`${item.text}-${itemIndex}`} {...{ item, section, itemIndex, targetPrefix, noTruncation }} />
            : <Tooltip key={`${item.text}-${itemIndex}`} enterDelay={400} enterNextDelay={400} placement='bottom' content={item.info} lean>
                <div>
                  <RenderItem {...{ item, section, itemIndex, targetPrefix, noTruncation }} />
                </div>
              </Tooltip>
          )
        : <div className='section-empty'>No {section.name} yet</div>
      }
      {Tail && <Tail className='accounts-nav-item' />}
    </>
  )
}

const noop = () => {}
function RenderItem({ item, section, itemIndex, targetPrefix, noTruncation }){
  const location = useLocation()
  const target = targetPrefix + section.target(item)
  const text = item.text || item.name
  const shortened = text?.slice(0, 11)
  const { count, options, RightIcon, selected = noop } = section
  const icon = item.icon || section.icon

  const [optionsAnchor, setOptionsAnchor] = useState()

  const isSelected = useMemo(() => {
    return location.pathname + location.search === target || selected(item)
  }, [location, target, selected, item])

  return (
    <Link
      key={item.id || itemIndex}
      to={target}
      className={`accounts-nav-item ${isSelected ? 'is-selected' : ''}`}
      onMouseLeave={() => setOptionsAnchor(null)}
      onClick={() => section.onClick?.(item)}
    >
      <div>
        {icon && <div className='icon-wrapper'><i className={icon.includes(' ') ? icon : `far fa-${icon}`} /></div>}
        {!noTruncation && !section.noTruncation && shortened !== text ? shortened + '...' : text}
      </div>
      <div>
        {count && <div className='item-count'>{count(item)}</div>}
        {options && <RenderOptions options={options} {...{ item, optionsAnchor, setOptionsAnchor }} />}
        {RightIcon &&
        <div className='right-icon' onClick={e => e.preventDefault()} >
          <RightIcon {...{ optionsAnchor, setOptionsAnchor, ...item }} />
        </div>}
      </div>
    </Link>
  )
}

function RenderOptions({ options, item, optionsAnchor, setOptionsAnchor }){
  const ref = useRef()
  const [loading, setLoading] = useState()
  const [promptModal, setPromptModal] = useState(null)

  const optionClick = async option => {
    const { onClick, redirecting, isDelete, prompt } = option
    if(prompt){
      if(!promptModal){
        setOptionsAnchor(null)
        return setPromptModal(option)
      }else{
        setPromptModal(null)
      }
    }

    if(redirecting)
      return onClick(item)

    setLoading(true)
    await onClick(item)
    setOptionsAnchor(null)
    if(!isDelete)
      setLoading(false)
  }

  return (
    <div className='right-icon' onClick={e => e.preventDefault()} ref={ref} >
      <div>
        {!loading
          ? <MoreHoriz className='more-icon' onClick={() => setOptionsAnchor(ref.current)} />
          : <CircularProgress className='working-indicator' size={12} />
        }
      </div>
      <Popper anchorEl={optionsAnchor} open={Boolean(optionsAnchor)} placement='bottom-end' container={optionsAnchor}>
        <ClickAwayListener onClickAway={() => setOptionsAnchor(null)}>
          <div className='nav-options-popper card'>
            {options.map(option => (
              <Hoverable key={option.text} onClick={() => optionClick(option)}>{option.text}</Hoverable>
            ))}
          </div>
        </ClickAwayListener>
      </Popper>
      <ConfirmModal
        open={Boolean(promptModal)}
        onClose={() => setPromptModal(null)}
        onConfirm={() => optionClick(promptModal)}
        text={promptModal?.prompt}
      />
    </div>
  )
}

export default SubNav