import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { updateCurrentConnector, deleteConnector } from '../actions'
import AutoSyncSwitch from './AutoSyncSwitch'
import { setToastMessage } from "../../App/actions"
import { trackMixpanelEvents } from '../../../helpers/mixpanel'
import ConfirmModal from '../../../components/ConfirmModal';
import { formatDistanceToNow } from 'date-fns/esm'
import ConnectButton from './ConnectButton'
import { BlueButton } from '../../../components/Button'
import AppStatusBox from './AppStatusBox'
import Mapping from './Mapping'
import { useCurrentConnector } from '../helpers'

import Scrollable from '../../../components/Scrollable'

function GeneralSettings () {
    const { section } = useParams()
    const { currentConnector, connectorSpec } = useCurrentConnector()
    const history = useHistory()
    const dispatch = useDispatch()
    
    const [appEnabled, setAppEnabled] = useState(currentConnector?.status === 'active' ? true : false)
    const [dialogOpen, setdialogOpen] = useState(false)
    const switchDisabled = currentConnector?.authentication.status !== 'authenticated'

    const alertIfDisabled = () => {
        if(switchDisabled)
            dispatch(setToastMessage(`Authentication invalid. App cannot be enabled`,`error`))
    }

    const updateAppStatus = async (event) => {
        const { checked } = event.target
        setAppEnabled(checked)
        const updatedConnector = { ...currentConnector, status: event.target.checked ? 'active' : 'draft' }
        trackMixpanelEvents("apps_updated_status")
        const response = await dispatch(updateCurrentConnector(updatedConnector))
        if(response?.error)
            setAppEnabled(currentConnector.status === 'active' ? true : false)
    }

    const [deleting, setDeleting] = useState(false)
    const handleDeleteConnector = async () => {
        setDeleting(true)
        await dispatch(deleteConnector(currentConnector.id))
        setDeleting(false)
        trackMixpanelEvents("connectors_delete_connector")
        history.push('/apps')
    }

    const mappingRef = useRef()
    const containerRef = useRef()
    useEffect(() => {
        if(section === 'mapping' && mappingRef.current && containerRef.current)
            setTimeout(() => {
                containerRef.current.scrollTo({
                    top: mappingRef.current.offsetTop + mappingRef.current.parentNode.offsetTop + 1, // + 1 compensates for top border
                    behavior: 'smooth'
                })
            }, 300)
    }, [mappingRef.current, containerRef.current, section])

    return (
        <Scrollable className="settings-container" ref={containerRef}>
            {currentConnector && <AppStatusBox {...{ currentConnector, connectorSpec }} />}
            <div className="general-settings-container">
                <div id="app-settings-header">
                    <div className="left-side">
                        <img id="logo" src={connectorSpec?.icon_url} />
                        <div>
                            <div id='app-name'>{currentConnector?.name ?? connectorSpec.name}</div>
                            <div className="description">
                                {currentConnector
                                    ? `Added ${formatDistanceToNow(new Date(currentConnector.created_at))} ago`
                                    : 'Not connected to Plantt'
                                }
                            </div>
                            <div className="description">
                                {currentConnector
                                    ? `Last synced ${formatDistanceToNow(new Date(currentConnector?.sync_status?.last_sync || 0))} ago`
                                    : `Connect ${connectorSpec.name} to start syncing with your workspace`
                                }
                            </div>
                        </div>
                    </div>
                    <div className="right-side">
                        <ConnectButton {...{ currentConnector, connectorSpec }} />
                        {currentConnector &&
                        <BlueButton outline onClick={() => setdialogOpen(true)}>Delete</BlueButton>}
                    </div>
                </div>
                {connectorSpec?.data_sync && 
                <div id='app-sync-info'>
                    <div id='auto-sync-wrapper' onClick={alertIfDisabled}>
                        <AutoSyncSwitch onChange={updateAppStatus} checked={appEnabled} disabled={switchDisabled} />
                    </div>
                </div>}
                {currentConnector && connectorSpec?.need_mapping &&
                <section id='field-mapping' ref={mappingRef}>
                    <h2>Field Mapping</h2>
                    <Mapping />
                </section>}
                <ConfirmModal
                    open={dialogOpen}
                    onClose={() => setdialogOpen(false)}
                    onConfirm={handleDeleteConnector}
                    text='Are you sure you would like to delete this app?'
                    loading={deleting}
                />
            </div>
        </Scrollable>
    )
}
export default GeneralSettings