import { Link } from "react-router-dom"
import Scrollable from "../Scrollable"
import Tooltip from "../Tooltip/new"
import UserAvatar from "../UserAvatar"
import useComputedParticipants from "./useComputedParticipants"
import { useMemo } from "react"
import './style.scss'

function ParticipantAvatars({ participants, id = '', EmptyState, use = 'accounts', avatarSize = 32, tooltipTitle = null, alwaysShowTooltip = false}){
  const computed = useComputedParticipants(participants)
  const { accounts, emails } = computed
  return (
    <AvatarsWrapper {...{ computed, use, tooltipTitle, alwaysShowTooltip }}>
      <div className='participant-avatars'>
        {EmptyState && <EmptyState {...{ accounts, emails }} />}
        {computed[use].slice(0, 4).map(({ payload, type, contacts }) => (payload.name || payload.website) && (type !== 'workspace' || contacts.length > 1) ? (
          <UserAvatar
            size={avatarSize}
            key={payload.name}
            website={payload.website}
            name={payload.name || (payload.first_name ? `${payload.first_name} ${payload.last_name}` : (id.includes('@') ? id : null))}
            url={payload.profile_image}
            fontRatio={0.45} 
          />
        ) : null)}
      </div>
    </AvatarsWrapper>
  )
}

function AvatarsWrapper({ children, computed, use, tooltipTitle, alwaysShowTooltip }){
  const { emails, accounts } = computed
  const filteredAccounts = useMemo(() => {
    return accounts.filter(({ contacts, type }) => type !== 'workspace' || contacts?.length > 1)
  }, [accounts])

  return filteredAccounts.length || emails.length || alwaysShowTooltip
    ? <Tooltip
        content={<TooltipContent {...{ use, computed, tooltipTitle }} accounts={filteredAccounts} />}
        placement='bottom'
        theme={{ backgroundColor: '#fff', borderColor: '#ddd', color: 'inherit' }}
        interactive
        styles={{ wrapper: { maxWidth: 'none' }}}
        enterDelay={400}
        enterNextDelay={400}
      >
        {children}
      </Tooltip>
    : children
}

function TooltipContent({ accounts, computed, use, tooltipTitle }){
  const { emails } = computed
  return (
    <Scrollable className='moment-participants-tooltip'>
      {tooltipTitle && <h1>{tooltipTitle}</h1>}
      {computed[use].map(({ contacts, payload, type }) => (
        <div className='tooltip-account-container' key={payload.id}>
          <div className='tooltip-account-details'>
            <UserAvatar
              size={use === 'accounts' ? 32 : 24}
              website={payload.website}
              name={payload.name}
              url={payload.profile_image}
              fontRatio={0.45} 
            />
            {payload.name && (type === 'account' ? <Link to={`/account/${payload.id}`}>{payload.name}</Link> : payload.name)}
          </div>
          {contacts && contacts.map(contact => (
            <div className='tooltip-account-contact' key={contact.id}>
              <UserAvatar
                size={18}
                name={contact.name}
                fontRatio={0.45} 
              />
              {contact.name}
            </div>
          ))}
        </div>
      ))}
      {emails.length > 0 &&
      <ul style={{ padding: '0 12px 0 16px', margin: `${accounts.length ? '12px' : '0'} auto 0` }}>
        {emails.map(({ payload }) => <li key={payload.id}>{payload.id}</li>)}  
      </ul>}
    </Scrollable>
  )
}

export default ParticipantAvatars