import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import OwnerSelector from '../../../components/OwnerSelector'
import { setCSM } from "../actions"
import { useMemo, Fragment } from "react"
import { groupPath } from '../../Accounts/helpers'
import AppIcons from './AppIcons'

function OverviewTitle({ showAppIcons, ...appIconsProps }){
  const history = useHistory()
  const dispatch = useDispatch()
  const account = useSelector(state => state.accounts.currentAccount)
  const watchers = useSelector(state => state.global.watchers)
  const groups = useMemo(() => account?.fields?._segments || [], [account?.fields])
  
  const matchedWatchers = useMemo(() => {
    if(account?.predictions && watchers)
      return account.predictions.map(({ watcher_id }) => watchers.find(w => w.id === watcher_id)).filter(p => p !== undefined)
  }, [watchers, account?.predictions])

  const comboboxStyles = {
    padding: '0 0 4px',
    border: 'none',
    borderBottom: '1px transparent solid',
    marginTop: 2,

    '&:hover': {
      borderColor: '#19270d !important'
    }
  }

  return (
    <div className='notebook-note-block'>
      <div className='note-title-block'>
        {account?.fields?.name || <div className='untitled-gist'>Untitled Account</div>}
        <div className='account-metadata'>
          <OwnerSelector
            ownerId={account?.owner_id}
            className='select-csm'
            comboboxStyles={comboboxStyles}
            setCSM={member => dispatch(setCSM(member))}
            placeholder='Select a CSM'
            sizes={{ font: 16, avatar: 24 }}
          />
          <div className='account-groups'>
            {groups?.map((group, index) => (
              <Fragment key={group}>
                <Link to={groupPath(group)}>{group}</Link>
                {index < groups.length - 1 && ', '}
              </Fragment>
            ))}
            {matchedWatchers?.map((watcher, index) => {
              return (
                <Fragment key={watcher.id}>
                  {(!index && groups?.length) || index ? ', ' : null}
                  <Link to={`/watchers/view/${watcher.id}`}>{watcher.name}</Link>
                </Fragment>
              )
            })}
          </div>
        </div>
        {showAppIcons && <AppIcons {...appIconsProps} />}
      </div>
    </div>
  )
}

export default OverviewTitle