import formats from './formats'
const markFormats = formats.filter(format => format.type === 'mark')

const RenderLeaf = ({ attributes = {}, children, leaf }) => {
  markFormats.forEach(({ format, Render }) => {
    if(leaf[format])
      children = <Render leaf={leaf}>{children}</Render>
  })

  return <span {...attributes}>{children}</span>
}

export default RenderLeaf