import { useDispatch } from "react-redux"
import { useDrop } from 'react-dnd'
import { setToastMessage } from "../../App/actions"
import { NativeTypes } from "react-dnd-html5-backend"

function DropZone({ blockId, types, onDrop = () => {} }){
  const dispatch = useDispatch()
  
  const [{ showZone }, drop] = useDrop({
    accept: types,
    collect: monitor => ({ showZone: !!monitor.isOver() }),
    drop: dropped => {
      if(dropped.files)
        dropped.type = NativeTypes.FILE

      if(!types.includes(dropped.type))
        return dispatch(setToastMessage('You tried to drop the wrong sort of element here', 'info'))

      onDrop(blockId, dropped)
    }
  })

  return (
    <div ref={drop} className='notebook-drop-zone'>
      <div>
        <div className={showZone ? 'show' : undefined}></div>
      </div>
    </div>
  )
}

export default DropZone