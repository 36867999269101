import './style.scss';
import { useSelector } from 'react-redux'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CreateNewModal from './CreateNewModal';
import { useLayoutConfig } from '../../components/PlanttLayout/LayoutConfig';

function RenderAccount({ account }){
    const PlanttAccountID = localStorage.getItem('PlanttAccountID')
    const switchAccount = (accountId) => {
        localStorage.setItem('PlanttAccountID', accountId )
        window.location.reload()
    }

    return (
        <TableRow>
            <TableCell component="th" scope="row">
                {account.name} <br />
                {account.email}<br />
                {account.id}
            </TableCell>
            <TableCell align="right">
                {account.id === PlanttAccountID
                    ? <b>ACCOUNT IN USE</b>
                    : <a href="#" className="pointer" onClick={() => switchAccount(account.id)}>Switch</a>
                }
            </TableCell>
        </TableRow>
    )
}

function Manage() {
    const isAdmin = useSelector(state => state.global.currentMember.type === 'admin')
    useLayoutConfig({ title: 'Manage Workspaces' })

    const PlanttAccountID = localStorage.getItem('PlanttAccountID')
    const workspaces = useSelector(state => {
        const workspaces = state.global.availableWorkspaces
        return workspaces.filter(a => a.id === PlanttAccountID)
                    .concat(workspaces.filter(a => a.id !== PlanttAccountID))
    }) 
  return (
      /*eslint-disable */
    <div className="manage-accounts-page">
        <div className="table-container">
            <TableContainer component={Paper}>
                <Table  aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Account Name</TableCell>
                        {isAdmin &&
                        <TableCell style={{ position: 'relative' }}>
                            <CreateNewModal />
                        </TableCell>}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {workspaces.map(account => <RenderAccount key={account.id} account={account} />)}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    </div>
  );
}

export default Manage
