import { useLayoutEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import GistEditor from "."
import { useLayoutConfig } from "../../components/PlanttLayout/LayoutConfig"
import injectReducer from "../../utils/injectReducer"
import appsReducer from '../Apps/reducer'
import { getNote } from "./actions"

function ManageTemplate(){
  const dispatch = useDispatch()
  const { templateId } = useParams()
  const templateTitle = useSelector(state => state.notes.currentNote.blocks?.noteTitle.payload.text)

  const hasTemplateTitle = typeof templateTitle !== 'undefined'
  // Was originally "hasTemplate", but hasTemplateTitle can be used for same purpose

  useLayoutConfig(hasTemplateTitle ? { title: `Thread Templates / ${templateTitle || 'Untitled'}`, HeaderRight: TemplateIndicator } : undefined)

  useLayoutEffect(() => {
    if(templateId)
      return dispatch(getNote())
  }, [templateId])

  return hasTemplateTitle ? <GistEditor /> : null
}

function TemplateIndicator(){
  return <div style={{ backgroundColor: '#3aaf4b', color: '#fff', borderRadius: 3, padding: '4px 8px', marginRight: 20 }}>Template</div>
}

export default injectReducer({ key: 'connectors', reducer: appsReducer })(ManageTemplate)