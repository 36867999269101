import { faExpandAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Modal } from "@material-ui/core"
import { Fragment, useMemo } from "react"
import { useState } from "react"
import NewTable from "../../../components/PlanttTable/NewTable"
import useAddressSelector from "../../../helpers/useAddressSelector"

function FullscreenTable(props){
  const amountAddress = useMemo(() => {
    const address = [...props.itemsAddress]
    address[address.length - 1] = 'records_amount'
    return address
  }, [props.itemsAddress])
  const recordsAmount = useAddressSelector(amountAddress)

  const [open, setOpen] = useState(false)
  return (
    <Fragment>
      {recordsAmount > 0 && <FontAwesomeIcon icon={faExpandAlt} className='fullscreen-icon'  onClick={() => setOpen(true)}/>}
      <Modal open={open} onClose={() => setOpen(false)} className='notebook-fullscreen-table'>
        <div className='fullscreen-table-wrapper'>
          <NewTable {...props} />
        </div>
      </Modal>
    </Fragment>
  )
}

export default FullscreenTable