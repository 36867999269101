import { BlueButton } from "../../../components/Button";
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActionInModal } from '../actions'


/* eslint-disable react-hooks/rules-of-hooks */

function ButtonBlock({ readonly }){
  return ({ block, blockId }) => {
    const dispatch = useDispatch()
    const { action_id, name, spec_ref } = block.payload
    const app = useSelector(state => state.global.connectorSpecs.find(app => app.id === spec_ref))

    return (
      <div className='note-button-block'>
        <BlueButton
          onClick={e => {
            const isConfig = Boolean(!action_id || !name || e.target.classList.contains('config-button') || e.target.closest('.config-button'))
            dispatch(setActionInModal({ id: action_id, blockId, isConfig }))
          }}
          className={`${!action_id || !name ? 'is-disabled' : ''}`}
        >
          <img src={app?.icon_url} />
          {(action_id && name) || 'Click to Configure'}
          <div className='config-button'>
            <i className='fas fa-cog' />
          </div>
        </BlueButton>
      </div>
    )
  }
}

export default ButtonBlock