import React from 'react'
import {useSelector } from 'react-redux'
import CheckIcon from '@material-ui/icons/Check';
import { useParams } from 'react-router-dom'
import { useLayoutConfig } from '../../../components/PlanttLayout/LayoutConfig';
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator'

function PaymentDone() {
    useLayoutConfig({ title: 'Payment Done' })
    
    const params = useParams()
    const billingInfo = useSelector(state => state.global.workspace.billing_info)
    const paymentMethodData = useSelector(state => state.setting.paymentMethodData)
    const paymentCallStatus = useSelector(state => state.setting.replaceCreditCardStatus)

    const printPage = () => {
        window.print()
    }
    const normal = () => {
        return <div className="payment-done-container">
        <div className="payment-done-icon"><CheckIcon fontSize='inherit' /></div>
        <div className="payment-done-title">
          Payment Successful              
        </div>
        <div className="flex-row payment-done-info-line">
            <span>Company Name</span>
            <span>{billingInfo.company_name}</span>
        </div>                        
        <div className="flex-row payment-done-info-line">
            <span>Billing Email</span>
            <span>{billingInfo.billing_email}</span>
        </div>
        <div className="flex-row payment-done-info-line">
            <span>Card Name</span>
            <span>{paymentMethodData.name}</span>
        </div>
        <div className="flex-row payment-done-info-line">
            <span>Card Holder Name</span>
            <span>{billingInfo.first_name} {billingInfo.lastName}</span>
        </div>
        <div className="flex-row payment-done-amount-line">
            <span>Amount Paid</span>
            <span>{params.plan} $</span>
        </div>
        <div className="payment-done-print-button pointer" onClick={printPage}>Print </div>
    </div>
    }
    switch (paymentCallStatus) {
        case 2:
            return normal()
        case 1: 
            return (
                <div className="checkout-container">
                   <LoadingIndicator  />
                </div>
            )
        case 3: 
            return (
                <div className="checkout-container">ERROR</div>
            )
    }

  }
export default PaymentDone