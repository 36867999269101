/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const DEFAULT_LOCALE = 'en'
export const SET_FULL_ACCOUNT_DATA = 'App/SET_FULL_ACCOUNT_DATA'
export const SET_APP_DIMENSIONS = 'App/SET_APP_DIMENSIONS'
export const ENQUEUE_SNACKBAR = 'App/ENQUEUE_SNACKBAR'
export const CLOSE_SNACKBAR = 'App/CLOSE_SNACKBAR'
export const REMOVE_SNACKBAR = 'App/REMOVE_SNACKBAR'
export const SET_LOGIN_ERROR = 'App/SET_LOGIN_ERROR'
export const SET_GLOBAL_LOADING = 'App/SET_GLOBAL_LOADING'

export const FETCH_ONBOARDING_STATUSES_SUCCESS = 'App/FETCH_ONBOARDING_STATUSES_SUCCESS'
export const FETCH_SIGNALS_SUCCESS = 'App/FETCH_SIGNALS_SUCCESS'


export const FETCH_WATCHERS_SUCCESS = 'App/FETCH_WATCHERS_SUCCESS'
export const DELETE_WATCHER_SUCCESS = 'App/DELETE_WATCHER_SUCCESS'
export const UPDATE_WATCHER_DISPLAYED_COLUMNS = 'App/UPDATE_WATCHER_DISPLAYED_COLUMNS'

export const SET_SEGMENTS = 'App/SET_SEGMENTS'
export const UPDATE_SEGMENT_DISPLAYED_COLUMNS = 'App/UPDATE_SEGMENT_DISPLAYED_COLUMNS'

export const FETCH_CONNECTORS_SUCCESS = 'App/FETCH_CONNECTORS_SUCCESS'
export const FETCH_CONNECTOR_SPECS_SUCCESS = 'App/FETCH_CONNECTOR_SPECS_SUCCESS'

export const FETCH_ALL_FIELDS_SUCCESS = 'App/FETCH_ALL_FIELDS_SUCCESS'
export const FETCH_ALL_FIELDS_ERROR = 'App/FETCH_ALL_FIELDS_ERROR'

export const SYNC_GOOGLE_COMPLETE = 'App/SYNC_GOOGLE_COMPLETE'
export const SYNC_GOOGLE_ERROR = 'App/SYNC_GOOGLE_ERROR'

export const SET_WORKSPACE_MEMBERS = 'App/SET_WORKSPACE_MEMBERS'

export const SAVE_ACCOUNT_FIELD_CHANGES_SUCCESS = 'App/SAVE_ACCOUNT_FIELD_CHANGES_SUCCESS'
export const DELETE_FIELD_SUCCESS = 'App/DELETE_FIELD_SUCCESS'

export const FETCH_ACCOUNTS_SUCCESS = 'App/FETCH_ACCOUNTS_SUCCESS'
export const UPDATE_MEMBER = 'App/UPDATE_MEMBER'

export const TOGGLE_SHOW_GOOGLE_BANNER = 'App/TOGGLE_SHOW_GOOGLE_BANNER'

export const FETCH_ACTIONS_SUCCESS = 'App/FETCH_ACTIONS_SUCCESS'