import { useEffect, useMemo, useState } from "react"
import Select from "../../../components/FormControls/Select"
import LinkToolbar from "../../../components/TextEditor/LinkToolbar"
import ToolbarFormats from "../../../components/TextEditor/ToolbarFormats"
import { setBlock } from "../actions"
import { focusAndSelect } from "../helpers"
import { EmojiPicker } from "./EmojiDisplay"

function FloatingToolbar({ isFocused, block, contentRef, blockId, editor }){
  const [linkModal, setLinkModal] = useState(false)
  const [keepAlive, setKeepAlive] = useState(false)
  const isNestedBlock = useMemo(() => blockId.includes('/'), [blockId])

  const toggleList = type => {
    type = type.split('-')[0]
    setBlock(blockId, { list: block.list === type ? null : type })
  }

  if((!isFocused && !keepAlive && !linkModal) || !contentRef.current)
    return null

  return (

      <div id='notebook-floating-toolbar' className={isNestedBlock ? 'nested-block' : ''}>
        <LinkToolbar {...{ editor, setLinkModal }} />
        <ToolbarFormats
          {...{ editor, linkModal, setLinkModal, keepAlive, setKeepAlive, block, blockId, editor }}
          // hideLink //TEMP!!
          hideLists={isNestedBlock}
          Start={!isNestedBlock ? ToolbarStart : undefined}
          // End={EmojiPicker}
          overrides={{
            'ordered-list': { click: () => toggleList('ordered-list'), active: block.list === 'ordered' },
            'unordered-list': { click: () => toggleList('unordered-list'), active: block.list === 'unordered' }
          }}
        />
      </div>

  )
}

function ToolbarStart({ keepAlive, setKeepAlive, block, blockId, editor }){
  const { payload } = block || {}
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if(!open && keepAlive)
      setKeepAlive(false)
  }, [open])

  const onSelect = ({ selectedItem }) => {
    let heading = null
    if(selectedItem.startsWith('H'))
      heading = selectedItem.replace('H', '')
    setBlock(blockId, { payload: { ...payload, heading }})
    focusAndSelect(editor)
  }

  return (
    <div className='text-type-select' onMouseDown={() => setKeepAlive(true)}>
      <div>
        <Select
          items={['H1', 'H2', 'H3', 'Text']}
          initialValue={payload.heading ? `H${payload.heading}` : 'Text'}
          onSelect={onSelect}
          setOpen={setOpen}
          popperProps={{ className: 'text-type-popper' }}
        />
      </div>
      <div className='type-divider' />
    </div>
  )
}

export default FloatingToolbar