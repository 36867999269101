import { Modal } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { setInOverlay } from "../actions"

function EditorOverlay(){
  const dispatch = useDispatch()
  const inOverlay = useSelector(state => state.notes.inOverlay)

  return inOverlay ? (
    <Modal open={true}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', outline: 'none' }} onClick={() => dispatch(setInOverlay(null))}>
        {inOverlay.origin === 'youtube' && (
          <iframe
            style={{ width: '150vh', height: '85vh' }}
            src={`https://www.youtube.com/embed/${inOverlay.id}?autoplay=1`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        )}
        {inOverlay.origin === 'loom' && (
          <iframe
            src={`https://www.loom.com/embed/${inOverlay.id}?autoplay=1`}
            frameborder="0"
            title='Loom video player'
            style={{ width: '150vh', height: '85vh' }}
          />
        )}
      </div>
    </Modal>
  ) : null
}

export default EditorOverlay