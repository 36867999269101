import { useMemo } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

function useMomentsInfo(){
  const params = useParams()

  return useMemo(() => {
    const mode = params.mode || 'unread'
    const queryParams = {}
    if(mode === 'all')
      Object.assign(queryParams, { archive: true, completed: true })

    return { mode, queryParams }
  }, [params])
}

export default useMomentsInfo