import { useEffect, useState, useRef } from 'react'
import useAddressSelector from '../../../helpers/useAddressSelector'
import FieldError from '../../FormControls/FieldError'

function PlanttField(props){
  const { item } = props
  
  return (
    <span>
      {!item.type.readonly
        ? <InputComponent {...props} />
        : item.planttName
      }
    </span>
  )
}

const InputComponent = ({ item, stateAddress, setState }) => {
  const { index, errors } = item
  const state = useAddressSelector(stateAddress)

  const [value, setValue] = useState(item.planttName || '')
  const [editing, setEditing] = useState(false)

  const ref = useRef()
  useEffect(() => {
    if(ref.current && item.firstRender) {
      ref.current.focus()
      setEditing(true)
    }
  }, [ref.current])

  const saveChanges = () => {
    const fields = [ ...state.fields ]
    fields[index] = { ...fields[index], planttName: value, errors: { ...fields[index].errors }, firstRender: false }
    for(const e in fields[index].errors)
      fields[index].errors[e] = false
      
    for(const field of fields){
      const name = field.planttName
      if(name === undefined) continue
      if(field.index !== index && name.length && name.toLowerCase() === value.toLowerCase()){
        fields[index].errors = { ...fields[index].errors, planttNameNotUnique: true }
        break
      }
    }
    const planttNameEmpty = (value === undefined || value === '') ? true : false
    fields[index].errors = { ...fields[index].errors, planttNameEmpty }
    setState({ ...state, fields })
  }

  return (
    <span>
      <input
        type='text'
        value={!editing ? item.planttName : value}
        onKeyDown={e => e.key === 'Enter' ? ref.current?.blur() : null}
        onMouseUp={() => setEditing(true)}
        placeholder="New Plantt Field"
        onChange={e => setValue(e.target.value)}
        onBlur={saveChanges}
        className='column-input'
        ref={ref}
      />
      <FieldError anchorEl={ref.current} hasErrors={errors.planttNameEmpty || errors.planttNameNotUnique}>
        {errors.planttNameEmpty ? 'Field name cannot be empty' : errors.planttNameNotUnique ? 'Must be unique' : ''}
      </FieldError>
    </span>

  )

}

export default PlanttField
