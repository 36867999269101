import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { useDispatch } from "react-redux"
import { store } from "../../.."
import { clearSelection, updateCurrentNoteSelection } from "../actions"
import { focusHiddenInput } from "../helpers"

function SelectionTracker({ containerRef, buildGroups }){
  const dispatch = useDispatch()
  const [selection, setSelection] = useState({})
  const ref = useRef()
  const cleanup = useRef(null)

  useEffect(() => {
    const initializeSelection = e => {
      if(e.which !== 1 || (e.target.id !== 'note-editor-content' && !e.target.closest('#note-editor-content')))
        return

      const pos = { top: e.clientY, left: e.clientX, scrollTop: containerRef.current.scrollTop }
      setSelection({ ...pos, initial: pos, width: 0, height: 0 })
    }
    const resetSelection = e => {
      cleanup.current?.() // This makes sure a mousemove is not fired after this, immediately creating a new selection
      cleanup.current = null

      const selectionLen = store.getState().notes.selection.length
      if(selectionLen > 1 || (selectionLen && !document.activeElement)){
        focusHiddenInput()
        buildGroups()
      }else if(document.activeElement){
        clearSelection()
      }

      setSelection({})
    }
    window.addEventListener('mousedown', initializeSelection)
    window.addEventListener('mouseup', resetSelection)

    return () => {
      window.removeEventListener('mousedown', initializeSelection)
      window.removeEventListener('mouseup', resetSelection)
    }
  }, [])

  useEffect(() => {
    if(Object.keys(selection).length){
      let lastUpdate = 0
      const selectBlocks = e => {
        if(lastUpdate > Date.now() - 50)
          return
        else
          lastUpdate = Date.now()
          
        const isScroll = e.type === 'scroll'
        const { initial } = selection

        const clientX = !isScroll ? e.clientX : selection.clientX
        const clientY = !isScroll ? e.clientY : selection.clientY
        const scrollOffset = containerRef.current.scrollTop - initial.scrollTop

        let width = clientX - initial.left
        let height = clientY - initial.top + scrollOffset

        const newStart = { ...initial, initial: initial }
        if(width < 0){
          newStart.left += width
          width = Math.abs(width)
        }
        if(height < 0){
          newStart.top += height
          height = Math.abs(height)
        }
          
        newStart.top -= scrollOffset

        if(height > 12)
          e.preventDefault()

        setSelection({ ...newStart, width, height, clientX, clientY  })

        if(!ref.current)
          return

        const selectionRect = ref.current.getBoundingClientRect()
        const selectables = containerRef.current.querySelectorAll('.selectable')
        const selectedBlocks = []

        for(const selectable of selectables){
          const rect = selectable.getBoundingClientRect()
      
          if(rect.top + rect.height > selectionRect.top
            && rect.left + rect.width > selectionRect.left
            && rect.bottom - rect.height < selectionRect.bottom
            && rect.right - rect.width < selectionRect.right
          ){
              selectedBlocks.push({ blockId: selectable.getAttribute('data-key'), index: selectedBlocks.length })
          }
        }

        const currentSelection = store.getState().notes.selection
        for(const item of selectedBlocks)
          if(selectedBlocks.length < currentSelection.length || !currentSelection.find(i => i.blockId === item.blockId))
            return dispatch(updateCurrentNoteSelection(selectedBlocks))
      }
      window.addEventListener('mousemove', selectBlocks)
      containerRef.current.addEventListener('scroll', selectBlocks)
      cleanup.current = () => {
        window.removeEventListener('mousemove', selectBlocks)
        containerRef.current.removeEventListener('scroll', selectBlocks)
      }
      return cleanup.current
    }
  }, [selection, containerRef.current])

  return selection.width > 12 && selection.height > 12
    ? createPortal(
        <div id='notebook-selection-tracker' style={{ ...selection }} ref={ref}></div>,
        document.querySelector('body')
      )
    : null
}

export default SelectionTracker