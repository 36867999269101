import { makeStyles } from "@material-ui/core"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import Autocomplete from "./FormControls/Autocomplete"
import UserAvatar from "./UserAvatar"

const useStyles = makeStyles({
  csm: ({ comboboxStyles, sizes }) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: sizes.font,

    '& .account-avatar': {
      marginRight: 8,
      marginBottom: 2
    },

    '& .autocomplete-combobox': {
      borderRadius: 0,
      flexGrow: 1,
      ...comboboxStyles,

      '& input': {
        width: '100%',
        padding: 0,
        fontSize: 'inherit',
      }
    }
  })
})

const defaultSizes = { font: 14, avatar: 18 }

function OwnerSelector({ ownerId, setCSM, className, placeholder, comboboxStyles, sizes = defaultSizes, noAvatar }){
  const classes = useStyles({ comboboxStyles, sizes })
  const members = useSelector(state => state.global.workspaceMembers)
  const CSM = useMemo(() => {
    return ownerId && members?.find(member => member.id === ownerId)
  }, [ownerId])

  return (
    <div className={`${classes.csm} ${className || ''}`}>
      {!noAvatar &&
      <UserAvatar
          size={sizes.avatar}
          fontRatio={0.47}
          name={CSM?.name}
          url={CSM?.profile_image}
          colors={{ backgroundColor: CSM ? 'rgba(58, 175, 75, 0.1)' : '#ddd', color: '#3aaf4b' }}
      />}
      <Autocomplete
        initialValue={CSM}
        inputProps={{ placeholder: !CSM ? placeholder : undefined }}
        items={members}
        getItem={item => item.name}
        filterBy='name'
        onSelect={member => member ? setCSM(member) : null}
        resetOnClickAway='initial'
      />
    </div>
  )
}

export default OwnerSelector