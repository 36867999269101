import { useContext, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import Skeleton from "react-loading-skeleton"
import TableContext from "./TableContext"
import { setHoveredRow } from "./reducer"

function RenderCell({ item, column, style, filler = false, index, children }){
  const dispatch = useDispatch()
  const { classes, onCellClick, rowHeight, getId, isHighlighted } = useContext(TableContext)
  const isHovered = useSelector(state => state.tableState.hoveredRow === getId(item))
  const { Content } = column || {}

  const onClick = event => {
    if(!item || !onCellClick)
      return
  
    const isOrClosest = className => event.target.classList.contains(className) || event.target.closest(`.${className}`)
    if(!isOrClosest('column-input') && !isOrClosest('select-popper'))
      onCellClick(event, item)
  }

  const highlighted = useMemo(() => isHighlighted(item), [item, isHighlighted])
  const classList = useMemo(() => {
    return [
      [true, 'new-table-cell'],
      [classes.cell, classes.cell],
      [isHovered, 'is-hovered'],
      [highlighted, 'is-highlighted'],
      [column?.noPadding, 'no-padding']
    ].filter(([condition]) => condition).map(([_, className]) => className).join(' ')
  }, [classes, isHovered, highlighted, column])

  return (
    <div
      className={classList}
      onMouseEnter={onCellClick ? () => dispatch(setHoveredRow(getId(item))) : undefined}
      onClick={onClick}
      style={{ height: rowHeight, cursor: onCellClick ? 'pointer' : 'auto', width: column?.width, ...style }}
    >
      {!children && (!item?.skeleton
        ? (column ? <Content hovered={isHovered} {...{ item, highlighted, index }} /> : null)
        : <div style={{ flexGrow: 1, minWidth: !column?.width && !filler ? 100 : undefined }}>
            {column?.Skeleton ? <column.Skeleton /> : (!column?.noSkeleton && !filler && <Skeleton />)}
          </div>
      )}
      {children}
    </div>
  )
}

export default RenderCell