import { useMemo } from "react"
import deserialize from '../../../components/TextEditor/deserialize'
import RenderNode from '../../../components/TextEditor/RenderNode'

const headings = {
  1: 1.875,
  2: 1.5,
  3: 1.3
}

function TextBlock({ block = {}, placeholder, value }) {
  const { payload = {} } = block
  const text = useMemo(() => deserialize(value || payload.text || ''), [payload.text, value])

  const isTitle = useMemo(() => block.type === 'title', [block.type])

  return (
    <div
      className='block-content'
      style={payload.heading ? { fontSize: headings[payload.heading] + 'em', fontWeight: 500 } : undefined}
    >
      <div className={!isTitle ? 'note-editor-block' : `note-title-block ${!payload.text && !value ? 'is-empty' : ''}`}>
        {text.map((node, index) => <RenderNode key={index} {...node} />)}
        {!payload.text && !value &&
        <div className='placeholder'>
          {placeholder || (isTitle ? 'Thread Title' : payload.heading ? 'Heading ' + payload.heading : undefined)}
        </div>}
      </div>
    </div>
  )
}

export default TextBlock