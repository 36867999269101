import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export { default as ContactAlerts } from './ContactAlerts'

const useStyles = makeStyles(() => ({
  tooltiWrapper: {
    backgroundColor: '#1d222c',
    color: '#dddddd',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    fontSize: 11,
    padding: '10px 8px',
    border: 'none',
    position: 'relative',
    borderRadius: '4px',
    minWidth: '150px',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-8px',
      right: '4px',
      width: 0,
      height: 0,
      borderLeft: '8px solid transparent',
      borderRight: '8px solid transparent',
      borderBottom: '8px solid #1d222c',
      zIndex: 2,
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      top: '-8.5px',
      right: '4px',
      width: 0,
      height: 0,
      borderLeft: '8px solid transparent',
      borderRight: '8px solid transparent',
      borderBottom: '8px solid #9b9b9b',
      zIndex: 1,
    }
  },
  tooltipTitle: {
    borderBottom: 'solid 1px #e6e7e8',
    width: '100%',
    marginBottom: 4,
    paddingBottom: 4
  }

}));

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    background: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: 0,
    margin: 0,
    padding: 0,
    paddingTop: 9,
  },
}))(props => (
  <Tooltip
    {...props}
  />
));

const TooltipComponent = ({ tooltipTitle, tooltipDescription, tooltipElement }) => {
  const classes = useStyles();
  return (
    <CustomTooltip
      placement="bottom-end"
      title={(
        <React.Fragment>
          <div className={classes.tooltiWrapper}>
            <div className={classes.tooltipTitle}>
              {tooltipTitle}
            </div>
            <div>
              {tooltipDescription}
            </div>
          </div>
        </React.Fragment>
      )}
    >
      {tooltipElement}
    </CustomTooltip>
  );
};

TooltipComponent.propTypes = {
  tooltipTitle: PropTypes.string,
  tooltipDescription: PropTypes.string,
  tooltipElement: PropTypes.any
};

export default TooltipComponent;
