import { KeyboardArrowDown } from "@material-ui/icons"
import { Fragment } from "react"
import { RenderBlock } from "."
import useToggle from "../../../../components/Toggle/useToggle"

function ToggleBlock({ title, blocks, noSideLine = false, BlockComponent = RenderBlock, openByDefault = false }){
  const { toggleItem, contentProps, isOpen } = useToggle({ open: openByDefault, transitionLen: 500 })

  // TEMP Typo fix!
  if(title.endsWith('ys'))
    title = title.replace('ys', 'ies')

  return (
    <Fragment>
      <div className={`toggle-block-title ${isOpen ? 'block-open' : ''}`} onClick={toggleItem}>
        <KeyboardArrowDown /> {title}
      </div>
      <div {...contentProps}>
        <div className='toggle-block-content'>
          {!noSideLine
            ? <div className='mrkdwn-side-line'>
                <div></div>
                <div>
                  {blocks.map((block, index) => <BlockComponent key={index} block={block} />)}
                </div>
              </div>
            : blocks.map((block, index) => <BlockComponent key={index} block={block} />)
          }
        </div>
      </div>
    </Fragment>
  )
}

export default ToggleBlock