import { useMemo } from "react"
import formats from "./formats"
import RenderLeaf from "./RenderLeaf"

function RenderLeaves({ children }){
  return children?.map((leaf, index) => {
    if(leaf.children)
      return <RenderNode key={index} {...leaf} />

    return <RenderLeaf key={index} leaf={leaf} children={leaf.text} />
  }) || null
}

function RenderNode({ type, href, children }){
  const format = useMemo(() => formats.find(({ format }) => format === type) || {}, [type])
  const { Render, Child } = format
  if(href)
    return <Render element={{ href }}><RenderLeaves children={children} /></Render>
  else if(type !== 'paragraph' && Render)
    return (
      <Render attributes={{}}>
        {children.map((listItem, index) => (
          <Child key={index}>
            <RenderLeaves children={listItem.children} />
          </Child>
        ))}
      </Render>
    )

  return <div><RenderLeaves children={children} /></div>
}

export default RenderNode