import { makeStyles, Popper } from '@material-ui/core'
import { Close, KeyboardArrowDown } from '@material-ui/icons'
import { useCombobox } from 'downshift'
import { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import Scrollable from '../Scrollable'
import FieldError from './FieldError'
import './style.scss'

const { FunctionCloseMenu, FunctionOpenMenu, FunctionSelectItem, InputKeyDownEnter, ItemClick, InputChange } = useCombobox.stateChangeTypes

const useInputClasses = makeStyles({
  wrapper: {
    width: '100%',
    display: 'flex',
    border: '1px transparent solid',
    borderRadius: 3,
    height: ({ height }) => height || '100%',
    padding: '8px',
    whiteSpace: 'nowrap',
    borderColor: ({ hasErrors, isFocused }) => hasErrors ? '#fd6c4b' : (isFocused ? '#155788' : '#ddd'),
    cursor: 'pointer',
    '&:focus, &[aria-expanded="true"], &:hover': {
      outline: 'none',
      '&:not([disabled])': {
        borderColor: ({ hasErrors }) => !hasErrors ? '#155788' : '#fd6c4b',
        '& .reset-icon': {
          opacity: 1
        }
      }
    },
    '&[disabled]': {
      borderColor: '#eee',
      color: '#ccc',
      cursor: 'not-allowed'
    },
    '& svg:not(.reset-icon)': {
      fontSize: 18,
      opacity: 0.8
    },
    '& .reset-icon': {
      opacity: 0,
      fontSize: 12,
    }
  },
  input: {
    border: 'none',
    padding: '0 8px',
    flexGrow: 1,
    fontSize: 12,
    outline: 'none !important',
    width: 0,
    '&:disabled': {
      backgroundColor: 'transparent'
    },
    '&::placeholder': {
      color: '#9b9b9b'
    }
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
  },
  makeNew: {
    borderTop: '1px solid #efefef',
    color: '#155788',
    display: 'flex',
    alignItems: 'flex-start',
    padding: '8px 12px',
    cursor: 'pointer',
  
    '& > div': {
      marginLeft: 4
    },
    '&.is-active': {
      backgroundColor: '#efefef'
    }
  },
  infoText: {
    padding: 8,
    color: '#979797'
  }
})

const noop = () => {}

function Autocomplete({
  label,
  icon = null,
  initialValue = '',
  filterBy,
  selectedItem,
  getItem = (i => i),
  onSelect = noop,
  onInput = noop,
  onOpen = noop,
  onBlur = noop,
  onReevaluateItems,
  inputProps = {},
  popperProps = {},
  errorProps = {},
  items = [],
  inputClasses,
  resetOnClickAway,
  canMakeNew,
  noFiltering,
  disabled,
  hideArrowIcon,
  focusOnRender,
  loading,
  showNoOptions = true
}) {
  const getSelected = useCallback(selected => filterBy ? selected && selected[filterBy] : selected, [filterBy])
  const stateReducer = useCallback((state, { type, changes }) => {
    switch (type) {
      case FunctionCloseMenu: {
        if(!resetOnClickAway || getSelected(changes.selectItem) === changes.inputValue || changes.selectedItem?.makeNew)
          return changes
        
        const newChanges = { ...changes }
        if(resetOnClickAway !== 'initial' || changes.selectedItem !== null)
          newChanges.inputValue = getSelected(changes.selectedItem) || ''
        else
          Object.assign(newChanges, { inputValue: getSelected(initialValue), selectedItem: initialValue })
        return newChanges
      }
      case FunctionSelectItem: {
        if(filterBy)
          return { ...changes, inputValue: state.selectedItem ? state.selectedItem[filterBy] : '', selectedItem: state.selectedItem }
        
        return changes
      }
      case InputKeyDownEnter:
      case ItemClick: {
        if(!changes.selectedItem?.makeNew)
          return { ...changes, inputValue: !filterBy ? changes.inputValue : changes.selectedItem[filterBy] }
  
        return { ...changes, inputValue: state.inputValue, selectedItem: state.inputValue }
      }
      case InputChange: {
        onInput(changes.inputValue)
        return changes
      }
      default:
        return changes
    }
  }, [resetOnClickAway, initialValue, onInput])

  const [inputItems, setInputItems] = useState(items || [])
  const [selectedIsNew, setSelectedIsNew] = useState(false)

  const reevaluateInputItems = changes => {
    let { selectedItem, inputValue } = changes
    if(onReevaluateItems)
      return onReevaluateItems({ changes, setInputItems })

    let filtered = items
    if(inputValue && !noFiltering)
      filtered = items.filter(item => { 
        if(filterBy)
          item = item[filterBy]
        return item.toLowerCase().includes(inputValue.toLowerCase())
      })
    const inItems = inputItems.filter(i => {
      if(filterBy)
        i = i[filterBy]
      return canMakeNew && !i.makeNew && i.toLowerCase() === inputValue.toLowerCase()
    }).length > 0

    if(filterBy && selectedItem)
      selectedItem = selectedItem[filterBy]

    if(canMakeNew && inputValue && selectedItem !== inputValue && !inItems )
      filtered.push({ makeNew: true })
      
    setInputItems(filtered)
  }

  useEffect(() => {
    reevaluateInputItems({ selectedItem, inputValue })
  }, [items])

  const comboboxRef = useRef()
  const lastItem = { item: inputItems[inputItems.length - 1], index: inputItems.length - 1 }
  const onSelectedItemChange = ({ type, selectedItem }) => {
    if(type === FunctionOpenMenu) //FunctionOpenMenu happens on reset scenario (see below)
      return onInput('')
    else if((filterBy ? selectedItem[filterBy] : selectedItem) === initialValue)
      return
    
    onSelect(selectedItem)
    const isInList = items.includes(selectedItem)
    if(!isInList && !selectedIsNew)
      setSelectedIsNew(true)
    else if(isInList && selectedIsNew)
      setSelectedIsNew(false)

    if(comboboxRef.current)
      comboboxRef.current.querySelector('input').focus()
  }
  
  const {
    isOpen,
    inputValue,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    highlightedIndex,
    openMenu,
    closeMenu,
    reset,
    selectItem,
    setInputValue
  } = useCombobox({
    items: inputItems,
    initialSelectedItem: initialValue,
    initialInputValue: getSelected(initialValue),
    onSelectedItemChange,
    onInputValueChange: reevaluateInputItems,
    onIsOpenChange: changes => !changes.isOpen && reevaluateInputItems(changes),
    stateReducer
  })

  useEffect(() => {
    selectItem(initialValue)
    setInputValue(getSelected(initialValue))
    if(comboboxRef.current)
      comboboxRef.current.querySelector('input').blur()
  }, [initialValue])

  const onComboboxClick = () => {
    if(disabled)
      return
      
    openMenu()
    onOpen()
    if(comboboxRef.current)
      comboboxRef.current.querySelector('input').focus()
  }

  const [isFocused, setIsFocused] = useState(false)
  const inputEvents = useMemo(() => ({
    onBlur: () => {
      onBlur()
      closeMenu()
      setIsFocused(false)
    },
    onFocus: () => setIsFocused(true),
  }), [onBlur, closeMenu])

  const defaultInputClasses =  useInputClasses({ hasErrors: errorProps.hasErrors && !errorProps.info, height: inputProps.height, isFocused })
  inputClasses = inputClasses || defaultInputClasses

  const errorPropsToPass = { ...errorProps }
  delete errorPropsToPass.text

  //  MaterialUI does not allow an undefined anchorEl, and downshift requires the menu element to always
  //  be rendered. This silly querySelector is a workaround to make them play well together
  const [comboboxAnchor, setComboboxAnchor] = useState(document.querySelector('body'))
  useLayoutEffect(() => {
    if(comboboxRef.current){
      setComboboxAnchor(comboboxRef.current)
      if(focusOnRender)
        onComboboxClick()
    }
  }, [comboboxRef.current])
  
  const resetInput = () => {
    onSelectedItemChange({ selectedItem: '' })
    reset()
  }

  return (
    <>
      {label && <label {...getLabelProps()}>{label}</label>}
      <div {...getComboboxProps({ className: `${inputClasses.wrapper} autocomplete-combobox`, ref: comboboxRef, onClick: onComboboxClick, disabled })}>
        {icon}
        <input autoComplete='off' {...getInputProps({ ...inputProps, ...inputEvents, className: inputClasses.input, disabled })} />
        <div className={inputClasses.controls}>
          <Close onClick={resetInput} className='reset-icon' />
          {!hideArrowIcon &&  <KeyboardArrowDown />}
        </div>
      </div>
      <FieldError {...errorPropsToPass} anchorEl={comboboxAnchor}>{errorProps.text}</FieldError>
      {!isOpen && <div {...getMenuProps({}, { suppressRefError: true })}></div>}
      {isOpen &&
      <Popper
        anchorEl={comboboxAnchor}
        open={true}
        placement='bottom-start'
        {...popperProps}
        className={`select-popper ${popperProps.className || ''}`}
      >
        <div
          className={`options-container card ${isOpen ? 'is-open' : ''}`}
          style={{ minWidth: comboboxRef.current ? comboboxRef.current.offsetWidth : undefined, maxHeight: 'none' }}
          {...getMenuProps({}, { suppressRefError: true })}
        >
          {isOpen &&
          <Fragment>
            <Scrollable style={{ maxHeight: 200 }}>
              {inputItems.map((item, index) => {
                return !item.makeNew ? (
                  <div
                    key={`${item}${index}`}
                    {...getItemProps({ item, index, onMouseDown: e => e.preventDefault(), disabled: item.isSectionHeader })}
                    className={`select-option ${highlightedIndex === index ? 'is-active' : ''} ${item.isSectionHeader ? 'section-header' : ''} ${item.indent ? 'indent' : ''}`}
                  >{getItem(item)}</div>
                ) : null
              })}
              {(loading || (!inputItems.length && showNoOptions)) &&
              <div className={`select-option ${inputClasses.infoText}`}>
                {loading ? 'Loading options...' : 'No options found'}
              </div>}
            </Scrollable>
            {canMakeNew && lastItem.item?.makeNew &&
            <div
              {...getItemProps({ ...lastItem, onMouseDown: e => e.preventDefault() })}
              className={`${inputClasses.makeNew} ${highlightedIndex === lastItem.index ? 'is-active' : ''}`}
            >
              + <div>Create New {canMakeNew} '{inputValue}'</div>
            </div>}
          </Fragment>}
        </div>
      </Popper>}
    </>
  )
}

export default Autocomplete