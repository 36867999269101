import React, { Fragment, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { updateConnectorAuthenticationInfo } from '../actions'
import TopProgressBar from '../../../components/TopProgressBar'
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator'
import { trackMixpanelEvents } from '../../../helpers/mixpanel'
function Authenticate2({ connectorSpecs }) {
    const dispatch = useDispatch()
    const authenticationStatus = useSelector(state => state.connectors.authenticationStatus)
    const history = useHistory()
    const searchParams = new URLSearchParams(window.location.search)
    const code = searchParams.get('code')
    const ConnectorInstanceId = useMemo(() => {
        return localStorage.getItem('isAddingConnector')
            ? JSON.parse(localStorage.getItem('isAddingConnector')).instanceId
            : false
    }, [code])

    useEffect(() => {
        if(ConnectorInstanceId) {
            dispatch(updateConnectorAuthenticationInfo(ConnectorInstanceId, { code }, true))
            localStorage.removeItem('isAddingConnector')
        }
    }, [code])

    useEffect(() => {
        if(authenticationStatus === 3) {
            trackMixpanelEvents('connector_connected')
            //Good, Forward to mapping
            const usersSampleUrl = connectorSpecs[localStorage.getItem('inAddingConnectorIndex')].user_sample_url
            localStorage.removeItem('isAddingConnector')
            localStorage.removeItem('inAddingConnectorIndex')
                history.replace(`/apps/add/activate/${ConnectorInstanceId}`)
        }
        else if (authenticationStatus === 2){
            //Something went wrong, sent the user back to Authenticate
            history.replace('/apps/add/authenticationError')
        }
    }, [authenticationStatus])
        return (
        <Fragment>
            <TopProgressBar progress="33" />
            <div className='samples-loading-status'>
                    <LoadingIndicator />
                    <div>Finishing Authentication Process...</div>
                </div>
        </Fragment>
        )
}
export default Authenticate2