import { useMemo, useRef } from "react"
import Tooltip from "./Tooltip/new"

const stepCount = 7
const steps = new Array(stepCount).fill(true).map((_, index) => (1 / stepCount) * index)
const normalizeOpacity = opacity => {
  for(let s in steps){
    s = parseInt(s)
    if(opacity >= steps[s] && (!steps[s + 1] || opacity < steps[s + 1]))
      return ((s + 1) / 10) + 0.3
  }
}

const defaultColor = [21, 87, 136]
function HeatMap({ columns = [], datasets = [], minSize = 5, sizeFactor = 1, RenderHint = ({ hint }) => hint }){
  const ref = useRef()
  const style = useMemo(() => {
    const squareSize = minSize + sizeFactor * 10
    return {
      display: 'grid',
      gridTemplateColumns: `repeat(${columns.length}, ${squareSize}px)`,
      gridTemplateRows: `repeat(${datasets.length}, ${squareSize}px)`,
      alignItems: 'center',
      justifyItems: 'center',
      gridGap: 1,
      width: 'fit-content'
    }
  }, [minSize, sizeFactor, columns, datasets])

  return (
    <div style={style} ref={ref}>
      {datasets.map(({ data, color = defaultColor, max = 0 }, index) => {
        if(!max)
          for(const item in data)
            if(data[item] > max)
              max = data[item]

        return columns.map(col => {
          const backgroundColor = `rgba(${color}, ${normalizeOpacity(data[col].y)})`
          const size = (Math.round(data[col].x * 10) * sizeFactor) + minSize

          return (
            <Tooltip key={`${col}-${index}`} enterNextDelay={400} enterDelay={400} content={<RenderHint hint={data[col].hint} />} placement='bottom' styles={{ wrapper: { padding: 4 } }}>
              <div style={{
                height: size,
                width: size,
                borderRadius: 100,
                backgroundColor,
                cursor: 'auto'
              }}></div>
            </Tooltip>
          )
        })
      })}
    </div>
  )
}

export default HeatMap