import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { isGistPath } from '../../containers/GistEditor/helpers'

export const useHeights = ({ headerRef }) => {
  const bannerOpen = useSelector(state => state.global.showGoogleBanner)
  const layoutHeight = useMemo(() => bannerOpen ? `calc(100vh - ${bannerOpen ? 60 : 0}px)` : '100vh', [bannerOpen])
  const contentMaxHeight = useMemo(() =>  {
    return `calc(${layoutHeight} - ${headerRef.current?.offsetHeight || 0}px)`
  }, [headerRef.current, layoutHeight])

  return { contentMaxHeight, layoutHeight }
}

export const useMenu = ({ setTransitioning }) => {
  const compactDisplay = useSelector(state => state.global.dimensions.compactDisplay)
  const role = useSelector(state => state.global.workspace.role)
  const location = useLocation()
  const [expanded, setExpanded] = useState(!compactDisplay)
  const [closing, setClosing] = useState(false)

  const transitionLength = 600
  const toggleMenu = () => {
    setTransitioning(true)
    setTimeout(() => {
      if(expanded)
        setClosing(true)
      else
        setExpanded(true)
    })
    setTimeout(() => setTransitioning(false), transitionLength)
  }

  useEffect(() => {
    if(closing)
      setTimeout(() => {
        setExpanded(!expanded)
        setClosing(false)
      }, transitionLength)
  }, [closing])

  useEffect(() => {
    if(compactDisplay && expanded)
      setClosing(true)
    else if(!compactDisplay && !expanded && !isGistPath())
      setExpanded(true)
  }, [compactDisplay, location.pathname])

  useEffect(() => {
    if(compactDisplay && expanded){
      const onClickAway = e => {
        if(!e.target.classList.contains('open-plantt-menu') && !e.target.closest('.open-plantt-menu'))
          toggleMenu()
      }
      window.addEventListener('click', onClickAway)
      return () => window.removeEventListener('click', onClickAway)
    }
  }, [compactDisplay, expanded])

  return { toggleMenu, transitionLength, expanded, closing }
}