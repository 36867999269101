import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContent, Button, Dialog } from '@material-ui/core';
import ContinueButton from '../../../components/ContinueButton';

const style = makeStyles(() => ({
  dialogContent: {
    padding: 16,
    textAlign: 'center'
  },
  btnPrimary: {
    borderRadius: 3,
    border: 'solid 1px #fd6c4b',
    backgroundColor: '#fd6c4b',
    fontSize: 14,
    color: '#ffffff',
    minHeight: 36,
    marginRight: 8,
    minWidth: 66,
    maxHeight: 39,
    textTransform: 'unset',
    '&:disabled': {
      color: '#fd6c4b',
    },
    '&:hover': {
      backgroundColor: '#fd532c',
    }
  },
  btnSecondary: {
    borderRadius: 3,
    border: 'solid 1px #cdcdcd',
    backgroundColor: '#ffffff',
    fontSize: 14,
    color: '#4a4a4a',
    textTransform: 'unset',
    minHeight: 36,
    minWidth: 66,
    maxHeight: 39,
  },
  title: {
    fontFamily: 'Helvetica',
    fontSize: 18,
    marginBottom: 12
  }
}));

function RemoveDialog({ onClose, onRemove, open, title, continueText, loading }) {
  const classes = style();
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.title}>
          {title}
        </div>
        <div>
          <ContinueButton
            loading={loading}
            classes={classes.btnPrimary}
            onContinue={onRemove}
            label={continueText}
          />
          <Button
            className={classes.btnSecondary}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default RemoveDialog;

RemoveDialog.defaultProps = {
  continueText: 'Continue'
};

RemoveDialog.propTypes = {
  continueText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};