/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import createReducer from './reducers';
import thunk from 'redux-thunk';
import io from 'socket.io-client'
import {getAccount} from './containers/App/actions'
import { firebase } from '.';
import history from './utils/history';

const createPlanttChatServerMiddleware = (url) => {
  return store => {
    
    // let socket = io(url, { query: { uid: "" } });
    
    // socket.on("activity", (message) => {
    //   store.dispatch({
    //     type: "Plantt/MESSAGE_RECEIVED",
    //     payload: message
    //   });
    // });

    let isFirstAuthChange = true
    firebase.auth.onAuthStateChanged(async user => {
      // Setting isFirstAuthChange = false during this function makes sure that guest
      // users are signed out only if they were already signed in when the app was loaded
      
      if(!user)
        return isFirstAuthChange = false

      const { claims } = await firebase.auth.currentUser.getIdTokenResult()
      if(!claims.guest){
        store.dispatch(getAccount())
      }else if(isFirstAuthChange){
        firebase.auth.signOut()
        localStorage.removeItem('PlanttAccountID')
      }

      isFirstAuthChange = false
    })

    return next => action => {

      if (action.type === "Plantt/SEND_MESSEAGE") {
        const activity = {
          ...action.msg,
        }
        console.log("Plantt/SEND_MESSEAGE", activity)
        // socket.emit('activity', JSON.stringify({ activity: activity }));
      }

      if (action.type === "Plantt/AUTHENTICATE") {
        // socket.emit('authenticate', JSON.stringify({ uid: action.uid }));
      }

      return next(action);
    }
  }
}
const planttMiddleware = createPlanttChatServerMiddleware("https://chat.leshem.co")
const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [thunk, planttMiddleware, sagaMiddleware, routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
      // Prevent recomputing reducers for `replaceReducer`
      shouldHotReload: false,
      trace: true,
      traceLimit: 25
    })
    : compose;
  /* eslint-enable */

  const store = createStore(createReducer(), initialState, composeEnhancers(...enhancers));

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
      store.dispatch({ type: '@@REDUCER_INJECTED' });
    });
  }

  return store;
}
