import { useLayoutEffect, useMemo, useRef, useState } from 'react'

import { BlueButton } from '../../../../components/Button';
import { CircularProgress } from '@material-ui/core';
import { doAction } from '../../actions';
import DebouncedInput from '../../../../components/FormControls/DebouncedInput';
import isHotkey from 'is-hotkey';
import Tooltip from '../../../../components/Tooltip/new';
import DatePicker from 'react-datepicker';
import CustomSwitch from '../../../../components/FormControls/Switch';
import EmailBox from '../../../../components/FormControls/EmailBox';
import { Fragment } from 'react';
import FieldError from '../../../../components/FormControls/FieldError';
import useDynamicFields from './useDynamicFields';
import { DynamicDropdown, StaticDropdown } from './Dropdown';

const noop = () => () => {}

function ActionForm({ autoFocus = true, setPayload, onSuccess, action, payload, blockId, submitOverride, ...props }) {
  const { values, dynamicValues } = payload
  const blockRef = useRef()
  const { fieldList, dynamicFieldsLoading } = useDynamicFields({ action, setPayload, payload, blockId })

  const [emptyFields, setEmptyFields] = useState([])
  const setValue = (field, value) => {
    const emptyIndex = emptyFields.findIndex(key => key === field.key)
    if(emptyIndex >= 0){
      const newEmpty = [ ...emptyFields ]
      newEmpty.splice(emptyIndex, 1)
      setEmptyFields(newEmpty)
    }

    const newPayload = { ...payload }
    const valuesKey = !field.is_dynamic ? 'values' : 'dynamicValues'
    newPayload[valuesKey] = { ...payload[valuesKey], [field.key]: value }
    setPayload(newPayload)
  }

  useLayoutEffect(() => {
    if(autoFocus)
      setTimeout(() => blockRef.current?.querySelector('input')?.focus())
  }, [autoFocus])

  const [submitting, setSubmitting] = useState(false)
  const onSubmit = async () => {
    if(submitOverride)
      return submitOverride()

    let payload = { ...values, ...dynamicValues, action_id: action.id }
    const empty = []
    for(const field of fieldList){
      if(field.is_required && (!payload[field.key] || (typeof payload[field.key] === 'object' && !payload[field.key][0]?.email && !payload[field.key].value)))
        empty.push(field.key)
    }
    
    if(empty.length)
      return setEmptyFields(empty)

    if(action.spec_ref === 'monday') //Replacement of payload is done here and not earlier to avoid messing with validation
      payload = { form_fields: { ...values, ...dynamicValues }, action_id: action.id }

    Object.keys(payload).forEach(key => {
      if(Array.isArray(payload[key]) && typeof payload[key][0] === 'object' && payload[key][0].email)
        return payload[key] = payload[key].map(item => item.email)
      if(typeof payload[key] === 'object' && payload[key].value){
        if(payload[key].type)
          payload.object_type = payload[key].type
        payload[key] = payload[key].value
      }
    })

    setSubmitting(true)
    const response = await doAction(action.spec_ref, payload)
    if(!response?.block)
      setSubmitting(false)
    else
      await onSuccess(response)

    setSubmitting(true)
  }

  return (
    <div className='note-action-form' ref={blockRef} onKeyDown={e => isHotkey('mod+Enter', e) ? onSubmit() : null}>
        {fieldList.map((field, index) => (
          <RenderField
            key={field.key}
            setValue={setValue}
            {...{ action, index, field, values, dynamicValues, emptyFields, blockRef, ...props }}
          />
        ))}
        {dynamicFieldsLoading && <CircularProgress className='dynamic-fields-loading' size={24} />}
        <Tooltip lean content='Cmd + Enter to submit'>
        <BlueButton loading={submitting} padding='8px 12px' style={{ height: 34, minWidth: 60 }} onClick={onSubmit}>
            {action.submit_button || 'Done'}
        </BlueButton>
        </Tooltip>
    </div>
  )
}

function RenderField ({ index, values = {}, dynamicValues = {}, field, emptyFields, ...props }) {
  const ref = useRef()
  const hasErrors = useMemo(() => emptyFields.includes(field.key), [emptyFields, field])

  //Still need to take care of number, string
  const FormControl = useMemo(() => {
    const { type, dropdown_settings } = field
    switch(type){
      case 'dropdown':
        return dropdown_settings.type === 'dynamic' ? DynamicDropdown : StaticDropdown
      case 'date':
        return DateTime(false)
      case 'datetime':
        return DateTime(true)
      case 'boolean':
        return BooleanField
      case 'email_list':
        return EmailList
      default:
        return InputField
    }
  }, [field])

  const value = useMemo(() => {
    const valueSet = field.is_dynamic ? dynamicValues : values
    return valueSet[field.key] || ''
  }, [values[field.key], dynamicValues[field.key]])
  
  return FormControl
    ? <Fragment>
        <label ref={ref} style={field.type === 'boolean' ? { flexDirection: 'row', alignItems: 'center' } : field.type.includes('date') ? { marginBottom: 0 } : {}}>
          {field.label}{field.is_required && ' *'} <FormControl {...{ field, value, values, ...props }} />
        </label>
        <FieldError anchorEl={ref.current} hasErrors={hasErrors}>This field is required</FieldError>
      </Fragment>
    : null
}

function InputField({ field, setValue, value, setRef = noop, onFieldFocus = noop }){
  return (
    <DebouncedInput
      initialValue={value}
      setValue={value => setValue(field, value)}
      onFocus={onFieldFocus(field)}
      ref={setRef(field)}
      type={['number', 'text'].includes(field.type) ? field.type : undefined}
    />
  )
}

function EmailList({ value, setValue, field }){
  const [errors, setErrors] = useState({})
  const toggle = email => {
    const newValue = [ ...value ]
    const index = newValue.findIndex(item => item.email === email)

    if(index < 0)
      newValue.push({ email, error: false })
    else
      newValue.splice(index, 1)

    setValue(field, newValue)
  }

  return (
    <EmailBox
      emails={value || []}
      onRemove={toggle}
      onSave={toggle}
      onFocus={() => setErrors({ ...errors, noEmails: false })}
      error={errors.emailInvalid}
      setError={value => setErrors({ ...errors, emailInvalid: value })}
    />
  )
}

function BooleanField({ value, setValue, field }){
  return <CustomSwitch checked={value} onChange={() => setValue(field, !value)} style={{ marginLeft: 24 }} />
}


/*eslint-disable*/
const DateTime = withTime => ({ field, setValue, value }) => {
  const now = useMemo(() => new Date(), [])
  const selected = useMemo(() => value ? new Date(value) : now, [value])

  return (
    <DatePicker
      selected={selected}
      onChange={newDate => setValue(field, newDate.getTime())}
      dateFormat={"LLLL do Y" + (withTime ? "@ H:mmaaa" : "")}
      showTimeSelect={withTime}
      disabledKeyboardNavigation
    />
  )
}

export default ActionForm