import { useMemo } from "react"
import { useSelector } from "react-redux"

const useComputedParticipants = participants => {
  const workspace = useSelector(state => state.global.workspace)

  const computed = useMemo(() => {
    const computed = { members: [], contacts: [], allContacts: [], accounts: [], emails: [] }
    if(!participants)
      return computed

    participants.forEach(p => computed[`${p.type === 'account' ? 'account' : p.type}s`].push(p))
    const contactsWithoutAccount = computed.contacts.filter(c => !c.payload.identity_id).map(({ payload }) => ({ payload: { ...payload, id: payload.email } }))
    computed.emails = computed.emails.concat(contactsWithoutAccount)
    const { accounts } = computed
 
    const remainingContacts = []
    computed.contacts.forEach(contact => {
      const accountIndex = accounts.findIndex(account => account.payload.id === contact.payload.identity_id)
      if(accountIndex > -1)
        accounts[accountIndex] = { ...accounts[accountIndex], contacts: [ ...(accounts[accountIndex].contacts || []), contact.payload ] }
      else
        remainingContacts.push(contact)
    })
    computed.allContacts = computed.contacts
    computed.contacts = remainingContacts
    
    if(accounts.length || computed.members.length > 1)
      accounts.unshift({ type: 'workspace', payload: workspace, contacts: computed.members.map(({ payload}) => payload) })

    return computed
  }, [participants, workspace])

  return computed
}

export default useComputedParticipants