import firebase from 'firebase/app';
import {
  HOOK,
  HOOK_SUCCESS,
  HOOK_ERROR,
  FETCH_ACCOUNT_INVITE_SUCCESS,
  SET_ACCOUNT_INVITE_SUCCESS,
  SET_INVITE_DIALOG_OPEN,
  FETCH_API_KEY_SUCCESS,
  FETCH_API_KEY_ERROR,
  POST_API_KEY_SUCCESS,
  POST_API_KEY_ERROR,
  DELETE_API_KEY_SUCCESS,
  DELETE_API_KEY_ERROR,
  FETCH_TEAM_SUCCESS,
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_ERROR,
  UPDATING_SETTINGS,
  UPDATING_SETTINGS_DISMISSED,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_ERROR,
  SET_SETTINGS_PROPERTY_BY_PATH,
  FETCH_INVOICES_SUCCESS,
  RESET_CHECKOUT_PLAN,
  SET_CHECKOUT_PLAN,
  SET_CHECKOUT_TOKEN,
  REPLACE_CREDIT_CARD_STATUS,
  PAYMENT_MEHTOD_DATA,
  SET_SUBSCRIPTION_ID

} from './constants';

import { NEW_sendPostRequest, sendGetRequest, sendPostRequest, sendDeleteRequest, sendPutRequest, NEW_sendDeleteRequest } from '../../apis/api-utils';
import { BASE_URL, BASE_URL2 } from '../../apis/constant';
import { setFullAccountData, setToastMessage } from '../App/actions'
export const hook = payload => ({ type: HOOK, payload });
export const hookSuccess = data => ({ type: HOOK_SUCCESS, data });
export const hookError = error => ({ type: HOOK_ERROR, error });

export const resetCheckoutPlanInfo = () => ({type:RESET_CHECKOUT_PLAN})
export const getCheckoutPlanInfoSuccses = (planData) => ({type:SET_CHECKOUT_PLAN,planData})

export const getCheckoutPlanInfo = (plan) => async dispatch => {
  try {
    switch (plan) {
      case 'basic': {
        dispatch(getCheckoutPlanInfoSuccses({
          "id": "Customized",
          "name": "basic",
          "description": "Basic plan",
          "monthly_price": 250,
          "yearly_discount": 37.5
        }))
      }
      break
      case 'growth': {
        dispatch(getCheckoutPlanInfoSuccses({
          "id": "Customized",
          "name": "growth",
          "description": "Growth plan",
          "monthly_price": 600,
          "yearly_discount": 90
        }))
      }
      case 'agents': {
        dispatch(getCheckoutPlanInfoSuccses({
          "id": "agents",
          "name": "agents",
          "description": "Agents plan",
          "monthly_price": 0,
          "yearly_discount": 0
        }))
      }
    }
    //FETCH PLAN DETAILS FROM API

  } catch (error){
    console.log(`Error fethching subscription plan details, ${error}`)
  }
}
export const setCheckoutToken = (token) => ({type:SET_CHECKOUT_TOKEN,token})
export const getCheckOutToken = () => async dispatch => {
  try {
    const response = await sendGetRequest (`${BASE_URL}billing/checkouttoken`)
    dispatch(setCheckoutToken(response.token))
  } catch (error) {
    console.log('Error getting checkout token', error);
  }
}

export const changeReplaceCreditCardStatus = (newStatus) => ({type:REPLACE_CREDIT_CARD_STATUS,newStatus})



export const fetchPaymentMethodSeccuss = (data) => ({type:PAYMENT_MEHTOD_DATA,data})

export const fetchPaymentMethod = () => async dispatch => {
  try {
    const paymentMethodData = await sendGetRequest (`${BASE_URL}payment_method`)
    dispatch(fetchPaymentMethodSeccuss(paymentMethodData))
  } catch (error) {
    console.log(`Error in getting payment method data ${error}`)
  }
}
export const setPaymentMethod = (paymentMethodData) => async dispatch => {
    try {
      console.log('234')
      const call = await NEW_sendPostRequest(`${BASE_URL}payment_method`, undefined, JSON.stringify(paymentMethodData))
      if(call.ok) {
        dispatch(fetchPaymentMethodSeccuss(
          {
            last_digits: paymentMethodData.cc_info.last_digits,
            name: paymentMethodData.cc_info.name,
          }
        ))
        dispatch(changeReplaceCreditCardStatus(2))
      }
      else {
        dispatch(changeReplaceCreditCardStatus(3))
        console.log('EROR!')
      }

    } catch (error) {
      console.log(`Error in changing the payment method ${error}`)
    }
}

export const updateSubscriptionId = subscriptionId => ({ type: SET_SUBSCRIPTION_ID, subscriptionId });
export const addNewSubscription = (newSubscriptionData) => async dispatch => {
  try {
    const newSubscription = await sendPostRequest(`${BASE_URL}subscription`,undefined,JSON.stringify(newSubscriptionData))
    dispatch(updateSubscriptionId(newSubscription.records[0].id))
  } catch (error) {
    console.log(`Error in changing the payment method ${error}`)
  }
}

export const updateSubscription = (newSubscriptionData) => async dispatch => {
  try {
    await sendPutRequest(`${BASE_URL}subscription/${newSubscriptionData.subscription_id}`,undefined,JSON.stringify(newSubscriptionData))
  } catch (error) {
    console.log(`Error in changing the payment method ${error}`)
  }
}
export const addAccountData = billing_info => async (dispatch,getState) => {
  try {
    const workspace = getState().global.workspace
    const updatedWorkspace = {
      ...workspace,
      billing_info,
      status: 'activated',
    }
    dispatch(setFullAccountData(updatedWorkspace))
    const response = await sendPutRequest(`${BASE_URL}account`,undefined,JSON.stringify(updatedWorkspace))
    console.log(response)
    //Send PUT request to update billing info
  } catch (error) {
    console.log(`Error in adding or changing billing information to account ${error}`)
  }
}


export const fetchAccountInvoicesSuccess = invoices => ({type: FETCH_INVOICES_SUCCESS, invoices})
export const fetchAccountInvoices = () => async dispatch => {
  try {
    const invoices = await sendGetRequest (`${BASE_URL}invoices`)
    dispatch(fetchAccountInvoicesSuccess(invoices))
  } catch (error) {
    console.log('Error fetching Invoices', error);
  }
}


export const fetchAccountInviteSuccess = invitation => ({ type: FETCH_ACCOUNT_INVITE_SUCCESS, invitation });
export const fetchAccountTeamSuccess = team => ({ type: FETCH_ACCOUNT_INVITE_SUCCESS, team });
export const setAccountInviteSuccess = success => ({ type: SET_ACCOUNT_INVITE_SUCCESS, success });

export const fetchTeamMembers = () => async dispatch => {
  try {
    const members = await sendGetRequest (`${BASE_URL}account/team`)
    dispatch(fetchTeamMembersSuccess(members))
  } catch (error) {
    console.log('error fetching API key from server', error);
  }
}
export const fetchTeamMembersSuccess = members => ({ type: FETCH_TEAM_SUCCESS, members });

export const  fetchAPIKey = () => async dispatch => {
  try {
    const accountAPIKey = await sendGetRequest (`${BASE_URL}auth/api_key`)
    dispatch(fetchAPIKeySuccess(accountAPIKey))
  } catch (error) {
    console.log('error fetching API key from server', error);
  }
};
export const fetchAPIKeySuccess = apiKeys => ({type:  FETCH_API_KEY_SUCCESS, apiKeys})
export const fetchAPIKeyError = error => ({type:  FETCH_API_KEY_ERROR, error})

export const  postAPIKey = () => async dispatch => {
  const authToken = await firebase.auth().currentUser.getIdToken().then(idToken => idToken);
  const apiKeys = {
    headers: {
      Authorization: `token ${authToken}`
    }
  };
  try {
    await sendPostRequest (`${BASE_URL}auth/api_key`, apiKeys)
    dispatch(fetchAPIKey())
  } catch (error) {
    console.log('error post API key to server', error);
  }
}
export const postAPIKeySuccess = apiKeys => ({type:  POST_API_KEY_SUCCESS, apiKeys})
export const postAPIKeyError = apiKeys => ({type:  POST_API_KEY_ERROR, apiKeys})

export const rmApiKey = apiKeyId => async dispatch => {
  const authToken = await firebase.auth().currentUser.getIdToken().then(idToken => idToken);
  const options = {
    headers: {
      Authorization: `token ${authToken}`
    }
  };

  try {
    await sendDeleteRequest(`${BASE_URL}auth/api_key/${apiKeyId}`, options);
    dispatch(fetchAPIKey())
  } catch (error) {
    console.log('error deleting APi key from server', error);
  }
}

export const deleteAPIKeySuccess = api => ({type:  DELETE_API_KEY_SUCCESS, api})
export const deleteAPIKeyError = api => ({type:  DELETE_API_KEY_ERROR, api})

export const fetchAccountInvite = () => async dispatch => {
  try {
    const accountInvitation = await sendGetRequest(`${BASE_URL}account/invite`);
    dispatch(fetchAccountInviteSuccess(accountInvitation));
  } catch (error) {
    console.log('error fetching Account Invitey from server', error);
  }
};

export const fetchAccountTeam = () => async dispatch => {
  try {
    const accountTeam = await sendGetRequest(`${BASE_URL}account/team`);

    dispatch(fetchAccountInviteSuccess(accountTeam));
  } catch (error) {
    console.log('error fetching Account Invite from server', error);
  }
};

export const sendAccountInvite = (email, name) => async (dispatch, getState) => {
  const { invitations } = getState().setting;

  if(invitations && invitations.filter(data => data.email === email).length)
    return { accountExists: true }

  const firebaseEmailData = await firebase.auth().fetchSignInMethodsForEmail(email) || []
  if (firebaseEmailData.length)
    return { accountExists: true }

  try {
    await sendPostRequest(`${BASE_URL}account/invite`, {}, JSON.stringify({ email, name }))
    dispatch(fetchAccountInvite())
    dispatch(setAccountInviteSuccess(true))
    return { success: true }
  } catch (error) {
    console.log('Error sending invite:', error);
  }
}

export const deleteAccountInvite = email => async dispatch => {
  const authToken = await firebase.auth().currentUser.getIdToken().then(idToken => idToken);
  const options = {
    headers: {
      Authorization: `token ${authToken}`
    }
  };

  try {
    await sendDeleteRequest(`${BASE_URL}account/invite/${email}`, options);
    dispatch(fetchAccountInvite());
  } catch (error) {
    console.log('error deleting Account Invite from server', error);
  }
};

export const setInviteDialogOpen = (isOpen) => ({ type: SET_INVITE_DIALOG_OPEN, isOpen })


const fetchGeneralSettingsSuccess = settings => ({ type: FETCH_SETTINGS_SUCCESS, settings });
const fetchGeneralSettingsError = error => ({ type: FETCH_SETTINGS_ERROR, error });

export const fetchGeneralSettings = () => async dispatch => {
  try {
    const settings = await sendGetRequest(`${BASE_URL}account/settings`);
    dispatch(fetchGeneralSettingsSuccess(settings.settings));
  } catch (error) {
    console.log('error fetching settings from server', error);
    dispatch(fetchGeneralSettingsError(error));
  }
}

const updatingGeneralSettings = () => ({ type: UPDATING_SETTINGS });
const updateGeneralSettingsSuccess = settings => ({ type: UPDATE_SETTINGS_SUCCESS, settings });
const updateGeneralSettingsError = error => ({ type: UPDATE_SETTINGS_ERROR, error });

export const updatingGeneralSettingsDismissed = () => ({ type: UPDATING_SETTINGS_DISMISSED });
export const updateGeneralSettings = data => async dispatch => {
  try {
    dispatch(updatingGeneralSettings());
    await sendPutRequest(`${BASE_URL}account/settings`, {}, JSON.stringify({ settings: data }));
    dispatch(updateGeneralSettingsSuccess());
  } catch (error) {
    console.log('error updating settings', error);
    dispatch(updateGeneralSettingsError());
  }
}

export const setSettingsPropertyByPath = (value, path) => ({ type: SET_SETTINGS_PROPERTY_BY_PATH, value, path });