import { useEffect, useRef } from "react"
import { useLocation } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import { setDisplayedInDock } from "../actions"

export const currentAppSelector = createSelector(
  state => {
    const { connectorSpecs, connectors } = state.global
    return {
      displayedApp: state.accounts?.displayedInDock,
      dockData: state.notes?.dockData,
      appSpecs: connectorSpecs,
      apps: connectors
    }
  },
  ({ displayedApp, dockData, appSpecs, apps }) => {
    const isPlantt = displayedApp === 'plantt'
    const appSpec = appSpecs.find(spec => spec.id === displayedApp)
    
    let currentDockData = displayedApp && !isPlantt ? dockData[displayedApp] : null
    if(currentDockData?.blocks?.length === 1 && currentDockData.blocks[0].type === 'toggle')
      currentDockData = { ...currentDockData, blocks: currentDockData.blocks[0].blocks }

    return {
      currentApp: displayedApp && !isPlantt ? appSpec : (isPlantt ? { name: 'Plantt' } : null),
      currentDockData,
      currentAppId: apps.find(app => app.spec_ref === displayedApp)?.id || null,
      displayedApp
    }
  }
)

const isAccountPath = path => path.startsWith('/account/')
export const useCloseDock = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const displayedInDock = useSelector(state => state.accounts?.displayedInDock)

  const previousPath = useRef('')
  useEffect(() => {
    if(isAccountPath(previousPath.current) && (!isAccountPath(location.pathname) || location.pathname.endsWith('overview')) && displayedInDock)
      dispatch(setDisplayedInDock(null))
    previousPath.current = location.pathname
  }, [location.pathname])
}