import React from 'react';
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator';
import ContinueButton from '../../../components/ContinueButton';

function InstallComponent({ specs, title, buttonText, params, setParams, moveOn, loading }){
    const updateParams = (e,id) => setParams({...params, [id]: e.target.value });

    return specs ? (
        <div className="install-container"> 
            <div className="install-form">
                <div className="install-form-header">
                    <img src={specs.image_url} className="integration-logo" />
                </div>
                <div className="install-form-title">
                    {title}
                </div>
                <div className="user-params-fields-container" >
                {specs.user_params && specs.user_params.map((item,i) => (
                    <div key={i}>
                        <label className="field-label" key={`userparamfile${i}`}>{item.label}:</label>
                        <input 
                            type="text"
                            value={params[item.id]}
                            onChange={(e) => updateParams(e, item.id)}
                            className='params-input'
                        />
                    </div>
                ))} 
                </div>
                <ContinueButton
                    loading={loading}
                    classes="integrate-button"
                    onContinue={moveOn}
                    label={buttonText}
                />
            </div>
        </div>
    ) : <LoadingIndicator style={{top: '40vh'}} />;
}

export default InstallComponent;