/* eslint-disable react-hooks/rules-of-hooks */

import { format } from "date-fns"
import { useRef } from "react"
import ChartTemplate from "../../../../components/ChartJS/ChartTemplate"
import ChartBlock from "."

const processData = payload => ({
  datasets: [{
    barPercentage: 0.5,
    barThickness: 40,
    data: payload.data.map(item => item[1]), // item values
    backgroundColor: [ '#003f5c', '#374c80', '#7a5195', '#bc5090', '#ef5675', '#ff764a', '#ffa600' ],
  }],
  labels: payload.data.map(item => !payload.labels_are_dates ? item[0] : format(new Date(item[0]), 'LLL y')) // item keys
})

function BarChartBlock({ readonly } = {}){
  return (props) => <ChartBlock {...props} readonly={readonly} processData={processData} RenderChart={RenderChart} />
}

function RenderChart({ data }){
  const ref = useRef()

  const options = {
    responsive: true,
    legend: {display: false },
    scales: {
      yAxes: [{ ticks: { beginAtZero: true, maxTicksLimit: 5 } }]
    },
    animation: false
  }

  return (
    <ChartTemplate
      containerRef={ref}
      type='bar'
      portionOfHeight={1.07}
      {...{ data, options }}
      widthPercentage={90}
    >
      {({ children }) => (
        <div className='chart-block-chart-container' ref={ref}>
          {children}
        </div>
      )}
    </ChartTemplate>
  )
}

export default BarChartBlock