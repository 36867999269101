import { NEW_sendGetRequest } from "../../apis/api-utils";
import { BASE_URL2 } from "../../apis/constant";
import { FETCH_TASK_RELATED_ACCOUNTS_SUCCESS, SET_MEMBER_TASKS_FROM_SNAPSHOT } from "./constants";
import { v4 as uuidv4 } from 'uuid'
import { firebase, store } from "../..";
import serialize from "../../components/TextEditor/serialize";
import { applyUpdates } from "../GistEditor/actions";

export const makeTask = ({ accountId = null, ref = null, preset }) => {
  const memberId = store.getState().global.currentMember.id
  const taskId = uuidv4()
  const task = { description: '', assignees: [memberId], account_id: accountId, ref, ...preset }
  return { taskId, task }
}

export const setTask = (taskId, task, changes) => {
  const workspaceId = store.getState().global.workspace.id
  task = { ...task, ...changes }
  delete task.id

  if(typeof task.description !== 'string')
    task.description = serialize(task.description)
  task.updated_at = new Date().getTime()

  applyUpdates(`${workspaceId}/tasks/${taskId}`, 'set', task)
}

export const deleteTask = ({ ref, id, account_id }) => {
  const workspaceId = store.getState().global.workspace.id

  const commit = updates => applyUpdates(workspaceId, 'update', { [`/tasks/${id}`]: null, ...updates })

  if(!ref?.block)
    return commit()

  const blocksPath = `/${account_id}/${ref.note}/blocks`
  firebase.database.ref(`${workspaceId}${blocksPath}`).once('value', snapshot => {
    const blocks = snapshot.val()
    const block = blocks?.[ref.block]

    switch(block?.type){
      case 'task':
        const previousBlock = Object.keys(blocks).find(blockId => blocks[blockId].next_block === ref.block)
        return commit({
          [`${blocksPath}/${previousBlock}/next_block`]: block.next_block || null,
          [`${blocksPath}/${ref.block}`]: null
        })
      case 'to-do-list':
        const index = block.payload.fields.findIndex(i => i.id === ref.item)
        if(index >= 0)
          return commit({ [`${blocksPath}/${ref.block}/payload/fields/${index}/task_id`]: null })
      default:
        return commit()
    }
  })
}

export const createTask = makeArgs => {
  const workspaceId = store.getState().global.workspace.id
  const { task, taskId } = makeTask(makeArgs)
  applyUpdates(`${workspaceId}/tasks/${taskId}`, 'set', task)
  return taskId
}

export const setMemberTasksFromSnapshot = snapshot => (dispatch, getState) => {
  const { global: { currentMember }, tasks: { memberTasks } } = getState() 
  const memberId = currentMember.id
  const tasks = Object.entries(snapshot.val() || {}).filter(([id, task]) => memberTasks[id] || task.assignees?.includes(memberId))

  dispatch(fetchTaskRelatedAccounts(tasks.map(([id, task]) => task.account_id)))
  dispatch({ type: SET_MEMBER_TASKS_FROM_SNAPSHOT, tasks: Object.fromEntries(tasks) })
}

export const fetchTaskRelatedAccountsSuccess = accounts => ({ type: FETCH_TASK_RELATED_ACCOUNTS_SUCCESS, accounts })
export const fetchTaskRelatedAccounts = accountIds => async (dispatch, getState) => {
  const { relatedAccounts } = getState().tasks
  const accountsToFetch = accountIds.filter((id, index, self) => typeof id !== 'undefined' && !relatedAccounts[id] && self.indexOf(id) === index)
  if(!accountsToFetch.length)
    return

  try {
    const accounts = await NEW_sendGetRequest(`${BASE_URL2}identities`, {}, { identity_ids: JSON.stringify(accountsToFetch) })
    if(!accounts.ok)
      throw new Error(accounts.text)

    dispatch(fetchTaskRelatedAccountsSuccess(accounts.text.records))
  } catch(error) {
    console.error('Failed to fetch realted accounts', error)
  }
}