import { useDrop } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { computeFieldValue } from '../../../../helpers'
import Favicon from '../../../../images/plantt.svg'
import { setToastMessage } from '../../../App/actions'
import { setBlock } from '../../actions'
import { PLANTT_FIELD } from '../../constants'
import WidgetWrapper from './WidgetWrapper'

function PlanttWidgetBlock({ blockId, block, dragPreview }){
  const dispatch = useDispatch()

  const { payload } = block || {}
  const { fields } = payload || []
  
  const [{ showZone }, drop] = useDrop({
    accept: PLANTT_FIELD,
    collect: monitor => ({ showZone: !!monitor.isOver() }),
    drop: ({ type: droppedType, id }) => {
      if(droppedType !== PLANTT_FIELD)
        return dispatch(setToastMessage('You can only drop Plantt fields in here', 'info'))
      else if(fields.includes(id))
        return dispatch(setToastMessage('Cannot add same field twice', 'error'))

      setBlock(blockId, { payload: { fields: [...fields, id] } }, false)
    }
  })

  const setRefs = elem => {
    dragPreview(elem)
    drop(elem)
  }

  return (
    <WidgetWrapper
      name='Plantt.io'
      icon_url={Favicon}
      wrapperClassName={`note-plantt-widget-block ${showZone ? 'drop-zone' : ''}`}
      style={{ minWidth: '92%' }}
      ref={setRefs}
    >
        <div className='mrkdwn-side-line'>
          <div></div>
          <div className='field-grid'>
            {fields?.map(field => <RenderField field={field} key={field} />)}
          </div>
        </div>
        <div className='drag-more'>
          Add more fields by dragging them into this widget
        </div>
    </WidgetWrapper>
  )
}

function RenderField({ field }){
  const fieldList = useSelector(state => state.global.fieldLists.accounts)
  const value = useSelector(state => state.accounts.currentAccount?.fields?.[field])
  const { label, type } = fieldList?.find(f => f.key === field) || {}

  return typeof value !== 'undefined' ? <><div>{label}</div><div>{computeFieldValue(type, value)}</div></> : null
}

export default PlanttWidgetBlock