import { useEffect, useState } from "react"
import { SkeletonTheme } from "react-loading-skeleton"
import { NEW_sendGetRequest } from "../../../apis/api-utils"
import { BASE_URL2 } from "../../../apis/constant"
import { default as MomentComponent } from "../../Moments/partials/Moment"

const Moment = MomentComponent(false, false)

function MomentWidget({ block }){
  const [moment, setMoment] = useState({ skeleton: true })
  useEffect(async () => {
    if(moment.skeleton){
      try {
        const response = await NEW_sendGetRequest(`${BASE_URL2}moment/${block.payload.moment_id}`)
        if(!response.ok)
          throw new Error(response.text)

        setMoment(response.text)
      } catch(error) {
        console.error('Failed to fetch moment:', error)
      }
    }
  }, [block])

  return (
    <SkeletonTheme color='#ddd' highlightColor='#eee'>
      <Moment doResize={() => {}} index={0} {...moment} />
    </SkeletonTheme>
  )
}

export default MomentWidget