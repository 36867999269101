import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import './style.scss';

export const Spinner = () => (
  <CircularProgress
    color="inherit"
    size={18}
    thickness={5}
  />
);

const ContinueButton = ({
  disabled, loading, onContinue, label = 'Continue', classes = 'continue-button'
}) => (
  <button
    type="submit"
    className={classes}
    disabled={disabled}
    onClick={onContinue}
  >
    {loading ? <Spinner /> : label}
  </button>
);

ContinueButton.propTypes = {
  loading: PropTypes.bool,
  onContinue: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  classes: PropTypes.any,
};

export default ContinueButton;
