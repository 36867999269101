import { Modal } from "@material-ui/core"
import { SearchRounded } from "@material-ui/icons"

import isHotkey from "is-hotkey"
import { Fragment, useEffect, useMemo, useRef, useState } from "react"
import Skeleton from "react-loading-skeleton"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { NEW_sendGetRequest } from "../../apis/api-utils"
import { BASE_URL2 } from "../../apis/constant"
import { setToastMessage } from "../../containers/App/actions"
import { AccountsIcon, ContactsIcon, WatchersIcon } from "../../icons"
import DebouncedInput from "../FormControls/DebouncedInput"
import Scrollable from "../Scrollable"
import Tooltip from "../Tooltip/new"
import { trackMixpanelEvents } from '../../helpers/mixpanel'
const initialState = () => ({ open: false, search: '', results: [], noMatch: false, loading: false, hovered: null })
const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0

function Search(){
  const ref = useRef()
  const scrollableRef = useRef()
  const workspace = useSelector(state => state.global.workspace)
  const [state, setState] = useState(initialState())
  const { open, search, results, noMatch, loading, hovered } = state
  const modalWidth = 400
  const resultsHeight = 220

  const reset = () => {
    setState(initialState())
    if(open)
      setState({ ...state, open: false })
  }

  useSearchEffect({ search, state, setState })

  useEffect(() => {
    const focus = event => {
      if(isHotkey('mod+k', event)){
        event.preventDefault()
        setState({ ...state, open: true })
      }
    }
    window.addEventListener('keydown', focus);
    return () => window.removeEventListener('keydown', focus);
  }, [])

  useEffect(() => {
    if(open){
      if(scrollableRef.current)
        scrollableRef.current.scrollTop = 0

      setTimeout(() => ref.current?.querySelector('input')?.focus())
      //For some reason this works only with a timeout... Must be related to event loop and when ref is set on it
    }else{
      reset()
    }
  }, [open])

  const setHovered = value => setState({ ...state, hovered: value })

  const preparedResults = useMemo(() => {
    if(loading){
      const filtered = results.filter(r => (r.name || r.first_name)?.toLowerCase().startsWith(search.toLowerCase()))
      return filtered.concat(new Array(6).fill({ skeleton: true }))
    }else{
      return results
    }
  }, [results, loading, search])

  const hasResults = useMemo(() => preparedResults.length > 0, [preparedResults])

  const itemsRef = useRef({})
  const onKeyDown = event => {
    if(event.key === 'ArrowDown' && hovered !== results.length - 1){
      event.preventDefault()
      const newHovered = hovered !== null ? hovered + 1 : 0
      setHovered(newHovered)
      const elem = itemsRef.current[newHovered]?.elem
      if(scrollableRef.current){
        if(!newHovered){
          scrollableRef.current.scrollTop = 0
        }else if(elem){
          const bottomPos = elem.offsetTop + elem.offsetHeight - scrollableRef.current.scrollTop
          if(bottomPos > resultsHeight)
            scrollableRef.current.scrollTop += bottomPos - resultsHeight
        }
      }
    }else if(event.key === 'ArrowUp' && hovered !== 0){
      event.preventDefault()
      const newHovered = hovered ? hovered - 1 : results.length - 1
      setHovered(newHovered)
      const elem = itemsRef.current[newHovered]?.elem
      if(scrollableRef.current)
        if(newHovered === results.length - 1 || (elem && elem.offsetTop < scrollableRef.current.scrollTop))
          scrollableRef.current.scrollTop = elem.offsetTop
    }else if(event.key === 'Enter' && itemsRef.current[hovered]?.click){
      itemsRef.current[hovered].click()
      ref.current?.querySelector('input').blur()
    }
  }

  return (
    <Fragment>
      <Tooltip
        content={<Fragment>Quickly find Accounts, Contacts and Threads<br/>({isMac ? '⌘ Cmd' : 'Ctrl'} + K)</Fragment>}
        theme={{ color: '#fff', backgroundColor: '#979797', borderColor: 'transparent' }}
        styles={{ wrapper: { maxWidth: 140, textAlign: 'center' }}}
        placement='bottom'
        lean
      >
        <div id='menu-quick-search' onClick={() => setState({ ...state, open: true })}>
          <SearchRounded /> Search
        </div>
      </Tooltip>
      <Modal open={open} onClose={() => setState({ ...state, open: false })} id='quick-search-modal'>
        <div ref={ref}>
          {hasResults || noMatch
            ? <div className='card' style={{ width: modalWidth }} id='quick-search-wrapper'>
                <SearchBox {...{ onKeyDown, workspace, state, setState }} />
                <Scrollable ref={scrollableRef} style={{ height: resultsHeight }}>
                  {noMatch && <div className='search-information'>We could not find any results to match your search</div>}
                  {preparedResults.map((result, index) => (
                    <RenderResult key={result.id ? `${result.id}-${index}` : index} {...{ reset, hovered, setHovered, index, itemsRef, ...result }} />
                  ))}
                </Scrollable>
              </div>
            : <div style={{ padding: 20, width: modalWidth + (20 * 2) }} id='quick-search-wrapper'>
                <SearchBox standalone {...{ onKeyDown, workspace, state, setState }} />
                <div style={{ height: resultsHeight }} />
              </div>
          }
        </div>
      </Modal>
    </Fragment>
  )
}

const useSearchEffect = ({ search, state, setState }) => {
  const dispatch = useDispatch()
  const fetchTimestamp = useRef()

  useEffect(async () => {
    if(search.length){
      const currentTimestamp = new Date().getTime()
      fetchTimestamp.current = currentTimestamp
      const add = { hovered: null, expanded: state.width > 220 }
      setState({ ...state, noMatch: false, loading: true, ...add })
      try {
        trackMixpanelEvents("global_search_used")
        const fetched = await NEW_sendGetRequest(`${BASE_URL2}search`, {}, { text: search })
        if(!fetched.ok)
          throw fetched.text
        if(currentTimestamp === fetchTimestamp.current)
           setState({ ...state, results: fetched.text, noMatch: fetched.text.length < 1, loading: false, ...add })
      } catch(error) {
        dispatch(setToastMessage('Something went wrong with your search. Please try again', 'error'))
        setState({ ...state, loading: false })
      }
    }else{
       setState({ ...state, results: [] })
    }
  }, [search])

  return null
}

function SearchBox({ standalone = false, onKeyDown, workspace, state, setState }){
  return (
    <label id='quick-search-input' className={standalone ? 'card' : undefined}>
      <SearchRounded />
      <DebouncedInput
        onKeyDown={onKeyDown}
        placeholder={`Search ${workspace.name.slice(0, 15)}...`}
        debounce={500}
        initialValue={state.search}
        setValue={search => setState({ ...state, search })}
      />
    </label>
  )
}

function RenderResult({ reset, type, name, first_name, last_name, id, account_name, account_id, skeleton, index, hovered, setHovered, itemsRef }) {
  const history = useHistory()
  const { Icon, backgroundColor, onClick } = useMemo(() => {
    switch(type){
      case 'account':
        return {
          Icon: AccountsIcon,
          backgroundColor: '#3aaf4b',
          onClick: () => history.push(`/account/${id}`)
        }
      case 'contact':
        return {
          Icon: ContactsIcon,
          backgroundColor: '#155788',
          onClick: () => account_id ? history.push(`/account/${account_id}`) : null
        }
      case 'watcher':
        return {
          Icon: WatchersIcon,
          backgroundColor: '#fd6c4b',
          onClick: () => history.push(`/watchers/view/${id}`)
        }
      default:
        return {}
    }
  }, [type])

  const onMouseDown = e => {
    e?.preventDefault()
    if(onClick){
      onClick()
      reset()
    }
  }

  return (
    <div
      className='quick-search-item'
      style={hovered === index ? { backgroundColor: '#efefef' } : undefined}
      onMouseDown={onMouseDown}
      onMouseEnter={() => setHovered(index)}
      onMouseLeave={() => setHovered(null)}
      data-item-index={index}
      ref={elem => itemsRef.current[index] = { elem, click: onMouseDown }}
    >
      <div className={`icon-wrapper ${type}`} style={{ backgroundColor }}>
        {!skeleton ? <Icon /> : <Skeleton height={20} width={24} />}
      </div>
      <div className='text-wrapper'>
        <div className='result-name'>{!skeleton ? (name || `${first_name} ${last_name}`) : <Skeleton height={13} width={60} />}</div>
        <div className='result-type'>
          {!skeleton
            ? type[0].toUpperCase() + type.slice(1) + (type === 'contact' ? ` @ ${account_name}` : '')
            : <Skeleton height={12} width={100} />
          }
        </div>
      </div>
    </div>
  )
}

export default Search