import ReactDOM from 'react-dom';
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { BlueButton, RedButton } from "../../../components/Button";

import { getNoteInfo, setNoteChannel, getChannelToken, setNoteChannelParticipant } from "../actions";
import HoverableIcon from "../../../components/HoverableIcon";
import Draggable from 'react-draggable';

import AgoraRTC from "agora-rtc-sdk-ng"
import UserAvatar from "../../../components/UserAvatar";
import { Popper } from '@material-ui/core';
import Hoverable from '../../../components/Hoverable';
import createDeepEqualSelector from '../../../utils/createDeepEqualSelector';

const PULSE_INTERVAL = 4

let rtc = {
    localAudioTrack: null,
    localVideoTrack: null,
    client: AgoraRTC.createClient({ mode: "rtc", codec: "vp8" })
};

let uid = null

let localTracks = {
    videoTrack: null,
    audioTrack: null
};

let remoteUsers = {
}

let appId = "d7b5ab20e3cc489397232d50ab4852e9"

const isChannelActive = (participants) => {
    // return Object.entries(participants).length >0 
    // console.log({participants})
    // check if there are active participants in the last X seconds
    return Object.entries(participants || {}).some(item => {
        const [uid, participant] = item
        return participant?.lastSeen > Date.now() - PULSE_INTERVAL*1000
    })

}

const participantsSelector = createDeepEqualSelector(
    state => state.notes.currentNote?.channel?.participants || {},
    participants => {
        // filter only active participants
        return Object.entries(participants).reduce((activePartcipants, item) => {
            const [participantUid, participant] = item
            if (participantUid != uid && participant?.lastSeen > Date.now() - PULSE_INTERVAL*1000) {
                activePartcipants[participantUid] = participant?.collabId
            }
            return activePartcipants
        }, {})
    })

const activePartcipantsSelector = createDeepEqualSelector(participantsSelector,
    (participants) => participants)
  
function GistTalk() {

    const currentCollabId = useSelector(state => state.firebase?.auth?.uid)
    const channelId = getNoteInfo().noteId
    

    const [users, setUsers] = useState({})
    const [loading, setLoading] = useState(false)
    
    // The UID of joined user if joined the channel. If not in the channel set to null.
    const [joined, setJoined] = useState(null)
    const [isActive, setIsActive] = useState(false)
    const [controlBar, setControlBar] = useState(false)

    useEffect(() => {
        if(isActive) {
            setControlBar(true)
        }

    }, [isActive])

    useEffect(() => {
        // Interval to check if channel is active
        const interval = setInterval(()=> {
                // send pulse check
                const { note: gist } = getNoteInfo()
                const participants = gist.channel?.participants
                setIsActive(isChannelActive(participants))
            }, PULSE_INTERVAL*1000/2)
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        Object.entries(users).forEach(item => {
            const [uid, user] = item
            
            if (user.videoTrack){
                user.videoTrack.play(`player-${uid}`);
            }
        })
    }, [users])

    useEffect(() => {
        // set pulse check
        const interval = setInterval(()=> {
                if (joined) {
                // send pulse check
                    const { note: gist } = getNoteInfo()
                    setNoteChannelParticipant(joined, {...gist.channel?.participants[joined], lastSeen: Date.now()})
                }
            }, PULSE_INTERVAL*1000/2)
        return () => clearInterval(interval);
    }, [joined])

    const leaveThreadChannel = async () => {
        setLoading(true)
        await leave()
        setUsers({})
        remoteUsers = {}
        const { note: gist } = getNoteInfo()
        const participants = { ...gist.channel?.participants }
        delete participants[uid]
        
        setNoteChannel({ participants: participants })
        setLoading(false)
        setJoined(null)
    }
    
    const onJoin = (user) => {
        // triggers when someone enters the channel
        remoteUsers[user.uid] = user 
        setUsers({ ...users, [user.uid]: user })
    }
    
    const onLeave = (user) => {
        // remove participant from channel?
        const clonedUsers = {...users}
        delete clonedUsers[user.uid]
        delete remoteUsers[user.uid]
        setUsers(clonedUsers)
    }

    const joinThreadChannel = async (micEnabled, camEnabled) => {
        
        setLoading(true)
        const onPublish = (user, mediaType) => {
            // triggers when remote users started publish audio/video
            // play remote users track
            remoteUsers[user.uid] = user 
            setUsers({ ...users, [user.uid]: user })
        }
        
        const joinedUid = await join(channelId, micEnabled, camEnabled, onPublish, onJoin, onLeave)
        
        const newParticipant = {
            collabId: currentCollabId,
            lastSeen: Date.now()
        }
        
        const { note: gist } = getNoteInfo()
        const participants = { ...gist.channel?.participants, ...{ [uid]: newParticipant } }
        
        setNoteChannel({ participants: participants })
        setJoined(joinedUid)
        setLoading(false)
    }

    return (
        <>
            <div className='actions-bar-button' onClick={() => setControlBar(!controlBar)}>
                <HoverableIcon icon='headset' size={36} active={controlBar} tooltipProps={{content: "Video chat", placement: "bottom"}} />
            </div>

            {controlBar && <ControlBar {...{isActive, joined, joinThreadChannel, leaveThreadChannel, loading, users}} />}

            {/* {(isChannelActive(gist?.channel?.participants) && !joined) && <JoinNotification />} */}

            <VideoChatPanel {...{joined, users} } />
            
        </>
    )
}

const VideoChatPanel = ({joined, users}) => {

    return ReactDOM.createPortal(
        <Draggable handle=".video-chat-panel">
                <div className="video-panel" style={{ position: 'absolute', top: 100, left: 80, zIndex: 3000 }}>
                    <div className="video-chat-panel">
                        <ParticipantPlayer style={{display: joined ? 'block': 'none'}} uid={joined} hasAudio={true} hasVideo={true} local={true} />
                        {
                            Object.entries(remoteUsers || {}).map(([uid, user]) => <ParticipantPlayer key={uid} uid={uid} hasAudio={user.hasAudio} hasVideo={user.hasVideo} local={false} />) 
                        }

                    </div>
                </div>
            </Draggable>, 
            document.body
    )
}
const ParticipantPlayer = ({uid, hasAudio, hasVideo, local, style}) => {
    
    const collabId = useSelector(state => state.notes.currentNote.channel?.participants?.[uid]?.collabId)
    const name = useSelector(state => state.notes.currentNote.collaborators?.[collabId]?.name)
    const avatar_url = useSelector(state => state.notes.currentNote.collaborators?.[collabId]?.avatar_url)
    const email = useSelector(state => state.notes.currentNote.collaborators?.[collabId]?.email)

    return (
    <div className="video-chat-participant-wrapper" style={style}>
        {hasVideo && <div style={{width: '100%', height: '100%', 'borderRadius': '10px'}} id={local ? 'local-player' : `player-${uid}`}></div>}

        {!hasVideo && <div><UserAvatar url={avatar_url} name={name} size={60} /></div>} 
        <div className="video-chat-participant-title">{name || email}</div>
        {!hasAudio && <div className="video-chat-participant-mute">
            <i className="far fa-microphone-slash" />
        </div>}
    </div>
    )
}
const JoinNotification = ({ gist }) => {

    const joinMeeting = async () => {
        await join(gist.channel.id, gist.channel.token)
    }

    return (
        <>
                <div className="join-channel-notification" style={{ border: '1px solid black', 'zIndex': '3000', position: 'absolute', top: '100px' }}>
                    <div className="handle" style={{ width: '15px', height: '15px' }}>x</div>
                    Someone started a session.
                    <button onClick={joinMeeting}>Join</button>

                </div>
        </>
    )

}


const ControlBar = ({isActive, joined, joinThreadChannel, leaveThreadChannel, loading, users }) => {
    // set default camera and microphone state 
    const [micEnabled, setMicEnabled] = useState(localTracks.audioTrack?.enabled || false)
    const [camEnabled, setCamEnabled] = useState(localTracks.videoTrack?.enabled || false)

    const [camId, setCamId] = useState(null)
    const [micId, setMicId] = useState(null)

    const [devices, setDevices] = useState({aduioInputs: [], videoInputs: []})

    const devicesMenuRef = useRef()
    const [openDevicesMenu, setOpenDevicesMenu] = useState(false) 

    let joinBtn
    // const participants = useSelector(state => state.notes.currentNote.channel?.participants || {})
    // const participants = useSelector(state => state.notes.currentNote.channel?.participants || {})


    const getDevices = async () => {
        const videoInputs = await AgoraRTC.getCameras()
        const aduioInputs = await AgoraRTC.getMicrophones()
        setDevices({videoInputs, aduioInputs})
        setOpenDevicesMenu(!openDevicesMenu)
    }

    const changeMic = async (micId) => {
        if (joined && localTracks.audioTrack) {
            // set a new video track and republish
            await localTracks.audioTrack.setDevice(camId)
        }
        setMicId(micId)
        setOpenDevicesMenu(false)
    }

    const changeCam = async (camId) => {
        if (joined && localTracks.videoTrack) {
            // set a new video track and republish
            await localTracks.videoTrack.setDevice(camId)
        }
        setCamId(camId)
        setOpenDevicesMenu(false)
    }

    const toggleCam = async () => {        
        // If the user already in channel we need to handle the current video track and publish it
        if (joined) {
            if (localTracks.videoTrack) {
                await localTracks.videoTrack.setEnabled(!camEnabled)
            } else {
                // create a local track and publish it
                localTracks.videoTrack = await AgoraRTC.createCameraVideoTrack({cameraId: camId || undefined})
                localTracks.videoTrack.play("local-player");
                // since already joined the room, just start streaming
                await rtc.client.publish(localTracks.videoTrack);
            }
        }

        setCamEnabled(!camEnabled)
    }

    const toggleMic = async () => {
        
        // If the user already in channel we need to handle the current video track and publish it
        if (joined) {
            if (localTracks.audioTrack) {
                await localTracks.audioTrack.setEnabled(!micEnabled)
            } else if (joined) {
                // create a local track and publish it if already joined the channel
                localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack()
                await rtc.client.publish(localTracks.audioTrack);
            }
        }   

        setMicEnabled(!micEnabled)
    }

    if (isActive && joined) {
        // Channel is active and the member is joined ==> "Leave"
        joinBtn = <RedButton className='action-button' onClick={leaveThreadChannel} loading={loading}>Leave</RedButton>
    }
    else if (isActive && !joined) {
        // Channel is active but the member is joined ==> "Join"
        joinBtn = <RedButton className='action-button' onClick={async () => {await joinThreadChannel(micEnabled, camEnabled)}} loading={loading}>Join</RedButton>
    } else {
        // Channel isn't active ==> "Start"
        joinBtn = <BlueButton className='action-button' onClick={async () => {await joinThreadChannel(micEnabled, camEnabled)}} loading={loading}>Start</BlueButton>
    }
    return (
        <div className='channel-control-bar'>
            <div className="action-button-wrapper">
                <HoverableIcon icon='sliders-v' size={36} ref={devicesMenuRef} onClick={getDevices} />
                <Popper
                open={openDevicesMenu}
                anchorEl={devicesMenuRef.current}
                placement='top'
                className="device-selector"
                >
                    <div className='card'>
                        <div className="title">Select a Camera</div>
                        {devices.videoInputs.map(device => 
                            (<Hoverable className="device-selector-option" key={device.deviceId} onClick={async () => {await changeCam(device.deviceId)}}>
                                {camId == device.deviceId && <i className="fal fa-check"></i>}
                                {device.label}
                            </Hoverable>)
                        )}
                        <div className="title">Select a Microphone</div>
                        {devices.aduioInputs.map(device => 
                            (<Hoverable className="device-selector-option" key={device.deviceId} onClick={async () => {await changeMic(device.deviceId)}}>
                                {micId == device.deviceId && <i className="fal fa-check"></i>}
                                {device.label}
                            </Hoverable>)
                        )}
                    </div>

                </Popper>
            </div>
            <div className="action-button-wrapper">
                {camEnabled && <HoverableIcon icon='video' size={36}  onClick={toggleCam} tooltipProps={{content: "Stop video", placement: "top"}} />}
                {!camEnabled && <HoverableIcon icon='video-slash' size={36}  onClick={toggleCam} tooltipProps={{content: "Start video", placement: "top"}} />}
            </div>
            <div className="action-button-wrapper">
                {micEnabled && <HoverableIcon icon='microphone' size={36}  onClick={toggleMic} tooltipProps={{content: "Mute", placement: "top"}} />}
                {!micEnabled && <HoverableIcon icon='microphone-slash' size={36}  onClick={toggleMic} tooltipProps={{content: "Unmute", placement: "top"}} />}
            </div>
            <div className="action-button-wrapper">
                {joinBtn}
            </div>
        </div>
    )
}


async function join(channelId, micEnabled, camEnabled, onPublish, onJoin, onLeave) {


    rtc.client.on("user-joined", async (user) => {
        console.log('USER JOINED')
        onJoin(user)
    })

    rtc.client.on("user-left", async (user) => {
        // A remote user calls leave and leaves the channel.
        // A remote user has dropped offline. If no data packet of the user or host is received for 20 seconds, the SDK assumes that the user has dropped offline. 
        // A poor network connection may cause a false positive.
        onLeave(user)
        console.log('USER LEFT')
    })



    // Listen for the "user-published" event, from which you can get an AgoraRTCRemoteUser object.
    rtc.client.on("user-published", async (user, mediaType) => {

        // Subscribe to the remote user when the SDK triggers the "user-published" event
        await rtc.client.subscribe(user, mediaType);
        console.log("subscribe success");

        onPublish(user, mediaType)
        if (mediaType === 'video') {
            // add user to user list => trigger UI render
        }

        // If the remote user publishes an audio track.
        if (mediaType === "audio") {

            // Show faces ==> UI render => Change UI state => Redux => Dispatch Redux Action.

            // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
            const remoteAudioTrack = user.audioTrack;
            // Play the remote audio track. No need to pass any DOM element.
            remoteAudioTrack.play();
        }

        // Listen for the "user-unpublished" event
        rtc.client.on("user-unpublished", user => {
            // update the users list with new data 
            onPublish(user)
        });

    });
    // rtc.client.on("disconnect")
    
    //get user token
    const channelToken = await getChannelToken();
    
    // join a channel and create local tracks, we can use Promise.all to run them concurrently
    uid = await rtc.client.join(appId, channelId, channelToken || null)
    console.log('user joined a channel', uid)
    if (micEnabled) {
        // check if local audio track already created
        // happens when user enables mic before joining the channel
        if (!localTracks.audioTrack) {
            localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack()
        }
        await rtc.client.publish(localTracks.audioTrack);
        console.log("publish success: audio")
    }
    if (camEnabled) {
        // check if local video track already created
        // happens when user enables video before joining the channel
        if (!localTracks.videoTrack) {
            localTracks.videoTrack = await AgoraRTC.createCameraVideoTrack()
            localTracks.videoTrack.play("local-player");
        }

        // publish the video and start streaming
        await rtc.client.publish(localTracks.videoTrack);
        console.log("publish success: video")
    }
    
   
    return uid

}

async function leave() {
    for (var trackName in localTracks) {
        var track = localTracks[trackName];
        if (track) {
            track.stop();
            track.close();
            localTracks[trackName] = undefined;
        }
    }

    // remove remote users and player views

    // leave the channel
    await rtc.client.leave();

    console.log("client leaves channel success");
}


export default GistTalk