import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useSelector } from 'react-redux'

function OverlayDropZone({ blockId, types, onDrop = () => {}, top = false }){
  const isDragging = useSelector(state => state.notes.isDragging)
  const [{ showZone }, drop] = useDrop({
    accept: types,
    collect: monitor => ({ showZone: !!monitor.isOver() }),
    drop: dropped => {
      if(dropped.files)
        dropped.type = NativeTypes.FILE
      onDrop(blockId, dropped, top)
    }
  })

  return isDragging ? (
    <div ref={drop} className={`block-overlay-drop-zone ${top ? 'top' : 'bottom'} ${showZone ? 'is-visible' : ''}`}>
      <div />
    </div>
  ) : null
}

export default OverlayDropZone 