export const START_ADDING_CONNECTOR = 'plantt/Connectors/startAddingConnector'
export const SET_IMPORT_DATA = 'plantt/Connectors/setImportData'
export const SET_IMPORT_DATA_FIELDS = 'plantt/Connectors/setImportDataFields'
export const SET_AUTHENTICATION_STATUS = 'plantt/Connectors/setAuthenticationStatus'
export const SET_OAUTH2_PROCESS_STATUS = 'plantt/Connectors/setOauth2ProcessStatus'
export const SET_AUTHENTICATION_PAGE_ERROR = 'plantt/Connectors/SET_AUTHENTICATION_PAGE_ERROR'
export const FETCH_CONNECTOR_SAMPLES_SUCCESS =  'plantt/Connectors/fetchConnectorSamplesSuccsess'

export const UPDATE_CONNECTOR =  'plantt/Connectors/updateConnector'
export const DELETE_CONNECTOR =  'plantt/Connectors/deleteConnector'

export const UPDATE_LOADING =  'plantt/Connectors/updateLoading'
export const UPDATE_MAPPING_TABLE_FIELDS =  'plantt/Connectors/updateMappingTableFields'
export const RESET_MAPPING_TABLE_DATA =  'plantt/Connectors/resetMappingTableData'
export const SET_ALL_MAPPING_FIELDS_TO_READ_ONLY =  'plantt/Connectors/setAllMappingFieldsToReadOnly'

export const FETCH_ACCOUNT_FIELDS_SUCCESS = 'plantt/Connectors/FETCH_ACCOUNT_FIELDS_SUCCESS'
export const FETCH_ACCOUNT_FIELDS_ERROR = 'plantt/Connectors/FETCH_ACCOUNT_FIELDS_ERROR'










