import { Fragment, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'

import { LayoutConfigContext } from './LayoutConfig'
import LeftMenu from './LeftMenu'
import { AccountName } from './AccountHeaderElems'
import useLogout from './useLogout'
import GoogleSyncBanner from './GoogleSyncBanner'
import DockContent from '../../containers/Account/AppsDock/DockContent'
import { useHeights, useMenu } from './helpers'
import { isGistPath } from '../../containers/GistEditor/helpers'
import { store } from '../..'

import { ExitToApp, KeyboardArrowLeftRounded, Menu } from '@material-ui/icons'
import './style.scss'


const logoutOnlyStyle = { zIndex: 4000, backgroundColor: 'rgba(255, 255, 255, 0.7)' }
function PlanttLayout({ children }){
  const compactDisplay = useSelector(state => state.global.dimensions.compactDisplay)
  const history = useHistory()
  const [layoutConfig, setLayoutConfig] = useState({})

  const logoutOnly = useMemo(() => history.location.pathname.includes(`/processing`), [history.location.pathname])
  const logout = useLogout()
  
  const headerRef = useRef()
  const contentRef = useRef()

  const [transitioning, setTransitioning] = useState(false)
  const { contentMaxHeight, layoutHeight } = useHeights({ headerRef })
  const { toggleMenu, transitionLength, expanded, closing } = useMenu({ setTransitioning })

  const menuClosedClass = className => closing || !expanded || compactDisplay ? className : ''
  
  const goBack = useMemo(() => {
    const { pathname } = history.location
    if(isGistPath() && pathname.split('/')[1] === store.getState().global.workspace.id)
      return () => {
        const { account_id } = store.getState().notes.currentNote
        if(account_id)
          return history.push(`/account/${account_id}`)
        
        return history.push('/threads')
      }

    if(pathname.startsWith('/account/'))
      return () => {
        const splitPath = pathname.split('/')
        if(splitPath.length === 4)
          return history.push(splitPath.slice(0, 3).join('/'))
          
        const queryString = store.getState().relationships?.backToQuery || ''
        history.push('/accounts' + queryString)
      }

    if(pathname.startsWith('/apps/') && pathname !== '/apps/all')
      return () => history.push('/apps')
      
    if(pathname.startsWith('/settings/templates/'))
      return () => history.push('/settings/templates')

    return null
  }, [history.location.pathname])

  const displayedInDock = useSelector(state => state.accounts?.displayedInDock)
  const menuWidth = expanded && !closing ? 220 : 0
  const [appsDockWidth, setAppsDockWidth] = useState(380)
  const [currentInDock, setCurrentInDock] = useState(null)

  if(history.location.pathname.includes(`onboarding-chatbot`))
    return (
      <div>
        <Helmet defaultTitle="Plantt Console"><meta name="description" content="Plantt Console" /></Helmet>
        {children}
      </div>
    )

  return (
    <Fragment>
      <Helmet titleTemplate='%s - Plantt Console' defaultTitle='Plantt Console'>
        {layoutConfig.title && layoutConfig.title !== 'account_header' && <title>{layoutConfig.title.split(' /')[0]}</title>}
        <meta name="description" content="Plantt Console" />
      </Helmet>
      <GoogleSyncBanner />
      <div id='layout-new' style={{ height: layoutHeight }}>
        <LeftMenu {...{ expanded, closing, toggleMenu, transitionLength, logoutOnly, layoutHeight, menuWidth }} />
        <LayoutConfigContext.Provider value={{ layoutConfig, setLayoutConfig, contentRef, expanded, layoutHeight }}>
          <div
            className='layout-content'
            style={{
              transition: transitioning ? `width ${transitionLength / 1000}s` : undefined,
              width: `calc(100vw - ${!compactDisplay ? menuWidth : 0}px - ${(currentInDock && displayedInDock) ? appsDockWidth : 0}px)`,
            }}
          >
            <div id='layout-header' ref={headerRef} style={logoutOnly ? logoutOnlyStyle : undefined}>
              <div id='layout-header-left'>
                <div className={`open-plantt-menu ${menuClosedClass('visible')}`} onClick={toggleMenu}><Menu /></div>
                <div id='page-name'>
                  {goBack && <KeyboardArrowLeftRounded onClick={goBack} className='back-icon' />}
                  {layoutConfig.title !== 'account_header' ? layoutConfig.title : <AccountName />}
                </div>
              </div>
              <div id='layout-header-right'>
                {logoutOnly &&
                <div onClick={logout} className='logout-only'>
                  <ExitToApp fontSize="small" style={{ marginRight: '4px' }} />
                  Logout
                </div>}
                {layoutConfig.HeaderRight && <layoutConfig.HeaderRight containerRef={headerRef} />}
              </div>
            </div>
            <div
              id='page-content'
              style={{ maxHeight: contentMaxHeight }}
              ref={contentRef}
              className={!layoutConfig.nonScrollable ? 'layout-scrollbar scrollable-container' : undefined}
            >
              {children}
            </div>
          </div>
        </LayoutConfigContext.Provider>
        <DockContent
          current={currentInDock}
          setCurrent={setCurrentInDock}
          {...{ transitionLength, appsDockWidth, setAppsDockWidth, transitioning, setTransitioning }}
        />
      </div>
    </Fragment>
  )
}

export default PlanttLayout