import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { Editor } from "cs-slate"
import { useDispatch } from "react-redux"

import ActionCreator from "./InteractionMenu/ActionCreator"
import MakeBlocksGroup from "./Blocks/MakeBlocksGroup"
import TagMenu from "./InteractionMenu/TagMenu"
import CollabTracker from "./partials/CollabTracker"
import SelectionTracker from "./partials/SelectionTracker"
import ErrorBoundary from "../../components/ErrorBoundary"
import ErrorState from "./partials/ErrorState"
import EditorOverlay from "./partials/EditorOverlay"

import { focusAndSelect, useBlockGroups } from "./helpers"
import { createBlock, replaceInitialBlock, setIsDragging, updateCurrentNoteSelection } from "./actions"
import Scrollable from "../../components/Scrollable"
import useGistHistory, { blocksRef } from "./useGistHistory"
import useGistSelection, { useTriggerSelectionShortcuts } from "./useGistSelection";

import { store } from "../.."
import './style.scss'

function GistEditor(){
  const dispatch = useDispatch()
  const [interactionMenu, setInteractionMenu] = useState({ anchor: null })
  const { noteId, templateId } = useParams()
  const containerRef = useRef()
  const readonly = useSelector(state => {
    return state.notes.currentViewer?.permissions === 'read'
  })


  useEffect(() => {
    if(interactionMenu.anchor){
      const closeMenu = event => {
        if(event.key !== 'Escape')
          return

        const { editor } = blocksRef.current[interactionMenu.id]
        if(editor){
          focusAndSelect(editor, interactionMenu.selection)
          setInteractionMenu({ anchor: null })
        }
      }
      window.addEventListener('keydown', closeMenu)
      return () => window.removeEventListener('keydown', closeMenu)
    }
  }, [interactionMenu.anchor])

  const handleGistMouseDown = e => {
    if(readonly || e.target !== e.currentTarget)
      return

    const { currentNote: { blocks }, blockOrder } = store.getState().notes
    const lastBlockId = blockOrder[blockOrder.length - 1];
    const block = blocks[lastBlockId]

    if(block?.type === 'initial')
      return replaceInitialBlock(lastBlockId)
    if(typeof block?.payload?.text === 'undefined' || block?.type === 'title')
      return createBlock(lastBlockId, { type: 'basic', payload: { text: '' } })

    e.preventDefault()
    const { editor } = blocksRef.current[lastBlockId]
    return focusAndSelect(editor, Editor.end(editor, []))
  }

  const { buildGroups, blocksGroups } = useBlockGroups()

  const { gistKeyDown } = useGistHistory()
  const changeGistSelection = useGistSelection()
  const triggerSelectionShortcuts = useTriggerSelectionShortcuts()
  const handleGistKeyDown = (e) => {
    const { blocks } = store.getState().notes.currentNote
    gistKeyDown(e, blocks)
    changeGistSelection(e, blocksGroups, buildGroups)
    triggerSelectionShortcuts(e, blocks)
  }

  const openActionMenu = e => {
    setInteractionMenu({
      interaction: 'actions',
      anchor: e.target,
      selection: null,
      editor: null,
      id: store.getState().notes.focusedEditor || null,
      placement: 'top-end'
    })
  }

  useEffect(() => {
    let mouseUpAdded = false
    const onDragOver = () => {
      dispatch(setIsDragging({ type: 'file' }))
      if(mouseUpAdded)
        return
      const onMouseUp = () => {
        dispatch(setIsDragging(null))
        window.removeEventListener('mouseup', onMouseUp)
        mouseUpAdded = false
      }
      window.addEventListener('mouseup', onMouseUp)
      mouseUpAdded = true
    }
    window.addEventListener('dragover', onDragOver)
    return () => window.removeEventListener('dragover', onDragOver)
  }, [])
  
  const clearSelection = () =>{
    dispatch(updateCurrentNoteSelection([]))
    buildGroups()
  }

  useEffect(() => {
    const clearSelectionIfNeeded = () =>{
      const hoverdNodeList = document.querySelectorAll(":hover")
      for(const item of hoverdNodeList)
        if(item.getAttribute("draggable") === "true")
          return
      clearSelection()
    }
    window.addEventListener('mousedown', clearSelectionIfNeeded)
    return () => window.removeEventListener('mousedown',clearSelectionIfNeeded)
  }, [])

  // console.log('%cGistEditor has rerendered', 'font-size: 13px; color: #fd6c4b; font-weight: bold')

  return (
    <ErrorBoundary ErrorState={ErrorState}>
      <input onKeyDown={handleGistKeyDown} id='hiddenInput' style={{ position: 'absolute', opacity: 0, height: 0 }} />
      <Scrollable
        id='note-editor-content'
        className={readonly ? 'is-read-only' : ''}
        ref={containerRef}
        onKeyDown={handleGistKeyDown}
        onMouseDown={handleGistMouseDown}
      >
          {blocksGroups.map((group, index) => (
            <MakeBlocksGroup
              key={index}
              {...{ readonly, group, blocksRef, interactionMenu, setInteractionMenu, containerRef, buildGroups }}
            />
          ))}
          <ActionCreator {...interactionMenu} setInteractionMenu={setInteractionMenu} />
          <TagMenu {...{ setInteractionMenu, ...interactionMenu }} />
          {!templateId && <CollabTracker />}
          {!readonly && <SelectionTracker {...{ buildGroups, containerRef }} />}
      </Scrollable>
      <EditorOverlay />
      {!readonly &&
      <div id='add-block-button' onClick={openActionMenu} style={!noteId ? { right: 28 } : undefined}>
        <i className="fal fa-cube"></i>
        <i className="fas fa-plus-circle"></i>
      </div>}
    </ErrorBoundary>
  )
}

export default GistEditor