import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { makeStyles, Modal, Switch } from "@material-ui/core";
import Autocomplete from "../../../components/FormControls/Autocomplete";
import UserAvatar from "../../../components/UserAvatar";
import { OrangeButton } from "../../../components/Button";
import Scrollable from "../../../components/Scrollable";
import Select from "../../../components/FormControls/Select";

import { applyUpdates, getNoteInfo, getNoteParams, setNotePublic, shareGist } from "../actions";
import { setToastMessage } from "../../App/actions";
import { NEW_sendPostRequest } from "../../../apis/api-utils";
import { BASE_URL2 } from "../../../apis/constant";
import { firebase, store } from "../../..";
import useToggle from "../../../components/Toggle/useToggle";
import { useLayoutEffect } from "react";

const getContactName = ({ fields }) => fields.first_name ? `${fields.first_name} ${fields.last_name}` : fields.name ?? fields.email

const contactsSelector = createSelector(
  state => state.accounts?.contacts?.records,
  contacts => contacts?.map(contact => ({ name: getContactName(contact), id: contact.id, email: contact.fields.email }))
)

const filteredContactsSelector = sharedWith => createSelector(
  contactsSelector,
  contacts => contacts.filter(contact => !sharedWith?.find(({ contact_email }) => contact_email === contact.email))
)

const permissionOptions = { read: 'Can view', write: 'Can edit' }

const copyToClipboard = el => {
  if(!el)
    return

  el.select()
  el.setSelectionRange(0, 99999) /* For mobile devices */
  document.execCommand("copy")
  document.activeElement.blur()
  store.dispatch(setToastMessage('Link copied to clipboard successfully!', 'success'))
}

function GistShare({ openMenu, setOpenMenu }){
  const gist = useSelector(state => state.notes.currentNote || {})

  const [sharedWith, setSharedWith] = useState()
  useEffect(async () => {
    if(gist?.shared_with && !sharedWith)
      try {
        const list = await NEW_sendPostRequest(`${BASE_URL2}gist/shared-with`, {}, JSON.stringify({ user_ids: gist.shared_with }))
        if(!list.ok)
          throw new Error(list.text)

        setSharedWith(list.text)
      } catch(error) {
        console.error('Failed to fetch list of contacts this gist was shared with', error)
      }
  }, [gist?.shared_with])

  return (
    <>
      <OrangeButton className='actions-bar-button' onClick={() => setOpenMenu('share')}>
        Share
      </OrangeButton>
      <Modal id='gist-share-modal' open={openMenu === 'share'} placement='bottom-end' onClose={() => setOpenMenu(null)} disableEnforceFocus>
        <div className='card'>
          <ModalContent {...{ openMenu, gist, sharedWith, setSharedWith }} />
        </div>
      </Modal>
    </>
  )
}

function ModalContent({ openMenu, gist, sharedWith, setSharedWith }){
  const role = useSelector(state => state.global.workspace.role)
  const [contact, setContact] = useState(null)
  const [search, setSearch] = useState('')
  const contacts = useSelector(filteredContactsSelector(sharedWith))
  const linkRef = useRef()

  const { contentProps } = useToggle({ open: Boolean(gist.public), setOpen: () => {}, transitionLen: 200 })
  useEffect(() => {
    if(!openMenu){
      setSearch('')
      setContact(null)
      setLoading(false)
    }
  }, [openMenu])

  const [permissions, setPermissions] = useState('read')
  const [loading, setLoading] = useState(false)
  const [addedCount, setAddedCount] = useState(0)
  const onSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const user = await shareGist(contact?.email || search, permissions)
    if(user?.uid){
      if(!gist.shared_with?.includes(user.uid)){
        applyUpdates(`${getNoteInfo().notePath}/shared_with`, 'set', [...(gist.shared_with ?? []), user.uid])
        setSharedWith([user, ...(sharedWith || []) ])
      }
      setSearch('')
      setAddedCount(addedCount + 1)
    }
    setLoading(false)
  }

  return (
    <>
      <h1>
        <div className='icon-wrapper'><i className='far fa-user-plus' /></div>
        Share thread and start collaborating
      </h1>
      <div id='gist-link'>
        <label id='toggle-public'>
          <div id='toggle-public-text'>
            Public Access {gist.public ? 'Enabled' : 'Disabled'}
            <div>{gist.public ? 'Anyone with the link' : 'Only people added'} can access this thread</div>
          </div>
          <CustomSwitch checked={Boolean(gist.public)} onChange={(e, checked) => setNotePublic(checked ? 'read' : null)} />
        </label>
        <div {...contentProps}><div id='toggle-public-content'>
          <div id='link-input-wrapper'>
            <input ref={linkRef} defaultValue={window.location.host + window.location.pathname} />
            <button type='button' onClick={() => copyToClipboard(linkRef.current)}>
              <i className='fal fa-copy' />
            </button>
          </div>
          <label id='allow-editing'>
            Allow editing
            <CustomSwitch  checked={Boolean(gist.public === 'write')} onChange={(e, checked) => setNotePublic(checked ? 'write' : 'read')} />
          </label>
        </div></div>
      
      </div>
      <Scrollable>
        <form onSubmit={onSubmit} key={addedCount}>
          <UserAvatar name={contact?.name || search} size={24} />
          {role !== 'lite'
            ? <Autocomplete
                initialValue={contact}
                inputProps={{ placeholder: 'Select a contact or type an email address' }}
                items={contacts}
                onInput={setSearch}
                getItem={contact => contact.name}
                onSelect={setContact}
                filterBy='name'
                showNoOptions={false}
              />
            : <input placeholder='Type an email address to share with' onChange={e => setSearch(e.target.value)} />
          }
          <Select
            popperProps={{ id: 'share-permissions' }}
            initialValue={permissions}
            items={Object.keys(permissionOptions)} getItem={item => permissionOptions[item]}
            onSelect={({ selectedItem }) => setPermissions(selectedItem)}
          />
          <OrangeButton loading={loading} disabled={!contact && !search.includes('@')}>Share</OrangeButton>
        </form>
        {sharedWith?.map((item, index) => <SharedWithItem key={index} {...item} />)}
      </Scrollable>
    </>
  )
}

function SharedWithItem({ email, name, uid }) {
  const dispatch = useDispatch()
  const [permissions, setPermissions] = useState('read')
  useLayoutEffect(() => {
    const { noteId } = getNoteParams()
    const permissionsRef = firebase.database.ref(`authorization/${uid}/${noteId}/permissions`)
    permissionsRef.on('value', snapshot => setPermissions(snapshot.val()))
    return () => permissionsRef.off()
  }, [uid])

  const updatePermissions = async ({ selectedItem }) => {
    if(selectedItem === permissions)
      return

    setPermissions(selectedItem)
    const user = await shareGist(email, selectedItem)
    if(!user){
      setPermissions(permissions)
      dispatch(setToastMessage('Something went wrong while changing permissions. Please try again', 'error'))
    }
  }

  if(!name && !email)
    return null

  return (
    <div className={`shared-with-item`}>
      <div>
        <UserAvatar name={name || email} size={24} />
        <div>
          {name && <div>{name}</div>}
          <div className='email-address'>{email}</div>
        </div>
      </div>
      <div>
        <Select
          popperProps={{ id: 'share-permissions' }}
          initialValue={permissions}
          items={Object.keys(permissionOptions)} getItem={item => permissionOptions[item]}
          onSelect={updatePermissions}
        />
      </div>
    </div>
  )
}

const useSwitchStyles = makeStyles({
  root: { width: 40, height: 20, padding: 2 },
  switchBase: {
    padding: '4px 6px',
    color: 'white !important',
    
    '&$checked': {
        transform: 'translateX(16px)',
        '& + $track': { opacity: 1, backgroundColor: '#3aaf4b' },
    },
  },
  thumb: { width: 12, height: 12 },
  track: { borderRadius: 15, opacity: 1, backgroundColor: '#cacfd2' },
  checked: {}
})

function CustomSwitch(props){
  const classes = useSwitchStyles()
  return <Switch {...props} classes={classes} />
}

export default GistShare