import PlanttIcon from '../../images/plantt.svg'
import Scrollable from '../../components/Scrollable'
import './style.scss'
import Logo from '../../images/logo.svg'
import { useSelector } from 'react-redux'
import LoadingIndicator from '../../components/LoadingIndicator'
import AppsDock from '../Account/AppsDock'

function GistLoading(){
  const workspace =  useSelector(state => state.global.workspace)
  const currentViewer =  useSelector(state => state.notes.currentViewer)
  return !workspace
    ? <div id='gist-loading'>
        <div id='gist-view-header'>
          <div>{currentViewer.account_name}</div>
          <img src={PlanttIcon} />
        </div>
        <Scrollable id='note-editor-content'>
          <div id='loading-logo'><img src={Logo} /></div>
        </Scrollable>
      </div>
    : <div className='account-overview'>
        <div style={{ height: '100%' }}>
          <Scrollable id='note-editor-content'>
            <LoadingIndicator />
          </Scrollable>
          {/* <AppsDock /> */}
        </div>
      </div>
}

export default GistLoading