import { withStyles, makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { Fragment } from 'react';

const useStyles = makeStyles(() => ({
  wrapper: ({ backgroundColor, borderColor, color, lean }) => ({
    backgroundColor,
    color,
    border: `1px transparent solid`,
    borderColor,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    fontSize: 12,
    padding: !lean ? '12px 16px' : '4px 8px',
    position: 'relative',
    borderRadius: 4,
    minWidth: !lean ? 150 : 'fit-content',
    maxWidth: 220,

    '& .popper-arrow, & .popper-arrow::after': {
      position: 'absolute',
      width: 8,
      height: 8,
      backgroundColor,
      transform: 'rotate(45deg)',
      border: `1px transparent solid`,
      borderColor,
    },

  }),
  title: {
    borderBottom: 'solid 1px #e6e7e8',
    width: '100%',
    marginBottom: 4,
    paddingBottom: 4
  },

}));

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    background: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: 0,
    margin: 0,
    padding: '8px 0',
  },
}))(Tooltip);

function Content({ styles = {}, content, title, description, theme, lean, noArrow }) {
  const classes = useStyles({ ...theme, lean })
  return (
    <div className={classes.wrapper} style={styles.wrapper}>
      {content ||
      <Fragment>
        <div className={classes.title} style={styles.title}>{title}</div>
        <div style={styles.description}>{description}</div>
      </Fragment>}
      {!noArrow && <div className='popper-arrow' data-popper-arrow></div>}
    </div>
  )
}

const defaultTheme = { color: '#dddddd', backgroundColor: '#1d222c', borderColor: 'transparent' }

const TooltipComponent = ({ children, placement = 'bottom-end', styles, content, title, description, theme = defaultTheme, lean, noArrow, ...props }) => {
  return (
    <CustomTooltip
      title={<Content {...{ styles, content, title, description, theme, lean, noArrow }} />}
      data-custom-tooltip
      {...{ ...props, placement }}
      className={`custom-tooltip ${props.className || ''}`}
    >
      {children}
    </CustomTooltip>
  )
}

export default TooltipComponent
