import { useParams } from "react-router-dom"
import { BlueButton, OrangeButton } from "../../../components/Button"
import { createNote } from '../../GistEditor/actions'
import GistList from "../../Gists/GistList"

function AccountGistList(){
  const { id: accountId } = useParams()

  return (
    <GistList
      {...{ EmptyState, ListHeader, accountId }}
    />
  )
}

function ListHeader({ gists }){
  return gists.length > 0 && !gists[0].skeleton
    ? <div id='add-gist-button-wrapper'>
        <BlueButton outline padding='4px 8px' onClick={() => createNote()}>
          <i className='fal fa-plus' />Add Thread
        </BlueButton>
      </div>
    : null
}

function EmptyState(){
  return (
    <div className='no-gists'>
      Looks like you don't have any gists on this account yet...
      <OrangeButton rounded onClick={() => createNote()}>Add Thread</OrangeButton>
    </div>
  )
}

export default AccountGistList
