import { store } from '..'
export const trackMixpanelEvents = (eventName, accountInfo = null) => {
    //accountInfo is being used only on SignUp Event
    const { workspace } = store.getState().global
    if(!workspace)
        return //TEMP!!!
    window.mixpanel.track(eventName, {
        'plantt_workspace_id': accountInfo ? accountInfo.firebase_owner_uid :workspace.id,
        'plantt_workspace_name': accountInfo? accountInfo.name : workspace.name
        })
}