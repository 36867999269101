import { CircularProgress, makeStyles } from "@material-ui/core";
import { forwardRef } from "react";


const disabledColors = { backgroundColor: '#ccc', borderColor: '#ccc' }

const useStyles = makeStyles({
  full: ({ color, darkColor }) => ({
    color: '#fff',
    border: `1px solid ${color}`,
    '&:not(:disabled)': {
      backgroundColor: color,
      '&:hover': {
        backgroundColor: darkColor
      }
    },
    '&:disabled': disabledColors,
  }),
  outline: ({ color }) => ({
    backgroundColor: 'transparent',
    '&:not(:disabled)': {
      color: color,
      border: `1px solid ${color}`,
      '&:hover': {
        backgroundColor: color,
        color: '#fff'
      }
    },
    '&:disabled': {
      border: 'none',
      color: '#fff',
      ...disabledColors
    },
  })
})

const Button = forwardRef(({ outline, rounded, padding, style = {}, className, loading, spinnerProps = {}, ...remainder }, ref) => {
  const { children, color, darkColor, defaultRoundedPadding, ...props } = remainder
  const classes = useStyles({ color, darkColor })
  const variant = !outline ? 'full' : 'outline'
  style = {
    padding: !padding && rounded ? defaultRoundedPadding : padding,
    borderRadius: rounded ? 100 : 3,
    ...style
  }
  return (
    <button
      {...{ ...props, style }}
      className={`${classes[variant]} ${className || ''}`}
      ref={ref}
    >
      {!loading ? children : <CircularProgress size={16} {...spinnerProps} color='inherit' />}
    </button>
  )
})

export const BlueButton = forwardRef((props, ref) => {
  return <Button color='#1F8DED' darkColor='#004578' {...props} ref={ref} />
})

export const OrangeButton = forwardRef((props, ref) => {
  return <Button color='#fd6c4b' darkColor='#fd532c' defaultRoundedPadding='12px 60px' {...props} ref={ref} />
})

export const RedButton = forwardRef((props, ref) => {
  return <Button color='#FF4848' darkColor='#DA0037' defaultRoundedPadding='12px 60px' {...props} ref={ref} />
})

export default Button