import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { useDispatch } from "react-redux"

import Scrollable from "../../components/Scrollable"
import { BlueButton } from "../../components/Button"
import PlanttModal from "../../components/PlanttModal"
import FieldError from "../../components/FormControls/FieldError"
import useAddressSelector from "../../helpers/useAddressSelector"
import { trackMixpanelEvents } from '../../helpers/mixpanel'
function EntityEditor({ title, open, setOpen, onSubmit, type, fieldsAddress, entity }){
  const dispatch = useDispatch()
  const fieldList = useAddressSelector(fieldsAddress)
  const fields = useMemo(() => {
    const initialValues = entity?.fields || {}
    return entity
      ? Object.keys(initialValues).map(key => {
        const field = fieldList.find(f => f.key === key) || { key: key, label: key }
        return { ...field, value: initialValues[key] }
      })
      : fieldList
  }, [fieldList, entity])

  const required = useMemo(() => type === 'Account' ? ['name'] : ['first_name', 'last_name'], [type])

  const [values, setValues] = useState({})
  const [errors, setErrors] = useState({})

  const reset = () => {
    const newValues = { ...values }
    fields.forEach(({ key, value }) => {
      newValues[key] = value || ''
    })
    setValues(newValues)
    if(Object.keys(errors).length)
      setErrors({})
  }
  useLayoutEffect(reset, [fields])

  const [loading, setLoading] = useState(false)
  
  const create = async e => {
    e.preventDefault()
    const newErrors = {}
    required.forEach(r => {
      if(!values[r])
        newErrors[r] = true
    })
    if(Object.keys(newErrors).length === required.length){
      setErrors(newErrors)
      return
    }
    setLoading(true)
    await dispatch(onSubmit(type, entity, values))
    setLoading(false)

    trackMixpanelEvents(`accounts_${entity ? 'updated' : 'added'}_${type.toLowerCase()}`)
    setOpen(false)
    if(!entity)
      setValues({})
  }

  const onChange = (e, key) => {
    setValues({ ...values, [key]: e.target.value })
    if(errors[key])
      setErrors({})
  }

  const containerRef = useRef()

  const sorted = useMemo(() => {
    const requiredFields = []
    const remaining = []

    fields.forEach(field => {
      if(required.includes(field.key))
        requiredFields.push(field)
      else
        remaining.push(field)
    })
    return requiredFields.concat(remaining).filter(({ key }) => key[0] !== '_')
  }, [required, fields])

  return (
    <PlanttModal
      id='entity-editor'
      onClose={() => setOpen(false)}
      {...{ title, open }}
    >
      {({ classes }) => (
        <form onSubmit={create}>
          <Scrollable className='content-container' ref={containerRef}>
            <div>
              {sorted.map(field => <RenderField key={field.key} {...{ field, values, errors, required, onChange, containerRef }} />)}
            </div>
          </Scrollable>
          <div className={classes.buttons}>
            <BlueButton outline type='button' onClick={reset}>
              Reset Fields
            </BlueButton>
            <BlueButton loading={loading}>
              {entity ? 'Update' : 'Create'} {type}
            </BlueButton>
          </div>
        </form>
      )}
    </PlanttModal>
  )
}

function RenderField({ field, values, errors, required, onChange, containerRef }){
  const { key, label, type } = field
  const ref = useRef()

  useEffect(() => {
    if(errors[key])
      containerRef.current.scrollTo({ top: ref.current.offsetTop - 60, behavior: 'smooth' })
  }, [errors[key]])

  return (
    <div className='account-field'>
      {label || key}{required.includes(key) && ' *'}
      {type === 'duration' || type === 'currency' ? ` (in ${type === 'currency' ? '$' : 'minutes'})` : null}
      <input
        className={errors[key] ? 'has-error' : undefined}
        type={type === 'number' || type === 'duration' ? 'number' : 'text'}
        value={values[key]}
        onChange={e => onChange(e, key)}
        ref={ref}
      />
      <FieldError anchorEl={ref.current} containerEl={containerRef.current} hasErrors={errors[key]}>
        Fill at least one required field
      </FieldError>
    </div>
  )
}

export default EntityEditor