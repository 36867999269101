import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { clearcurrentAccount, fetchCurrentAccount } from './actions'
import { setBackToQuery } from '../Accounts/actions'
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator'
import Scrollable from '../../components/Scrollable'
import Tabulator from '../../components/Tabulator'

import { useLayoutConfig } from '../../components/PlanttLayout/LayoutConfig'
import injectReducer from '../../utils/injectReducer'
import accountsReducer from './reducer'
import connectorsReducer from '../Apps/reducer'

import OverviewTitle from './partials/OverviewTitle'
import GistList from './partials/GistList'
import ActionModal from './partials/ActionModal'
import GistEditor from '../GistEditor'
import AppsDock from './AppsDock'
import { trackMixpanelEvents } from '../../helpers/mixpanel'

import { useAvailableApps } from './helpers'

import './style.scss'
import Overview from './partials/Overview'
import { store } from '../..'


function Account(){
  const { id, noteId } = useParams()

  const history = useHistory()
  const dispatch = useDispatch()
  const loading = useSelector(state => state.accounts.loading.currentAccount)
  const availableApps = useAvailableApps()
  const currentApp = noteId && availableApps?.find(({ spec }) => spec.id === noteId || (noteId === 'overview' && spec.id === 'plantt'))

  const isOverview = noteId === 'overview' || currentApp
  const mode = isOverview ? 'overview' : !noteId ? 'gists' : 'gist'

  useLayoutConfig(!noteId ? { title: `account_header`, nonScrollable: true } : undefined)

  useEffect(() => {
    trackMixpanelEvents("account_page_viewed")
    if(id)
      dispatch(fetchCurrentAccount(id))

    return () => {
      dispatch(clearcurrentAccount())
      dispatch(setBackToQuery(''))
    }
  }, [id])

  const setMode = mode => {
    history.push(`/account/${id || store.getState().notes.currentNote.account_id}${mode === 'overview' ? '/overview' : ''}`)
  }

  const ref = useRef()
  useEffect(() => {
    ref.current?.scrollTo(0, 0)
  }, [history.location])

  return (
    <div className='account-overview'>
      <div id='account-header'>
        <Tabulator tabs={['Overview', 'Gists']} mode={mode} setMode={setMode} />
      </div>
      <div style={{ height: 'calc(100% - 24px)'}}>
        {!loading || !id
          ? <>
              {!noteId || isOverview
                ? <Scrollable id='note-editor-content' className='is-overview' ref={ref} style={isOverview ? { paddingRight: 52 } : undefined}>
                    <OverviewTitle {...{ availableApps, currentApp }} showAppIcons={isOverview} containerRef={ref} />
                    {!noteId ? <GistList /> : <Overview {...{ availableApps, currentApp }} />}
                  </Scrollable>
                : <GistEditor />
              }
              {!isOverview && <AppsDock />}
            </>
          : <div id='note-editor-content' data-loading><LoadingIndicator /></div>
        }
      </div>
      <ActionModal />
    </div>
  )
}

//Connectors reducer is being added here for the disabled_action scenario (see inside Notes/Blocks/Widget)
export default injectReducer({
  reducersList: [{ key: 'accounts', reducer: accountsReducer }, { key: 'connectors', reducer: connectorsReducer }]
})(Account)