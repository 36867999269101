import injectReducer from '../../utils/injectReducer'
import reducer from './reducer'
import { Route, Switch } from 'react-router-dom';
import AppsList from './AppsList'
import ViewApp from './ViewApp'
import AddApp from './AddApp'
import './style.scss';

import { AppsBookDemo } from '../../components/BookADemo'

function Connectors() {
  return (
    <>
      <Switch>
        <Route exact path='/apps/book-a-demo' component={AppsBookDemo} />
        <Route path='/apps/add' component={AddApp} />
        <Route path='/apps/view/:connectorId' component={ViewApp} />
        <Route exact path={['/', '/apps/:all?']} component={AppsList} />
      </Switch>
    </>
  )
}

export default injectReducer({ reducersList: [{ key: 'connectors', reducer }] })(Connectors)
