import { useRef, useState } from "react"
import { addMonths, addWeeks, format, nextMonday, setDate } from "date-fns"
import { ClickAwayListener, Popper } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import HoverableIcon from '../../components/HoverableIcon'
import SimpleHoverableIcon from '../../components/HoverableIcon/Simple'
import Hoverable from "../../components/Hoverable"
import Tooltip from "../../components/Tooltip/new"

const taskOptions = [
  ['Next Week', nextMonday(new Date())],
  ['In Two Weeks', addWeeks(nextMonday(new Date()), 1)],
  ['Next Month', addMonths(setDate(new Date(), 1), 1)]
]

function DueDate({ task, setTask, MenuTop, iconProps, tooltipText, simpleIcon = false, ...props }){
  // const task = useSelector(state => state.notes.tasks[task_id])
  const ref = useRef()
  const [open, setOpen] = useState(null)
  const selectedDate = new Date(task?.datetime || 0)
  const onDateClose = () => setOpen(null)

  return (
    <>
      <Tooltip placement='top' lean content={tooltipText} enterDelay={400} enterNextDelay={400}>
        <div>
          {!simpleIcon
            ? <HoverableIcon ref={ref} {...iconProps} onClick={() => setOpen('menu')}/>
            : <SimpleHoverableIcon ref={ref} {...iconProps} onClick={() => setOpen('menu')}/>
          }
        </div>
      </Tooltip>
      {open === 'menu' &&
      <Popper id='due-date-popper' open={true} anchorEl={ref.current}>
        <ClickAwayListener onClickAway={() => setOpen(null)}>
          <div className='card' onClick={e => e.target.id !== 'custom-date' && setOpen(null)}>
            {MenuTop && <MenuTop {...{ task, setTask, ...props }} />}
            <div className='due-date-legend'>{task?.datetime ? 'Change' : 'Set'} Due Date:</div>
            {taskOptions.map(([label, value]) => (
              <Hoverable key={label} onClick={() => setTask(value)}>
                {label}
                <div className='date-display'>{format(value, 'LLL d')}</div>
              </Hoverable>
            ))}
            <Hoverable id='custom-date' onClick={() => setOpen('date-picker')}>Custom Date</Hoverable>
          </div>
        </ClickAwayListener> 
      </Popper>}
      <DatePicker
        minDate={new Date()} open={open === 'date-picker'}
        onClose={onDateClose}
        value={selectedDate}
        onChange={() => {}}
        onAccept={setTask}
        style={{ display: 'none' }}
        autoOk
      />
    </>
  )
}

export default DueDate