import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sendPutRequest } from '../../../apis/api-utils';
import InstallComponent from './InstallComponent';
import { BASE_URL } from '../../../apis/constant';
import { fetchAvailableIntegrations, fetchIntegrationForEdit } from '../actions';

function Install() {
    const history = useHistory()
    const { integrationId } = useParams();
    const [userParamsValue, setuserParamsValue] = useState({})
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const availableIntegrations = useSelector(state => state.integrations.availableIntegrations);
    const editing = useSelector(state => state.integrations.editing);
    const specId = editing ? editing.spec_id : null;
    const integrationSpecs = availableIntegrations ? availableIntegrations[specId] : null;

    useEffect(() => {
        dispatch(fetchAvailableIntegrations());
        dispatch(fetchIntegrationForEdit(integrationId));
    }, [])

    useEffect(() => {
        if(editing)
            setuserParamsValue(editing.data);
    }, [editing])

    const moveOn = async () => {
        if (integrationSpecs.oauth_url !== ''){
            return /* This behaviour should be changed */
        }
        var data = userParamsValue;
        integrationSpecs.user_params.forEach(param => {
            if(!data[param.id]) data[param.id] = null;
        })
        try {
            setLoading(true);
            await sendPutRequest(`${BASE_URL}integration/${integrationId}`, {}, JSON.stringify({ ...editing, data }));
            history.push('/settings/integrations');
        } catch(error) {
            setLoading(false);
            console.log('Error installing: ', error);
        }
    }

    return <InstallComponent
        specs={integrationSpecs}
        title={`Update ${editing ? editing.name : null}`}
        buttonText='Update'
        params={userParamsValue}
        setParams={setuserParamsValue}
        moveOn={moveOn}
        loading={loading}
    />
}

export default Install

