import firebase from 'firebase/app';
import { NEW_sendPutRequest, sendPutRequest } from '../../apis/api-utils';
import { UPDATE_ACCOUNT_URL } from '../../apis/constant';
import { setFullAccountData, setToastMessage, updateMember } from '../App/actions';

export const updadeAccountName = (name) => async (dispatch, getState) => {
  try {
    const { currentMember, workspaceMembers } = getState().global
    const member = workspaceMembers.find(m => m.id === currentMember.id)
    await Promise.all([
      firebase.auth().currentUser.updateProfile({displayName:name}),
      dispatch(updateMember(member, { name }))
    ])
    dispatch(setToastMessage('Your name has been updated successfully', 'success'))
  } catch (err) {
    console.error(err)
    dispatch(setToastMessage('Something went wrong while trying to update your name', 'error'));
  }
}

export const updateUserEmailAddress = email => async (dispatch, getState) => {
  try {
    // check if new email is already used
    const data = await firebase.auth().fetchSignInMethodsForEmail(email)
    if (data?.length){
      const error = new Error('You attempted to change to an email address that is already registered with Plantt')
      error.code = 'email-exists'
      throw error
    }

    await firebase.auth().currentUser.updateEmail(email)
    const { workspace, currentMember, workspaceMembers } = getState().global
    const { name, contact_name, firebase_owner_uid } = workspace

    if(currentMember.id === firebase_owner_uid){
      const updated = { email, name, contact_name, firebase_owner_uid }
      const response = await NEW_sendPutRequest(UPDATE_ACCOUNT_URL, {}, JSON.stringify(updated))
      if(!response.ok)
        throw new Error(response.text)

      dispatch(setFullAccountData(response.text))
    }
    await dispatch(updateMember(workspaceMembers.find(m => m.id === currentMember.id), { email }))

    dispatch(setToastMessage('Your email address has been updated successfully', 'success'))
  } catch(error) {
    const message = !['auth/requires-recent-login', 'email-exists'].includes(error.code)
      ? 'Something went wrong while trying to update your email'
      : error.message
    dispatch(setToastMessage(message, 'error'))
    console.error(error)
    return error.code
  }
}

export const updateUserPassword = (oldPassword, newPassword) => async dispatch => {
  const user = firebase.auth().currentUser
  const credential = firebase.auth.EmailAuthProvider.credential(user.email, oldPassword)

  try {
    await user.reauthenticateWithCredential(credential)
    firebase.auth().currentUser.updatePassword(newPassword)
    dispatch(setToastMessage('Your password has been updated successfully', 'success'))
  } catch(error) {
    console.error(error)
    dispatch(setToastMessage('Something went wrong while trying to update your password', 'error'))
  }
}

export const updateAccountMetadata = metaData => async (dispatch, getState) => {
    const workspace = getState().global.workspace
    const UpdatedData = {
      metadata: { ...(workspace?.metadata || {}), ...metaData }
    }
    window.Intercom('update',{
      company: {
        company_id: workspace.id,
        ...UpdatedData.metadata
      }
    });
    try {
      const UpdatedResponse = await sendPutRequest(UPDATE_ACCOUNT_URL, {}, JSON.stringify(UpdatedData));
      dispatch(setFullAccountData(UpdatedResponse))
    } catch (err) {
      console.log('onboarding error',err)
    }
}

export const updateAccountOnPostInstall = () => async (dispatch, getState) => {
  try {
    const workspace = getState().global.workspace
    const UpdatedData = {
        metadata: { ...workspace.metadata, crm_connected: new Date().getTime(), onboarding_status: 'done'},
        status: 'processing'
    }
    const UpdatedResponse = await sendPutRequest(UPDATE_ACCOUNT_URL, {}, JSON.stringify(UpdatedData));
    dispatch(setFullAccountData(UpdatedResponse))
  } catch (err) {
    console.log('Failed to update account status: ', err)
  }
}

/* FOR DEVELOPMENT PURPOSES */
export const changeAccountStatus = status => async dispatch => {
  try {
    const UpdatedResponse = await sendPutRequest(UPDATE_ACCOUNT_URL, {}, JSON.stringify({ status }));
    dispatch(setFullAccountData(UpdatedResponse))
  } catch (err) {
    console.log('Failed to update account status: ', err)
  }
}