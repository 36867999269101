
import { useSelector } from "react-redux"
import { useState } from "react"
import { useHistory } from "react-router"
import Skeleton from "react-loading-skeleton"
import { OrangeButton } from "../../../components/Button"

import { useLayoutConfig } from "../../../components/PlanttLayout/LayoutConfig"
import PlanttFocus from '../../../images/PlanttFocus.svg'
import { createTemplate, deleteTemplate } from "../../GistEditor/actions"
import TemplateIcon from "../../GistEditor/partials/TemplateIcon"

import ConfirmModal from "../../../components/ConfirmModal"
import TooltipComponent from "../../../components/Tooltip/new"
import HoverableIcon from "../../../components/HoverableIcon"
import SimpleHoverableIcon from "../../../components/HoverableIcon/Simple"
import Scrollable from "../../../components/Scrollable"

const defaultGists = ['onboarding_kickoff', 'feature_request', 'customer_check-in', 'qbr_summary', 'user_interview']

// const skeletonItems = [new Array(4).fill(true).map((_, index) => ({ id: index, skeleton: true }))]

function GistTemplates(){
  useLayoutConfig({ title: 'Settings / Thread Templates' })
  const templates = useSelector(state => Object.entries(state.notes.templates).sort(a => defaultGists.includes(a[0]) ? 1 : -1))

  const [nowDeleting, setNowDeleting] = useState(null)
  const doDelete = async () => {
    deleteTemplate(nowDeleting)
    setNowDeleting(null)
  }

  return (
    <Scrollable id='note-editor-content' className='account-overview'>
      {templates.length
        ? <>
            <div className='notebook-note-block' id='gists-page-header'>
              <OrangeButton onClick={createTemplate} rounded padding='8px 12px 8px 8px'><i className='fas fa-plus' /> New Template</OrangeButton>
            </div>
            <div className='notebook-note-block'>
              <div className='gist-group-wrapper'>
                {templates.map(([id, template]) => <TemplateItem key={id} {...template} id={id} setNowDeleting={setNowDeleting} />)}
              </div>
              <ConfirmModal
                open={Boolean(nowDeleting)}
                onClose={() => setNowDeleting(null)}
                onConfirm={doDelete}
                text='Are you sure you would like to delete this template?'
              />
            </div>
          </>
        : <div id='page-empty-state' style={{ width: 740, margin: '0 auto' }}>
            <h2>No Custom Templates</h2>
            <h3>Create thread templates to suit your organizational needs, improve productivity, and make the most of Plantt</h3>
            <img src={PlanttFocus} />
            <OrangeButton rounded onClick={createTemplate}>Create a Template</OrangeButton>
          </div>
      }
    </Scrollable>
  )
}

function TemplateItem({ blocks, id, setNowDeleting, skeleton }){
  const history = useHistory()

  const onItemClick = e => {
    if(!e.target.classList.contains('hoverable-icon-wrapper') && !e.target.closest('.hoverable-icon-wrapper'))
      history.push(`/settings/templates/${id}`)
  }

  return (
    <div className='gist-list-item' onClick={onItemClick}>
      <SimpleHoverableIcon icon='cubes' />
      {/* <TemplateIcon id={id} className='template-icon' /> */}
      <div>
        <div className='gist-name'>
          {!skeleton
            ? blocks.noteTitle.payload.text || <div className='untitled-gist'>Untitled</div>
            : <Skeleton width={300} />
          }
        </div>
      </div>
      <div className='avatars-wrapper' />
      {!skeleton && <GistActions {...{ id, setNowDeleting }} />}
    </div>
  )
}

function GistActions({ id, setNowDeleting }){
  return (
    <div className='gist-actions' style={{ top: 9 }}>
      <HoverableIcon icon='trash' onClick={() => setNowDeleting(id)} />
    </div>
  )
}

export default GistTemplates