

import DeleteIcon from '@material-ui/icons/Delete'
import makeRoundIcon from '../../makeRoundIcon'
import CustomSwitch from '../../FormControls/Switch'
import useAddressSelector from '../../../helpers/useAddressSelector'

const RoundDeleteIcon = makeRoundIcon(DeleteIcon)

function TrackableField({ item, stateAddress, setState}){
    const state = useAddressSelector(stateAddress)
    const { index } = item

    const deleteField = () => {
        const fields = [ ...state.fields ]
        fields.splice(index,1)
        let  i = index
        while(i < fields.length) {
            fields[i] = {...fields[i],index:fields[i].index -1}
            i++
        }
        setState({ ...state, fields })
    }
    const onCheckChange = () => { 
        const fields = [ ...state.fields ]
        fields[index] = { ...fields[index]}
        fields[index].trackable = fields[index].trackable ? false : true
        setState({ ...state, fields })
    }
    return !item.type.isNew ? (
        <div className="trackable-row">
            <CustomSwitch 
                disabled={item.type.readonly}
                checked={item.trackable}
                onChange={onCheckChange}
                readonly={true}
            />
            {!item.type.readonly && 
                <RoundDeleteIcon onClick={deleteField} />
            }
        </div>
    ) : null
}

export default TrackableField