import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useDrag } from 'react-dnd'
import { ReactEditor } from "cs-slate-react";

import EditorBlock from "./Editor"
import ActionBlock from "./Action"
import WidgetBlock from "./Widget"
import PlanttWidgetBlock from "./Widget/PlanttWidget"
import CheckListBlock from "./Itemized/CheckList"
import PollBlock from "./Itemized/Poll"
import InitialBlock from "./Initial"
import MomentWidget from "./MomentWidget";
import DisabledActionBlock from "./Action/DisabledAction"
import TaskBlock from "./Task"

import { handleBlockDrop, clearSelection, getBlockOffspring, updateCurrentNoteSelection, setIsDragging } from "../actions"

import { BASIC_BLOCK, DOCK_WIDGET, GIST_BLOCK, PLANTT_FIELD, TO_DO_ITEM } from "../constants"
import LockHandler from "../partials/LockHandler"
import RenderTags from "../partials/RenderTags"
import MetricsBlock from "./Itemized/Metrics"
import BarChartBlock from "./Chart/Bar";
import PieChartBlock from "./Chart/Pie";
import EmojiDisplay from "../partials/EmojiDisplay";
import ReadOnlyBlock from "./ReadOnlyBlock";
import EditableTable from "./EditableTable";
import ButtonBlock from "./Button";
import { NativeTypes } from "react-dnd-html5-backend";
import FileUploadBlock from "./File/Upload";
import ImageBlock from "./File/Image";
import FileBlock from "./File";
import EmbedBlock from "./Embed";
import { store } from "../../.."

import OverlayDropZone from "../partials/OverlayDropZone";
import BlockLeft from "../partials/BlockLeft";
import { isItemizedBlock } from "./Itemized/helpers";
// import MockAnalytics from "../MockAnalytics";

const allowedDropTypes = [GIST_BLOCK, BASIC_BLOCK, PLANTT_FIELD, DOCK_WIDGET, TO_DO_ITEM, NativeTypes.FILE]

function MakeBlock({ groupRef, firstBlockIdInGroup, lastBlockIdInGroup, buildGroups, selection, ...props }){
  const dispatch = useDispatch()
  const { blockId, blocksRef, setInteractionMenu } = props

  const block = useSelector(state => state.notes.currentNote.blocks[blockId])
  const isSelected = useSelector(state => Boolean(state.notes.selection.find(selectionBlock => selectionBlock.blockId === blockId)))
  const isLocked = useSelector(state => block?.lock && block.lock.by !== state.firebase.auth.uid)
  const { type } = block || {}
  const blockRef = useRef()

  const dropZoneProps = useMemo(() => ({ blockId, onDrop: handleBlockDrop, types: allowedDropTypes }), [blockId])

  const [{ isDragging }, drag, dragPreview] = useDrag({
    item: { type: type === 'basic' ? BASIC_BLOCK : GIST_BLOCK, id: firstBlockIdInGroup || blockId, lastBlockIdInGroup },
    collect: monitor => ({ isDragging: !!monitor.isDragging() })
  })

  useEffect(() => {
    dispatch(setIsDragging(isDragging ? { blockId, type } : null))
  }, [isDragging])

  if(groupRef) dragPreview(groupRef.current)

  const RenderBlock = useBlockComponent(type)
  const showSettings = useMemo(() => !['initial', 'title'].includes(type), [type])

  if(!block)
    return null

  const onMouseUp = e => {
    // const id = !isItemizedBlock(block) ? blockId : `${blockId}/${block.payload.fields[0].id}`
    // const { editor } = blocksRef.current[id] || {}
    // const isOrClosest = cls => e.target.classList.contains(cls) || e.target.closest(`.${cls}`)
    // if(editor
    //   && !isOrClosest('note-editor-block')
    //   && !isOrClosest('note-title-block')
    //   && !e.target.closest('.select-popper')
    // ){
    //   e.preventDefault()
    //   ReactEditor.focus(editor) 
    // }
  }

  return (
    <>
      <div
        onMouseUp={onMouseUp}
        data-key={blockId}
        className={`notebook-note-block selectable ${isSelected && type !== 'title' ? 'selected' : ''} ${isLocked ? 'locked' : ''}`}
        style={{ display: 'flex', opacity: isDragging ? 0.5 : 1 }}
        ref={blockRef}
      >
        {!['to-do-list', 'title'].includes(type) && !selection &&
        <>
          <OverlayDropZone {...dropZoneProps} top />
          <OverlayDropZone {...dropZoneProps} />
        </>}
        {showSettings && <BlockLeft {...{ block, drag, blockId, buildGroups }} />}
        <RenderBlock dragPreview={!groupRef ? dragPreview: undefined} blockSelectionRef={blockRef} {...{ blockRef, block, ...props}} />
        {!isItemizedBlock(block) && <LockHandler {...{ block, blockId }} />}
      </div>
      {block.reactions?.emoji && <EmojiDisplay blockId={blockId} emojiById={block.reactions.emoji} />}
      {type === 'title' && <RenderTags className='notebook-note-block' setInteractionMenu={setInteractionMenu} />}
    </>
  )
}

export const useBlockComponent = type => {
  const readonly = useSelector(state => state.notes.currentViewer?.permissions === 'read')
  return useMemo(() => {
    switch(type){
      case 'title':
      case 'basic': return !readonly ? EditorBlock : ReadOnlyBlock
      case 'widget': return WidgetBlock
      case 'plantt-widget': return PlanttWidgetBlock
      case 'moment-widget': return MomentWidget
      case 'to-do-list': return CheckListBlock({ readonly })
      case 'poll': return PollBlock({ readonly })
      case 'metrics': return MetricsBlock({ readonly })
      case 'bar-chart': return BarChartBlock({ readonly })
      case 'pie-chart': return PieChartBlock({ readonly })
      case 'editable-table': return EditableTable({ readonly })
      case 'button': return ButtonBlock({ readonly })
      case 'file_upload': return FileUploadBlock
      case 'image': return ImageBlock
      case 'file': return FileBlock
      case 'embed': return EmbedBlock
      case 'action': return ActionBlock
      case 'disabled_action': return DisabledActionBlock
      case 'initial': return InitialBlock({ readonly })
      case 'task': return TaskBlock
      default: return () => !readonly ? <div>Unsupported Block Type: {type}</div> : null
    }
  }, [type, readonly])
}

export default MakeBlock