import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { firebase, store } from "../.."
import { BASE_URL2 } from "../../apis/constant"
import { setGlobalLoading, setToastMessage } from "../App/actions"
import { setCurrentViewer } from "../GistEditor/actions"

const sendRequest = async (url, method = 'get', body) => {
  const fetched = await fetch(url, {
    method: method.toUpperCase(),
    headers: { 'Content-Type': 'application/json', 'Connection': 'keep-alive', 'Keep-Alive': 'timeout=0, max=1000' },
    body
  })

  let text = await fetched.text()
  try {
    const parsed = JSON.parse(text)
    text = parsed
  } catch(error) {
    //
  }

  return { status: fetched.status, ok: fetched.ok, text }
}

const useAuthorize = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const workspace = useSelector(state => state.global.workspace)
  const { workspaceId: account_id, noteId: gist_id, hash } = useParams()
  const [authorized, setAuthorized] = useState(false)

  const firstAuthStateChange = useRef(true)
  useEffect(async () => {
    const unsubscribe = firebase.auth.onAuthStateChanged(async user => {
      if(user){
        const { claims } = await firebase.auth.currentUser.getIdTokenResult()

        if(!claims.guest){
          if(hash){
            const data = await sendRequest(`${BASE_URL2}gist/shared/${hash}`)
            history.replace(`/${data.text.account_id}/${data.text.gist_id}`)
          }
          return
        }
 
        // If firstAuthStateChange.current is true, we are dealing with a guest user that is already logged in
        // when the app was loaded. This user is going to be thrown out, which means they are actually unauthorized.
        // The exception here is when a user is logged in with a hash (recognizeable by the firebase_user_id), and is therefore authorized on first authStateChange
        if(!firstAuthStateChange.current || store.getState().notes.currentViewer.firebase_user_id)
          return setAuthorized(true)

        return firstAuthStateChange.current = false
      }

      firstAuthStateChange.current = false

      try {
        let data = !hash
          ? await sendRequest(`${BASE_URL2}gist/public`, 'post', JSON.stringify({ account_id, gist_id }))
          : await sendRequest(`${BASE_URL2}gist/shared/${hash}`)
          
        if(!data.ok){
          const redirect = !hash ? history.location.pathname : `/${data.text.account_id}/${data.text.gist_id}`
          return history.push(`/login?redirect=${redirect}`)
        }

        dispatch(setGlobalLoading({ user: false }))
        const { token, account_id: accountId, gist_id: gistId, firebase_user_id } = data.text
        await firebase.auth.signInWithCustomToken(token)

        const permissionsPath = hash
          ? `authorization/${firebase_user_id}/${gistId}/permissions`
          : `${account_id}/notes/${gist_id}/public`

        const permissions = (await firebase.database.ref(permissionsPath).once('value')).val()
        dispatch(setCurrentViewer({ ...data.text, permissions }))

        if(hash)
          history.push(`/${accountId}/${gistId}`)
      } catch(error) {
        console.error(error)
      }
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    if(workspace)
      setAuthorized(true)
  }, [workspace])

  return authorized
}

export default useAuthorize