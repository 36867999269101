import { NearMeRounded } from "@material-ui/icons"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { firebase } from "../../.."
import { getNoteParams, setActiveCollborators, setCollaborator } from "../actions"

function CollabTracker(){
  const dimensions = useSelector(state => state.global.dimensions)
  const userId = useSelector(state => state.firebase.auth.uid)
  const collaborators = useSelector(state => state.notes.currentNote.collaborators)

  const lastRender = useRef(0)
  useEffect(() => {
    const renderInterval = setInterval(() => lastRender.current = Date.now(), 50)
    return () => clearInterval(renderInterval)
  }, [])

  useEffect(() => {
    let lastUpdate = 0
    const trackMouse = event => {
      const now = Date.now()
      if(lastUpdate < now - 50){
        lastUpdate = now
        setCollaborator(event)
      }
    }
    window.addEventListener('mousemove', trackMouse)

    const checkIn = setInterval(() => {
      if(lastUpdate <= Date.now() - 5000)
        setCollaborator()
    }, 5000)

    return () => {
      window.removeEventListener('mousemove', trackMouse)
      clearInterval(checkIn)
    }
  }, [])

  if(!collaborators)
    return null

  return Object.entries(collaborators).map(([id, collaborator], index) => (
    <RenderCollaborator key={id + index} {...{ id, dimensions, index, lastRender, userId }} {...collaborator} />
  ))
}

function RenderCollaborator({ id, dimensions, name, email, color, index, lastRender, userId }){
  const dispatch = useDispatch()
  const [live, setLive] = useState(null)

  useEffect(() => {
    const { workspaceId, noteId } = getNoteParams()
    const liveRef = firebase.database.ref(`/${workspaceId}/live/${noteId}/${id}`)
    liveRef.on('value', snapshot => {
      setTimeout(() => {
        setLive(snapshot.val())
        dispatch(setActiveCollborators('add', id))
      }, (50 - (Date.now() - lastRender.current)))
    })
    return () => liveRef.off()
  }, [id])

  useEffect(() => {
    if(live){
      const removeInactive = () => {
        if(live.timestamp < Date.now() - 15000){
          setLive(null)
          dispatch(setActiveCollborators('delete', id))
        }
      }

      const activeInterval = setInterval(removeInactive, 1000)
      removeInactive()
      return () => clearInterval(activeInterval)
    }
  }, [live])

  if(!live || id === userId)
    return null

  color = `hsl(${color * 20}, 90%, 46%)`
  return (
    <div  
      key={id}
      className='note-collaboration-tracker'
      style={{ top: live.y, left: ((dimensions.width - 220) / 2) + live.x + (index * 60) }}
    >
      <NearMeRounded style={{ color }} />
      <div className='member-name' style={{ backgroundColor: color }}>{name || email}</div>
    </div>
  )
}

export default CollabTracker