import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CreateIcon from '@material-ui/icons/Create';
import { Divider, Grid } from '@material-ui/core';
import { renameIntegration } from '../actions';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export const renderOption = ({ image_url, name }) => (
    <div>
      <div className='search-item'>
        <div className='icon'>
          <img src={image_url} />
        </div>
        <div>{name}</div>
      </div>
    </div>
);

export const renderInput = params => {
  return (
    <div ref={params.InputProps.ref} className='search-input'>
      <input 
        type="text"
        {...params.inputProps}
        placeholder="Connect a new integration..."
      />
      <div className='arrow' onClick={params.inputProps.onMouseDown}>
        <KeyboardArrowDownIcon />
      </div>
    </div>
  )
  }

export function IntegrationItem({ integration, nowEditing, setNowEditing, remove, index }) {
    const { id, name, spec_id } = integration;
    const availableIntegrations = useSelector(state => state.integrations.availableIntegrations);
    const [localName, setLocalName] = useState(name);
    const dispatch = useDispatch();

    const rename = () => {
        if(localName !== name)
            dispatch(renameIntegration(integration, localName));
    }

    useEffect(rename, [nowEditing])

    let allowSettingsEdit, imgSrc;
    if(availableIntegrations[spec_id]){
      allowSettingsEdit = availableIntegrations[spec_id].oauth_url === ''; /* Figure out relevant beahviour */
      imgSrc = availableIntegrations[spec_id].image_url;
    }

    return (
        <Grid item xs={12} key={id}>
            {!index && <Divider />}
            <div className='settings-row integrations-item'>
                <img src={imgSrc} alt='icon' className='icon' />
                <label htmlFor={`changename-${id}`}>
                    <CreateIcon
                        className='clickable-icon'
                        onClick={() => setNowEditing(id)}
                    />
                </label>
                <input 
                    id={`changename-${id}`}
                    value={localName}
                    onChange={e => setLocalName(e.target.value)}
                    onBlur={rename}
                />
                <div className='float-right integrations'>
                    {allowSettingsEdit && <Link to={`/inte/${id}/settings`}>Settings</Link>}
                    {!allowSettingsEdit && <a disabled>Settings</a>}
                    <a onClick={remove}>Remove</a>
                </div>
            </div>
            <Divider />
        </Grid>
    )
}