export const FETCH_INTEGRATION_FOR_EDIT_SUCCESS = 'plantt/Integrations/FETCH_INTEGRATION_FOR_EDIT_SUCCESS'
export const CLEAR_INTEGRATION_FOR_EDIT = 'plantt/Integrations/CLEAR_INTEGRATION_FOR_EDIT'
export const FETCH_INTEGRATION_ERROR = 'plantt/Integrations/FETCH_INTEGRATION_ERROR'
export const CREATE_INTEGRATION_SUCCESS = 'plantt/Integrations/CREATE_INTEGRATION_SUCCESS'
export const CREATE_INTEGRATION_ERROR = 'plantt/Integrations/CREATE_INTEGRATION_ERROR'
export const UPDATE_INTEGRATION_SUCCESS = 'plantt/Integrations/UPDATE_INTEGRATION_SUCCESS'
export const UPDATE_INTEGRATION_ERROR = 'plantt/Integrations/UPDATE_INTEGRATION_ERROR'
export const DELETE_INTEGRATION_SUCCESS = 'plantt/Integrations/DELETE_INTEGRATION_SUCCESS'
export const DELETE_INTEGRATION_ERROR = 'plantt/Integrations/DELETE_INTEGRATION_ERROR'
export const FETCHING_AVAILABLE_INTEGRATIONS = 'plantt/Integrations/FETCHING_AVAILABLE_INTEGRATIONS'
export const FETCH_AVAILABLE_INTEGRATIONS_SUCCESS = 'plantt/Integrations/FETCH_AVAILABLE_INTEGRATIONS_SUCCESS'
export const FETCH_AVAILABLE_INTEGRATIONS_ERROR = 'plantt/Integrations/FETCH_AVAILABLE_INTEGRATIONS_ERROR'
export const FETCHING_CURRENT_INTEGRATIONS = 'plantt/Integrations/FETCHING_CURRENT_INTEGRATIONS'
export const FETCH_CURRENT_INTEGRATIONS_SUCCESS = 'plantt/Integrations/FETCH_CURRENT_INTEGRATIONS_SUCCESS'
export const FETCH_CURRENT_INTEGRATIONS_ERROR = 'plantt/Integrations/FETCH_CURRENT_INTEGRATIONS_ERROR'
export const RENAME_INTEGRATION_ERROR = 'plantt/Integrations/RENAME_INTEGRATION_ERROR'