import React, { Fragment, useLayoutEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UserAvatar from '../UserAvatar'
import { createSelector } from 'reselect'
import { NEW_sendPutRequest } from '../../apis/api-utils'
import { BASE_URL2 } from '../../apis/constant'
import { UPDATE_MEMBER } from '../../containers/App/constants'
import { setToastMessage } from '../../containers/App/actions'
import { Helmet } from 'react-helmet'
import HoverableIcon from '../HoverableIcon/Simple'
import { useLocation, useParams } from 'react-router-dom'
import { updateNoteMetadata } from '../../containers/GistEditor/actions'

const accountSelector = createSelector(
  state => {
    const account = state.accounts.currentAccount
    const memberId = state.global.currentMember.id
    const member = state.global.workspaceMembers.find(m => m.id === memberId)
    return { account, member }
  },
  ({ account, member }) => {
    return { member, account }
  }
)
export function AccountName(){
  const location = useLocation()
  const { noteId } = useParams()
  const dispatch = useDispatch()
  const [isStarred, setIsStarred] = useState(false)
  const { account, member } = useSelector(accountSelector)
  const currentNote = useSelector(state => state.notes.currentNote)
  const fields = account?.fields

  useLayoutEffect(() => {
    if(!location.pathname.startsWith('/account')){
      setIsStarred(Boolean(currentNote.metadata?.favorite))
    }else if(member){
      const starred = member.starred_identities.map(({ identity_id }) => identity_id).includes(account.id)
      if(isStarred !== starred)
        setIsStarred(starred)
    }
  }, [member, account, currentNote.metadata])

  const onStarClick = async () => {
    if(!location.pathname.startsWith('/account'))
      return updateNoteMetadata({ id: noteId, favorite: !currentNote.metadata?.favorite || null })
    if(!member)
      return dispatch(setToastMessage('Only members of this workspace can mark accounts as favorites', 'info'))

    try {
      setIsStarred(!isStarred)
      const response = await NEW_sendPutRequest(`${BASE_URL2}member/${member.id}/starred`, {}, JSON.stringify({ identity_id: account.id, is_starred: !isStarred }))
      if(!response.ok)
        throw new Error(response.text)
        
      dispatch({ type: UPDATE_MEMBER, member: response.text })
    } catch(error) {
      console.error('Failed to change star status:', error)
      setIsStarred(!isStarred)
    }
  }

  const withoutAccount = Object.keys(currentNote).length > 0 && !currentNote.account_id

  const data = useMemo(() => {
    return fields || (withoutAccount ? { name: currentNote.blocks?.noteTitle?.payload?.text } : null)
  }, [fields, withoutAccount, currentNote.blocks?.noteTitle])

  return data ? (
    <Fragment>
      {data?.name && <Helmet><title>{data.name}</title></Helmet>}
      <div id='account-header'>
        {!withoutAccount && 
        <>
          <UserAvatar size={28} fontRatio={0.5} website={data?.website} name={data?.name} />
          <div className={`activity-dot ${account?.status?.toLowerCase() === 'active' ? 'is-active' : ''}`}></div>
        </>}
        {data?.name || (withoutAccount && <div className='placeholder'>Untitled Thread</div>)}
        <HoverableIcon type='far' icon='star' active={isStarred} onClick={onStarClick} />
      </div>
    </Fragment>
  ) : <div style={{ color: '#bbb' }}>Loading...</div>
}