/* eslint-disable react-hooks/rules-of-hooks */

import { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Skeleton from "react-loading-skeleton"
import MomentHeader from "./MomentHeader"
import MomentHeadline from "./MomentHeadline"
import RenderBlock from "./RenderBlock"
// import { TextEditorWithHistory } from "../../../components/TextEditor"
// import { defaultValue } from "../../../components/TextEditor/withHistory"
import useMomentAnimation from "../helpers/useMomentAnimation"
// import UserAvatar from "../../../components/UserAvatar"
// import { modifyMomentNotes, modifyMomentNotesSuccess, setModifyingNote, updateNoteState, fetchMemberStatusBar } from "../actions"
// import { setToastMessage } from "../../App/actions"
// import MomentNotes from "./MomentNotes"
// import { BlueButton } from "../../../components/Button"
// import serialize from "../../../components/TextEditor/serialize"
// import { trackMixpanelEvents } from '../../../helpers/mixpanel'

const Moment = (animateActions, showActions = true) => ({ doResize, index, ...moment }) => {
  // const dispatch = useDispatch()
  // const currentMember = useSelector(state => {
  //   const member = state.global.workspaceMembers.find(m => m.id === state.global.currentMember.id)
  //   if(member)
  //     return member
    
  //   //Temporary workaround for admin user scenario
  //   const { name, firebase_owner_uid: id } = state.global.workspace
  //   return { id, name }
  // })
  
  const ref = useRef()
  const { skeleton, id, blocks } = moment
  const style = useMomentAnimation({ ref, moment, doResize })

  // const [editorFocused, setEditorFocused] = useState(false)
  // const [value, setValue] = useState(defaultValue())
  // const modifyingNote = useSelector(state => state.moments.modifyingNote !== null)

  // const noteState = useSelector(state => state.moments.noteState[moment.id])
  // useEffect(() => {
  //   if(noteState)
  //       setValue(noteState)
  // }, [noteState])

  // useEffect(() => {
  //   doResize()
  //   if(editorFocused && modifyingNote)
  //     dispatch(setModifyingNote(null))
  // }, [editorFocused])

  // const addNote = useCallback(async e => {
  //   if(!serialize(value).length)
  //     return

  //   const response = await modifyMomentNotes({ author_id: currentMember.id, moment, note: value })
  //   if(!response.error){
  //     trackMixpanelEvents(`moments_added_note`)

  //     dispatch(modifyMomentNotesSuccess(index, response))
  //     dispatch(updateNoteState(id))
  //     if(!moment.notes.length)
  //       dispatch(fetchMemberStatusBar())
  //   }else{
  //     dispatch(setToastMessage('Something went wrong while trying to add your note. Please try again', 'error'))
  //   }
  // }, [value, currentMember, moment])

  return (
    <div className='moments-item card' style={style} ref={ref}>
      {showActions && <MomentHeader {...{ moment, animateActions }} />}
      <MomentHeadline {...moment} />
      <div className='moment-content'>
        {!skeleton
          ? blocks?.map((block, index) => <RenderBlock key={index} momentId={id} accountId={moment.identity_id} {...{ ...block, index, showActions }} />)
          : <div style={{ width: '100%', marginTop: 0 }}>{new Array(3).fill(true).map((_, index) => <Skeleton key={index} />)}</div>
        }
      </div>
      {/* <MomentNotes momentIndex={index} {...{ moment, doResize }} /> */}
      {/* <div className='moment-footer'>
        <UserAvatar
          size={28}
          name={currentMember?.name}
          fontRatio={0.5} 
        />
        <TextEditorWithHistory
          {...{ value, setValue }}
          placeholder="Add a note to this moment…"
          isFocused={editorFocused}
          setIsFocused={setEditorFocused}
          onResize={doResize}
          onSubmit={addNote}
          onBlur={() => dispatch(updateNoteState(id, value))}
          submitButton={<BlueButton padding='8px 12px'>Add Note</BlueButton>}
          toolbarMode='hide_on_blur'
        />
      </div> */}
    </div>
  )
}

export default Moment