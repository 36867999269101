import { useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { applyAction, addNotes } from "../actions"
import deserialize from "../../../components/TextEditor/deserialize"
import RenderNode from "../../../components/TextEditor/RenderNode"
import { CircularProgress } from "@material-ui/core"

const emojiTranslation = {
  ':slightly_smiling_face:': '🙂',
  ':expressionless:': '😑',
  ':white_frowning_face:': '☹️',
  ':bell:': '🔔'
}

const replaceEmoji = text => {
  Object.keys(emojiTranslation).forEach(key => text = text.replace(key, emojiTranslation[key]))
  return text
}

function RenderBlock({ type, payload, text, elements, index, momentId, accountId, showActions }){
  switch(type){
    case 'section': {
      if(text.type === 'mrkdwn'){
        const content = deserialize(text.text)
        return content.map((node, index) => <RenderNode {...node} key={index} />)
      }

      if(text.type !== 'plain_text')
        return null

      return <div>{replaceEmoji(text.text)}</div>
    }
    case 'actions': {
      // The condition here is a workaround for when the moment is displayed inside a gist, in which case add_notes button is irrelevant
      return showActions || elements[0]?.action_id !== 'add_notes' ? (
        <div>
          {elements.map(elem => <RenderActionElem key={elem.action_id} {...{ ...elem, momentId, accountId, payload }} blockIndex={index} />)}
        </div>
      ) : null
    }
    default:
      return null
  }
}

function RenderActionElem({ type: elemType, text, action_id, payload, accountId, ...actionProps }) {
  const [loading, setLoading] = useState(false) // Loading state was added especially for add_notes action
  const history = useHistory()
  const dispatch = useDispatch()

  const doAction = useMemo(() => {
    switch(action_id){
      case 'finish_onboarding':
        return () => history.push('/apps/all')
      case 'show_history': // Needs to be removed!
        return () => {}
      case 'add_notes':
        return async () => {
          setLoading(true)
          const { error } = await dispatch(addNotes(actionProps))
          if(error)
            setLoading(false)
        }
      default:
        return () => dispatch(applyAction({ actionId: action_id, ...actionProps }))
    }
  }, [action_id])

  if(elemType !== 'button' || text.type !== 'plain_text')
    return null
  
  const withEmoji = replaceEmoji(text.text)
  const isEmoji = Object.values(emojiTranslation).includes(withEmoji)
  const isSelected = payload?.selected === action_id

  return (
    <button onClick={doAction} className={isSelected ? 'is-selected' : ''}>
      {!loading 
        ? (!isEmoji ? text.text : <span>{withEmoji}</span>)
        : <CircularProgress size={14} color='inherit' style={{ transform: 'translateY(3px)', margin: '0 24px'}} />
      }
    </button>
  )
}

export default RenderBlock