import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/functions';

const config = {
  apiKey: 'AIzaSyBgNZ6CZXlB8fSd6XlS_yb6roZWrInUvUA',
  authDomain: 'plantt-console.firebaseapp.com',
  databaseURL: 'https://plantt-console-b7694.firebaseio.com/',
  projectId: 'plantt-console',
  storageBucket: 'plantt-console-uploads',
  messagingSenderId: '39958656149',
  appId: '1:39958656149:web:e54e3ad135cdc7a2f7979c',
  measurementId: 'G-Q3YQF7EPWW',
};

/* TODO - this is basically debug code */
let initializedApp = null;

class Firebase {
  constructor() {
    if (initializedApp === null) {
      app.initializeApp(config);
      initializedApp = app;
    }
    this.db = app.firestore();
    this.database = app.database();
    this.auth = app.auth();
    this.firebaseAuth = app.auth;
    this.functions = app.functions();
    this.storage = app.storage();
  }
}

export default Firebase;
