import { useContext } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import LoginContext from '../LoginContext'
import useLogin from '../useLogin'

import Logo from '../../../images/logo.svg'
import PlanttBee from '../../../images/PlanttBee.svg'
import '../style.scss'

function EmailSent() {
  const handleLogin = useLogin({ isResend: true })
  const location = useLocation()
  const isSignup = location.search === '?signup'

  const { state } = useContext(LoginContext)

  if(!state.email)
    return <Redirect to='/' />

  return (
    <div>
      <img src={Logo} id='sign-in-logo' />
      <h1 className='is-signup'>Check Your Email</h1>
      <h2>
        We have sent you a magic link to <a href={`mailto: ${state.email}`}>{state.email}</a> to {!isSignup ? 'login' : 'signup'} securely
      </h2>
      <div id='email-sent-info'>
        Can’t find your link? Check your spam folder, or <span id='resend' onClick={handleLogin}>resend</span>.
      </div>
      <div id='sign-in-bottom'>
        <img src={PlanttBee} id='sign-in-plantt-bee' />
      </div>
    </div>
  )
}

export default EmailSent