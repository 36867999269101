import { produce, original } from 'immer'
import { isEqual } from 'lodash'
import {
  SET_FOCUSED_EDITOR,
  SET_CURRENT_NOTE_FROM_SNAPSHOT,
  SET_TASKS_FROM_SNAPSHOT,
  COMMIT_FIREBASE_UPDATE,
  COMMIT_FIREBASE_REMOVE,
  COMMIT_FIREBASE_SET,
  CLEAR_NOTES_AND_TASKS,
  SET_EXISTING_TAGS,
  ADD_TO_EXISTING_TAGS,
  SET_DOCK_DATA,
  CLEAR_DOCK_DATA,
  APPLY_OBJECT_UPDATE,
  SET_TEMPLATES_FROM_SNAPSHOT,
  FETCH_DEFAULT_TEMPLATES_SUCCESS,
  SET_NOTE_LIST_FROM_SNAPSHOT,
  SET_ACTION_IN_MODAL,
  UPDATE_CURRENT_NOTE_SELECTION,
  SET_CURRENT_VIEWER,
  SET_IN_OVERLAY,
  SET_IS_DRAGGING,
  SET_NOTE_CARET_POSITION,
  SET_ACTIVE_COLLABORATORS,
} from './constants'

export const initialState = () => ({
  currentNote: {},
  blockOrder: [],
  selection: [],
  noteList: { pathname: null, items: [[]] },
  tasks: {},
  loaded: false,
  focusedEditor: null,
  currentTemplate: {},
  existingTags: null,
  dockData: {},
  templates: {},
  defaultTemplates: [],
  actionInModal: null,
  currentViewer: {},
  inOverlay: null,
  isDragging: null,
  activeCollaborators: []
})

const notesReducer = produce((draft, action) => {
  const setBlockOrder = () => {
    const { blocks } = draft.currentNote
    if(!blocks)
      return

    const order = ['noteTitle']
    let { next_block } = blocks.noteTitle
    while(next_block){
      order.push(next_block)
      next_block = blocks[next_block].next_block
    }

    if(!isEqual(order, original(draft.blockOrder))) // isEqual is here to optimize performance... It's a sort of memo technique
      draft.blockOrder = order
  }
  // const newLoading = loading => ({ loading: { ...state.loading, ...loading } })
  const getRefPath = () => {
    const path = action.ref.split('/')
    if(path.length === 1)
      return path

    return path.slice(path[2] === 'templates' ? 2 : 4)
  }
  const getByPath = (path = getRefPath()) => {
    let obj = draft.currentNote
    if(path[0] === 'templates'){
      obj = draft.templates
      path.shift()
    }

    while(path.length > 1){
      const key = path.shift()
      if(['collaborators', 'reactions'].includes(key) && !obj[key])
        obj[key] = {}
      obj = obj[key]
    }

    return { obj, key: path[0] }
  }

  switch(action.type){
    case CLEAR_NOTES_AND_TASKS: {
      Object.assign(draft, { currentNote: {}, tasks: {} })
      draft.selection = [] //reset selection
      return
    }
    case SET_NOTE_CARET_POSITION: {
      draft.caretPosition = action.position
      return
    }
    case UPDATE_CURRENT_NOTE_SELECTION: {
      draft.selection = action.selection
      return
    }
    case FETCH_DEFAULT_TEMPLATES_SUCCESS: {
      draft.defaultTemplates = action.templates.filter(template => !template.account_id) //FILTER IS TEMP
      return
    }
    case SET_TEMPLATES_FROM_SNAPSHOT: {
      draft.templates = action.templates
      return
    }
    case SET_CURRENT_NOTE_FROM_SNAPSHOT: {
      Object.assign(draft.currentNote, action.note)
      draft.loaded = true
      setBlockOrder()
      return
    }
    case SET_NOTE_LIST_FROM_SNAPSHOT: {
      draft.noteList = { pathname: action.pathname, items: action.items }
      return
    }
    case SET_TASKS_FROM_SNAPSHOT: {
      draft.tasks = action.tasks
      return
    }
    case SET_FOCUSED_EDITOR: {
      draft.focusedEditor = action.blockId
      return
    }

    case COMMIT_FIREBASE_UPDATE: {
      const refPath = getRefPath()
      Object.entries(action.value).forEach(([path, value]) => {
        path = refPath.concat(path.split('/').filter(s => Boolean(s)))

        if(path[1] === 'tasks')
          return
        else if(action.metadata?.origin.includes('CheckListTask'))
          path = path.slice(3)

        try {
          const { obj, key } = getByPath(path)
          if(value !== null)
            obj[key] = value
          else
            delete obj[key]
        } catch(error) {
          console.warn('The following error is a temporary workaround!')
          console.error('Something went wrong with a firebase update', error)
        }
      })
      setBlockOrder()
      return
    }
    case COMMIT_FIREBASE_REMOVE: {
      const { obj, key } = getByPath()
      delete obj[key]
      setBlockOrder()
      return
    }
    case COMMIT_FIREBASE_SET: {
      const { obj, key } = getByPath()
      if(typeof key === 'undefined')
        return
        
      if(action.value !== null)
        obj[key] = action.value
      else
        delete obj[key]
      setBlockOrder()
      return
    }

    case SET_EXISTING_TAGS: {
      draft.existingTags = action.tags
      return
    }

    case ADD_TO_EXISTING_TAGS: {
      const index = draft.existingTags.findIndex(tag => tag === action.tag)
      if(index < 0)
        draft.existingTags.push(action.tag)
      return
    }

    case SET_DOCK_DATA: {
      draft.dockData[action.specRef] = action.data
      return
    }

    case CLEAR_DOCK_DATA: {
      draft.dockData = {}
      return
    }

    case APPLY_OBJECT_UPDATE: {
      const { specRef, blockIndex, objectIndex, object, changes } = action.updateData
      draft.dockData[specRef].blocks[blockIndex].objects[objectIndex] = action.rollback ? object : { ...object, ...changes }
      return
    }

    case SET_ACTION_IN_MODAL: {
      draft.actionInModal = action.action
      return
    }

    case SET_CURRENT_VIEWER: {
      draft.currentViewer = action.viewer
      return
    }

    case SET_IN_OVERLAY: {
      draft.inOverlay = action.inOverlay
      return
    }

    case SET_IS_DRAGGING: {
      draft.isDragging = action.blockId
      return
    }

    case SET_ACTIVE_COLLABORATORS: {
      if(action.action === 'add'){
        draft.activeCollaborators.push(action.id)
      }else if(action.action === 'delete'){
        const index = original(draft.activeCollaborators).findIndex(id => id === action.id)
        draft.activeCollaborators.splice(index, 1)
      }
      return
    }

    default:
      return
  }
}, initialState())

// function notesReducer(state = initialState(), action) {
//   const setBlockOrder = updatedState => {
//     const { blocks } = updatedState.currentNote
//     if(!blocks)
//       return

//     const order = ['noteTitle']
//     let { next_block } = blocks.noteTitle
//     while(next_block){
//       console.log({ blocks, next_block })
//       order.push(next_block)
//       next_block = blocks[next_block].next_block
//     }

//     if(!isEqual(order, updatedState.blockOrder)) // isEqual is here to optimize performance... It's a sort of memo technique
//       updatedState.blockOrder = order
//   }

//   const getRefPath = () => {
//     const path = action.ref.split('/')
//     if(path.length === 1)
//       return path

//     return path.slice(path[2] === 'templates' ? 2 : 4)
//   }

//   const setByPath = ({ path = getRefPath(), updatedState, value = null }) => {
//     const property = path[0] === 'templates' ? 'templates' : 'currentNote'
//     if(path[0] === 'templates')
//       path.shift()
  
//     if(updatedState[property] === state[property])
//       updatedState[property] = { ...state[property] }
  
//     let updatedObj = updatedState[property]
//     let currObj = state[property]
  
//     while(path.length > 1){
//       const key = path.shift()
//       if(updatedObj[key] === currObj[key])
//         updatedObj[key] = { ...currObj[key] }
//       updatedObj = updatedObj[key]
//       currObj = currObj[key]
//     }
  
//     const key = path[0]
//     if(typeof key === 'undefined')
//       return
//     if(value === null)
//       return delete updatedObj[key]
  
//     updatedObj[key] = value
//   }

//   switch(action.type){
//     case CLEAR_NOTES_AND_TASKS: {
//       return { ...state, currentNote: {}, task: {}, selection: [] }
//     }
//     case UPDATE_CURRENT_NOTE_SELECTION: {
//       return { ...state, selection: action.selection }
//     }
//     case FETCH_DEFAULT_TEMPLATES_SUCCESS: {
//       const defaultTemplates = action.templates.filter(template => !template.account_id) //FILTER IS TEMP
//       return { ...state, defaultTemplates }
//     }
//     case SET_TEMPLATES_FROM_SNAPSHOT: {
//       return { ...state, template: action.templates }
//     }
//     case SET_CURRENT_NOTE_FROM_SNAPSHOT: {
//       const updatedState = { ...state, currentNote: { ...state.currentNote, ...action.note }, loaded: true }
//       setBlockOrder(updatedState)
//       return updatedState
//     }
//     case SET_NOTE_LIST_FROM_SNAPSHOT: {
//       return { ...state, noteList: { pathname: action.pathname, items: action.items } }
//     }
//     case SET_TASKS_FROM_SNAPSHOT: {
//       return { ...state, tasks: action.tasks }
//     }
//     case SET_FOCUSED_EDITOR: {
//       return { ...state, focusedEditor: action.blockId }
//     }

//     case COMMIT_FIREBASE_UPDATE: {
//       const refPath = getRefPath()
//       const updatedState = { ...state }
//       Object.entries(action.value).forEach(([path, value]) => {
//         path = refPath.concat(path.split('/').filter(s => Boolean(s)))
//         if(path[1] === 'tasks')
//           return
//         else if(action.metadata?.origin.includes('CheckListTask'))
//           path = path.slice(3)

//         setByPath({ path, updatedState, value })
//       })
//       setBlockOrder(updatedState)
//       return updatedState
//     }

//     case COMMIT_FIREBASE_REMOVE:
//     case COMMIT_FIREBASE_SET: {
//       const updatedState = { ...state }
//       setByPath({ updatedState, value: action.type !== COMMIT_FIREBASE_REMOVE ? action.value : null })
//       setBlockOrder(updatedState)
//       return updatedState
//     }

//     case SET_EXISTING_TAGS: {
//       return { ...state, existingTags: action.tags }
//     }

//     case ADD_TO_EXISTING_TAGS: {
//       const index = state.existingTags.findIndex(tag => tag === action.tag)
//       if(index >= 0)
//         return state

//       return { ...state, existingTags: [...state.existingTags, action.tag] }
//     }

//     case SET_DOCK_DATA: {
//       return { ...state, dockData: { ...state.dockData, [action.specRef]: action.data} }
//     }

//     case CLEAR_DOCK_DATA: {
//       return { ...state, dockData: {} }
//     }

//     // case APPLY_OBJECT_UPDATE: {
//     //   const { specRef, blockIndex, objectIndex, object, changes } = action.updateData
//     //   draft.dockData[specRef].blocks[blockIndex].objects[objectIndex] = action.rollback ? object : { ...object, ...changes }
//     //   return
//     // }

//     case SET_ACTION_IN_MODAL: {
//       return { ...state, actionInModal: action.action }
//     }

//     case SET_CURRENT_VIEWER: {
//       return { ...state, currentViewer: action.viewer }
//     }

//     case SET_IN_OVERLAY: {
//       return { ...state, inOverlay: action.inOverlay }
//     }

//     case SET_IS_DRAGGING: {
//       return { ...state, isDragging: action.blockId }
//     }

//     default:
//       return state
//   }
// }

export default notesReducer