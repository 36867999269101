import { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Scrollable from '../Scrollable'

import { MenuOpen, DonutSmall, Dashboard, Brush, QuestionAnswerRounded, ViewCompact, PersonAdd, KeyboardArrowRightRounded } from '@material-ui/icons'
import QuickInviteModal from './QuickInviteModal'
import { Fragment } from 'react'
import useToggle from '../Toggle/useToggle'
import WorkspaceMenu from './WorkspaceMenu'
import Search from './Search'
import { createSelector } from 'reselect'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { segmentsSelector } from '../../containers/Accounts/partials/RenderNav'
import { useCurrentSegment } from '../../containers/Accounts/helpers'

function AccountsMenu(){
  const watchers = useSelector(state => state.global.watchers)
  const segments = useSelector(segmentsSelector)
  const currentSegment = useCurrentSegment()
  const location = useLocation()
  const current = location.pathname + location.search
  const loading = false

  return (
    <>
      <div className='menu-separator' style={{ margin: '12px auto 0' }} />
      <Scrollable style={{ maxHeight: 200 }}>
          <ToggleSection openByDefault={false} name='Watchers' items={watchers} {...{ current, loading }} getName={watcher => watcher.name} getPath={watcher => `watchers/view/${watcher.id}`} icon='telescope' />
          <ToggleSection openByDefault={false} name='Groups' items={segments} {...{ current, loading }} getName={group => group.text} getPath={group => `accounts?segment=${group.text}`} icon='pizza-slice' selected={({ text }) => text === currentSegment} />
      </Scrollable>
      <div className='menu-separator' style={{ margin: '12px auto' }} />
    </>
  )
}

function LeftMenu({ expanded, closing, toggleMenu, transitionLength, logoutOnly, layoutHeight, menuWidth }){
  const compactDisplay = useSelector(state => state.global.dimensions.compactDisplay)
  const workspace = useSelector(state => state.global.workspace)
  const hasApps = useSelector(state => Boolean(state.global.connectors.length))
  const role = workspace.role
  const location = useLocation()
  const current = location.pathname + location.search

  const menuPages = useMemo(() => {
    const adminOnly = true
    const appsAndTasks = [
      { name: 'Apps', icon: 'puzzle-piece', path: hasApps ? 'apps' : 'apps/all' },
      { name: 'Tasks', icon: 'tasks', path: 'tasks/by-date' }
    ]
    const pages = {
      chat_bot:  [
        { name: 'Discover', Icon: Dashboard },
        { name: 'Conversations', Icon: QuestionAnswerRounded },
        { name: 'Intents', Icon: DonutSmall },
        { name: 'Editor', Icon: Brush },
        { name: 'Temp Dashboard', Icon: ViewCompact, path: 'dashboard', adminOnly }
      ],
      customer_success: [
        { name: 'Moments', icon: 'inbox' },
        ...appsAndTasks,
        { name: 'Activity', icon: 'server', adminOnly },
      ],
      lite: [
        { name: 'Threads', icon: 'layer-group', isSelected: location.pathname === '/' },
        { name: 'Starred', icon: 'star' },
        ...appsAndTasks
      ]
    }
    pages.beta = pages.customer_success

    // if(compactDisplay && (location.pathname.startsWith('/accounts') || location.pathname.startsWith('/watchers/view')))
    //   pages.customer_success.splice(2, 0, { id: 'accounts-menu', Component: AccountsMenu })

    return pages
  }, [location.pathname, compactDisplay, hasApps])

  const [inviteOpen, setInviteOpen] = useState(false)

  const [maxHeights, setMaxHeights] = useState({ scrollable: null })
  const topRef = useRef()
  const inviteRef = useRef()
  useEffect(() => {
    if(topRef.current && inviteRef.current)
      setMaxHeights({ scrollable: `calc(${layoutHeight} - ${topRef.current.offsetHeight}px - ${inviteRef.current.offsetHeight}px - 2px)` })
  }, [topRef.current, inviteRef.current])

  return (
    <>
      <div style={{ position: 'absolute', backgroundColor: '#000', opacity: 0, transition: 'opacity 0.2s', ...(compactDisplay && expanded && !closing ? { width: '100vw', height: '100vh', opacity: 0.3, zIndex: 1004 } : {}) }} />
      <div
        className={`plantt-menu-container ${expanded && !closing ? 'expanded' : ''}`}
        style={{
          transition: `width ${transitionLength / 1000}s`,
          width: menuWidth,
          ...(compactDisplay ? { position: 'absolute', left: 0, zIndex: 1004, borderRight: '1px solid #979797' } : {})
        }}
      >
        <div id='plantt-menu' style={{ width: 220 }}>
          <div ref={topRef}>
            <div id='menu-header'>
              <WorkspaceMenu logoutOnly={logoutOnly} />
              <MenuOpen id='close-menu' onClick={toggleMenu} />
            </div>
            <div className='menu-separator' />
            {['beta', 'customer_success'].includes(role)
              ? <>
                  <Search />
                  <RenderAccounts {...{ location, current }} />
                </>
              : <div style={{ padding: 6 }} />}
            {workspace && menuPages[role].map(page => (
              !page.Component ? <MenuLink key={page.name} {...{ ...page, current }} /> : <page.Component key={page.id} />
            ))}
            <div className='menu-separator' style={{ margin: '16px auto 0' }} />
          </div>
          <Scrollable style={{ height: maxHeights.scrollable }}>
            {!['lite', 'chat_bot'].includes(role) && <AccountsDisplay {...{ current }} />}
          </Scrollable>
          <div ref={inviteRef}>
            <div className='menu-separator' />
            <MenuLink className='invite-teammates' name='Invite teammates' Icon={PersonAdd} onClick={() => setInviteOpen(true)} {...{ current }} />
            <QuickInviteModal open={inviteOpen} setOpen={setInviteOpen} />
          </div>
        </div>
      </div>
    </>
  )
}

const accountsSelector = createSelector(
  state => {
    const { global: { workspaceMembers, currentMember, menuAccounts, loading }, accounts } = state
    const member = workspaceMembers.find(m => m.id === currentMember.id)
    return { member, accounts: menuAccounts, loading: loading.menuAccounts, currentAccount: accounts?.currentAccount || {} }
  },
  ({ member, accounts, loading, currentAccount }) => {
    //Matching structure to account structure
    let starred = member?.starred_identities.map(({ identity_id, name }) => ({ identity: { id: identity_id, fields: { name } } })) || []
    
    if(Object.keys(currentAccount).length && !accounts.find(a => a.identity.id === currentAccount.id))
      accounts = [ { identity: currentAccount }, ...accounts ]
    accounts = accounts.filter(account => !starred.find(s => s.identity.id === account.identity.id))

    return { starred, accounts, loading }
  }
)

function AccountsDisplay({ current }){
  const { starred, accounts, loading } = useSelector(accountsSelector)
  
  const getName = account => account.identity?.fields.name
  const getPath = account => `account/${account.identity?.id}`
  const icon = 'layer-group'

  return (
    <Fragment>
      <SkeletonTheme color='#444' highlightColor='#555'>
        <ToggleSection name='Starred' items={starred} {...{ current, loading, getName, getPath, icon }} />
        <ToggleSection name='Accounts' items={accounts} {...{ current, loading, getName, getPath, icon }} openByDefault={true} />
      </SkeletonTheme>
    </Fragment>
  )
}

function ToggleSection({ name, current, items = [], loading, getName, getPath, icon, openByDefault = true, selected = () => {} }){
  const [open, setOpen] = useState(openByDefault)
  let { toggleItem, isOpen, contentProps } = useToggle({ open, setOpen, transitionLen: 500 })

  if(loading)
    items = new Array(3).fill({})

  return items.length || loading ? (
    <Fragment>
      <div className='menu-section-title' onClick={toggleItem}>
        <KeyboardArrowRightRounded className={isOpen ? 'is-open' : undefined} />
        {!loading ? name : <Skeleton width={152} />}
      </div>
      <div className='menu-section-content' {...contentProps}>
        <div>
          {items.map((item, index) => {
            const name = getName(item)
            const truncated = name?.slice(0, 16)
            
            return (
              <MenuLink
                key={index}
                name={name === truncated ? name : truncated + '...'}
                icon={icon}
                path={!loading ? getPath(item) : undefined}
                isSelected={current.startsWith(getPath(item)) || selected(item)}
                {...{ current, loading }}
              />
            )
          })}
        </div>
      </div>
    </Fragment>
  ) : null
}

function RenderAccounts({ location, current }){
  const groups = useSelector(state => state.global.segments)
  const currentGroup = useCurrentSegment()
  let { toggleItem, isOpen, contentProps } = useToggle({ transitionLen: 300 })

  const locationStartsWith = str => location.pathname.startsWith(str)
  const isSelected = (!isOpen || currentGroup === 'All Accounts') && (locationStartsWith('/watchers') || locationStartsWith('/accounts'))
    ? 'is-selected'
    : ''

  const hasGroups = groups.length > 1;

  return (
    <>
      <Link to='/accounts' className={`menu-link ${isSelected} ${hasGroups ? 'accounts' : ''}`}>
        <i className='far fa-layer-group' />
        <span className='menu-link-text'>
          Accounts
          {hasGroups &&
          <div className='toggle-arrow-wrapper' onClick={e => { e.preventDefault(); toggleItem() }}>
            <i className={`fas fa-chevron-down ${isOpen ? 'is-open' : ''}`} />
          </div>}
        </span>
        <div></div>
      </Link>
      {hasGroups &&
      <div {...contentProps} className='account-groups-display'>
        <div>
          {groups.filter(g => g.name !== 'All Accounts').map(({ name, id }) => (
            <MenuLink
              key={id}
              icon='pizza-slice'
              path={`accounts?${btoa(`segment=${name}`)}`}
              isSelected={currentGroup === name}
              {...{ name, current }}
            />
          ))}
        </div>
      </div>}
    </>
  )
}

function MenuLink({ current, path, name = '', icon, Icon, adminOnly, isSelected, showSoon, onClick, className = '', loading = false }){
  const isAdmin = useSelector(state => state.global.currentMember.type === 'admin')

  isSelected = isSelected || (name && current.split('/')[1].split('?')[0] === name.toLowerCase()) || current === `/${path}` ? 'is-selected' : ''

  const Component = useMemo(() => {
    return !onClick
      ? ({ children }) => <Link to={`/${path || name.toLowerCase()}`} className={`menu-link ${isSelected} ${className}`}>{children}</Link>
      : ({ children }) => <div className={`menu-link ${isSelected} ${className}`} onClick={onClick}>{children}</div>
  }, [onClick, path, name, isSelected])

  if(adminOnly && !isAdmin)
    return null

  return !loading 
    ? <Component>
        {Icon
          ? <Icon />
          : <i className={icon.includes(' ') ? icon : `far fa-${icon}`}></i>
        }
        <span className='menu-link-text'>{name}</span>
        {showSoon && <span className='coming-soon'>Soon!</span>}
      </Component>
    : <Component><Skeleton width={120} /></Component>
}

export default LeftMenu