import  { useState, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom'
import { createSelector } from 'reselect';

import { deleteConnector } from './actions'
import useAddApp from './useAddApp'

import { useLayoutConfig } from '../../components/PlanttLayout/LayoutConfig';
import ConfirmModal from '../../components/ConfirmModal';
import Scrollable from '../../components/Scrollable';
import { OrangeButton } from '../../components/Button';
import AppStatus from './AppStatus';
import { trackMixpanelEvents } from '../../helpers/mixpanel'
import SubNav from '../../components/PlanttLayout/SubNav';
import PlanttFlowerDiagram from '../../images/PlanttFlowerDiagram.svg'

const appsSelector = createSelector(
    state => state.global,
    ({ connectors, connectorSpecs }) => {
        const apps = connectors.map(app => {
            const appSpec = connectorSpecs.find(spec => spec.id === app.spec_ref)
            return { ...app, icon_url: appSpec?.icon_url, description: appSpec?.description }
        })
        return { apps, appSpecs: connectorSpecs }
    }
)

function AppsList() {
    const { all } = useParams()
    const dispatch = useDispatch()

    useLayoutConfig({ title: 'Apps' })
    const { apps, appSpecs } = useSelector(appsSelector)

    const [appToDelete, setAppToDelete] = useState(null)
    const handleDelete = (event, appId) => {
        event.stopPropagation()
        setAppToDelete(appId)
    }
    const confirmDelete = async () => {
        trackMixpanelEvents("connectors_deleted_connector")
        await dispatch(deleteConnector(appToDelete))
        setAppToDelete(null)
    }

    const containerRef = useRef()

    const navSpec = useMemo(() => {
        return [
            {
              items: [ { text: 'Installed Apps' } ],
              icon: 'puzzle-piece',
              target: () => '',
              noTruncation: true
            },
            {
              items: [ { text: 'All Apps' } ],
              icon: 'puzzle-piece',
              target: () => '/all',
              noTruncation: true,
              selected: () => all
            }
          ]
    }, [all])

    return (
        <div id='app-pages'>
            <SubNav targetPrefix='/apps' navSpec={navSpec} />
            <Scrollable id="apps-list" ref={containerRef}>
                <div className="apps-list-section">
                    {(!all ? apps : appSpecs).map(app => <AppCard key={app.id} {...{ app, handleDelete }} isInstalled={!all} />)}
                    {!all && !apps.length && <EmptyState />}
                </div>
            </Scrollable>
            <ConfirmModal
                open={Boolean(appToDelete)}
                onClose={() => setAppToDelete(null)}
                onConfirm={confirmDelete}
                text='Are you sure you would like to delete this app?'
            />
        </div>
    )
}

const comingSoon = ['freshdesk'] //TEMP hardcoded list
function AppCard({ app, handleDelete, isInstalled = false }){
    const history = useHistory()
    const { name, description, id, records, icon_url, is_premium } = app
    const addApp = useAddApp()
    const isComingSoon = comingSoon.includes(id)

    return(
        <div className={`card ${isInstalled ? 'is-installed' : ''}`} onClick={isInstalled ? () => history.push(`/apps/view/${id}`) : undefined}>
            {!isInstalled && !isComingSoon &&
            <div className='add-app-overlay'>
                <OrangeButton rounded padding='8px 12px' onClick={() => addApp(id)}>
                    Add to Plantt
                </OrangeButton>
            </div>}
            <div className='app-card'>
                {!isInstalled && is_premium &&
                <div className='premium-token'><i className='fas fa-star'></i>Premium</div>}
                {isComingSoon &&
                <div className='coming-soon-token'><i className='fas fa-seedling'></i>Upcoming</div>}
                {isInstalled && <AppStatus connector={app} />}
                <img className="app-logo" src={icon_url} />
                <div className="app-text">
                    <div className="name">{name}</div>
                    <div className="description">{description}</div>
                </div>
            </div>
            {/* {records &&
            <div className="record-counts">
                ({Object.keys(records).map((key, index) => {
                    return `${index ? ', ' : ''}${upperFirst(key)}: ${records[key]}`
                })})
            </div>} */}
            {isInstalled && handleDelete &&
            <i className="fas fa-trash" onClick={(e) =>  handleDelete(e, id)} />}
        </div>
    )
}

function EmptyState(){
    return (
        <div id='apps-empty'>
            <h1>No apps installed yet</h1>
            <h2>Go to "All Apps" to connect your apps to Plantt</h2>
            <img src={PlanttFlowerDiagram} />
            <Link to='/apps/all'><OrangeButton rounded>All Apps</OrangeButton></Link>
        </div>
    )
}
export default AppsList