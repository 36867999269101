import { createContext, useContext, useEffect } from 'react'

const defaultConfig = () => ({ title: '', nonScrollable: false })

export const LayoutConfigContext = createContext({})

export function useLayoutConfig(config){

  const { layoutConfig, setLayoutConfig, contentRef, expanded } = useContext(LayoutConfigContext)
  useEffect(() => {
    if(layoutConfig && config){
      const newConfig = { ...defaultConfig(), ...config }
      let update = Object.keys(layoutConfig).length !== Object.keys(newConfig).length
      if(!update) for(const c in newConfig){
        if(layoutConfig[c] !== newConfig[c]){
          update = true
          break
        }
      }

      if(update)
        setLayoutConfig(newConfig)
    }
  }, [config])

  return { contentRef, expanded }
}