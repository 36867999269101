import { useSelector } from "react-redux";
import EmojiDisplay from "../partials/EmojiDisplay";
import { useBlockComponent } from "./MakeBlock";
// import RenderTags from "../GistEditor/partials/RenderTags"

function MakeBlock({ contactDetails, ...props }){
  const { blockId } = props
  const block = useSelector(state => state.notes.currentNote.blocks[blockId])
  const { type, payload } = block || {}

  const RenderBlock = useBlockComponent(type, true)

  if(!block || ['action', 'disabled-action', 'reminder'].includes(type) || (type === 'to-do-list' && payload.fields.length === 1))
    return null

  return (
    <>
      <div
        className={`notebook-note-block ${type !== 'basic' || payload.text ? 'is-hoverable' : ''}`}
        style={{ position: 'relative', display: 'flex', margin: `0 auto ${type !== 'title' ? '12px' : ''}` }}
      >
        <RenderBlock {...props} {...{ block, contactDetails }} />
        {block.reactions?.emoji && <EmojiDisplay blockId={blockId} emojiById={block.reactions.emoji} />}
      </div>
      {/* type === 'title' && <RenderTags readonly className='notebook-note-block' /> */}
    </>
  )
}

export default MakeBlock