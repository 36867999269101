
import { useSelector, useDispatch } from 'react-redux'
import { setAuthenticationStatus, setAuthenticationPageError } from '../actions'
import { useHistory } from 'react-router-dom'
function AuthenticationError( {connectorSpec} ) {
    const dispatch = useDispatch()
    const history  = useHistory()
    const authenticationError = useSelector(state => state.connectors.errors.authenticationPageError)
    const tryAgian = () => {
        dispatch(setAuthenticationStatus(0))
        dispatch(setAuthenticationPageError(''))
        if(connectorSpec.authentication.type != 'oauth2')
            history.go(-1)
        else 
            history.push('/apps/add/authenticate')
    }
    const openIntercom = () => {
        window.Intercom('show')
    }
    return(
       <div className="authentication-error-page">
           <div className="oops" >Oops...</div>
           <div className="content">
               <div className="title">
                   We ran into an error while adding your new app
               </div>
               <div className="red-error">
                   Authentication failed:  {authenticationError}.
               </div>
               <div className="list">
                   <ol className="sefi">
                    <li> Make sure you click "Allow" or "Accept" on the permission page</li>
                    <li className="item"> Double check your account has the correct permissions</li>
                    <li className="item"> Contact your account manager or leave us a message <span className="here" onClick={openIntercom}>here</span></li>
                   </ol>
               </div>
               <div className="footer">
                    <div className="dark-blue-button pointer" onClick={tryAgian}>
                        Try again
                    </div>
                    <div className="contact-us pointer" onClick={openIntercom}>
                        Contact support
                    </div>
               </div>
           </div>
       </div>
    )
}

export default AuthenticationError