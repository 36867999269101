
import { useRef, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { BlueButton } from "../Button"
import EmailBox from "../FormControls/EmailBox"
import FieldError from "../FormControls/FieldError"
import { Modal } from "@material-ui/core"
import { BASE_URL } from '../../apis/constant'
import { NEW_sendPostRequest, sendGetRequest } from '../../apis/api-utils'
import firebase from 'firebase/app';
import { setToastMessage } from '../../containers/App/actions'
import { ContentText } from '../../containers/Onboarding/items/InviteTeam'

function QuickInviteModal({ open, setOpen }){
  const [emails, setEmails] = useState([])
  const [errors, setErrors] = useState({ emailInvalid: false, noEmails: false })

  useEffect(() => {
    if(!open){
      setErrors({ emailInvalid: false, noEmails: false })
      if(!emails.length)
        setEmails([])
    }
  }, [open])

  const toggle = email => {
    const newEmails = [ ...emails ]
    const index = newEmails.findIndex(item => item.email === email)

    if(index < 0)
      newEmails.push({ email, error: false })
    else
      newEmails.splice(index, 1)

    setEmails(newEmails)
  }

  const noEmails = () => setErrors({ ...errors, noEmails: true })
  const { invite, inviting } = useInvite({ emails, setEmails, open, setOpen, noEmails })
  const emailsRef = useRef()

  return (
    <Modal
      id='quick-invite-modal'
      className='list-modal'
      open={open}
      onClose={() => setOpen(false)}
      disableEnforceFocus
    >
      <div className='modal-content'>
        <div className='content-header'>
          Invite your team members
        </div>
        <form className='content-container' onSubmit={invite}>
          <ContentText />
          <div ref={emailsRef}>
            Invite with email:
            <EmailBox
              displayed={open}
              emails={emails}
              onRemove={toggle}
              onSave={toggle}
              onFocus={() => setErrors({ ...errors, noEmails: false })}
              error={errors.emailInvalid}
              setError={value => setErrors({ ...errors, emailInvalid: value })}
              containerEl={emailsRef.current}
            />
            <FieldError containerEl={emailsRef.current} anchorEl={emailsRef.current} hasErrors={errors.noEmails}>
              Enter at least one email to proceed
            </FieldError>
          </div>
          <div>
            <BlueButton className='invite-button' loading={inviting}>
              Invite
            </BlueButton>
          </div>
        </form>
      </div>
    </Modal>
  )
}

const useInvite = ({ emails, setEmails, open, setOpen, noEmails }) => {
  const dispatch = useDispatch()
  const existingMembers = useSelector(state => state.global?.workspaceMembers)
  const [existingInvites, setExistingInvites] = useState([])
  const [inviting, setInviting] = useState(false)

  useEffect(async () => {
    if(open && !existingInvites.length){
      const invites = await sendGetRequest(`${BASE_URL}account/invite`)
      setExistingInvites(invites)
    }
  }, [open])

  const invite = useCallback(async e => {
    e.preventDefault()
    if(!emails.length)
      return noEmails()

    setInviting(true)

    const errors = {}
    const payload = []
    for(const item of emails){
      if(existingMembers.find(member => member.email === item.email))
        errors[item.email] = 'member'
      else if(existingInvites.find(invite => invite.email === item.email))
        errors[item.email] = 'invite'
      else if((await firebase.auth().fetchSignInMethodsForEmail(item.email) || []).length)
        errors[item.email] = 'system'
      else
        payload.push({ email: item.email, name: '' })
    }
  
    if(Object.keys(errors).length){
      const newEmails = [ ...emails ]
      for(const i in newEmails){
        const item = newEmails[i]
        if(errors[item.email])
          newEmails[i] = { ...item, error: errors[item.email] }
      }
      setInviting(false)
      return setEmails(newEmails)
    }

    try {
      const response = await Promise.all(payload.map(i => NEW_sendPostRequest(`${BASE_URL}account/invite`, {}, JSON.stringify(i))))
      const canBeRemoved = []
      for(const r in response)
        if(response[r].ok)
          canBeRemoved.push(payload[r].email)
  
      if(canBeRemoved.length){
        setOpen(false)
        dispatch(setToastMessage('Invites sent successfully', 'success'))
        setExistingInvites([])
        setEmails(emails.filter(({ email }) => !canBeRemoved.includes(email)))
      }

      if(canBeRemoved.length !== response.length)
        dispatch(setToastMessage('Not all invites were sent successfully', 'error'))
    } catch (error) {
      console.error('Failed to completed invite process: ', error);
    }
    setInviting(false)
  }, [emails, existingMembers, existingInvites])

  return { invite, inviting }
}

export default QuickInviteModal