import { useContext, useEffect, useRef } from 'react'
import app from 'firebase/app'
import GoogleIcon from '../../../images/google.svg';

import AuthContext from '../../App/AuthContext';
import { useLocation } from 'react-router-dom';

function GoogleSignIn() {
    const auth = useContext(AuthContext)
    const ref = useRef()
    const location = useLocation()
    const isSignup = location.pathname === '/signup'
    
    useEffect(() => {
        if(auth && ref.current)
            auth.attachClickHandler(ref.current, {}, handleSuccess, console.error)
    }, [auth, ref.current])

    const handleSuccess = async (googleUser) => {
        try {
            const credential = app.auth.GoogleAuthProvider.credential(googleUser.getAuthResponse().id_token)
            await app.auth().signInWithCredential(credential)
        }catch(error){
            console.error(error)
        }
    }

    return (
        <button id="new-google-sign-in" ref={ref}>
            <img src={GoogleIcon} /> Sign {!isSignup ? 'in' : 'up'} with Google
        </button>
    )
}
export default GoogleSignIn