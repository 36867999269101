import { useSelector } from "react-redux"
import currency from 'currency.js'

export const upperFirst = str => str?.length ? str[0].toUpperCase() + str.slice(1) : ''

export const idGen = () => Math.random().toString(36).substr(2, 9)

export const useBillingStatus = () => {
  const billing_status = useSelector(state => state.global.workspace?.metadata.billing_status)
  return { isTrial: billing_status === 'trial', isExpired: billing_status === 'trial_expired' }
}

export const pluralize = (value, condition) => condition ? `${value}s` : value
export const duration = minutes => {
  if(minutes < 60)
    return (minutes || 0) + ` ${pluralize('minute', minutes !== 1)}`
  
  const hours = Math.floor(minutes / 60)
  const remainder = ['¼', '½', '¾'][Math.round((minutes - (hours * 60)) / 15) - 1]

  return `${hours}${(hours <= 24 && remainder) || ''} ${pluralize('hour', remainder || hours > 1)}`
}

export const computeFieldValue = (type, value) => {
  switch(type){
    case 'currency': return currency(value || 0, { precision: 0 }).format()
    case 'duration': return duration(value)
    default:         return value
  }
}

export const getTrialMetadata = () => ({ billing_status: 'trial', trial_started: new Date().getTime() / 1000 })