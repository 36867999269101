import FirebaseContext, { withFirebase } from './context';
import Firebase from './firebase';
import { checkRegistration } from './checkRegistration';


export default Firebase;
export {
  FirebaseContext,
  withFirebase,
  checkRegistration,
};
