import {
  HOOK, 
  HOOK_SUCCESS, 
  HOOK_ERROR, 
  FETCH_ACCOUNT_INVITE_SUCCESS, 
  FETCH_ACCOUNT_TEAM_SUCCESS, 
  SET_ACCOUNT_INVITE_SUCCESS, 
  SET_INVITE_DIALOG_OPEN,
  FETCH_API_KEY,
  FETCH_API_KEY_SUCCESS,
  FETCH_API_KEY_ERROR,
  POST_API_KEY,
  POST_API_KEY_SUCCESS,
  POST_API_KEY_ERROR,
  FETCH_TEAM,
  FETCH_TEAM_SUCCESS,
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_ERROR,
  UPDATING_SETTINGS,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_ERROR,
  SET_SETTINGS_PROPERTY_BY_PATH,
  UPDATING_SETTINGS_DISMISSED,
  FETCH_INVOICES_SUCCESS,
  RESET_CHECKOUT_PLAN,
  SET_CHECKOUT_PLAN,
  SET_CHECKOUT_TOKEN,
  REPLACE_CREDIT_CARD_STATUS,
  PAYMENT_MEHTOD_DATA,
  SET_SUBSCRIPTION_ID
} from './constants';

import objectPath from 'object-path';
import { produce } from 'immer';

export const initialState = {
  loading: false,
  error: false,
  data: false,
  invitations: [],
  inviteDialogOpen: false,
  apiKeys: [],
  members: [],
  generalSettings: null,
  updatingSettings: false,
  invoices:[],
  checkoutPlan: {},
  checkoutToken: '',
  replaceCreditCardStatus: 0,
  paymentMethodData: {},
  subscription_id: '',
  loadingObj: { invitations: true, members: true }
};

const SettingReducer = produce((draft, action) => {
  switch (action.type) {
    case HOOK: {
      Object.assign(draft, {
        loading: true,
        error: false,
        data: false
      });
      return
    }
    case HOOK_SUCCESS: {
      Object.assign(draft, {
        loading: false,
        data: action.data
      });
      return
    }

    case HOOK_ERROR: {
      Object.assign(draft, {
        error: action.error,
        loading: false
      });
      return
    }

    case FETCH_ACCOUNT_INVITE_SUCCESS: {
      draft.invitations = action.invitation;
      draft.loadingObj.invitations = false;
      return
    }

    case SET_ACCOUNT_INVITE_SUCCESS: {
      Object.assign(draft, {
        inviteSucess: action.success,
        inviteDialogOpen: false
      });
      return
    }

    case SET_INVITE_DIALOG_OPEN: {
      draft.inviteDialogOpen = action.isOpen;
      return;
    }

    case FETCH_API_KEY: {
      draft.loading = true;
      return
    }
    case FETCH_API_KEY_SUCCESS: {
        Object.assign(draft, {
          loading: false,
          apiKeys: action.apiKeys
        });
        return
    }
    case FETCH_API_KEY_ERROR: {
      Object.assign(draft, {
        loading: false,
        error: action.error
      });
      return
    }
    case POST_API_KEY: {
      draft.loading = true;
      return
    }
    case POST_API_KEY_SUCCESS: {
      Object.assign(draft, {
        loading: false,
        apiKeys: action.apiKeys
      });
      return
    }
    case POST_API_KEY_ERROR: {
      Object.assign(draft, {
        loading: false,
        error: action.error
      });
      return
    }
    case FETCH_TEAM: {
      draft.loading = true;
      return
    }
    case FETCH_TEAM_SUCCESS: {
      draft.loadingObj.members = false
      draft.members = action.members
      return
    }

    case FETCH_SETTINGS_SUCCESS: {
      draft.generalSettings = action.settings;
      return
    }

    case FETCH_SETTINGS_ERROR: {
      draft.error = action.error;
      return
    }

    case UPDATING_SETTINGS: {
      draft.updatingSettings = true;
      return
    }

    case UPDATING_SETTINGS_DISMISSED: {
      draft.updatingSettings = false;
      return
    }

    case UPDATE_SETTINGS_SUCCESS: {
      draft.updatingSettings = 'succeeded';
      //draft.generalSettings = action.settings;
      return
    }

    case UPDATE_SETTINGS_ERROR: {
      draft.error = action.error;
      draft.updatingSettings = 'failed';
      return
    }

    case SET_SETTINGS_PROPERTY_BY_PATH: {
      const path = `generalSettings.${action.path}.value`
      objectPath.set(draft, path, action.value);
      return
    }
    case FETCH_INVOICES_SUCCESS: {
      draft.invoices = action.invoices
      return
    }
    case RESET_CHECKOUT_PLAN: {
      draft.checkoutPlan = {}
      return
    }
    case SET_CHECKOUT_PLAN: {
      draft.checkoutPlan = action.planData
      return
    }
    case SET_CHECKOUT_TOKEN: {
      draft.checkoutToken = action.token
      return
    }
    case REPLACE_CREDIT_CARD_STATUS: {
      draft.replaceCreditCardStatus = action.newStatus
      return
    }
    case PAYMENT_MEHTOD_DATA: {
      draft.paymentMethodData = action.data
      return
    }

    case SET_SUBSCRIPTION_ID: {
      draft.subscription_id = action.subscriptionId
      return
    }
    default:
        return
  }
}, initialState);

export default SettingReducer;
