import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddTeamMemberDialog from '../dialogs/AddTeamMemberDialog';
import { deleteAccountInvite, fetchAccountInvite, setInviteDialogOpen, fetchTeamMembers } from '../actions';
import { useLayoutConfig } from '../../../components/PlanttLayout/LayoutConfig';
import UserAvatar from '../../../components/UserAvatar';
import { BlueButton } from '../../../components/Button';
import ConfirmModal from '../../../components/ConfirmModal';
import LoadingText from '../../../components/LoadingIndicator/LoadingText';
import Scrollable from '../../../components/Scrollable';

function Teams() {
  const dispatch = useDispatch()
  useLayoutConfig({ title: 'Settings / Team' })

  const inviteDialogOpen = useSelector(state => state.setting.inviteDialogOpen)
  const members = useSelector(state => state.setting.members.users)
  const invitations = useSelector(state => state.setting.invitations);
  const loaded = useSelector(state => !state.setting.loadingObj.members && !state.setting.loadingObj.invitations)

  const [loading, setLoading] = useState(false)
  const [removeInvite, setRemoveInvite] = useState(null)

  useEffect(() => {
    dispatch(fetchTeamMembers())
    dispatch(fetchAccountInvite())
  }, [])

  const doRemoveInvite = async () => {
    setLoading(true)
    await dispatch(deleteAccountInvite(removeInvite.email))
    await dispatch(fetchAccountInvite())
    setLoading(false)
    setRemoveInvite(null)
  }

  return (
    <>
      {loaded
        ? <Scrollable id='team-settings'>
            <div>
              <h2>
                Team Members
                <BlueButton onClick={() => dispatch(setInviteDialogOpen(true))}>
                  <i className='far fa-plus'/> Invite
                </BlueButton>
              </h2>
              <div className='team-members-container'>
                {members && members.map(member => <AccountSection key={member.email} {...member} />)}
              </div>
              {invitations?.length > 0 &&
              <>
                <h2>
                  Invitations
                </h2>
                <div className='team-members-container'>
                  {invitations.map(member => (
                    <AccountSection key={member.email} {...member}>
                      <i className='fas fa-trash' onClick={() => setRemoveInvite(member)} />
                    </AccountSection>
                  ))}
                </div>
              </>}
            </div>
          </Scrollable>
        : <LoadingText id='team-loading' />
      }
      <AddTeamMemberDialog
        open={inviteDialogOpen}
        onClose={() => dispatch(setInviteDialogOpen(false))}
      />
      <ConfirmModal
        open={Boolean(removeInvite)}
        onClose={() => setRemoveInvite(null)}
        text={`Are you sure you would like to revoke ${removeInvite?.name || removeInvite?.email}'s invitation?`}
        loading={loading}
        onConfirm={doRemoveInvite}
      />
    </>
  )
}

function AccountSection({ email, name, children }) {
  return (
    <div className={`team-member-card card`}>
      <UserAvatar name={name} />
      <div>
        {name && <div className='member-name'>{name}</div>}
        {email && <div className='member-email'>{email}</div>}
      </div>
      {children}
    </div>
  )
}


export default Teams;
