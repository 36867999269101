import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { createSegment, deleteSegment } from '../actions'

import { setSegments, setToastMessage } from '../../App/actions'
import SubNav from '../../../components/PlanttLayout/SubNav'

import { CircularProgress } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { deleteWatcher } from '../actions'
import { getCurrentSegment, useCurrentSegment, useSearch } from '../helpers'
import { createSelector } from 'reselect'
import { groupPath } from '../helpers'
import { trackMixpanelEvents } from '../../../helpers/mixpanel'
export const segmentsSelector = createSelector(
  state => state.global.segments || [],
  segments => segments.map(segment => ({ text: segment.name })).filter(segment => segment.text !== 'All Accounts')
)

const base64archived = btoa('archived=true')

function RenderNav() {
  const { watcherId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const segments = useSelector(segmentsSelector)
  const watchers = useSelector(state => state.global.watchers)
  const workspace = useSelector(state => state.global.workspace)
  const [newSegment, setNewSegment] = useState(null)
  const currentSegment = useCurrentSegment()

  const isArchived = history.location.search === `?${base64archived}`

  const navSpec = useMemo(() => {
    const spec = [
      {
        items: [ { text: 'All Accounts' } ],
        icon: 'layer-group',
        target: () => '/accounts',
        selected: () => !watcherId && !isArchived && currentSegment === 'All Accounts',
        noTruncation: true,
        onClick: () => localStorage.removeItem('PlanttAccountGroup')
      },
      {
        name: 'groups',
        items: segments,
        Tail: props => newSegment !== null ? <NewSegmentInput {...{ newSegment, setNewSegment, ...props }} /> : null,
        icon: 'pizza-slice',
        target: item => groupPath(item.text),
        add: () => setNewSegment(''),
        RightIcon: SegmentDelete,
        selected: ({ text }) => text === currentSegment,
        info: 'Customized segments saved for quick access',
        onClick: ({ text: group }) => localStorage.setItem('PlanttAccountGroup', group)
      }
    ]

    if(workspace.watchers_enabled)
      spec.splice(1, 0, {
        name: 'watchers',
        items: watchers,
        icon: 'telescope',
        add: () => history.push(workspace.role !== 'beta' ? '/watchers/add' : '/watchers/book-a-demo'),
        target: item => `/watchers/view/${item.id}`,
        count: item => item.identity_count,
        options: [
          { text: 'Delete', onClick: async ({ id }) => await dispatch(deleteWatcher(id)), isDelete: true, prompt: 'Are you sure you would like to delete this watcher?' },
          { text: 'Modify', onClick: ({ id }) => history.push(`/watchers/edit/${id}`), redirecting: true }
        ],
        selected: ({ id }) => history.location.pathname === `/watchers/view/${id}`,
        info: 'Watchers contain predictions made by Plantt'
      })

    return spec
  }, [segments, newSegment, currentSegment, watchers, watcherId, workspace, isArchived])

  const bottomSection = {
    name: '',
    items: [ { text: 'Archived' } ],
    icon: 'archive',
    target: () => `/accounts?${base64archived}`,
    selected: () => isArchived,
    noTruncation: true
  }

  return <SubNav navSpec={navSpec} bottomSection={bottomSection} />
}

function SegmentDelete({ text: name }){
  const dispatch = useDispatch()
  const history = useHistory()
  const segment = useSelector(state => state.global.segments.find(s => s.name === name))
  const [deleting, setDeleting] = useState(false)
  
  const doDelete = async e => {
    e.stopPropagation()
    e.preventDefault()
    if(segment){
      setDeleting(true)
      const segments = await deleteSegment(segment.id)
      if(!segments.error){
        dispatch(setSegments(segments))
        if(getCurrentSegment() === name)
          history.push('/accounts')
      }else{
        dispatch(setToastMessage(segments.error.message, 'error'))
        setDeleting(false)
      }
    }
  }

  return name !== 'All Accounts' ? (!deleting
    ? <Delete className='delete-icon' onClick={doDelete} />
    : <CircularProgress size={16} color='inherit' />
  ) : null
}

function NewSegmentInput({ newSegment, setNewSegment, className, ...props }){
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const ref = useRef()
  const history = useHistory()

  const escaped = useRef()
  const saveNewSegment = async () => {
    if(!newSegment.length || escaped.current){
      escaped.current = false
      return setNewSegment(null)
    }

    setLoading(true)
    try {
      const segments = await createSegment(newSegment)
      dispatch(setSegments(segments))
      trackMixpanelEvents('group_created')
      setNewSegment(null)
      history.push(groupPath(newSegment))
    } catch(error) {
      console.error('Error creating segment:', error)
      dispatch(setToastMessage('Something went wrong while creating your segment. Please try again', 'error'))
      setLoading(false)
    }
  }

  useEffect(() => {
    if(ref.current)
      ref.current.focus()
  }, [ref.current])

  const onKeyDown = e => {
    const isEsc = e.key === 'Escape'
    if(isEsc)
      escaped.current = true
    if(e.key === 'Enter' || isEsc)
      ref.current?.blur()
  }

  return (
    <div {...props} className={`${className || ''} add-group-wrapper`}>
      <div>
        <div class='icon-wrapper'><i className='far fa-pizza-slice'></i></div>
        <input
          placeholder='Group name'
          ref={ref}
          value={newSegment}
          onChange={e => setNewSegment(e.target.value)}
          onBlur={saveNewSegment}
          onKeyDown={onKeyDown}
          disabled={loading}
        />
        <div className='loading-wrapper'>{loading && <CircularProgress color='inherit' size={14}  />}</div>
      </div>
      <div></div>
    </div>
  )
}

export default RenderNav