import { useMemo } from "react"
import { useSelector } from 'react-redux'
import { SyncRounded, EditRounded, CheckRounded, WarningRounded } from '@material-ui/icons'
import Tooltip from "../../components/Tooltip/new"
import { formatDistanceToNow } from "date-fns"

function AppStatus({ connector }) {
  const { status, sync_status, authentication, spec_ref } = connector
  const connectorSpec = useSelector(state => state.global.connectorSpecs.find(spec => spec.id === spec_ref))

  const [Icon, text, errors] = useMemo(() => {
    if(sync_status.status === `failed` || authentication.status !== 'authenticated') {
      const errors = []
      if(sync_status.status === 'failed')
        errors.push(sync_status.message)
      if(authentication.status !== 'authenticated')
        errors.push(`Connector authentication status is ${authentication.status}`)

      return [WarningRounded, 'Error', errors.join('\n')]
    }else if(status === 'draft') {
      return [EditRounded, 'Draft']
    }else if(connectorSpec.data_sync) {
      switch(sync_status.status){
        case 'done': return [CheckRounded, 'Done']
        case 'in_progress': return [SyncRounded, 'Syncing']
      }
    }else if(authentication.status === 'authenticated'){
      return [CheckRounded, 'Authenticated']
    }

    return []
  }, [status, sync_status, connectorSpec])

  return text ? (
    <span className={`connector-status ${text.toLowerCase()}`}>
      {text === 'Error' 
        ? <ErrorTooltip message={errors} errorText={text} {...{ Icon, sync_status }} />
        : <><Icon fontSize='inherit' /> {text}</>
      }        
    </span>
  ) : null
}

const theme = { color: '#19270d', backgroundColor: '#fff', borderColor: '#fd6c4b'}
function ErrorTooltip({ message, errorText, Icon, tooltipProps, sync_status, ...props }){
  const content = useMemo(() => (
    <div className='contact-alerts-tooltip-content'>
      {message}
      <div className='last-sync'>
        <b>Last Sync:</b>
        {formatDistanceToNow(new Date(sync_status.last_sync))} ago
      </div>
    </div>
  ), [])

  return  (
    <Tooltip {...{ content, theme }} styles={{ wrapper: { minWidth: 260 }}} {...tooltipProps}>
      <div  {...props}> <Icon fontSize='inherit' /> {errorText}</div>
    </Tooltip>
  )
}

export default AppStatus