import { useSelect } from 'downshift'
import { forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { makeStyles, Popper } from '@material-ui/core'
import Scrollable from '../Scrollable'
import { KeyboardArrowDown } from '@material-ui/icons'

const getHeight = ({ height }) => height || '100%'
const useSelectClasses = makeStyles({
  wrapper: {
    display: 'flex',
    border: '1px transparent solid',
    borderRadius: 3,
    height: getHeight,
    padding: '8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    borderColor: ({ hasErrors }) => hasErrors ? '#fd6c4b' : '#ddd',
    '&:focus, &[aria-expanded="true"], &:hover': {
      outline: 'none',
      '&:not([disabled])': {
        cursor: 'pointer',
        borderColor: ({ hasErrors }) => !hasErrors ? '#155788' : '#fd6c4b'
      }
    },
    '&[disabled]': {
      borderColor: '#eee',
      color: '#ccc',
      cursor: 'not-allowed'
    }
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  placeholder: {
    color: '#ccc'
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
  },
})

/*
  IMPORTANT NOTE: 
    If used within a MaterialUI Modal, the disableEnforceFocus prop
    MUST be set on it for the select dropdown to work properly.
*/

const noop = () => {}

function Select({
  setOpen = noop, label, initialValue, placeholder, getItem = (i => i), getSelection = (i, getItem) => getItem(i), onSelect, popperProps = {}, selectorProps = {}, errorProps = {}, items, selectClasses = {}, disabled = false, ...props
}, selectorRef){
  const {
    isOpen,
    selectedItem,
    highlightedIndex,
    selectItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    getLabelProps
  } = useSelect({ items, initialSelectedItem: initialValue, onSelectedItemChange: onSelect })

  useLayoutEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  useEffect(() => {
    if(initialValue && selectedItem !== initialValue)
      selectItem(initialValue)
  }, [initialValue])

  const internalRef = useRef()
  const toggleRef = selectorRef || internalRef
  popperProps.ref = useRef()

  const popperAnchor = toggleRef.current || document.querySelector('body')
  //  MaterialUI does not allow an undefined anchorEl, and downshift requires the menu element to always
  //  be rendered. This silly querySelector is a workaround to make them play well together

  const defaultSelectClasses =  useSelectClasses({ hasErrors: errorProps.hasErrors, height: selectorProps.height })
  selectClasses = { ...defaultSelectClasses, ...selectClasses }

  return (
    <div {...props} className={`select-container ${props.className || ''}`}>
      {label && <label {...getLabelProps()}>{label}</label>}
      <div
        {...getToggleButtonProps({ ref: toggleRef, disabled, ...selectorProps })}
        tabIndex={!disabled ? '0' : undefined}
        className={`${selectClasses.wrapper} select-selector ${selectorProps.className || ''}`}
      >
        <div className={`${selectClasses.text} ${!selectedItem ? selectClasses.placeholder : ''}`}>
          {selectedItem
            ? getSelection(selectedItem, getItem)
            : (typeof placeholder !== 'undefined' ? placeholder : 'Select Option')
          }
        </div>
        <div
          className={selectClasses.controls}
          disabled={disabled}
        >
          <KeyboardArrowDown color={'inherit'} />
        </div>
      </div>
      <Popper
        anchorEl={popperAnchor}       
        open={true}
        placement='bottom-start'
        {...popperProps}
        className={`select-popper ${popperProps.className || ''}`}
      >
        <Scrollable
          className={`options-container card ${isOpen ? 'is-open' : ''}`}
          style={{ minWidth: toggleRef.current ? toggleRef.current.offsetWidth : undefined }}
          {...getMenuProps({}, { suppressRefError: true })}
        >
          {isOpen && items.map((item, index) => (
          <div
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
              className={`select-option ${highlightedIndex === index ? 'is-active' : ''}`}
          >{getItem(item)}</div>
          ))}
          {isOpen && !items.length &&
          <div className='select-option'>No options</div>}
        </Scrollable>
      </Popper>
    </div>
  )
}

export default forwardRef(Select)