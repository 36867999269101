import { makeStyles, Switch } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
      width: 76,
      height: 30,
      padding: 2,
  },
  switchBase: {
    padding: '7px 6px 20px',
    color: 'white !important',
    
    '&:after': {
        content: '"No"',
        width: 60,
        position: 'absolute',
        fontSize: 14,
        left: 20,
        top: 5,
        lineHeight: 1.5
    },
    '&:before': {
      content: '"Yes"',
      width: 30,
      position: 'absolute',
      fontSize: 14,
      right: 36,
      top: 5,
      lineHeight: 1.5
    },
    '&$checked': {
        transform: 'translateX(47px)',
        '& + $track': {
            opacity: 1,
            backgroundColor: '#3aaf4b',
        },
    },
  },
  thumb: {
    width: 16,
    height: 16,
  },
  track: {
      borderRadius: 15,
      opacity: 1,
      backgroundColor: '#cacfd2',
  },
  checked: {}
})

function CustomSwitch({ style, ...props }){
  const classes = useStyles()
  return (
    <span style={{ cursor: props.readonly ? 'not-allowed' : 'pointer', ...style }}>
      <Switch {...props} classes={classes} />
    </span>
  )
}

export default CustomSwitch