import formats from "./formats"

const escape = str => {
  // Markdown characters are escaped in order to prevent the editor from crashing due to incorrect interpretation
  formats.forEach(({ markdown, prefix }) => {
    if(prefix)
      return str = str.replace(new RegExp(`^${prefix()}`, 'g'), `\\${prefix()}`)
    if(!markdown)
      return

    const char = markdown[0];
    str = str.replaceAll(char, `\\${char}`)
  })
  return str
}

const serialize = (nodes, type = 'paragraph') => {
  const format = formats.find(({ format }) => format === type)
  
  return nodes.map((node, index) => {
    if(node.type?.includes('-list'))
      return serialize(node.children, node.type)

    const serialized = node.children.map(child => {
      if(child.type === 'link')
        return `[${serialize([child], child.type)}](${child.href})`

      let tags = []
      formats.forEach(({ type, format, markdown }) => {
        if(type === 'mark' && markdown && child[format])
          tags.push(markdown)
      })

      const startTags = tags.map(tag => tag.start || tag).join('')
      const endTags = tags.reverse().map(tag => tag.end || tag).join('')
      return startTags + escape(child.text) + endTags
    }).join('')

    let listPrefix = node.type === 'list-item' && format?.prefix(index)
    return `${listPrefix || ''}${serialized}`
  }).join('\n')
}

export default serialize