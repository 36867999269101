import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { setDisplayedInDock } from "../actions"
import { currentAppSelector } from "./helpers"
import { trackMixpanelEvents } from '../../../helpers/mixpanel'
import { useAvailableApps } from "../helpers"

function AppsDock({ hidden }){
  const history = useHistory()
  const { currentAppId } = useSelector(currentAppSelector)
  const dockApps = useAvailableApps()

  const [closed, setClosed] = useState(true)
  const transitionLen = 300

  useEffect(() => {
    if(currentAppId)
      setClosed(false)
    else if(!closed)
      setTimeout(() => setClosed(true), transitionLen)
  }, [currentAppId])

  return (
    <div
      id='account-apps-dock'
      style={{ transition: `width ${transitionLen / 1000}s, left ${transitionLen / 1000}s` }}
    >
      <div id='apps-bar'>
        {dockApps.map((app) => (
          app.spec.id !== 'plantt' ? <AppIcon {...app.spec} key={app.spec.id} /> : null
        ))}
        <div id='apps-filler' />
        <AppIcon iconName='fal fa-plus' onClick={() => history.push('/apps/all')} />
      </div>
    </div>
  )
}

function AppIcon({ id, icon_url, iconName, onClick }){
  const dispatch = useDispatch()
  const displayedInDock = useSelector(state => state.accounts.displayedInDock)
  const iconWidth = ['salesforce', 'zendesk'].includes(id) ? 30 : 22 // Necessary because salesforce icon proportions are different from most icons...
  const appIconClick = (e) => {
    dispatch(setDisplayedInDock(id))
    trackMixpanelEvents(`{${id}}_dock_loaded (per app?)`)
  }
  return (
    <div className={`icon-wrapper ${displayedInDock === id ? 'is-open' : ''}`}>
      <div>
        <div className='app-icon' onClick={onClick || appIconClick}>
          {icon_url
            ? <img src={icon_url} style={{ width: iconWidth }} />
            : <i className={iconName} />
          }
        </div>
      </div>
    </div>
  )
}

export default AppsDock