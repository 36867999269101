import { KeyboardReturn, KeyboardTab } from "@material-ui/icons"
import { Fragment } from "react"
import { cloneElement, useCallback } from "react"
import Tooltip from "../Tooltip/new"

function TooltipContent(){
  const iconStyle = { fontSize: 12, marginRight: 4 }
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      Command or Ctrl + <KeyboardReturn style={{ marginLeft: 4, ...iconStyle }} /> Enter to submit
    </div>
  )
}

function ToolbarButtons({ toolbarButtons, submitButton, onSubmit }){
  const onMouseDown = useCallback(event => {
    event.preventDefault()
    if(submitButton?.props.onClick)
      submitButton?.props.onClick(event)
    if(submitButton?.props.onMouseDown)
      submitButton?.props.onMouseDown(event)
    onSubmit(event)
  }, [submitButton])

  const tooltipProps = { placement: 'bottom', lean: true, enterDelay: 300, enterNextDelay: 300 }

  return <Fragment>
    {toolbarButtons}
    {submitButton && 
    <Tooltip content={<TooltipContent />} {...tooltipProps}>
      {cloneElement(submitButton, { key: 'submit-button', onMouseDown })}
    </Tooltip>}
  </Fragment>
}

export default ToolbarButtons