import { useEffect, useState } from "react"
import { BASE_URL } from "../../apis/constant"
import { sendGetRequest, sendPostRequest } from '../../apis/api-utils';
import { stringify } from "query-string";

const dummy = () => {}

function useIntegrationHandler({ name, userParams = {}, beforeRedirect = dummy, afterNonOAuth = dummy }){
  const [integrationSpec, setIntegrationSpec] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(async () => {
    try {
        const integrationData = await sendGetRequest(`${BASE_URL}integration/spec/${name}`)
        setIntegrationSpec(integrationData)
    } catch (error) {
        console.error('Error fetching integration spec:', error)
        setError(error)
    }
  }, [name])

  const installOAuth = () => {
      //Joining built-in integration params with user params
      const params = {
          redirect_uri: `${window.location.origin}/inte/${name}/install/handle-user-decision`,
        ...integrationSpec.params,
        ...userParams
      }

      beforeRedirect()
      window.location.assign(`${integrationSpec.oauth_url}?${stringify(params)}`)
  }

  const installIntegration = async () => {
      const { type, id, name, user_params } = integrationSpec
      const data = userParams
      user_params.forEach(param => data[param.id] = data[param.id] || null)
      try {
          setLoading(true)
          const isOAuth = integrationSpec.oauth_url !== ''
          const status = isOAuth ? 'draft' : 'done'
          const integration = await sendPostRequest(`${BASE_URL}integration`, {}, JSON.stringify({ type, name, data, spec_id: id, status }))
          if(!integration.id)
              throw integration
          if (isOAuth){
              localStorage.setItem('integrationId', integration.id)
              installOAuth()
          } else {
              afterNonOAuth()
          }
      } catch(error) {
          setLoading(false);
          console.error('Error installing: ', error);
      }
  }

  return { installIntegration, loading, error, integrationSpec }
}

export default useIntegrationHandler