/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import history from './utils/history';
import 'sanitize.css/sanitize.css';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
// Import root app
import App from './containers/App';
import Firebase, { FirebaseContext } from './components/Firebase';
import app from 'firebase/app';
import { SnackbarProvider } from 'notistack'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
// Load the favicon
/* eslint-disable import/no-webpack-loader-syntax */
// import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import '!file-loader?name=[name].[ext]!./images/plantt.svg';
/* eslint-enable import/no-webpack-loader-syntax */
// Import CSS reset and Global Styles
import './styles/theme.scss';
import configureStore from './configureStore';
// Import all initialization stuff
import { registerLato } from './init';
export const firebase = new Firebase()


const theme = createMuiTheme({
  palette: {
    primary: { main: '#623465' }, // Purple and green play nicely together.
  },
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(',')
  }
});

registerLato();

// Create redux store with history
export const store = configureStore({}, history);

const MOUNT_NODE = document.getElementById('root');


const rrfProps = {
  firebase: app,
  config: {},
  dispatch: store.dispatch,
  // createFirestoreInstance // <- needed if using firestore
}

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <ThemeProvider theme={theme}>
          <FirebaseContext.Provider value={firebase}>
            <ConnectedRouter history={history}>
            <SnackbarProvider maxSnack={5}>
              <App />
            </SnackbarProvider>
            </ConnectedRouter>
          </FirebaseContext.Provider>
        </ThemeProvider>
      </ReactReduxFirebaseProvider>
    </Provider>,
    MOUNT_NODE
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

render();
