export const SET_LOADING = 'plantt/Relationships/SET_LOADING'
export const SET_CURRENT_SEGMENT = 'plantt/Relationships/SET_CURRENT_SEGMENT'

export const SET_FETCH_ACCOUNTS_TIMESTAMP = 'plantt/Relationships/SET_FETCH_ACCOUNTS_TIMESTAMP'
export const FETCH_ACCOUNTS_SUCCESS = 'plantt/Relationships/FETCH_ACCOUNTS_SUCCESS'
export const FETCH_ACCOUNTS_ERROR = 'plantt/Relationships/FETCH_ACCOUNTS_ERROR'
export const ARCHIVE_ACCOUNT_SUCCESS = 'plantt/Accounts/ARCHIVE_ACCOUNT_SUCCESS'

export const SET_CONJUNCTION = 'plantt/Relationships/SET_CONJUNCTION'
export const MODIFY_FILTER = 'plantt/Relationships/MODIFY_FILTER'
export const ADD_NEW_FILTER = 'plantt/Relationships/ADD_NEW_FILTER'
export const DELETE_FILTER = 'plantt/Relationships/DELETE_FILTER'
export const CLEAR_FILTERS = 'plantt/Relationships/CLEAR_FILTERS'
export const RESET_FILTERS = 'plantt/Relationships/RESET_FILTERS'

export const DELETE_ACCOUNT_SUCCESS = 'plantt/Relationships/DELETE_ACCOUNT_SUCCESS'

export const FETCH_CONTACTS_SUCCESS = 'plantt/Relationships/FETCH_CONTACTS_SUCCESS'

export const PRE_UPDATE_ACCOUNT_FIELDS = 'plantt/Relationships/PRE_UPDATE_ACCOUNT_FIELDS'
export const UPDATE_ACCOUNT_FIELDS_ERROR = 'plantt/Relationships/UPDATE_ACCOUNT_FIELDS_ERROR'
export const PRE_UPDATE_CONTACT_FIELDS = 'plantt/Relationships/PRE_UPDATE_CONTACT_FIELDS'
export const UPDATE_CONTACT_FIELDS_ERROR = 'plantt/Relationships/UPDATE_CONTACT_FIELDS_ERROR'

export const PRE_SET_CSM = 'plantt/Relationships/PRE_SET_CSM'
export const SET_CSM_ERROR = 'plantt/Relationships/SET_CSM_ERROR'

export const TOGGLE_CHECKED_ACCOUNT = 'plantt/Relationships/TOGGLE_CHECKED_ACCOUNT'
export const MERGE_CHECKED_ACCOUNTS_SUCCESS = 'plantt/Relationships/MERGE_CHECKED_ACCOUNTS_SUCCESS'

export const PRE_TOGGLE_PREDICTION_ACTION = 'plantt/Relationships/PRE_TOGGLE_PREDICTION_ACTION'
export const TOGGLE_PREDICTION_ACTION_ERROR = 'plantt/Relationships/TOGGLE_PREDICTION_ACTION_ERROR'

export const SET_WATCHER_ACCOUNT_METADATA_SUCCESS = 'plantt/Relationships/SET_WATCHER_ACCOUNT_METADATA_SUCCESS'

export const SET_FIELD_TO_EDIT = 'plantt/Relationships/SET_FIELD_TO_EDIT'
export const UPDATE_FIELD_TO_EDIT = 'plantt/Relationships/UPDATE_FIELD_TO_EDIT'

export const IMPORT_FROM_CSV = 'plantt/Accounts/IMPORT_FROM_CSV'
export const SET_IMPORT_DATA = 'plantt/Accounts/SET_IMPORT_DATA'
export const CLEAR_IMPORT_DATA = 'plantt/Accounts/CLEAR_IMPORT_DATA'
export const RESET_IMPORTED_FIELDS = 'plantt/Accounts/RESET_IMPORTED_FIELDS'
export const COMMIT_IMPORT_ERROR = 'plantt/Accounts/COMMIT_IMPORT_ERROR'

export const SET_UNIQUE_IDENTIFIER = 'plantt/Accounts/SET_UNIQUE_IDENTIFIER'
export const SET_UNIQUE_IDENTIFIER_ERROR = 'plantt/Accounts/SET_UNIQUE_IDENTIFIER_ERROR'

export const SET_BACK_TO_QUERY = 'plantt/Accounts/SET_BACK_TO_QUERY'

export const TOGGLE_MINE_ONLY = 'plantt/Accounts/TOGGLE_MINE_ONLY'
