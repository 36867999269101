/* eslint-disable react-hooks/rules-of-hooks */

import { useLayoutEffect, useRef, useState } from "react"
import AutosizeInput from "react-input-autosize"
import { useSelector } from "react-redux"
import { setBlock } from "../../actions"
import { createMetricField, setValue } from "./helpers"
import HoverableIcon from "../../../../components/HoverableIcon"

function MetricsBlock({ readonly } = {}){
  return ({ block, blockId }) => {
    const addMetric = () => {
      setBlock(blockId, { payload: { ...block.payload, fields: [ ...block.payload.fields, createMetricField() ] } })
    }

    const [mode, setMode] = useState(!readonly ? 'config' : 'view')

    return (
      <div className='note-metrics-block'>
        {!readonly &&
        <div className='toggle-mode'>
          <HoverableIcon type='far' active={mode === 'view'} icon='eye' onClick={() => setMode('view')} />
          <HoverableIcon type='far' active={mode === 'config'} icon='cog' onClick={() => setMode('config')} />
        </div>}
        <div className='metrics-title'>
          <div className='icon-wrapper'><i className='fas fa-tachometer-alt' /></div>
          KPIs
        </div>
        {block.payload.fields.map((field, index) => (
          <RenderField
            fieldsLen={block.payload.fields.length}
            key={field.id}
            {...field}
            {...{ block, blockId, mode, index, readonly }}
          />
        ))}
        {mode === 'config' &&
        <div className='add-metric' onClick={addMetric}>
          <i className='fal fa-plus' />
        </div>}
      </div>
    )
  }
}

function RenderField({ fieldsLen, id, label, value, unit, auto_focus, block, blockId, mode, index, readonly }) {
  const ref = useRef()
  const uid = useSelector(state => state.firebase.auth.uid)
  const update = (event, key) => mode !== 'view' ? setValue(key, { block, blockId, id }, false)(event.target.value) : null
  const deleteField = () => {
    const fields = [ ...block.payload.fields ]
    fields.splice(index, 1)
    setBlock(blockId, { payload: { ...block.payload, fields } })
  }
  
  useLayoutEffect(() => {
    if(!readonly && auto_focus === uid){
      setTimeout(() => ref.current?.input.focus())
      setValue('auto_focus', { block, blockId, id }, false)(null)
    }
  }, [auto_focus])

  if(mode === 'view' && !label && !value && !unit)
    return null

  return (
    <div className='metric-card'>
      {mode !== 'view' && fieldsLen > 1 && <HoverableIcon icon='trash' onClick={deleteField} />}
      <div className='metric-description'>
        <AutosizeInput
          value={label}
          onChange={e => update(e, 'label')} placeholder='Metric description'
          ref={ref}
          disabled={mode === 'view'}
        />
      </div>
      <AutosizeInput value={value} onChange={e => update(e, 'value')} className='metric-value' placeholder='0' disabled={mode === 'view'} />
    </div>
  )
}

export default MetricsBlock