import { NEW_sendGetRequest, NEW_sendPostRequest, NEW_sendPutRequest } from '../../apis/api-utils'
import { setToastMessage } from '../App/actions'
import { BASE_URL2 } from '../../apis/constant'

import {
  APPLY_ACTION_ERROR,
  FETCH_MOMENTS_SUCCESS,
  INITIATE_FETCH_MOMENTS,
  MODIFY_MOMENT_METADATA_ERROR,
  PRE_APPLY_ACTION,
  PRE_MODIFY_MOMENT_METADATA,
  START_REMOVE_MOMENT,
  END_REMOVE_MOMENT,
  MODIFY_MOMENT_NOTES_SUCCESS,
  SET_MODIFYING_NOTE,
  FETCH_NEW_MOMENTS_SUCCESS,
  APPLY_PREPEND_QUEUE,
  UPDATE_NOTE_STATE,
  CLEAR_NOTE_STATE,
  FETCH_MEMBER_STATUS_BAR_SUCCESS
} from './constants'
import serialize from '../../components/TextEditor/serialize'
import deserialize from '../../components/TextEditor/deserialize'
import { format } from 'date-fns'
import { createNote } from '../GistEditor/actions'
import history from '../../utils/history'
import { trackMixpanelEvents } from '../../helpers/mixpanel'

const initiateFetchMoments = (timestamp, nextUrl) => ({ type: INITIATE_FETCH_MOMENTS, timestamp, nextUrl })
const fetchMomentsSuccess = data => ({ type: FETCH_MOMENTS_SUCCESS, ...data })
export const fetchMoments = (queryParams, nextUrl) => async dispatch => {
  const timestamp = new Date()
  dispatch(initiateFetchMoments(timestamp, nextUrl))
  if(!nextUrl)
    dispatch({ type: CLEAR_NOTE_STATE }) //Doing some cleanup...
  try {
    let moments = await NEW_sendGetRequest(nextUrl || `${BASE_URL2}moments`, {}, queryParams)
    if(moments.ok){
      moments = { ...moments.text.meta, records: moments.text.records }
      dispatch(fetchMomentsSuccess({ moments, nextUrl, timestamp }))
    }else{
      throw new Error(moments.text)
    }
  } catch(error) {
    console.error('Failed to fetch moments:', error)
  }
}

// const fetchOnboardingStatusesSuccess = statuses => ({ type: FETCH_ONBOARDING_STATUSES_SUCCESS, statuses })
// const fetchOnboardingStatusesError = error => ({ type: FETCH_ONBOARDING_STATUSES_ERROR, error })

// export const fetchOnboardingStatuses = () => async dispatch => {
//   try {
//     const statuses = await NEW_sendGetRequest(`${BASE_URL}onboarding/statuses`)
//     if(!statuses.ok)
//       throw new Error(statuses.text)
//     else
//       dispatch(fetchOnboardingStatusesSuccess(statuses.text))
//   } catch(error) {
//     console.log('Failed to fetch onboarding statuses:', error)
//     dispatch(fetchOnboardingStatusesError(error))
//   }
// }

export const startRemoveMoment = moment => ({ type: START_REMOVE_MOMENT, moment })
export const endRemoveMoment = moment => ({ type: END_REMOVE_MOMENT, moment })

const preApplyAction = data => ({ type: PRE_APPLY_ACTION, ...data })
const applyActionError = (error, currentMoment) => ({ type: APPLY_ACTION_ERROR, error, currentMoment })

export const applyAction = data => async (dispatch, getState) => {
  const { momentId, actionId, payload = {} } = data
  const currentMoment = getState().moments?.records.find(m => m.id === momentId)
  if(!currentMoment)
    return
  dispatch(preApplyAction(data))

  try {
    const response = await NEW_sendPostRequest(`${BASE_URL2}moment/${momentId}/action/${actionId}`, {}, JSON.stringify(payload))
    trackMixpanelEvents(`moments_rank_change`)

    if(!response.ok)
      throw new Error(response.text)
  } catch(error) {
    console.error('Failed to apply action:', error)
    dispatch(setToastMessage('Something went wrong while trying to apply your changes. Please try again', 'error'))
    dispatch(applyActionError(error, currentMoment))
  }
}

export const addNotes = ({ momentId }) => async (dispatch, getState) => {
  try {
    const response = await NEW_sendPostRequest(`${BASE_URL2}moment/${momentId}/action/add_notes`)
    trackMixpanelEvents(`gist created from moment`)

    if(!response.ok)
      throw new Error(response.text)

    if(!response.text.error)
      history.push(`/${getState().global.workspace.id}/${response.text.gist_id}`)
    return response.text
  } catch(error) {
    console.error('Failed to apply action:', error)
    dispatch(setToastMessage('Something went wrong while trying to add notes. Please try again', 'error'))
    return { error }
  }
}

export const modifyMomentNotesSuccess = (momentIndex, moment) => ({ type: MODIFY_MOMENT_NOTES_SUCCESS, momentIndex, moment })
export const modifyMomentNotes = async ({ author_id, created_at, moment, note, noteIndex }) => {
  const unserializedMoment = { ...moment, notes: [ ...moment.notes ] }
  const now = new Date()
  note = note ? { note, author_id, created_at: created_at || now, updated_at: now } : null
  if(typeof noteIndex === 'undefined')
    unserializedMoment.notes.push(note)
  else if(!note)
    unserializedMoment.notes.splice(noteIndex, 1)
  else
    unserializedMoment.notes[noteIndex] = note

  const serializedMoment = { ...moment, notes: unserializedMoment.notes.map(note => ({ ...note, note: serialize(note.note) })) }

  try {
    const response = await NEW_sendPutRequest(`${BASE_URL2}moment/${moment.id}`, {}, JSON.stringify(serializedMoment))
    if(response.ok){
      if(note)
        ['created_at', 'updated_at'].forEach(k => note[k] = new Date(note[k]).toJSON())
      return unserializedMoment
    }else{
      throw new Error(response.text)
    }
  } catch(error) {
    console.error('Failed to modify moment notes:', error)
    return { error }
  }
}

export const setModifyingNote = modifyingNote => ({ type: SET_MODIFYING_NOTE, modifyingNote })

export const applyPrependQueue = () => ({ type: APPLY_PREPEND_QUEUE })
export const fetchNewMomentsSuccess = records => ({ type: FETCH_NEW_MOMENTS_SUCCESS, records })
export const fetchNewMoments = queryParams => async (dispatch, getState) => {
  const current = getState().moments.records
  const queue = getState().moments.prependQueue

  try {
    let moments = await NEW_sendGetRequest(`${BASE_URL2}moments`, {}, queryParams)
    if(!moments.ok)
      throw new Error(moments.text)
    
    let records = moments.text.records
    const overlapIndex = records.findIndex(moment => moment.id === current[0].id)
    if(overlapIndex < 0){
      dispatch(fetchNewMomentsSuccess('reload'))
    }else{
      records = records.slice(0, overlapIndex).map(moment => {
        moment.notes = moment.notes.map(note => {
          note.note = deserialize(note.note)
          return note
        })
        return moment
      })
      dispatch(fetchNewMomentsSuccess(records))
    }
  }catch(error){
    console.error('Failed to fetch new moments:', error)
  }
}

export const updateNoteState = (id, value) => ({ type: UPDATE_NOTE_STATE, id, value })

export const fetchMemberStatusBarSuccess = statusBar => ({ type: FETCH_MEMBER_STATUS_BAR_SUCCESS, statusBar })
export const fetchMemberStatusBar = () => async (dispatch, getState) => {
  const memberId = getState().global.currentMember.id
  try {
    const statusBar = await NEW_sendGetRequest(`${BASE_URL2}${memberId}/status-bar`)
    if(!statusBar.ok)
      throw statusBar.text

    dispatch(fetchMemberStatusBarSuccess(statusBar.text))
  } catch(error) {
    console.error('Failed to fetch member status bar:')
  }
}

const prepareNotesForPut = moment => moment.notes.map(note => ({ ...note, note: serialize(note.note) }))

const preModifyMomentMetadata = moment => ({ type: PRE_MODIFY_MOMENT_METADATA, moment })
const modifyMomentMetadataError = (error, moment) => ({ type: MODIFY_MOMENT_METADATA_ERROR, error, moment })
export const modifyMomentMetadata = ({ mode, moment, key, animateActions }) => async dispatch => {
  const { skeleton, metadata } = moment
  if(skeleton)
    return

  trackMixpanelEvents(`moments_click_${key}`)
  const newMoment = { ...moment, metadata: { ...metadata, [key]: !metadata[key] } }
  dispatch(preModifyMomentMetadata(newMoment))

  const momentToPut = { ...newMoment, notes: prepareNotesForPut(newMoment) }
  // Making sure that notes go back in serialized. newMoment is already inside redux, so it can't be modified anymore - hence the destructuring

  try {
    const response = await NEW_sendPutRequest(`${BASE_URL2}moment/${moment.id}`, {}, JSON.stringify(momentToPut))
    if(!response.ok)
      throw new Error(response.text)

    if(animateActions && mode === 'unread' && ((key === 'completed' && !metadata.completed) || key === 'archive'))
      dispatch(startRemoveMoment(moment))

    if(key === 'completed' && !metadata.completed)
      dispatch(fetchMemberStatusBar())
  } catch(error) {
    console.error('Failed to modify moment:', error)
    dispatch(setToastMessage('Something went wrong while trying to apply your changes. Please try again', 'error'))
    dispatch(modifyMomentMetadataError(error, moment))
  }
}