import { makeStyles, Switch } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
      width: 172,
      height: 30,
      padding: 2,
  },
  switchBase: {
    padding: '7px 6px 20px',
    color: '#fff !important',
    
    '&:after': {
        content: '"Auto Sync Disabled"',
        width: 160,
        position: 'absolute',
        fontSize: 14,
        left: 12,
        top: 5,
        lineHeight: 1.5,
        color: '#979797 !important'
    },
    '&:before': {
      content: '"Auto Sync Enabled"',
      width: 160,
      position: 'absolute',
      fontSize: 14,
      right: 15,
      top: 5,
      lineHeight: 1.5
    },
    '&$checked': {
        transform: 'translateX(143px)',
        '& + $track': {
            opacity: 1,
            backgroundColor: '#3aaf4b',
        },
    },
  },
  thumb: {
    width: 16,
    height: 16,
  },
  track: {
      borderRadius: 15,
      opacity: 1,
      backgroundColor: '#eee',
  },
  checked: {}
})

function CustomSwitch({ style, ...props }){
  const classes = useStyles()
  return (
    <span style={{ cursor: props.readonly ? 'not-allowed' : 'pointer', ...style }}>
      <Switch {...props} classes={classes} />
    </span>
  )
}

export default CustomSwitch