import { withStyles } from '@material-ui/core';
import React from 'react';

function makeRoundIcon(Icon, radius = 12, hoverScale = 1.1){
  const RoundIcon = ({ containerClassName, containerOnClick, disabled, classes, inverted, ...iconProps }) => (
    <span
      className={`round-icon ${classes.container} ${containerClassName || ''}`}
      onClick={containerOnClick || undefined}
      style={{ width: radius*2, height: radius*2, borderRadius: radius }}
      disabled={disabled}
    >
      <Icon {...iconProps} />
    </span>
  )

  const lightBlue = '#b9cddc'
  const darkBlue = '#155788'

  return withStyles({
    container: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: ({ inverted }) => !inverted ? lightBlue : darkBlue,
      marginLeft: 6,
      cursor: 'pointer',
      flexShrink: 0,
      color: ({ inverted }) => !inverted ? darkBlue : lightBlue,
      
      '&:not([disabled]):hover svg': {
        transform: `scale(${hoverScale})`
      },
      '&[disabled]': {
        opacity: 0.4
      },
      '& svg': {
        fontSize: radius * 1.5
      }
    }
  })(RoundIcon)
}

export default makeRoundIcon;