import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { KeyboardArrowDown } from '@material-ui/icons';
import { removeAutoFocus, setBlock, setFocusedEditor } from '../../actions';
import useToggle from '../../../../components/Toggle/useToggle';
import ActionForm from './ActionForm';


function ActionBlock({ interactionMenu, block, blockId, dragPreview, blocksRef, ...props }) {
  const dispatch = useDispatch()
  const focusedEditor = useSelector(state => state.notes.focusedEditor)
  const uid = useSelector(state => state.firebase.auth.uid)
  const { action, icon_url } = useSelector(state => {
    const action = state.global.allActions.find(action => action.id === block.payload.action_id) || {}
    const { icon_url } = (action && state.global.connectorSpecs.find(c => c.id === action.spec_ref)) || {}
    return { action, icon_url }
  })

  const [autoFocus, setAutoFocus] = useState(false)
  useEffect(() => {
    if(!interactionMenu.anchor && block.auto_focus === uid){
      setAutoFocus(true)
      removeAutoFocus(blockId)
    }
  }, [block, interactionMenu])

  const [open, setOpen] = useState(Boolean(block.auto_focus))
  const { toggleItem, contentProps, isOpen } = useToggle({ open, setOpen, transitionLen: 700 })


  const fieldId = field => `${blockId}/${field.key}`
  const setRef = field => el => blocksRef[fieldId(field)] = { el }

  return (
    <div className='note-action-block'>
      <div className={`action-block-title ${isOpen ? 'block-open' : ''}`} onClick={toggleItem} ref={dragPreview}>
      <div>
        <div className='app-avatar'><img src={icon_url} /></div>
        {action.name}
      </div>
      <div className='block-title-right'>
        <div className='draft-token'>Action Draft</div>
        <KeyboardArrowDown className='action-accordion-arrow' />
      </div>
      </div>
      <div {...contentProps}>
        <ActionForm
          setPayload={newPayload => setBlock(blockId, { payload: newPayload })}
          onSuccess={response => setBlock(blockId, response.block, false)}
          onFieldFocus={field => () => {
            const id = fieldId(field)
            if(focusedEditor !== id)
              dispatch(setFocusedEditor(id))
          }}
          payload={block.payload}
          {...{ autoFocus, action, setRef, blockId, ...props }}
        />
      </div>
    </div>
  )
}

export default ActionBlock