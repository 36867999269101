import {
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_ACCOUNTS_ERROR,
  SET_LOADING,
  SET_FETCH_ACCOUNTS_TIMESTAMP,
  SET_CONJUNCTION,
  MODIFY_FILTER,
  ADD_NEW_FILTER,
  DELETE_FILTER,
  CLEAR_FILTERS,
  RESET_FILTERS,
  FETCH_CONTACTS_SUCCESS,
  UPDATE_ACCOUNT_FIELDS_ERROR,
  PRE_UPDATE_ACCOUNT_FIELDS,
  UPDATE_CONTACT_FIELDS_ERROR,
  PRE_UPDATE_CONTACT_FIELDS,
  PRE_SET_CSM,
  SET_CSM_ERROR,
  TOGGLE_CHECKED_ACCOUNT,
  MERGE_CHECKED_ACCOUNTS_SUCCESS,
  PRE_TOGGLE_PREDICTION_ACTION,
  TOGGLE_PREDICTION_ACTION_ERROR,
  SET_WATCHER_ACCOUNT_METADATA_SUCCESS,
  SET_FIELD_TO_EDIT,
  UPDATE_FIELD_TO_EDIT,
  IMPORT_FROM_CSV,
  SET_IMPORT_DATA,
  CLEAR_IMPORT_DATA,
  RESET_IMPORTED_FIELDS,
  COMMIT_IMPORT_ERROR,
  SET_UNIQUE_IDENTIFIER,
  SET_UNIQUE_IDENTIFIER_ERROR,
  SET_BACK_TO_QUERY,
  ARCHIVE_ACCOUNT_SUCCESS,
  TOGGLE_MINE_ONLY
} from '../constants'

import {
  UPDATE_SEGMENT_DISPLAYED_COLUMNS,
  SAVE_ACCOUNT_FIELD_CHANGES_SUCCESS,
  DELETE_FIELD_SUCCESS,
  DELETE_WATCHER_SUCCESS,
  UPDATE_WATCHER_DISPLAYED_COLUMNS
} from '../../App/constants'

import { NEW_sendDeleteRequest, NEW_sendGetRequest, NEW_sendPostRequest, NEW_sendPutRequest } from '../../../apis/api-utils'
import { BASE_URL2 } from '../../../apis/constant'
import history from '../../../utils/history'
import { setSegments, setToastMessage } from '../../App/actions'
import { store } from '../../..'
import { getCurrentSegment, getSearch } from '../helpers'
import { matchPath } from 'react-router'
import { groupPath } from '../helpers'
import { trackMixpanelEvents } from '../../../helpers/mixpanel'

export const setLoading = loading => ({ type: SET_LOADING, loading })

export const setBackToQuery = queryString => ({ type: SET_BACK_TO_QUERY, queryString })

const setFetchAccountsTimestamp = timestamp => ({ type: SET_FETCH_ACCOUNTS_TIMESTAMP, timestamp })
const fetchAccountsSuccess = data => ({ type: FETCH_ACCOUNTS_SUCCESS, ...data })
const fetchAccountsError = (error, previousSegment) => ({ type: FETCH_ACCOUNTS_ERROR, error, previousSegment })
export const fetchAccounts = (queryParams = {}, nextUrl) => async (dispatch, getState) => {
  dispatch(setLoading(!nextUrl ? { accounts: true } : { nextAccounts: true }))
  const endpoint = nextUrl || `${BASE_URL2}identities`
  try {
    const timestamp = new Date()
    dispatch(setFetchAccountsTimestamp(timestamp))
    const accounts = await NEW_sendGetRequest(endpoint, {}, queryParams)
    if(!accounts.ok)
      throw new Error(accounts.text)

    const segment = queryParams.segment ? getState().global.segments.find(s => s.name === queryParams.segment) : null
    if(typeof segment === 'undefined'){
      history.push('/accounts')
      dispatch(setToastMessage('We could not find the group you were attempting to view', 'error'))
      throw new Error('Segment not found')
    }
    const records = accounts.text.records.map(record => ({ identity: record }))
    dispatch(fetchAccountsSuccess({ ...accounts.text, records, timestamp, queryParams, nextUrl, segment }))
  } catch(error) {
    throw error
  }
}

export const fetchWatcherAccounts = (watcherId, queryParams = {}) => async dispatch => {
  dispatch(setLoading({ accounts: true }))
  try {
    const timestamp = new Date()
    dispatch(setFetchAccountsTimestamp(timestamp))
    let accounts = await NEW_sendGetRequest(`${BASE_URL2}watcher/${watcherId}/predictions`, {}, queryParams)
    if(!accounts.ok)
      throw new Error(accounts.text)

    dispatch(fetchAccountsSuccess({
      meta: { records_amount: accounts.text.length, next_url: null },
      records: accounts.text,
      timestamp
    }))
  } catch(error) {
    history.replace('/accounts')
    dispatch(setToastMessage(`We haven't been able to find the watcher you were looking for`, 'error'))
  }
}

export const applyAccountQuery = (search = getSearch(), force) => async (dispatch, getState) => {
  const { search_term, filters, segment } = search
  const { accountSearchTerm, accountFilters, accounts: { mineOnly } } = getState().relationships
  if(!force && search_term === accountSearchTerm && filters === JSON.stringify(accountFilters)) // && segment === currentSegment
    return

  if(mineOnly)
    search = { ...search, mine: 'True' }

  try {
    await dispatch(fetchAccounts(search))
  } catch(error) {
    console.error('Failed to apply account query:', error)
    dispatch(fetchAccountsError(error, segment))
  }
}

export const setConjunction = (index, conjunction) => ({ type: SET_CONJUNCTION, index, conjunction })
export const modifyFilter = data => ({ type: MODIFY_FILTER, ...data })
export const addNewFilter = index => (dispatch, getState) => {
  const defaultField = getState().global.fieldLists.accounts[0]
  dispatch({ type: ADD_NEW_FILTER, index, defaultField })
}
export const clearFilters = index => ({ type: CLEAR_FILTERS, index })
export const deleteFilter = (index, setIndex) => ({ type: DELETE_FILTER, index, setIndex })
export const resetFilters = appliedFilters => ({ type: RESET_FILTERS, appliedFilters })

export const createSegment = async name => {
  const response = await NEW_sendPostRequest(`${BASE_URL2}segment`, {}, JSON.stringify({
    name,
    filters: [{
      conjunction: 'And',
      fieldSet: []//[{ field: 'name', type: 'field', value: '', operator: 'contains' }]
    }]
  }))
  if(!response.ok)
    throw new Error(response.text)

  const segments = [ ...store.getState().global.segments ]
  segments.push(response.text)
  return segments
}

export const saveSegment = () => async (dispatch, getState) => {
  const segments = [ ...getState().global.segments ]
  const currentSegment = getCurrentSegment()
  const { accountFilters } = getState().relationships

  const segmentIndex = segments.findIndex(s => s.name === currentSegment)
  const segment = segments[segmentIndex] = { ...segments[segmentIndex], filters: accountFilters }
  const response = await NEW_sendPutRequest(`${BASE_URL2}segment/${segment.id}`, {}, JSON.stringify(segment))

  if(!response.ok)
    throw new Error(response.text)

  dispatch(setSegments(segments))

  history.push(groupPath(currentSegment))
}

export const deleteSegment = async segmentId => {
  try {
    const response = await NEW_sendDeleteRequest(`${BASE_URL2}segment/${segmentId}`)
    if(!response.ok)
      throw new Error(response.text)
    return store.getState().global.segments.filter(segment => segment.id !== segmentId)
  } catch(error) {
    console.error('Failed to delete segment:', error)
    return { error }
  }
}

// export const deleteAccountSuccess = accountId => ({ type: DELETE_ACCOUNT_SUCCESS, accountId })
// export const deleteAccount = async accountId => {
//   try {
//     const response = await NEW_sendDeleteRequest(`${BASE_URL2}identity/${accountId}`)
//     if(response.ok)
//       return true
//     else
//       throw new Error(response.text)
//   } catch(error) {
//     console.error(`Failed to delete account:`, error)
//     return false
//   }
// }

const updateSegmentColumns = (columns, segmentIndex) => ({ type: UPDATE_SEGMENT_DISPLAYED_COLUMNS, columns, segmentIndex })
const updateWatcherColumns = (columns, watcherIndex) => ({ type: UPDATE_WATCHER_DISPLAYED_COLUMNS, columns, watcherIndex })
export const updateDisplayedColumns = columns => async (dispatch, getState) => {
  const { global, accounts } = getState()
  const { watcherId } = matchPath(window.location.pathname, { path: '/watchers/view/:watcherId' })?.params || {}
  let endpoint, index, entity, commitColumnsUpdate

  if(!watcherId){
    const currentSegment = getCurrentSegment()
    index = global.segments.findIndex(({ name }) => name === currentSegment)
    entity = global.segments[index]
    endpoint = `segment/${entity.id}`
    commitColumnsUpdate = updateSegmentColumns
  }else{
    index = global.watchers.findIndex(w => w.id === watcherId)
    entity = global.watchers[index]
    endpoint = `watcher/${watcherId}`
    commitColumnsUpdate = updateWatcherColumns
  }
  dispatch(commitColumnsUpdate(columns, index))

  try {
    const response = await NEW_sendPutRequest(`${BASE_URL2}${endpoint}`, {}, JSON.stringify({ ...entity, displayed_columns: columns }))
    if(!response.ok)
      throw new Error(response.text)
  } catch(error) {
    console.error(error)
    dispatch(commitColumnsUpdate(entity.displayed_columns, index))
    dispatch(setToastMessage('Something went wrong while applying your changes. Please try again', 'error'))
  }
}

const fetchContactsSuccess = contacts => ({ type: FETCH_CONTACTS_SUCCESS, contacts })
export const fetchContacts = () => async dispatch => {
  try {
    const contacts = await NEW_sendGetRequest(`${BASE_URL2}contacts`)
    if(!contacts.ok)
      throw new Error(contacts.text)

    dispatch(fetchContactsSuccess(contacts.text))
  } catch(error) {
    console.error(error)
    dispatch(setToastMessage('We had trouble fetching your contacts', 'error'))
  }
}

export const archiveAccount = account => async dispatch => {
  account = { ...account, metadata: { ...account.metadata, archived: !account.metadata.archived } }
  try {
    const response = await NEW_sendPutRequest(`${BASE_URL2}identity/${account.id}`, {}, JSON.stringify(account))
    if(!response.ok)
      throw new Error(response.text)

    dispatch({ type: ARCHIVE_ACCOUNT_SUCCESS, accountId: account.id })
  } catch(error) {
    console.error('Failed to update account:', error)
    dispatch(setToastMessage('We ran into trouble archiving this account. Please give it another try', 'error'))
  }
}

const updateTypes = {
  pre: { account: PRE_UPDATE_ACCOUNT_FIELDS, contact: PRE_UPDATE_CONTACT_FIELDS },
  error: { account: UPDATE_ACCOUNT_FIELDS_ERROR, contact: UPDATE_CONTACT_FIELDS_ERROR }
}
export const updateFields = (type, entity, changes) => async (dispatch, getState) => {
  const fields = getState().global.fieldLists.accounts
  type = type.toLowerCase()
  Object.keys(changes).forEach(key => {
    const field = fields.find(f => f.key === key)
    if(['currency', 'number', 'duration'].includes(field?.type))
      changes[key] = parseInt(changes[key]) || 0
  })
  const newEntity = { ...entity, fields: { ...entity.fields, ...changes } }
  dispatch({ type: updateTypes.pre[type], [type]: newEntity })

  if(type === 'account')
    type = 'identity'

  try {
    const response = await NEW_sendPutRequest(`${BASE_URL2}${type}/${entity.id}`, {}, JSON.stringify(newEntity))
    if(!response.ok)
      throw new Error(response.text)
  } catch(error) {
    console.error('Failed to update fields:', error)
    if(type === 'identity') type = 'account'
    dispatch({ type: updateTypes.error[type], [type]: entity })
    dispatch(setToastMessage('Oops! We had trouble applying your updates. Please give it another try', 'error'))
    return { error }
  }
}

const preSetCSM = account => ({ type: PRE_SET_CSM, account })
const setCSMError = (error, account) => ({ type: SET_CSM_ERROR, error, account })
export const setCSM = (account, newCSM) => async dispatch => {
  const newAccount = { ...account.identity, owner_id: newCSM.id }
  dispatch(preSetCSM(newAccount))
  try {
    const fetched = await NEW_sendPutRequest(`${BASE_URL2}identity/${account.identity.id}`, {}, JSON.stringify(newAccount))
    if(!fetched.ok)
      throw new Error(fetched.text)
  } catch (error) {
    console.error('Failed to set CSM:', error)
    dispatch(setCSMError(error, account.identity))
    dispatch(setToastMessage('Something went wrong while trying to set a new CSM. Please try again', 'error'))
  }
}

export const toggleCheckedAccount = id => ({ type: TOGGLE_CHECKED_ACCOUNT, id })

export const mergeCheckedAccountsSuccess = (account, primary) => ({ type: MERGE_CHECKED_ACCOUNTS_SUCCESS, account, primary })
export const mergeCheckedAccounts = primary => async (dispatch, getState) => {
  const secondary = getState().relationships.accounts.checked.filter(id => id !== primary)
  try {
    const merged = await NEW_sendPostRequest(`${BASE_URL2}identities/merge`, {}, JSON.stringify({ primary, secondary }))
    if(!merged.ok)
      throw new Error(merged.text)

    dispatch(mergeCheckedAccountsSuccess(merged.text, primary))
    dispatch(setToastMessage(`Accounts merged successfully`, 'success'))
  } catch(error) {
    console.error('Error merging accounts:', error)
    dispatch(setToastMessage(`We had trouble merging the accounts you've selected. Please try again`, 'error'))
  }
}

const preTogglePredictionAction = accounts => ({ type: PRE_TOGGLE_PREDICTION_ACTION, accounts })
const togglePredictionActionError = accounts => ({ type: TOGGLE_PREDICTION_ACTION_ERROR, accounts })

export const togglePredictionAction = (action, prediction_ids) => async (dispatch, getState) => {
  // const isBulk = !prediction_ids
  // if(isBulk)
  //   prediction_ids = getState().watchers.checkedPredictions

  const key = Object.keys(action)[0]
  const value = Object.values(action)[0]
  const changes = {}

  const accounts = getState().relationships.accounts.records
  let newAccounts = [ ...accounts ]
  const removeQueue = []
  prediction_ids.forEach(id => {
    const index = accounts.findIndex(p => p.id === id)
    if(index < 0)
      return

    const newAccount = { ...accounts[index] }
    if(prediction_ids.length > 1 || newAccount[key] !== value){
      newAccount[key] = changes[key] = value
      if(value === 'archive' || (key === 'is_verified' && !value))
        removeQueue.push(id)
    }else{
      newAccount[key] = changes[key] = key === 'status' ? 'active' : null
    }
    newAccounts[index] = newAccount
  })

  const update = async () => {
    dispatch(preTogglePredictionAction(newAccounts))
    try {
      const response = await NEW_sendPutRequest(`${BASE_URL2}predictions`, {}, JSON.stringify({ prediction_ids, ...changes }))
      if(!response.ok)
        throw new Error(response.text)
      // dispatch(clearCheckedPredictions())
    } catch(error) {
      console.error('Failed to confirm prediction:', error)
      dispatch(setToastMessage('Something went wrong while trying to apply your changes. Please try again', 'error'))
      dispatch(togglePredictionActionError(accounts))
    }
  }

  if(removeQueue.length){
    newAccounts = newAccounts.filter(({ id }) => !removeQueue.includes(id))
    setTimeout(update, 300)
  }else{
    update()
  }
}

const setWatcherAccountMetadataSuccess = account => ({ type: SET_WATCHER_ACCOUNT_METADATA_SUCCESS, account })

export const setWatcherAccountMetadata = (predictionId, key, value = false) => async (dispatch, getState) => {
  const accounts = getState().relationships.accounts.records
  const index = accounts.findIndex(prediction => prediction.id === predictionId)
  if(index < 0 || (accounts[index].metadata && accounts[index].metadata[key] === value))
    return
  const newPrediction = { ...accounts[index], metadata: { ...accounts[index].metadata, [key]: value } }

  try {
    const response = await NEW_sendPutRequest(`${BASE_URL2}prediction/${predictionId}`, {}, JSON.stringify(newPrediction))
    if(!response.ok)
      throw new Error(response.text)
    dispatch(setWatcherAccountMetadataSuccess(newPrediction))
  } catch(error) {
    console.error('Failed to set watcher account metadata:', error)
  }
}

export const setFieldToEdit = key => (dispatch, getState) => {
  const field = key !== null
    ? getState().global.fieldLists.accounts.find(field => field.key === key)
    : null
  if(field || field === null)
    dispatch({ type: SET_FIELD_TO_EDIT, field })
}

export const updateFieldToEdit = (key, value) => ({ type: UPDATE_FIELD_TO_EDIT, key, value })

export const saveFieldChangesSuccess = field => ({ type: SAVE_ACCOUNT_FIELD_CHANGES_SUCCESS, field })
export const saveFieldChanges = async field => {
  try {
    const updated = await NEW_sendPutRequest(`${BASE_URL2}identity_field/${field.key}`, {}, JSON.stringify(field))
    if(!updated.ok)
      throw new Error(updated.text)

    return updated.text
  } catch(error) {
    console.error('Failed to save changes to field:', error)
    return { error }
  }
}

export const deleteFieldSuccess = field => ({ type: DELETE_FIELD_SUCCESS, field })
export const deleteField = async field => {
  try {
    const response = await NEW_sendDeleteRequest(`${BASE_URL2}field/${field.key}`)
    if(!response.ok)
      throw new Error(response.text)

    return response
  } catch(error) {
    console.error('Failed to delete field:', error)
    return { error }
  }
}

export const createNewEntity = (type, entity, values) => async () => {
  const getId = () => {
    const regex = /\/(.+)\/(?<id>(.+))\/(.+)/i
    return window.location.pathname.match(regex).groups.id
  }
  const endpoint = type === 'Account' ? 'identity' : `identity/${type.toLowerCase()}/${getId()}`

  try {
    const entity = await NEW_sendPostRequest(`${BASE_URL2}${endpoint}`, {}, JSON.stringify({ fields: values }))
    if(entity.ok)
      return entity.text
    else
      throw new Error(entity.text)
  } catch(error) {
    console.error(`Failed to create new @ ${endpoint}:`, error)
    return { error }
  }
}

const deleteWatcherSuccess = watcherId => ({ type: DELETE_WATCHER_SUCCESS, watcherId })

export const deleteWatcher = id => async dispatch => {
  try {
    const response = await NEW_sendDeleteRequest(`${BASE_URL2}watcher/${id}`)
    if(!response.ok)
      throw new Error(response)
    
    trackMixpanelEvents("watchers_deleted_watcher")
    dispatch(deleteWatcherSuccess(id))
  } catch(error) {
    console.error('Failed to delete watcher: ', error)
  }
}

export const setUniqueIdentifier = value => ({ type: SET_UNIQUE_IDENTIFIER, value })
export const setUniqueIdentifierError = value => ({ type: SET_UNIQUE_IDENTIFIER_ERROR, value })
export { default as commitImport } from './commitImport'

export const importCSV = data => (dispatch, getState) => {
  const fields = getState().global.fieldLists.accounts
  dispatch({ type: IMPORT_FROM_CSV, data, fields })
}
export const resetImportedFields = () => (dispatch, getState) => {
  const fields = getState().global.fieldLists.accounts
  dispatch({ type: RESET_IMPORTED_FIELDS, fields })
}
export const clearCSV = () => ({ type: CLEAR_IMPORT_DATA })

export const setImportData = data => ({ type: SET_IMPORT_DATA, data })

export const toggleMineOnly = () => dispatch => {
  dispatch({ type: TOGGLE_MINE_ONLY })
  dispatch(applyAccountQuery())
}