import {
  SET_LOADING,
  FETCH_ACCOUNT_CONTACTS_SUCCESS,
  MODIFY_CONTACT_SUCCESS,
  MODIFY_CONTACT_ERROR,
  PRE_MODIFY_CONTACT,
  TOGGLE_CHECKED_CONTACT,
  MERGE_CHECKED_CONTACTS_SUCCESS,
  CLEAR_CHECKED_CONTACTS,
  DELETE_CHECKED_CONTACTS_SUCCESS,
  PRE_SET_CSM,
  SET_CSM_SUCCESS,
  SET_CSM_ERROR,
  FETCH_TRACKABLES_SUCCESS,
  CLEAR_CURRENT_ACCOUNT,
  FETCH_CURRENT_ACCOUNT_SUCCESS,
  SET_DISPLAYED_IN_DOCK,
  SET_ACTION_IN_MODAL,
} from '../constants'

import fetchRelationshipActivity from './fetchRelationshipActivity'

import { NEW_sendGetRequest, NEW_sendPostRequest, NEW_sendPutRequest } from '../../../apis/api-utils'
import { BASE_URL2 } from '../../../apis/constant'
import { setToastMessage } from '../../App/actions'
import history from '../../../utils/history'
import { getAvailableApps } from '../helpers'
import { fetchDock } from '../../GistEditor/actions'

export const setLoading = loading => ({ type: SET_LOADING, loading })
export { default as fetchRelationshipActivity } from './fetchRelationshipActivity'

const fetchAccountContactsSuccess = (contacts, page) => ({ type: FETCH_ACCOUNT_CONTACTS_SUCCESS, contacts, page })
export const fetchAccountContacts = ({ accountId, page }) => async (dispatch, getState) => {
  if(!accountId)
    accountId = getState().accounts.currentAccount.id
  dispatch(setLoading({ contacts: true }))

  try {
    const contacts = await NEW_sendGetRequest(`${BASE_URL2}identity/${accountId}/contacts`, {}, page !== 'initial' ? { page, take: 25 } : { take: 25 })
    if(!contacts.ok)
      throw new Error(contacts.text)

    dispatch(fetchAccountContactsSuccess({ ...contacts.text.meta, records: contacts.text.records }, page))
  } catch(error) {
    console.error('Failed to fetch contacts:', error)
  }
}

const preModifyContact = contact => ({ type: PRE_MODIFY_CONTACT, contact })
const modifyContactSuccess = contact => ({ type: MODIFY_CONTACT_SUCCESS, contact })
const modifyContactError = (error, contact) => ({ type: MODIFY_CONTACT_ERROR, error, contact })

export const toggleContactStar = contactId => async (dispatch, getState) => {
  const contact = getState().accounts.contacts.records.find(c => c.id === contactId)
  const newContact = { ...contact, starred: !contact.starred }
  dispatch(preModifyContact(newContact))
  try {
    const fetched = await NEW_sendPutRequest(`${BASE_URL2}contact/${contact.id}`, {}, JSON.stringify(newContact))
    if(fetched.ok)
      dispatch(modifyContactSuccess(newContact))
    else
      throw new Error(fetched.text)
  } catch (error) {
    console.error('Failed to toggle contact star:', error)
    dispatch(modifyContactError(error, contact))
    dispatch(setToastMessage('Something went wrong. Please try again', 'error'))
  }
}

export const toggleCheckedContact = id => ({ type: TOGGLE_CHECKED_CONTACT, id })
export const clearCheckedContacts = () => ({ type: CLEAR_CHECKED_CONTACTS })

export const deleteCheckedContacts = () => async (dispatch, getState) => {
  const { checked } = getState().accounts.contacts
  try {
    const deleted = await NEW_sendPostRequest(`${BASE_URL2}contacts/delete`, {}, JSON.stringify({ contact_ids: checked }))
    if(deleted.ok){
      dispatch({ type: DELETE_CHECKED_CONTACTS_SUCCESS })
      dispatch(setToastMessage(`Contacts deleted successfully`, 'success'))
    }else{
      throw new Error(deleted.text)
    }
  } catch(error) {
    console.error('Error deleting contacts:', error)
    dispatch(setToastMessage(`We had trouble deleting the contacts you selected. Please try again`, 'error'))
  }
}

export const mergeCheckedContactsSuccess = (contact, primary) => ({ type: MERGE_CHECKED_CONTACTS_SUCCESS, contact, primary })

export const mergeCheckedContacts = primary => async (dispatch, getState) => {
  const secondary = getState().accounts.contacts.checked.filter(id => id !== primary)
  try {
    const merged = await NEW_sendPostRequest(`${BASE_URL2}contacts/merge`, {}, JSON.stringify({ primary, secondary }))
    if(merged.ok){
      dispatch(mergeCheckedContactsSuccess(merged.text, primary))
      dispatch(setToastMessage(`Contacts merged successfully`, 'success'))
    }else{
      throw new Error(merged.text)
    }
  } catch(error) {
    console.error('Error merging contacts:', error)
    dispatch(setToastMessage(`We had trouble merging the contacts you've selected. Please try again`, 'error'))
  }
}

const preSetCSM = account => ({ type: PRE_SET_CSM, account })
const setCSMSuccess = account => ({ type: SET_CSM_SUCCESS, account })
const setCSMError = (error, account) => ({ type: SET_CSM_ERROR, error, account })

export const setCSM = newCSM => async (dispatch, getState) => {
  const { currentAccount } = getState().accounts
  const newAccount = { ...currentAccount, owner_id: newCSM.id }
  dispatch(preSetCSM(newAccount))
  try {
    const fetched = await NEW_sendPutRequest(`${BASE_URL2}identity/${currentAccount.id}`, {}, JSON.stringify(newAccount))
    if(fetched.ok)
      dispatch(setCSMSuccess(newAccount))
    else
      throw new Error(fetched.text)
  } catch (error) {
    console.error('Failed to set CSM:', error)
    dispatch(setCSMError(error, currentAccount))
    dispatch(setToastMessage('Something went wrong while trying to set a new CSM. Please try again', 'error'))
  }
}

const fetchTrackablesSuccess = trackables => ({ type: FETCH_TRACKABLES_SUCCESS, trackables })
export const fetchTrackables = accountId => async dispatch => {
  try {
    const trackables = await NEW_sendGetRequest(`${BASE_URL2}identity/${accountId}/trackables`)
    if(!trackables.ok)
      throw new Error(trackables.text)

    dispatch(fetchTrackablesSuccess(trackables.text))
  } catch(error) {
    console.error('Failed to fetch trackables: ', error)
  }
}


export const clearcurrentAccount = () => ({ type: CLEAR_CURRENT_ACCOUNT })
const fetchCurrentAccountSuccess = account => ({ type: FETCH_CURRENT_ACCOUNT_SUCCESS, account })

export const fetchCurrentAccount = accountId => async (dispatch, getState) => {
  if(getState().accounts?.currentAccount?.id === accountId)
    return

  try {
    const fetched = await Promise.all([
      NEW_sendGetRequest(`${BASE_URL2}identity/${accountId}`),
      dispatch(fetchRelationshipActivity(accountId, 'initial')),
      dispatch(fetchAccountContacts({ accountId, page: 'initial' })),
      dispatch(fetchTrackables(accountId))
    ])

    const [account] = fetched
    if(account.ok){
      dispatch(fetchCurrentAccountSuccess(account.text))
      getAvailableApps().forEach(({ app, spec }) => {
        if(app)
          dispatch(fetchDock({ appId: app.id, specRef: spec.id }))
      })
    }else{
      throw new Error(account.text)
    }
  } catch(error) {
    console.error('Failed to fetch account events:', error)
    history.push('/accounts')
    dispatch(setToastMessage('We had trouble fetching the account you were trying to look at. Please try again', 'error'))
    dispatch(setLoading({ currentAccount: false }))
  }
}

export const setDisplayedInDock = app => ({ type: SET_DISPLAYED_IN_DOCK, app })