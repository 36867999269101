import { ClickAwayListener, Popper } from "@material-ui/core"
import { SearchRounded } from "@material-ui/icons"
import { ReactEditor } from "cs-slate-react"
import { Editor } from "cs-slate"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import Scrollable from "../../../components/Scrollable"

function InteractionMenu({
  open,
  anchor,
  interact,
  resetInteractionMenu,
  editor,
  placement = 'bottom-start',
  search,
  setSearch,
  searchProps,
  items,
  RenderItem,
  resetHoverIndex = null
}){
  const scrollableRef = useRef()

  const [hovered, setHovered] = useState(null)
  const popperMaxHeight = 240
  const reset = () => {
    setSearch('')
    setHovered(null)
  }

  const searchRef = useRef()
  const [scrollHeight, setScrollHeight] = useState(0)
  useEffect(() => {
    if(anchor){
      setTimeout(() => {
        searchRef.current?.querySelector('input')?.focus()
        setScrollHeight(popperMaxHeight - (searchRef.current?.offsetHeight || 0) - 4)
      })
    }else{
      reset()
    }
  }, [anchor])

  useLayoutEffect(() => {
    if(scrollableRef.current){
      scrollableRef.current.scrollTop = 0
      setHovered(resetHoverIndex)
    }
  }, [items])

  const itemsRef = useRef({})
  const onKeyDown = event => {
    if(!search.length && ['Backspace', ' '].includes(event.key)){
      if(editor){
        resetInteractionMenu()
        ReactEditor.focus(editor, Editor.end(editor, []))
        if(event.key !== ' ')
          event.preventDefault()
      }
      return
    }

    if(event.key === 'ArrowDown' && hovered !== items.length - 1){
      event.preventDefault()
      let newHovered = hovered !== null ? hovered + 1 : 0
      if(items[newHovered].section){
        newHovered = newHovered + 1
        if(newHovered === items.length)
          return
      }

      setHovered(newHovered)
      const elem = itemsRef.current[newHovered]
      if(scrollableRef.current){
        if(!newHovered){
          scrollableRef.current.scrollTop = 0
        }else if(elem){
          const bottomPos = elem.offsetTop + elem.offsetHeight - scrollableRef.current.scrollTop
          if(bottomPos > scrollHeight)
            scrollableRef.current.scrollTop += bottomPos - scrollHeight
        }
      }
    }else if(event.key === 'ArrowUp' && hovered !== 0){
      event.preventDefault()
      let newHovered = hovered ? hovered - 1 : items.length - 1
      if(items[newHovered].section){
        newHovered = newHovered - 1
        if(newHovered < 0)
          return
      }

      setHovered(newHovered)
      const elem = itemsRef.current[newHovered]
      if(scrollableRef.current)
        if(newHovered === items.length - 1 || (elem && elem.offsetTop < scrollableRef.current.scrollTop))
          scrollableRef.current.scrollTop = elem.offsetTop
    }else if(event.key === 'Enter' && itemsRef.current[hovered]){
      interact(event, hovered)
    }
  }

  return (
    <Popper anchorEl={anchor} open={open} id='action-creator-popper' placement={placement}>
      <ClickAwayListener onClickAway={resetInteractionMenu} >
        <div className='card' style={{ width: 340, maxHeight: popperMaxHeight, margin: placement.includes('top') ? '0 -10px 10px 0' : undefined }}>
          <div className='action-search' ref={searchRef}>
            <i className={`fas fa-${searchProps.icon}`} />
            <input
              onKeyDown={onKeyDown}
              placeholder={searchProps.placeholder}
              value={search}
              onChange={event => setSearch(event.target.value)}
            />
          </div>
          <Scrollable ref={scrollableRef} style={{ maxHeight: scrollHeight }}>
            {searchProps.noMatch && !items.length && <div className='search-no-match'>No actions match your search</div>}
            {items.map((item, index) => (
              <RenderItem
                key={item.id ? `${item.id}-${index}` : index}
                onMouseEnter={() => setHovered(index)}
                onMouseLeave={() => setHovered(null)}
                itemRef={elem => itemsRef.current[index] = elem}
                style={hovered === index ? { backgroundColor: 'rgba(21, 87, 136, 0.05)' } : undefined}
                onClick={e => interact(e, hovered)}
                item={item}
              />
            ))}
          </Scrollable>
        </div>
      </ClickAwayListener>
    </Popper>
  )
}

export default InteractionMenu