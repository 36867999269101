

import { useEffect, useState } from 'react'
import { Switch, Route, matchPath } from 'react-router-dom'
import { useSelector } from 'react-redux'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { store } from '../..'

import LoginPage from '../LoginPage'
import Sefi from './sefi'
import Notifier from './Notifier';
import LoadingBar from '../../components/LoadingBar'
import Logo from '../../images/logo.svg'
import { checkRegistration } from '../../components/Firebase'
import AuthContext from './AuthContext'
import ViewGist from '../Gists/ViewGist'
import FinalizeRegistration from '../LoginPage/partials/FinalizeRegistration'
import Scrollable from '../../components/Scrollable'

import { setResizeListener, updateMember } from './actions'

import './style.scss';

const locationHelper = locationHelperBuilder({})

const UserIsAuthenticated = connectedRouterRedirect({
  wrapperDisplayName: 'UserIsAuthenticated',
  AuthenticatingComponent: () => null,
  allowRedirectBack: true,
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/login',
  authenticatingSelector: ({ firebase }) => !firebase.auth.isLoaded || firebase.isInitializing === true,
  authenticatedSelector: ({ firebase: { auth } }) => auth.isLoaded && !auth.isEmpty,
})

function App()  {
  const workspace = useSelector(state => state.global.workspace)
  const userLoading = useSelector(state => state.global.loading.user)
  const loading = useSelector(state => state.global.loading.page)
  const authEmpty = useSelector(({ firebase: { auth } }) => auth.isLoaded && auth.isEmpty)
  const authLoading = useSelector(state => !state.firebase.auth.isLoaded)
  
  const [auth, setAuth] = useState(null)
  useProfileImageUpdater(auth)
  
  useEffect(() => {
    setResizeListener()
    const tryGapi = setInterval(() => {
      if(!window.gapi)
        return

      window.gapi.load('auth2', () => {
        setAuth(window.gapi.auth2.init({
          client_id: '39958656149-d538uhvniis1khtd80j7u8df0br1bi2i.apps.googleusercontent.com',
          scope: 'profile email https://www.googleapis.com/auth/calendar'
        }))
      })
      clearInterval(tryGapi)
    }, 100)

    // debugGoogleSignIn()
  }, [])

  const isRegister = window.location.pathname === '/register' || window.location.pathname.startsWith('/invite')
  const isSignIn = window.location.pathname === '/signin'
  const isGist = Boolean(matchPath(window.location.pathname, { path: ['/:workspaceId(.{36})/:noteId', '/shared/:hash'] }))

  return (
    <AuthContext.Provider value={auth}>
      {!authEmpty && !isRegister && !isSignIn && !isGist &&
      <>
        <LoadingBar loading={(!workspace && userLoading) || loading || authLoading} />
        {((!workspace && userLoading) || authLoading) && <div id='loading-logo'><img src={Logo} /></div>}
      </>}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DndProvider backend={Backend}>
          <Switch>
            {!authEmpty && <Route path={"/register"}><Scrollable id='new-login-page'><FinalizeRegistration /></Scrollable></Route>}
            {!workspace && <Route path="/:workspaceId(.{36})/:noteId" component={ViewGist} />}
            <Route path="/shared/:hash" component={ViewGist} />
            <Route path={["/login", "/signin", "/signup", "/email-sent"]} component={checkRegistration(LoginPage)} />
            <Route path="/password-reset" component={LoginPage} />
            <Route path="/invite/:base64" component={LoginPage} />
            <Route path="" component={UserIsAuthenticated(Sefi)} />
          </Switch>
          <Notifier />
        </DndProvider>
      </MuiPickersUtilsProvider>
    </AuthContext.Provider>
  )
}

const useProfileImageUpdater = auth => {
  useEffect(() => {
    if(auth){
      const update = () => {
        const { workspaceMembers: members, currentMember } = store.getState().global
        if(!members.length || !Object.keys(currentMember).length)
          return setTimeout(update, 1000)
        //This is done instead of using selectors in order to avoid a complete app rerender when something on the member changes

        const member = members.find(m => m.id === currentMember.id)
        if(member && !member.profile_image){
          const profile_image = auth?.currentUser?.get()?.getBasicProfile()?.getImageUrl()
          if(profile_image)
            store.dispatch(updateMember(member, { profile_image }))
        }
      }
      setTimeout(update, 5000) //This is inside a timeout to avoid a race when finalizing invite
    }
  }, [auth])
}

// const debugGoogleSignIn = () => {
//   // USE THIS CODE ONLY TO DEBUG GOOGLE SIGN IN
//   window.gapi.load('auth2', function() {
//     let auth7 = window.gapi.auth2.init({ client_id: '39958656149-d538uhvniis1khtd80j7u8df0br1bi2i.apps.googleusercontent.com' })
//     if(auth7.isSignedIn.get())
//       auth7.signOut().then(() => console.log('User signed out.'))

//     window.email = undefined
//     app.auth().signOut()
//     localStorage.removeItem('PlanttAccountID')  
//   })
// }

export default App


