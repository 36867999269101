const icons = {
  onboarding_kickoff: { icon: 'far fa-stars', color: '#ffd100' },
  feature_request: { icon: 'far fa-gift', color: '#155788' },
  'customer_check-in': { icon: 'far fa-badge-check', color: '#3aaf4b' },
  qbr_summary: { icon: 'far fa-envelope-open-text', color: '#3aa9af' },
  user_interview: { icon: 'fad fa-users', color: '#eb711a' }
}

function TemplateIcon({ id, className }){
  return <i className={`${icons[id]?.icon || "far fa-cubes"} ${className}`} style={{ color: icons[id]?.color }} />
}

export default TemplateIcon