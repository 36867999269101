import { Editor, Range, Transforms } from "cs-slate"
import { ReactEditor } from "cs-slate-react"
import { useMemo, useState, useEffect, useCallback, useLayoutEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { applyUpdates, createEmbeds, getNoteInfo } from "./actions"
import { useParams } from "react-router"
import deserialize from "../../components/TextEditor/deserialize"
import serialize from "../../components/TextEditor/serialize"
import { isItemizedBlock } from './Blocks/Itemized/helpers'
import { compose } from "redux"
import { idGen, upperFirst } from "../../helpers"
import { createSelector } from "reselect"
import { store } from "../.."
import isEqual from "lodash.isequal"

export const useAppInfo = spec_ref => {
  const app = useSelector(state => state.global.connectorSpecs.find(connector => connector.id === spec_ref) || {})
  return { ...app, name: upperFirst(app.name) }
}

export const isNestedBlock = id => id?.includes('/')

export const focusAndSelect = (editor, range) => {
  if(ReactEditor.isFocused(editor))
    return
    
  if(!range)
    range = Editor.start(editor, [])
  ReactEditor.focus(editor)
  Transforms.select(editor, range)
}

export const getCurrentNode = editor => {
  const { anchor } = editor.selection || {}
  if(!anchor)
    return

  let node = editor
  let isList = false
  for(const step of anchor.path){
    node = node.children && node.children[step]
    if(node.type === 'list-item')
      isList = true
  }

  return { node, isList }
}

export const getSelectionOrder = (notesSelection = [], blockOrder = []) => {
  const notesSelectionIds = notesSelection.map(selectionBlock => selectionBlock.blockId)
  const selectionOrder = {}
  for(const blockId of blockOrder) {
    if(notesSelectionIds.includes(blockId)) {
      selectionOrder.start = blockId
      break
    }
  }
  for(const blockId of [...blockOrder].reverse()) {
    if(notesSelectionIds.includes(blockId)) {
      selectionOrder.end = blockId
      break
    }
  }
  return selectionOrder
}

export const useBlockGroups = () => {
  //This function build Groups of blocks and decides if this is a selected group or not
  const [blocksGroups, setBlocksGroups] = useState([])
  const blockOrder = useSelector(state => state.notes.blockOrder)

  useLayoutEffect(() => {
    buildGroups('useEffect')
  }, [blockOrder])

  const buildGroups = useCallback((origin) => {
    const { selection: notesSelection, blockOrder } = store.getState().notes
    const newBlockorder = [...blockOrder]

    if(!notesSelection.length) { //No selection
      const newGroups = [{ blocks: newBlockorder }]
      setBlocksGroups(blocksGroups => !isEqual(newGroups, blocksGroups) ? newGroups : blocksGroups)
      return
    }

    const { start, end } = getSelectionOrder(notesSelection, newBlockorder)
    const selectionStartPosition = newBlockorder.indexOf(start)
    const selectionEndPosition = newBlockorder.indexOf(end)

    const selectionGroup = []
    const beforeSelection = []
    const afterSelection = []
    for(const [index, id] of newBlockorder.entries()) {
      if(index >= selectionStartPosition && index <= selectionEndPosition)
        selectionGroup.push(id)
    }
    if(selectionGroup.length > 1 && selectionGroup.length === newBlockorder.length) //Selection contains all blocks
      return setBlocksGroups([{blocks:newBlockorder, selection:true}])

    if(selectionStartPosition != 0) {
      for(const [index, id] of newBlockorder.entries()) {
        if(index < selectionStartPosition)
          beforeSelection.push(id)
      }
    }
    if(selectionEndPosition != newBlockorder.length-1) {
      for(const [index, id] of newBlockorder.entries()) {
        if(index > selectionEndPosition)
          afterSelection.push(id)
      }
    }

    setBlocksGroups([{ blocks:beforeSelection }, { blocks:selectionGroup, selection:true }, { blocks:afterSelection }])
    return 
  }, [])
  return {
    blocksGroups,
    buildGroups
  }
}

export const getNestedBlockInfo = path => {
  const { blocks } = getNoteInfo()

  const [blockId, childId] = path.split('/')
  const block = blocks[blockId]

  const info = { blockId, childId, block }
  if(isItemizedBlock(block)){
    const { fields } = block.payload
    Object.assign(info, { fields, fieldIndex: fields.findIndex(f => f.id === childId) })
  }

  return info
}

export const mergeNodesOnRemove = ({ editor, nextEditor, removedText, receivingText }) => {
  if(!Editor.string(editor, []).length && !Editor.string(nextEditor, []).length)
    return {}

  const removed = deserialize(removedText)
  let currentText = deserialize(receivingText)
  const currentLast = currentText[currentText.length - 1]

  // if(removed[0].type.includes('list')){
  //   const firstItem = removed[0].shift()
  //   currentText = [ ...currentText ]
  //   if(currentLast.type === 'paragraph')
  //     currentText[currentText.length - 1] = { ...currentLast, children: currentLast.children.concat(firstItem.children)
  // }

  if(currentLast.type === removed[0].type){
    const firstRemoved = removed.splice(0, 1)[0]
    currentText = [ ...currentText ]
    currentText[currentText.length - 1] = { ...currentLast, children: currentLast.children.concat(firstRemoved.children) }
  }

  return { mergeResult: serialize(currentText.concat(removed)) }
}

export const onNotePaste = (editor, blockId, blocksRef) => e => {
  if(isNestedBlock(blockId))
    return

  const { blocks, notePath } = getNoteInfo()
  const { isList } = getCurrentNode(editor)
  let pasted = e.clipboardData.getData('text')

  const embeds = []
  const youtubeRegex = /(?:.+?)?(?:\/v\/|watch\/|\?v=|&v=|youtu\.be\/|\/v=|^youtu\.be\/|watch%3Fv%3D)([a-zA-Z0-9_-]{11})+/g
  const loomRegex = /(?:loom\.com\/share\/)(.{32})/g
  let matches
  while ((matches = youtubeRegex.exec(pasted)) != null)
    embeds.push({ origin: 'youtube', id: matches[1] })
  while ((matches = loomRegex.exec(pasted)) != null)
    embeds.push({ origin: 'loom', id: matches[1] })
  if(embeds.length)
    createEmbeds(blockId, embeds)
  
  pasted = pasted.replace('\r', '').split('\n')
  if(!pasted[pasted.length - 1].length)
    pasted.pop();

  if(pasted.length === 1)
    return


  e.preventDefault()
  
  if(!Range.isCollapsed(editor.selection))
    Transforms.delete(editor)

  if(isList){
    pasted.forEach(text => Transforms.insertNodes(editor, { type: 'list-item', children: [{ text }] }))
    return
  }

  const manipulations = { start: text => `${text.trim()} ${pasted.shift()}`, end: text => `${pasted.pop()} ${text.trim()}` }
  const getFragment = position => {
    let fragment = Editor.fragment(editor, { ...editor.selection, focus: Editor[position](editor, []) })
    return compose(manipulations[position], serialize)(fragment)
  }
  const fragments = ['start', 'end'].map(getFragment)
  pasted.reverse().forEach(text => fragments.splice(1, 0, text))

  const updates = {}
  let next_id
  let lastId
  fragments.forEach((text, index) => {
    const currentId = lastId = !index ? blockId : next_id
    next_id = index < fragments.length - 1 ? idGen() : (blocks[blockId].next_block || null)
    updates[`/blocks/${currentId}`] = { type: 'basic' , payload: { text }, next_block: next_id, updated_at: new Date().getTime() }
  })

  applyUpdates(notePath, 'update', updates)
  
  setTimeout(() => {
    if(blocksRef.current[lastId])
      ReactEditor.focus(blocksRef.current[lastId].editor)
  })
}

const currentMemberSelector = createSelector(
  ({ global: { currentMember, workspaceMembers } }) => ({ currentMember, workspaceMembers }),
  ({ currentMember, workspaceMembers }) => {
    const member = workspaceMembers.find(m => m.id === currentMember?.id)
    return member || (currentMember ? { ...currentMember, name: 'Admin' } : null)
  }
)

export const contactDetailsSelector = contactDetails => createSelector(
  currentMemberSelector,
  currentMember => {
    const id = contactDetails?.id ?? currentMember?.id

    let name = currentMember?.name
    if(contactDetails){
      const { contact_first_name, contact_last_name, contact_email } = contactDetails
      name = contact_first_name ? `${contact_first_name} ${contact_last_name}` : contact_email
    }

    return { id, name, type: contactDetails ? 'share' : 'member' }
  }
)


export const getImageDimensions = (file, maxHeight = 50) => {
  if(!file instanceof File)
    return console.error('getImageDimensions expects and instance of File')

  return new Promise(resolve => {
    const img = new Image()
    img.onload = () => {
      let { height, width } = img
      if(height > maxHeight){
        const ratio = height / maxHeight
        height = Math.ceil(height / ratio)
        width = Math.ceil(width /ratio)
      }
      resolve({ height, width })
    }
    img.src = window.URL.createObjectURL(file)
  })
}

export const changeSelection = (direction, blocksGroups, oldSelection) => { //direction can be up or down
  const isThereMoreBlocks = () => {
    if(direction === 'up')
      return blocksOrder[upperBlockLocation] && blocksOrder[upperBlockLocation] !== 'noteTitle' ? true : false
    if(direction === 'down')
      return blocksOrder[bootomBlockLocation] ? true : false
  }
  const addItem = (currentSelection) => {
    if(isThereMoreBlocks())
      if(direction === 'up')
        return [{blockId:blocksOrder[upperBlockLocation],index:currentSelection.length}, ...currentSelection]
      if(direction === 'down')
        return [...oldSelection, {blockId:blocksOrder[bootomBlockLocation],index:currentSelection.length*-1}]
    return currentSelection
  }


  let newSelection = [...oldSelection]
  const blocksOrder = []
  for(const group of blocksGroups) 
    for(const block of group.blocks)
      blocksOrder.push(block)

  const idsWithRealLocation = oldSelection.map(selectionItem => ({blockId:selectionItem.blockId, index:blocksOrder.indexOf(selectionItem.blockId)}))
  idsWithRealLocation.sort((a, b) => a.index - b.index)
  const upperBlockLocation = blocksOrder.indexOf(idsWithRealLocation[0].blockId) - 1
  const bootomBlockLocation = blocksOrder.indexOf(idsWithRealLocation[idsWithRealLocation.length-1].blockId) + 1

  //get the sum of all the indexes
  const reducer = (accumulator, curr) => accumulator + curr;
  const indexesSum = newSelection.map(selectionItem => selectionItem.index).reduce(reducer)
  //Add or Remove block item to selection
  if(indexesSum < 0)  //add to the selection with direction down
    if(direction === 'down') newSelection = addItem(newSelection) //Adding down
    else if(direction === 'up')  newSelection.pop()  //remove from bottom
  if(indexesSum > 0) 
    if(direction === 'down')  newSelection.shift() //remove from the top
    else if(direction === 'up') newSelection = addItem(newSelection) // adding to the top
  if(indexesSum === 0) newSelection = addItem(newSelection)


  //Finally, if we have a change, return it
  if(oldSelection.length !== newSelection.length) return newSelection
  return false
}

export const defineIndentation = (blocks, blockId, counter = 0) => {
  if(isNestedBlock(blockId)) return 0
  if(blocks[blockId]?.parent_id)
    return defineIndentation(blocks, blocks[blockId].parent_id, counter+1)
  return counter
}

export const focusHiddenInput = () => {
  document.activeElement.blur()
  document.getElementById('hiddenInput').focus()
}

export const isGistPath = () => /^\/.{36}\/.{9}$/.test(window.location.pathname)



