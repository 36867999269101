import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import '../style.scss';
import { DialogContent } from '@material-ui/core';

const ConfirmationDialogStyle = makeStyles(() => ({
  dialogContent: {
    padding: 16,
    textAlign: 'center'
  },
  btnPrimary: {
    borderRadius: 3,
    border: 'solid 1px #fd6c4b',
    backgroundColor: '#fd6c4b',
    fontSize: 14,
    color: '#ffffff',
    minHeight: 36,
    marginRight: 8,
    minWidth: 66,
    maxHeight: 39,
    textTransform: 'unset',
    '&:disabled': {
      color: '#fd6c4b',
    },
    '&:hover': {
      backgroundColor: '#fd532c',
    }
  },
  btnSecondary: {
    borderRadius: 3,
    border: 'solid 1px #cdcdcd',
    backgroundColor: '#ffffff',
    fontSize: 14,
    color: '#4a4a4a',
    textTransform: 'unset',
    minHeight: 36,
    minWidth: 66,
    maxHeight: 39,
  },
  title: {
    fontFamily: 'Helvetica',
    fontSize: 18,
    marginBottom: 12
  }
}));

export const ConfirmationDialog = ({
  onClose,
  callback,
  open,
  title,
  continueText
}) => {
  const classes = ConfirmationDialogStyle();
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.title}>
          {title}
        </div>
        <div>
          <Button
            className={classes.btnPrimary}
            onClick={() => {
              callback();
              onClose();
            }}
          >
            {continueText}
          </Button>
          <Button
            className={classes.btnSecondary}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

ConfirmationDialog.defaultProps = {
  continueText: 'Continue'
};

ConfirmationDialog.propTypes = {
  continueText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};
