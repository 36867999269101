import { makeStyles, Modal } from "@material-ui/core"

const useStyles = makeStyles({
  modal: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    backgroundColor: '#fff',
    borderRadius: 4,
    width: 540,
    outline: 0,
    overflow: 'hidden'
  },
  header: {
    backgroundColor: '#155788',
    padding: 20,
    color: '#fff',
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 24px',
    borderTop: '1px #ddd solid',

    '& button': {
      width: '48%',
      padding: '12px 16px',
    }
  }
})


function PlanttModal({ title, children, buttons, ...props }){
  const classes = useStyles()
  return (
    <Modal className={classes.modal} {...props}>
      <div className={classes.content}>
        <div className={classes.header}>{title}</div>
        {children instanceof Function ? children({ classes }) : children}
        {buttons && <div className={classes.buttons}>{buttons}</div>}
      </div>
    </Modal>
  )
}

export default PlanttModal