import { useMemo } from "react"
import formats from "./formats"

const differentStartAndEnd = formats.filter(({ type, markdown }) => type === 'mark' && typeof markdown === 'object')
const listFormats = formats.filter(({ list }) => list)

const unescape = str => {
  // Markdown characters are escaped in order to prevent the editor from crashing due to incorrect interpretation.
  // This function unescapes them.
  formats.forEach(({ markdown, prefix }) => {
    if(markdown){
      const char = markdown[0];
      str = str.replaceAll(`\\${char}`, char)
    }else if(prefix){
      str = str.replace(new RegExp(`^\\${prefix()}`, 'g'), prefix())
    }
  })
  return str
}

const extractLinks = str => {
  const nodes = []
  const links = str.match(/\[(.*?)\]\((.*?)\)/g)
  if(!links)
    return [str]
    
  for(let link of links){
    str = str.split(link)
    if(str[0].length)
      nodes.push({ text: str.shift() })
    else
      str.shift()

    const [text, href] = link.slice(1, link.length - 1).split('](')
    if(nodes[nodes.length - 1]?.href)
      nodes.push({ text: '' })

    nodes.push({ text, href })

    str = str.join(link)
  }
  if(str.length)
    nodes.push({ text: str })

  if(nodes[0].href)
    nodes.unshift({ text: '' })
  return nodes
}

const prepareChildren = fragment => {
  const byLinks = extractLinks(fragment)
  const organized = []

  for(let item of byLinks){
    if(item.href){
      organized.push({ type: 'link', href: item.href, children: prepareChildren(item.text) })
      continue
    }
    let str = typeof item === 'string' ? item : item.text
    if(!str.length)
      organized.push({ text: '' })
    else while(str.length){
      const search = str.match(/[^|^\\]((\*\*|--|~~|_)+)/m)
      if(search){
        let [markdown] = search
        if(!['*', '-', '~', '_'].includes(markdown[0]))
          markdown = markdown.slice(1);
        let reverse = markdown.split('').reverse().join('')
        differentStartAndEnd.forEach(format => reverse = reverse.replace(format.markdown.start, format.markdown.end))

        let split = str.split(markdown)
        if(split[0].length)
          organized.push({ text: unescape(split.shift()) })
        else
          split.shift()
        
        str = split.join(markdown)
        split = str.split(reverse)
        const node = { text: unescape(split.shift()) }
        str = split.length ? split.join(reverse) : ''

        const splitMarkdown = markdown.match(/(\*\*|--|~~|_)/gm)
        splitMarkdown.forEach(item => {
          const format = formats.find(f => f.type === 'mark' && (f.markdown === item || (typeof f.markdown !== 'string' && f.markdown?.start === item)))
          if(format)
            node[format.format] = true
        })
        organized.push(node)
      }else{
        organized.push({ text: unescape(str) })
        str = ''
      }
    }
  }

  //TEST!!

  if(!organized.length || organized[organized.length - 1].children)
    organized.push({ text: '' })

  return organized
}

const deserialize = str => {
  const parsed = []
  str = str.split('\n')
  let currentList = null
  while(str.length){
    const fragment = str.shift()

    const listIndicator = fragment.match(/^(?<indicator>(- |[0-9]+\. |>))(.*)/m)
    if(listIndicator){
      const { indicator } = listIndicator.groups

      //Making sure two different lists don't mix up.
      if(currentList){
        const listFormat = listFormats.find(({ format }) => format === currentList.type)
        if(parseInt(indicator) === 1 || (isNaN(indicator) && indicator !== listFormat.prefix()) || (!isNaN(indicator) && currentList.type !== 'ordered-list')){
          parsed.push(currentList)
          currentList = null
        }
      }
      
      if(!currentList){
        const listFormat = listFormats.find(({ prefix }) => indicator === prefix())
        currentList = { type: !isNaN(parseInt(indicator)) ? 'ordered-list' : listFormat?.format , children: [] }
      }
      currentList.children.push({ type: 'list-item', children: prepareChildren(fragment.replace(indicator, '')) })
    }

    if((!listIndicator || !str.length) && currentList){
      parsed.push(currentList)
      currentList = null
    }
    
    if(!listIndicator && !currentList)
      parsed.push({ type: 'paragraph', children: prepareChildren(fragment) })
  }

  //TEST!!
  if(!parsed.length)
    parsed.push({ type: 'paragraph', children: [{ text: '' }] })

  return parsed
}

export const useDeserialized = text => {
  return useMemo(() => text ? deserialize(text) : null, [text])
}

export default deserialize