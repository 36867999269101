import { Fragment, useMemo } from "react"
import deserialize from "../../../../components/TextEditor/deserialize"
import RenderNode from "../../../../components/TextEditor/RenderNode"
import { format } from "date-fns"

function CardBlock({ fields, truncateCardValueAt = null }){
  const deserialized = useMemo(() => {
    return fields.map(field => {
      if(field.type === 'text')
        field = { ...field, value: deserialize(field.value) }
      else if(field.type === 'date')
        field = { ...field, value: field.value != 0 ? format(new Date(field.value * 1000), 'LLLL do YYY') : 'N/A' }
      return field
    })
  }, [fields])

  return (
    <div className='mrkdwn-side-line'>
      <div></div>
      <div className='widget-card-block'>
        {deserialized.map(({ key, value, type }) => (
          <Fragment key={key}>
            <div>{key}</div>
            <div>
              {type === 'text'
                ? value.map((node, index) => <RenderNode key={index} {...node} />)
                : value
              }
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default CardBlock