import { useDispatch } from "react-redux"
import { upperFirst } from "../../../helpers"
import { setInOverlay } from "../actions"

function EmbedBlock({ block }){
  const dispatch = useDispatch()
  const { origin, id } = block.payload
  const originIcon = `https://firebasestorage.googleapis.com/v0/b/plantt-ui-icons/o/${origin}.svg?alt=media`
  return (
    <div className='note-embed-block'>
      <div className='video-embed' onClick={() => dispatch(setInOverlay(block.payload))}>
        {origin === 'youtube' && <img src={`http://img.youtube.com/vi/${id}/0.jpg`} style={{ width: 240 }} />}
        {origin === 'loom' &&
        <img src={`https://cdn.loom.com/sessions/thumbnails/${id}-with-play.gif`} style={{ width: 240 }} />}
        <div className='embed-overlay'>
          <img style={origin === 'loom' ? { filter: 'brightness(150%)'} : undefined} src={originIcon} />
        </div>
        <div className='embed-info'>
          <img style={{ width: origin === 'loom' ? 16 : 20 }} src={originIcon} />
          {upperFirst(origin)} Video
        </div>
      </div>
    </div>
  )
}

export default EmbedBlock