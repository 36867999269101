import { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Switch, Route, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withFirebase } from '../../components/Firebase';

import Teams from './partials/Team'
import API from './partials/API';
import General from './partials/General';
import Integrations from '../Integrations/partials/IntegrationsSettingsTab';
import Billing from './partials/Billing';
import Plans from './partials/Plans';
import Checkout from './partials/Checkout'
import Paymentdone from './partials/PaymentDone';
import GistTemplates from './partials/GistTemplates';

import SubNav from '../../components/PlanttLayout/SubNav';
import Scrollable from '../../components/Scrollable';

import './style.scss';

function SettingsPage() {
  const role = useSelector(state => state.global.workspace.role)
  const params = useParams()

  const navSpec = useMemo(() => {
    const items = [
      { text: 'Team', icon: 'user-friends' },
      { text: 'Thread Templates', icon: 'sticky-note', target: '/templates' },
    ]
    if(role !== 'lite')
      items.push({ text: 'Billing', icon: 'file-invoice' })
    if(role === 'chat_bot')
      items.splice(3, 0, { text: 'Integrations', icon: 'network-wired' }, { text: 'API', icon: 'file-code' }, { text: 'General', icon: 'wrench' })
    return [{ items, target: item => item.target || `/${item.text.toLowerCase()}` }]
  }, [role])

  return (
    <Fragment>
      <Helmet>
        <title>Workspace Settings</title>
        <meta name="description" content="Plantt Console" />
      </Helmet>
      <div className='settings'>
        {!['plans', 'checkout', 'paymentdone'].includes(params.page)
          ? <Fragment>
              <SubNav navSpec={navSpec} targetPrefix='/settings' noTruncation />
              {!['team', 'templates'].includes(params.page)
                ? <Scrollable className='settings-card card'>
                    <Switch>
                      <Route path="/settings/api" component={API} />
                      <Route path="/settings/general" component={General} />
                      <Route path="/settings/integrations" component={Integrations} />
                      <Route path="/settings/billing" component={Billing} />
                    </Switch>
                  </Scrollable>
                : <Switch>
                    <Route path="/settings/templates" component={GistTemplates} />
                    <Route path="/settings/team" component={Teams} />
                  </Switch>
              }
            </Fragment>
          : <Switch>
              <Route path="/settings/plans" component={Plans} />
              <Route path="/settings/paymentdone/:plan" component={Paymentdone} />
              <Route path="/settings/checkout/:plan" component={Checkout} />
            </Switch>
        }
      </div>
    </Fragment>
  );
}

SettingsPage.propTypes = {
  match: PropTypes.any
};


export default withFirebase(SettingsPage);
