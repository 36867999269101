import { useSelector, useDispatch } from 'react-redux'
import { doAction, fetchDock, setActionInModal, setDockData } from '../../GistEditor/actions'
import { useParams } from 'react-router-dom'

function ActionButton({ id, payload, specRef, RenderButton, onModalOpen = () => {} }){
  const { accountId } = useParams()
  const dispatch = useDispatch()
  console.log(payload,specRef)
  const action = useSelector(state => state.global.allActions.find(a => (a.id === id && a.spec_ref === specRef)))

  if(!action)
    return null

  const onClick = async e => {
    if(action.input.length){
      onModalOpen()
      return dispatch(setActionInModal({ id: action.id, payload }))
    }

    dispatch(setDockData(specRef, { loading: true }))
    await doAction(action.spec_ref, { action_id: id, identity_id: accountId })
    dispatch(fetchDock({ specRef }, true))
  }

  return <RenderButton onClick={onClick}>{action.name}</RenderButton>
}

export default ActionButton