import { ReactEditor, withReact } from "cs-slate-react"
import isHotkey from "is-hotkey"
import { useMemo, useRef } from "react"
import { createEditor, Editor, Transforms } from "cs-slate"

const HISTORY_HOTKEYS = {
  'mod+z': 'undo',
  'mod+shift+z': 'redo',
  'mod+y': 'redo',
}

export const useEditor = ref => {
  return useMemo(() => {
    const editor = withReact(createEditor())
    const { isInline } = editor
    editor.isInline = element => element.type === 'link' ? true : isInline(element)
    
    if(typeof ref === 'function')
      ref(editor)
    else
      ref.current = editor

    return editor
  }, [])
}

export const handleHistoryShortcuts = (event, history, doAction, callback = () => {}) => {
  for(const hotkey in HISTORY_HOTKEYS){
    if(isHotkey(hotkey, event)){
      event.preventDefault()
      const { stop, dir } = {
        undo: { stop: !history.current.position, dir: -1 },
        redo: { stop: history.current.position === history.current.records.length - 1, dir: 1 }
      }[HISTORY_HOTKEYS[hotkey]]

      return !stop ? doAction(dir) : null
    }
  }
  callback()
}

export const defaultValue = () => [{ type: 'paragraph', children: [{ text: '' }] }]
const noop = () => {}

const withHistory = EditorComponent => {
  return function({ editorRef = noop, setValue, onKeyDown = noop, onSubmit = noop, ...props }) {
    const editor = useEditor(editorRef)
    const history = useRef({ records: [], position: null, stopChange: false })

    const doAction = dir => {
      Object.assign(history.current, { position: history.current.position + dir, stopChange: true })
      let { position, records } = history.current
      ReactEditor.deselect(editor)
      setValue(records[position].value)
      setTimeout(() => {
        history.current.stopChange = true
        Transforms.select(editor, Editor.start(editor, []))
        Transforms.setSelection(editor, records[position].selection)
      })
    }

    const handleKeyDown = (callback = noop) => event => {
      handleHistoryShortcuts(event, history, doAction, () => {
        callback(event, editor)
        onKeyDown(event, editor)
      })
    }

    const historyTimeout = useRef(null)

    const handleChange = (callback = noop) => value => {
      if(history.current.stopChange){
        history.current.stopChange = false
        return
      }else if(history.current.position < history.current.records.length - 1){
        history.current.records = history.current.records.slice(0, history.current.position + 1)
      }
      if(historyTimeout.current)
        clearTimeout(historyTimeout.current)

      callback(value, editor)

      historyTimeout.current = setTimeout(() => {
        history.current.records.push({ value: value, selection: editor.selection })
        history.current.position = history.current.records.length - 1
        historyTimeout.current = null
      }, 300)
    }

    const handleSubmit = async event => {
      await onSubmit(event)
      setValue(defaultValue())
    }

    return <EditorComponent {...{ editor, setValue, handleKeyDown, handleChange, handleSubmit, ...props }} />
  }
}

export default withHistory