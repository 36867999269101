import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {updateAccountMetadata} from '../containers/MyProfile/actions'
import { useHistory } from 'react-router-dom';

function useCalendly(){
  const history = useHistory();
  const dispatch = useDispatch()

  const handleMessage = e => {
    const isCalendlyEvent = e.data.event && e.data.event.indexOf('calendly') === 0;
    if (isCalendlyEvent) {
      if(e.data.event === 'calendly.event_scheduled') {
        const metadata = { discovery_call_scheduled: true, onboarding_status: 'done' }
        dispatch(updateAccountMetadata(metadata))  
        history.push('/onboarding-chatbot/5')
      }
    }
  }
  useEffect(() => {
    window.Intercom('update', { hide_default_launcher: false });
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
      window.Intercom('update', { hide_default_launcher: true });
    }
  }, [])

  const workspace = useSelector(state => state.global.workspace);
  const openCalendly = () => window.Calendly.initPopupWidget({ url: `https://calendly.com/plantt/demo?name=${workspace.name}&email=${workspace.email}` })
  
  return { openCalendly }
}

export default useCalendly;