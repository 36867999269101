import { NEW_sendGetRequest } from "../../../apis/api-utils"
import { BASE_URL2 } from "../../../apis/constant"
import { upperFirst } from "../../../helpers"

/*
export const prepareFields = (fields, alreadyMappedNumber = 0) => {
  return fields.map((field, index) => {
    return { 
    index: index + alreadyMappedNumber,
    name: '',
    planttName: field.key,
    type: { is: field.type, readonly: true },
    errors: { planttNameEmpty: false, planttNameNotUnique: false }
  }})
}
*/
export const prepareFields = (fields, identityMapping, alreadyMappedNumber = 0) =>  {
  return Object.values(fields).map(((field, index) => { 
    const name = Object.entries(identityMapping).find(line => line[1] === field.key)
    return {
      index: index + alreadyMappedNumber,
      name: (name && name[0]) || '',
      planttName: field.label,
      errors: { planttNameEmpty: false, planttNameNotUnique: false, appNameNotUnique: false },
      type: { is: (field && field.type) || 'string' , readonly: true },
      trackable : (field && field.track_changes) || false
    }
  }))
}

export const importDataFactory = () => ({
  rawFields: [],
  fields: [],
  data: [],
  uniqueError: false,
  uniqueIdentifier: ''
})

export const fetchFields = async (type = 'identities') => {
  const list = await NEW_sendGetRequest(`${BASE_URL2}${type}/fields`)
  if(!list.ok)
    throw new Error(list.text)

  if(type === 'contacts')
    list.text = Object.keys(list.text).map(key => ({ key, label: key.split('_').map(s => upperFirst(s)).join(' '), type: list.text[key] }))
    
  return list.text
}

export const presentableFieldName = name => {
  if(!name)
    return name
    
  name = name.split('_')
  for(const n in name)
    name[n] = name[n][0].toUpperCase() + name[n].slice(1)
  return name.join(' ')
}