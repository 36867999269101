import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ImportTable from '../../../components/PlanttTable/ImportTable'
import { setImportData, fetchConnectorSamples, updateConnectorMapping, updateLoading, fetchAccountFields, resetMappingTable } from '../actions'
import { useHistory } from 'react-router-dom'
import { trackMixpanelEvents } from '../../../helpers/mixpanel'
import useAddressSelector from '../../../helpers/useAddressSelector'
import { BlueButton } from '../../../components/Button'
import { useCurrentConnector } from '../helpers'

function Mapping({ mode = 'editing' }) {
    const { currentConnector } = useCurrentConnector()
    const dispatch = useDispatch()
    const history = useHistory()
    const samplesLoading  = useSelector(state => state.connectors.loadings.samples)
    const mappingOutput = useSelector(state => state.connectors.loadings.mappingOutput)
    const mappingFields = useSelector(state => state.connectors.connectorFields)

    useEffect(() => {
        if(currentConnector){
            dispatch(updateLoading('updateConnectorStatus',0))
            dispatch(updateLoading('mappingOutput',0))
            dispatch(updateLoading('samples',0))
            dispatch(fetchAccountFields())
            dispatch(fetchConnectorSamples(currentConnector.id, currentConnector.spec_ref))
            return () => dispatch(resetMappingTable())
        }
    }, [])

    useEffect(() => {
        if(mappingOutput === 2) {
            trackMixpanelEvents("connectors_done_mapping")
            dispatch(updateLoading('mappingOutput',0))
            if(mode === 'adding')
                history.push(`/apps/add/activate/${currentConnector.id}`)
        }
    }, [mappingOutput])
        
    const stateAddress = ['connectors', 'mappingTableData']
    const setState = data => dispatch(setImportData(data))

    return currentConnector ? (
        <>
            <div className="mapping-description">
                <i className='fas fa-info-circle' />
                <div>
                    <div className="description-title">
                        Hint
                    </div>
                    <div className="description-text">
                        Each column below should be mapped to a company property in Plantt.
                        Some have already been mapped based on their names.
                        Any field can be manually mapped to a company property with the dropdown menu.
                        New properties can be created as well.
                    </div>
                </div>
            </div>
            <ImportTable
                nameOfName={currentConnector.name}
                labelsAddress={['connectors', 'connectorSamplesLabels']}
                {...{ stateAddress, setState, EmptyState }}
                fieldList={mappingFields}
                loading={samplesLoading === 0 ? 8 : false}
                footer={<TableFooter {...{ stateAddress, setState }} />}
                inline
            />
        </>
    ) : null
}

function EmptyState() {
    return (
        <div id="mapping-empty-state">
        Oops, something went wrong...
        </div>
    )
}

function TableFooter({ stateAddress, setState }){
    const dispatch = useDispatch()
    const state = useAddressSelector(stateAddress)
  
    const mappingOutput = useSelector(state => state.connectors.loadings.mappingOutput)
    const { currentConnector } = useCurrentConnector()
    const pushTableOutput = () => dispatch(updateConnectorMapping(currentConnector.id))
    
    const addNewField = () => {
      const fields = [ ...state.fields ]
      const index = fields.length
      fields[index] = {
        index,
        name: '',
        planttName: '',
        trackable: false,
        type: { is: 'string', readonly: false },
        errors: { planttNameEmpty: false, planttNameNotUnique: false },
        firstRender: true
      }
      // fields[index + 1] = { index: index + 1, type: { isNew: true }, errors: {} }
  
      setState({ ...state, fields })
    }
  
    return (
      <>
        <BlueButton onClick={addNewField}>Add Field</BlueButton>
        <BlueButton onClick={pushTableOutput} loading={mappingOutput === 1}>Save</BlueButton>
      </>
    )
  }


export default Mapping