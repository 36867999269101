import { MoreHoriz } from '@material-ui/icons'
import { useMemo } from 'react'
import './style.scss'

function TextTruncator({ text, maxLen = 15, items, makeItem = () => null, ...props }){
  items = items ? items : []
  let truncated = (text ||  items)?.slice(0, maxLen)
  let tail = useMemo(() => text ? '...' : <MoreHoriz />, [text])
  
  if(text){
    if(truncated === text || truncated.length + 3 >= text.length)
      return text
    
    const lastChar = truncated.length - 1
    if(truncated[lastChar] === ' ')
      truncated = truncated.slice(0, lastChar)
  }else if(items && items.length <= maxLen){
    return items.map(makeItem)
  }

  return (
    <div className='truncator' {...props}>
      {text ? truncated : truncated.map(makeItem)}{tail}
      <div className='full-display' {...props}>{text || items.map(makeItem)}</div>
    </div>
  )
}

export default TextTruncator