import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import isHotkey from 'is-hotkey'
import { Editable, Slate } from 'cs-slate-react'
import { Editor, Transforms } from 'cs-slate'

import formats from './formats'
import RenderLeaf from './RenderLeaf'
import ToolbarButtons from './ToolbarButtons'
import LinkToolbar from './LinkToolbar'
import withHistory from './withHistory'
import ToolbarFormats, { isActive, toggle } from './ToolbarFormats'

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+Enter': 'submit'
}

const noop = () => {}

export default function TextEditor({ className, editor, value, setValue, plainText = false, handleKeyDown, handleChange, handleSubmit = noop, isFocused = false, setIsFocused = noop, onResize, onKeyUp = noop, onBlur = noop, submitButton, toolbarButtons = [], toolbarMode = null, ...props }) {
  if(!value || !setValue)
    throw new Error('TextEditor must receive both value and setValue props')
  
  const ref = useRef()
  const [resize, triggerResize] = useState(0)
  const [savedHeight, setSavedHeight] = useState(0)
  useLayoutEffect(() => {
    if(ref.current && onResize){
      const currentHeight = ref.current?.offsetHeight
      if(currentHeight !== savedHeight){
        setSavedHeight(currentHeight)
        onResize()
      }
    }
  }, [resize, ref.current])

  const onKeyDown = (event, editor) => {
    if(plainText)
      return
      
    for(const hotkey in HOTKEYS){
      if(isHotkey(hotkey, event)){
        event.preventDefault()
        const directive = HOTKEYS[hotkey]
        if(directive === 'submit')
          handleSubmit(event)
        else
          toggle.mark(editor, directive, isActive.mark(editor, directive))
        break
      }
    }
  }

  const onChange = newValue => {
    if(editor.selection){ //This prevents trouble when multiple editors are used simultaneously
      setValue(newValue)
      triggerResize(resize => resize + 1)
    }
  }

  useEffect(() => {
    if(isFocused)
      Transforms.select(editor, Editor.end(editor, []));
  }, [isFocused])

  const [linkModal, setLinkModal] = useState(false)
  const toolbarHidden = useMemo(() => toolbarMode === 'hide' || (!linkModal && !isFocused && toolbarMode === 'hide_on_blur'), [isFocused, toolbarMode, linkModal])

  const combinedOnBlur = () => {
    onBlur()
    setIsFocused(false)
  }
//console.log('slate rendered')
  return (
    <div className={`${className || 'note-editor'} ${!toolbarHidden ? 'is-focused' : ''}`} ref={ref}>
      <Slate editor={editor} value={value} onChange={handleChange(onChange)}>
        <Editable
          {...props}
          onFocus={() => setIsFocused(true)}
          onBlur={combinedOnBlur}
          renderElement={renderElement}
          renderLeaf={RenderLeaf}
          spellCheck
          onKeyDown={handleKeyDown(onKeyDown)}
          onKeyUp={onKeyUp}
        />
        {!toolbarHidden &&
        <div className='note-editor-toolbar'>
          <LinkToolbar {...{ editor, setLinkModal }} />
          <ToolbarFormats {...{ editor, linkModal, setLinkModal }} />
          <div className='toolbar-buttons'>
            <ToolbarButtons {...{ toolbarButtons, submitButton }} onSubmit={handleSubmit} />
          </div>
        </div>}
      </Slate>
    </div>
  )
}

const renderElement = ({ attributes, children, element }) => {
  const { Render } = formats.find(({ format }) => format === element.type) || {}
  if(Render)
    return <Render {...{ attributes, children, element }} />
  
  return <p {...attributes}>{children}</p>
}



export const TextEditorWithHistory = withHistory(TextEditor)