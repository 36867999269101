import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { endRemoveMoment } from "../actions"

const useMomentAnimation = ({ ref, doResize, moment }) => {
  const dispatch = useDispatch()
  const removeList = useSelector(state => state.moments?.removeList)
  const [style, setStyle] = useState({ })

  const closeGap = style => {
    const transitionLen = 300  
    const transition = `${style.transition}, height ${transitionLen / 1000}s ease-out, margin ${transitionLen / 1000}s ease-out`
    requestAnimationFrame(() => {
      setStyle({ ...style, transition, height: ref.current?.offsetHeight })
      requestAnimationFrame(() => {
        setStyle({ ...style, transition, height: 0, overflow: 'hidden', marginBottom: 0 })

        const start = new Date().getTime()
        const reset = () => requestAnimationFrame(() => {
          doResize()
          if(new Date().getTime() <= start + transitionLen)
            reset()
        })
        reset()

        setTimeout(() => dispatch(endRemoveMoment(moment)), transitionLen)
      })
    })
  }
  
  const doAnimation = () => {
    const transitionLen = 700
    const transition = `right ${transitionLen / 1000}s`
    requestAnimationFrame(() => {
      setStyle({ ...style, transition, position: 'relative', right: 0 })
      requestAnimationFrame(() => {
        let adjust = 0
        if(ref.current)
          adjust = ref.current.offsetLeft
        const newStyle = { ...style, transition, right: -window.innerWidth + adjust }
        setStyle(newStyle)
        setTimeout(() => closeGap(newStyle), transitionLen - 500)
      })
    })
  }

  useEffect(() => {
    if(removeList?.includes(moment.id))
      doAnimation()
  }, [removeList])

  return style
}

export default useMomentAnimation