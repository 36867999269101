import { useDispatch, useSelector } from 'react-redux';
import { startAddingConnector, updateLoading, setAuthenticationStatus } from './actions'
import { useHistory } from 'react-router-dom';
import { trackMixpanelEvents } from '../../helpers/mixpanel'

const useAddApp = (onContinue = () => {}) => {
    const connectors = useSelector(state => state.global.connectorSpecs)
    const workspaceRole = useSelector(state => state.global.workspace.role)
    const history = useHistory()
    const dispatch = useDispatch()

    return appId => {
        const index = connectors.findIndex(connector => connector.id === appId)
        if(workspaceRole === 'beta' && connectors[index].is_premium)
            return history.push('/apps/book-a-demo');

        localStorage.setItem('inAddingConnectorIndex', index);
        dispatch(updateLoading('mappingOutput',0))
        dispatch(setAuthenticationStatus(0))
        dispatch(startAddingConnector(connectors[index]))
        trackMixpanelEvents("connectors_start_adding_connector")
        onContinue()
        history.push('/apps/add/authenticate')
    }
}

export default useAddApp