import React from 'react'
import CheckIcon from '@material-ui/icons/Check';
import { useHistory } from 'react-router-dom';
import { useLayoutConfig } from '../../../components/PlanttLayout/LayoutConfig';
import { OrangeButton } from '../../../components/Button';


function PlanItem({ name, title, description, price, features, secondary_title }) {
  const history = useHistory()

  return (
    <React.Fragment>
      <div className="plan-grid-box ">
        <div>
          <div className="box-title" >{title}</div>
          <div className="box-description" >{description}</div>
        </div>
        <div>
          {price &&
            <div className="box-price" >
              <div className="price">{price}$</div>
              <div className="price-details">
                <div className="top">per month</div>
                <div className="bottom">when billed annually</div>
              </div>
            </div>}
          {title !== 'Enterprise'
          ? <OrangeButton style={{ height: 40, width: 160}} onClick={() => history.push(`/settings/checkout/${name}`)}>
              Subscribe Now
            </OrangeButton>
          : <a href = "mailto:team@plantt.io">
              <OrangeButton style={{ height: 40, width: 160}}>Contact Us</OrangeButton>
            </a>
          }
        </div>
      </div>
      <div className="plan-grid-box-second ">
        <div className="features-box-title" >
        {secondary_title}
        </div>
        {features.map((feature) => (
          <div className="feature" >
            <span className="checkIcon"><CheckIcon /></span> {feature}
        </div>
        ))}
      </div>
    </React.Fragment>
  );
}

function Plans() {
  useLayoutConfig({ title: 'Subscription Plans' })
  
  return (
    <div className="choose-plan-container">
      <div className="choose-plan-title">Choose your plan</div>
      <div className="choose-plan-second-title">Analyze, automize and improve your customer communications to work more efficiantly</div>
      <div className='choose-plan-grid'>
        <PlanItem
          title='Basic'
          name='basic'
          description='Greate for improving CX and finding bottle necks.'
          price='250'
          features={['500 Active Users Included', '+ $0.20 Per Additional User', 'Up to 10 Automations', 'Conversation Designer', 'Chatbot Builder', 'Sentiment Analysis', 'Apps & Integrations']}
          secondary_title='Starter feature, plus:'
        />
        <PlanItem
          title='Growth'
          name='growth'
          description='For teams that need to manage high volumes and improve SLAs.'
          price='600'
          features={['3500 Active Users Included','+ $0.20 Per Additional User','Up to 50 Automations','Multi-language support (up to 5 different languages)', 'Premium Channels - WhatsApp, Apple Business Chat, Zendesk, etc.','Dedicated Automation Expert']}
          secondary_title='Basic feature, plus:'
        />
        <PlanItem
          title='Enterprise'
          description='For organizations that want fully personalized and advanced features.'
          features={['Unlimited Automations','Volume discounts','Voice analysis','Developer tools & Full API Access','Custom integrations','Salesforce Integration','Premium Support & SLAs']}
          secondary_title='Growth feature, plus:'
        />
      </div>
    </div>
  );
}


export default Plans
