import { InfoRounded, Refresh } from "@material-ui/icons"
import { useCallback, useEffect, useState } from "react"
import { NEW_sendPostRequest } from "../../../../apis/api-utils"
import { BASE_URL2 } from "../../../../apis/constant"
import { useDeserialized } from "../../../../components/TextEditor/deserialize"
import RenderNode from "../../../../components/TextEditor/RenderNode"
import WidgetWrapper from "./WidgetWrapper"
import { useAppInfo } from "../../helpers"

import CardBlock from "./CardBlock"
import ToggleBlock from "./ToggleBlock"
import { TableCard } from "../Table"
export { ToggleBlock }

function WidgetBlock({ block, dragPreview, BlockComponent = RenderBlock }){
  const { payload, dragPayload = {}, blocks: presetBlocks } = block
  const { spec_ref } = payload || dragPayload
  const [internalBlocks, setInternalBlocks] = useState(presetBlocks || null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if(presetBlocks?.length)
      setInternalBlocks(presetBlocks)
  }, [presetBlocks])

  const getWidget = useCallback(async () => {
    if(payload && spec_ref){
      if(error)
        setError(null)

      try {
        const isVersion2 = ['hubspot', 'monday'].includes(spec_ref)
        const response = await NEW_sendPostRequest(`${BASE_URL2}widget/${spec_ref}`, {}, JSON.stringify({ ...payload, version: isVersion2 ? 2 : undefined }))
        if(!response.ok)
          throw new Error(response.text)

        setInternalBlocks(response.text)
      } catch(err) {
        console.error('Failed to fetch widget:', err)
        setError({ })
      }
    }
  }, [spec_ref, error])

  useEffect(() => getWidget(), [spec_ref])

  const { name, icon_url } = useAppInfo(spec_ref)

  // Table blocks inside a widget are somewhat of a mess at the moment.
  // Below is a sort of workaround, with the noEdit prop, until we figure out
  // how to make it work properly.
  if(internalBlocks?.length === 1 && internalBlocks[0].type === 'table'){
    const block = internalBlocks[0]
    return block.objects.map((object, index) => (
      <TableCard
        key={index}
        blockIndex={0}
        name={`${name} - ${object.title}`}
        specRef={spec_ref}
        noEdit
        {...{ object, index, block, icon_url }}
      />
    ))
  }

  return (
    <WidgetWrapper loading={!internalBlocks && !error} {...{ icon_url, name: payload ? name : undefined }} style={{ minWidth: !error ? '70%' : '92%' }} ref={dragPreview}>
      {!error 
        ? internalBlocks?.map((block, index) => <BlockComponent key={index} block={block} />)
        : <RenderError {...{ error, getWidget, ...payload }} />
      }
    </WidgetWrapper>
  )
}

function RenderError({ error, getWidget }){
  return (
    <div className='widget-error'>
      <InfoRounded />
      <div>
        <div className='widget-error-title'>
          Something went wrong with this widget
        </div>
        {error.text && <div>{error.text}</div>}
        <div className='widget-reload'>
          Press the "refresh" icon to give it another try: <Refresh onClick={getWidget} />
        </div>
      </div>
    </div>
  )
}

export function RenderBlock({ block, ...props }){
  const { type, text } = block
  const content = useDeserialized(text)
  switch(type){
    case 'basic':
      return content ? <div style={{ display: 'flex', flexDirection: 'column' }}>{content.map((node, index) => <RenderNode {...node} key={index} />)}</div> : null
    case 'toggle':
      return <ToggleBlock {...block} />
    case 'card':
      return <CardBlock {...{ ...block, ...props }} />
    case 'widget':
      return <WidgetBlock block={block} />
    default:
      return null
  }
}

export default WidgetBlock