import React, { useEffect, useState } from 'react'
import { Chart } from 'chart.js'
import PropTypes from 'prop-types'

function ChartTemplate({ containerRef, data, type, options = {}, widthPercentage = 100, maxWidth, presetRatio, portionOfHeight = 0.8, children }){
  const [_, setChart] = useState() // eslint-disable-line no-unused-vars
  const [width, setWidth] = useState(296)
  const [ratio, setRatio] = useState(presetRatio || 0.8)

  useEffect(() => {
    if(!presetRatio)
      setRatio(Math.round(containerRef.current.offsetHeight * portionOfHeight / containerRef.current.offsetWidth * 100) / 100)
    setWidth(containerRef.current.offsetWidth * widthPercentage / 100)
  }, [containerRef])

  useEffect(() => {
    if(containerRef.current){
      const canvas = containerRef.current.querySelector('canvas').getContext('2d')
      setChart(new Chart(canvas, {
        type,
        data,
        options
      }))
    }
  }, [data, containerRef, ratio])

  const Children = children

  return (
    <Children key={ratio}>
      <div className='canvas-container' style={{ width: !maxWidth || width < maxWidth ? width : maxWidth }}>
        <canvas width='100%' height={`${ratio*100}%`} />
      </div>
    </Children>
  );
}

ChartTemplate.propTypes = {
  containerRef: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  options: PropTypes.object,
  widthPercentage: PropTypes.number,
  maxWidth: PropTypes.number,
  presetRatio: PropTypes.number,
  portionOfHeight: PropTypes.number,
  children: PropTypes.func.isRequired
}

export default ChartTemplate;