import { useState, useRef, useEffect, useMemo } from 'react'
import FieldError from '../../../components/FormControls/FieldError'
import { goIfEnterKey } from '../../../components/utils'
function  AuthField ({field, moveForward, authFieldsValues, handleFieldChange, nextClicked}) {
    const inputEl = useRef(null);
    const [anchorEl, setanchorEl] = useState(null)
    useEffect(() => {
        setanchorEl(inputEl.current)
    }, [inputEl])

    const showDescription = useMemo(() => field.description?.toLowerCase() !== field.label?.toLowerCase(), [field])
    //TEMP WORKAROUND

    return (
        <div className="field-box">
            <div>
                <div className="name">{field.label}</div>
                {showDescription && <div className="description">{field.description}</div>}
            </div>
            <input disabled={field.disabled} onKeyDown={(event) => goIfEnterKey(event, moveForward)} ref={inputEl} type="text" value={authFieldsValues[`${field.key}`].value} onChange={(e) => handleFieldChange(field.key,e.target.value)} className="textinput" placeholder={field.disabled ? `` : `Enter ${field.label}`} />
            <FieldError anchorEl={anchorEl} hasErrors={(nextClicked && authFieldsValues[`${field.key}`].value === '' && !field.disabled && field.required)? true : false}>Field is Empty</FieldError>
        </div>
    )
}
export default AuthField