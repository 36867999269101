import { HelpRounded } from "@material-ui/icons"
import Tooltip from "../../../components/Tooltip/new"
import FullscreenTable from "./FullscreenTable"

function OverviewBlock({ title, tooltipProps, children, fullscreen, rightFloat, ...props }){
  return (
    <div className='notebook-note-block' {...props}>
      <div className='account-overview-block'>
        <div className='overview-block-title'>
          <div>
            {title}
            {tooltipProps &&
            <Tooltip styles={{ wrapper: { maxWidth: 188, marginLeft: 8 } }} lean placement='right' {...tooltipProps}>
              <i className='far fa-info-circle' />
            </Tooltip>}
          </div>
          {fullscreen && <FullscreenTable {...fullscreen} />}
          {rightFloat}
        </div>
        {children}
      </div>
    </div>
  )
}

export default OverviewBlock