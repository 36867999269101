import { useEffect, useLayoutEffect, useRef, useState } from 'react'

function useToggle({ id, open = false, setOpen, transitionLen = 600, events = {} }){
  const [internalOpen, setInternalOpen] = useState(open)
  if(!setOpen){
    open = internalOpen
    setOpen = setInternalOpen
  }
  
  const isOpen = open === id || open === true
  const [close, setClose] = useState(false)

  const contentRef = useRef()
  const [initialLoad, setInitialLoad] = useState(true)
  const [contentHeight, setContentHeight] = useState()
  const [autoHeight, setAutoHeight] = useState(null)

  useLayoutEffect(() => {
    if(initialLoad){
      setInitialLoad(false)
      if(!isOpen)
        setContentHeight(0)
    }

    if(contentRef.current){
      if(isOpen || !initialLoad)
        setContentHeight(contentRef.current.firstChild.offsetHeight)
    
      if(isOpen){
        setAutoHeight(setTimeout(() => setContentHeight('auto'), transitionLen))
      }else{
        setClose(true)
        if(autoHeight){
          clearTimeout(autoHeight)
          setAutoHeight(null)
        }
      }
    }
  }, [isOpen])

  useEffect(() => {
    if(close){
      setClose(false)
      setContentHeight(0)
    }
  }, [close])

  useEffect(() => {
    if(contentHeight === 'auto' && events.afterOpen)
      events.afterOpen()
  }, [contentHeight])

  const toggleItem = () => {
    setOpen(isOpen ? null : id || true)
    if(events.onOpen)
      events.onOpen(open !== (id || true))
  }

  const contentProps = {
    style: {
      height: contentHeight,
      transition: `height ${transitionLen / 1000}s`,
      overflow: contentHeight === 'auto' ? 'visible' : 'hidden',
      flexShrink: 0
    },
    ref: contentRef
  }

  return { toggleItem, isOpen, contentProps, RenderArrow }
}

function RenderArrow({ isOpen }){
  return <i className='far fa-chevron-down' style={{ transition: '0.2s', transform: isOpen ? 'rotate(-180deg)' : 'translateY(1px)' }} />
}

export default useToggle