import { isEqual } from "lodash"
import { useLayoutEffect, useMemo, useState } from "react"
import serialize from "../../../../components/TextEditor/serialize"
import { setBlock } from "../../actions"
import EditorBlock from '../Editor'

function ChartBlock({ blockId, processData = data => data, RenderChart, readonly, ...props }){
  const { title, ...payload } = props.block.payload

  const [data, setData] = useState({ processed: [] })
  useLayoutEffect(() => {
    // Without this check, any change in the gist triggers a reset of
    // the data object, which causes the chart to rerender and flash.
    if(!isEqual(payload, data.raw)){
      setData({
        raw: payload,
        processed: processData(payload)
      })
    }
  }, [payload])

  const setTitle = text => setBlock(blockId, { payload: { ...props.block.payload, title: { text: serialize(text) } } })

  const memoizedChart = useMemo(() => <RenderChart data={data.processed} />, [data])

  return (
    <div className='note-chart-block'>
      {!readonly
        ? <EditorBlock
            {...props}
            blockId={`${blockId}/title`}
            auto_focus={title.auto_focus}
            value={title.text}
            setValue={setTitle}
            placeholder='Give your chart a title'
          />
        : <div className={`chart-block-title ${!title.text ? 'placeholder' : ''}`}>{title.text || 'Give your chart a title'} </div>
      }
      {memoizedChart}
    </div>
  )
}

export default ChartBlock