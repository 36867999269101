import { makeStyles } from '@material-ui/core'
import { memo, useEffect, useMemo, useState } from 'react'

const defaultColors = ['#0088ff', '#ffaa00', '#ff7700', '#ff0033', '#9911aa', '#aadd22']

const centeredFlex = { display: 'flex', justifyContent: 'center', alignItems: 'center' }
const useStyles = makeStyles({
  letters: ({ colors }) => ({
    color: colors.color || '#fff',
    whiteSpace: 'nowrap',
    lineHeight: 1
  }),
  image: {
    // border: '1px #155788 solid',
    overflow: 'hidden'
  },
  common: ({ size, fontRatio }) => ({
    height: size,
    width: size,
    fontSize: Math.round(size * fontRatio),
    borderRadius: 100,
    flexShrink: 0,
    zIndex: 2,
    ...centeredFlex
  })
})

function UserAvatar({ colors = {}, size = 32, fontRatio = 0.4, website, url, name, onLoad = () => {} }){
  const classes = useStyles({ size, fontRatio, colors })
  const [broken, setBroken] = useState(false)

  const { computedColor, avatarText } = useMemo(() => {
    let avatarText = ''
    let computedColor = colors.backgroundColor
    if(name){
      if(!computedColor){
        const charCode = name[0].toLowerCase().charCodeAt(0) - 97
        computedColor = defaultColors[charCode - Math.floor(charCode / defaultColors.length)*defaultColors.length]
      }

      const [first, last] = name.split(' ')
      avatarText = (first ? first[0].toUpperCase() : '') + (last ? last[0].toUpperCase() : '')
    }else if(!computedColor){
      computedColor = '#ccc'
    }
    return { avatarText, computedColor }
  }, [name])

  useEffect(() => {
    if(!website || broken)
      onLoad()
  }, [broken])

  return (website || url) && !broken
    ? <div className={`account-avatar ${classes.image} ${classes.common}`}>
        <img src={url || `https://logo.clearbit.com/${website}?size=${size}`} style={{ width: size }} onError={() => setBroken(true)} />
      </div>
    : <div
        className={`account-avatar ${classes.letters} ${classes.common}`}
        style={{ backgroundColor: computedColor }}
      ><span>{avatarText}</span></div>
}

export default memo(UserAvatar)