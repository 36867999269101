import firebase from 'firebase/app';
import { NEW_sendPostRequest } from '../../apis/api-utils'
import { BASE_URL, CREATE_ACCOUNT_URL } from '../../apis/constant';
import { getTrialMetadata } from '../../helpers';
import { fetchAccounts, setToastMessage } from '../App/actions';

const sendInvites = (accountId, { emails, sendInvites }) => async (dispatch, getState) => {
  try {
    if(!emails.length){
      dispatch(fetchAccounts())
      return { success: true }
    }

    let existingEmailCount = 0
    const invited = []
    const checkIfExists = await Promise.all(emails.map(item => {
      return firebase.auth().fetchSignInMethodsForEmail(item.email) || []
    }))
    for(const i in checkIfExists){
      if(checkIfExists[i].length){
        invited.push(emails[i].email)
        existingEmailCount++
      }
    }
    
    emails = emails.filter(item => !invited.includes(item.email))
    if(invited.length){
      let message = `The following emails could not be invited as they already have a Plantt account: ${invited.join(', ')}`
      if(invited.length === 1)
        message = `${invited} could not be invited as they already have a Plantt account`
      dispatch(setToastMessage(message, 'info'))
    }
    
    const options = { headers: { 'X-PlanttAccountID':  accountId } }
    const response = await Promise.all(emails.map(item => {
      return NEW_sendPostRequest(`${BASE_URL}account/invite`, options, JSON.stringify({ email: item.email, name: '', send_invite: sendInvites }))
    }))
    for(const r in response)
      if(response[r].ok)
        invited.push(emails[r].email)

    if(invited.length === response.length + existingEmailCount)
      dispatch(fetchAccounts())
    else
      dispatch(setToastMessage('Not all invites were sent successfully', 'error'))
    
    let links
    if(!sendInvites)
      links = response.map(({ text }, index) => ({ link: text, email: emails[index].email }))
    return { success: true, links }
  } catch(error) {
    console.error('Failed to create invites:', error)
    return { error }
  }
}

export const createNewAccount = state => async dispatch => {
  const { name, role } = state
  const data = { email: '', name, contact_name: name, role, firebase_owner_uid: '', metadata: getTrialMetadata() }
  try {
    const account = await NEW_sendPostRequest(CREATE_ACCOUNT_URL, {}, JSON.stringify(data))
    if(!account.ok)
      throw new Error(account.text)

    const inviteResult = await dispatch(sendInvites(account.text.id, state))
    dispatch(setToastMessage('Account created succesfully', 'success'))
    return inviteResult
  } catch(error) {
    console.error('Failed to create account:', error)
    return { error }
  }
}