/* eslint-disable react-hooks/rules-of-hooks */

import { ClickAwayListener, Popper } from "@material-ui/core"
import { DatePicker } from "@material-ui/pickers"
import { useEffect, useMemo, useRef, useState } from "react"
import { v4 as uuidv4 } from 'uuid'
import AutosizeInput from "react-input-autosize"
import Select from "../../../components/FormControls/Select"
import Hoverable from "../../../components/Hoverable"
import NewTable from "../../../components/PlanttTable/NewTable"
import { applyUpdates, getNoteInfo } from "../actions"

const columnTypes = [
  { label: 'Text', type: 'text', icon: 'align-left' },
  { label: 'Select', type: 'select', icon: 'chevron-square-down' },
  { label: 'Date', type: 'date', icon: 'calendar-alt' }
]

function EditableTable({ readonly }){
  return ({ block, blockId }) => {
    const columns = useColumns(block.payload.schema, blockId)
    const [menu, setMenu] = useState(null)
    const currentColumn = menu && block.payload.schema.find(col => col.key === menu.column)
 
    const onClick = (e, column) => {
      setMenu({ anchor: e.target, column: column.id })
    }

    const onClickAway = e => {
      if(!e.target.classList.contains('new-table-column-header') && !e.target.closest('.new-table-column-header'))
        setMenu(null)
    }

    const onTypeClick = type => () => {
      setColumnType(blockId, menu.column, type)
      setMenu(null)
    }

    return (
      <>
        <NewTable
          columns={columns}
          itemsAddress={['notes', 'currentNote', 'blocks', blockId, 'payload', 'objects']}
          inline
          className='note-editable-table-block'
          rowHeight={40}
          headerActions={{ onClick }}
          expansionActions={{ onAddColumn: () => addTableColumn(blockId), onAddRow: () => addTableRow(blockId) }}
        />
        <Popper id='table-column-settings' open={Boolean(menu)} anchorEl={menu?.anchor} placement='bottom-start'>
          <ClickAwayListener onClickAway={onClickAway}>
            <div className='card' style={{ width: menu?.anchor.offsetWidth }}>
              <div className='section-header'>Column Type</div>
              {columnTypes.map(({ label, type, icon }) => {
                const isCurrent = currentColumn?.type === type
                return (
                  <Hoverable
                    className={isCurrent ? 'is-current' : undefined}
                    key={type}
                    onClick={onTypeClick(type)}
                  >
                    <i className={`${!isCurrent ? 'far' : 'fas'} fa-${icon}`} /> {label}
                  </Hoverable>
                )
              })}
            </div>
          </ClickAwayListener>
        </Popper>
      </>
    )
  }
}

const useColumns = (schema, blockId) => {
  return useMemo(() => {
    return schema.map(column => ({
      id: column.key,
      icon: columnTypes.find(t => t.type === column.type)?.icon,
      name: column.name,
      Content: props => {
        const ref = useRef()
        const [active, setActive] = useState(false)

        useEffect(() => {
          if(active && ref.current){
            const select = query => ref.current.querySelector(query)
            const input = select('input')
            const el = select('.MuiInput-root') || select('.select-selector')
            if(el)
              el?.click()
            else if(input)
              input?.focus()
          }
        }, [active, ref.current])

        const isDate = column.type === 'date'
        return (
          <div
            ref={ref}
            tabIndex={active ? -1 : 0}
            className={`editable-table-cell ${active ? 'is-active' : ''}`}
            onDoubleClick={() => !isDate ? setActive(true) : null}
            onBlur={e => !e.target.classList.contains('editable-table-cell') ? setActive(false) : null}
            onKeyDown={e => e.target.classList.contains('editable-table-cell') && e.key === 'Enter' ? setActive(true) : null}
          >
            {active || isDate
              ? <RenderCell blockId={blockId} {...props} {...column} colKey={column.key} />
              : props.item[column.key]
            }
          </div>
        )
      }
    }))
  }, [schema, blockId])
}

function RenderCell({ item, index, colKey, type, options, blockId }){
  const ref = useRef()
  const setCellValue = value => setTableCell(colKey, value, blockId, index)
  switch(type){
    case 'text':
      return ( 
        <AutosizeInput
          value={typeof item[colKey] === 'string' ? item[colKey] : ''}
          onChange={e => setCellValue(e.target.value)}
        />
      )
    case 'select':
      return (
        <Select
          items={options || []}
          initialValue={typeof item[colKey] === 'string' ? item[colKey] : ''}
          onSelect={({ selectedItem }) => setCellValue(selectedItem)}
          placeholder=''
        />
      )
    case 'date':
      const date = parseInt(item[colKey])
      return (
        <DatePicker
          disableToolbar
          variant="inline"
          value={!isNaN(date) ? new Date(date) : null}
          onChange={newDate => setCellValue(newDate.getTime())}
          format='LLLL do yyyy'
        />
      )
    default:
      return null
  }
}

const setTableCell = (key, value, blockId, index) => {
  const { notePath } = getNoteInfo()
  applyUpdates(`${notePath}/blocks/${blockId}/payload/objects/${index}/${key}`, 'set', value)
}

const setColumnType = (blockId, key, type) => {
  const { notePath, blocks } = getNoteInfo()
  const { schema, objects } = blocks[blockId].payload
  const index = schema.findIndex(col => col.key === key)
  const column = { ...schema[index], type }
  if(type === 'select')
    column.options = objects
      .map(obj => obj[key])
      .filter((value, index, self) => typeof value === 'string' && value && self.indexOf(value) === index)
  else
    delete column.options
    
  applyUpdates(`${notePath}/blocks/${blockId}/payload/schema/${index}`, 'set', column)
}

const addTableColumn = blockId => {
  const { notePath, blocks } = getNoteInfo()
  const { schema, objects } = blocks[blockId].payload
  const key = uuidv4()
  const updates = {
    ...Object.fromEntries(objects.map((_, index) => ([`/objects/${index}/${key}`, '']))),
    [`/schema/${schema.length}`]: { key, name: `Column ${schema.length + 1}`, type: 'text' }
  }
  applyUpdates(`${notePath}/blocks/${blockId}/payload`, 'update', updates)
}

const addTableRow = blockId => {
  const { notePath, blocks } = getNoteInfo()
  const { schema, objects } = blocks[blockId].payload
  const newObj = Object.fromEntries(schema.map(({ key }) => [key, '']))
  applyUpdates(`${notePath}/blocks/${blockId}/payload/objects/${objects.length}`, 'set', newObj)
}

export default EditableTable