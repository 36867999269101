import { Fragment, useMemo, useRef, useState } from "react"
import AutosizeInput from "react-input-autosize"
import { Close } from "@material-ui/icons";
import FieldError from "./FieldError";

import './EmailBox.scss'

function EmailChip({ email, error, remove, containerEl }) {
  const ref = useRef()

  const errorText = useMemo(() => {
    switch(error){
      case 'invite': return 'invited'
      case 'member': return 'on your team'
      case 'system': return 'in our system'
    }
  }, [error])

  return (
    <Fragment>
      <div className='email-chip' key={email} ref={ref}>
        {email} <Close onClick={() => remove(email)} />
      </div>
      <FieldError containerEl={containerEl} anchorEl={ref.current} hasErrors={Boolean(error)}>
        Already {errorText}
      </FieldError>
    </Fragment>
  )
}

function EmailBox({ displayed = true, emails, onRemove, onFocus, onSave, error, setError, containerEl }){
  const ref = useRef()
  const containerRef = useRef()

  const [value, setValue] = useState('')
  const [focused, setFocused] = useState(false)

  const save = () => {
    if(!value.length){
      return
    }else if(value.includes('@')){
      onSave(value.trim())
      setValue('')
    }else{
      setError(true)
    }
  }

  const remove = email => {
    onRemove(email)
    if(ref.current)
      ref.current.focus()
  }

  const inputEvents = {
    onChange: e => setValue(e.target.value),
    onFocus: e => {
      setFocused(true)
      if(onFocus)
        onFocus(e)
    },
    onBlur: () => {
      setFocused(false)
      save()
    },
    onKeyDown: e => {
      if(e.key === 'Enter' && !value.length)
        return
      if(['Tab', 'Enter', ' ', ','].includes(e.key)){
        e.preventDefault()
        save()
      }
    },
    onInput: () => setError(false),
    onPaste: e => {
      e.preventDefault()
      const pasted = e.clipboardData.getData('text').split(' ')
      let paste = ''
      while(pasted.length){
        const item = pasted[0].replace(/,/g, '')

        if(item.includes('@'))
          onSave(item.trim())
        else if(item.length)
          paste += item
          
        pasted.shift()
      }

      if(paste.length){
        setValue(paste)
        setError(true)
      }
    }
  }

  const focusInput = e => {
    if(ref.current && !e.target.classList.contains('email-chip') && !e.target.closest('.email-chip'))
      ref.current.focus()
  }

  return (
    <div className={`email-box ${focused ? 'is-focused' : ''} ${error ? 'has-errors' : ''}`} onClick={focusInput} ref={containerRef}>
      {emails.map(item => <EmailChip key={item.email} {...{ ...item, remove, containerEl }} />)}
      <label>
        <AutosizeInput placeholder={!emails.length ? 'Enter email addresses' : undefined} {...{ value, ref, ...inputEvents }} />
      </label>
      <FieldError containerEl={containerEl} anchorEl={containerRef.current} hasErrors={displayed && error}>Emails must include an @ sign</FieldError>
    </div>
  )
}

export default EmailBox