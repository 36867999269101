import { sendGetRequest, sendPostRequest, sendDeleteRequest, sendPutRequest } from '../../apis/api-utils';
import { BASE_URL } from '../../apis/constant';

import {
  FETCH_INTEGRATION_FOR_EDIT_SUCCESS,
  FETCH_INTEGRATION_ERROR,
  UPDATE_INTEGRATION_SUCCESS,
  UPDATE_INTEGRATION_ERROR,
  DELETE_INTEGRATION_ERROR,
  FETCH_AVAILABLE_INTEGRATIONS_SUCCESS,
  FETCH_AVAILABLE_INTEGRATIONS_ERROR,
  FETCHING_CURRENT_INTEGRATIONS,
  FETCH_CURRENT_INTEGRATIONS_SUCCESS,
  FETCH_CURRENT_INTEGRATIONS_ERROR,
  RENAME_INTEGRATION_ERROR,
  DELETE_INTEGRATION_SUCCESS,
  FETCHING_AVAILABLE_INTEGRATIONS,
  CLEAR_INTEGRATION_FOR_EDIT
} from './constants';

export const updateIntegrationSpecs = data => ({ type: 'UPDATE_SPECS', data });
export const fetchIntegrationSpecs = (integrationName) => async dispatch => {

  try {
    const integrationData = await sendGetRequest(`${BASE_URL}integrations/${integrationName}/spec`);
    dispatch(updateIntegrationSpecs(integrationData));
  } catch (error) {
    console.log('Error fetching Integrations Specs from server', error);
  }
}
export const integrationIsDone = (integrationName,integrationParams) => async getState => {
  try {
    const payload = {
        "integration_name": integrationName,
        "account_id": getState().global.workspace.id,
        "auth_type": "api_key",
        "auth_key": "string",
        "auth_params": integrationParams
    }
     await sendPostRequest(`${BASE_URL}integrations/install`, {}, JSON.stringify(payload));
  } catch (error) {
    console.log('Error posting integration to server:', error);
  }
}

const fetchIntegrationForEditSuccess = integration => ({ type: FETCH_INTEGRATION_FOR_EDIT_SUCCESS, integration });
const fetchIntegrationError = error => ({ type: FETCH_INTEGRATION_ERROR, error });

export const fetchIntegrationForEdit = id => async dispatch => {
  try {
    dispatch({ type: CLEAR_INTEGRATION_FOR_EDIT });
    const integration = await sendGetRequest(`${BASE_URL}integration/${id}`);
    dispatch(fetchIntegrationForEditSuccess(integration));
  } catch (error) {
    console.log('error fetching integration from server', error);
    dispatch(fetchIntegrationError(error));
  }
}

const updateIntegrationSuccess = integration => ({ type: UPDATE_INTEGRATION_SUCCESS, integration });
const updateIntegrationError = error => ({ type: UPDATE_INTEGRATION_ERROR, error });

export const updateIntegration = integration => async dispatch => {
  try {
    await sendPutRequest(`${BASE_URL}integration/${integration.id}`, {}, JSON.stringify(integration));
    dispatch(updateIntegrationSuccess(integration));
  } catch (error) {
    console.log('error updating integration', error);
    dispatch(updateIntegrationError(error));
  }
}

export const renameIntegration = (integration, name) => async dispatch => {
  try {
    dispatch(updateIntegration({ ...integration, name }));
  } catch (error) {
    console.log('error renaming integration', error);
    dispatch({ type: RENAME_INTEGRATION_ERROR, error });
  }  
}

const deleteIntegrationSuccess = id => ({ type: DELETE_INTEGRATION_SUCCESS, id });
const deleteIntegrationError = error => ({ type: DELETE_INTEGRATION_ERROR, error });

export const deleteIntegration = id => async dispatch => {
  try {
    await sendDeleteRequest(`${BASE_URL}integration/${id}`);
    dispatch(deleteIntegrationSuccess(id));
  } catch (error) {
    console.log('error deleting integration from server', error);
    dispatch(deleteIntegrationError(error));
  }
}

const fetchAvailableIntegrationsSuccess = integrations => ({ type: FETCH_AVAILABLE_INTEGRATIONS_SUCCESS, integrations });
const fetchAvailableIntegrationsError = error => ({ type: FETCH_AVAILABLE_INTEGRATIONS_ERROR, error });
const fetchingAvailableIntegrations = () => ({ type: FETCHING_AVAILABLE_INTEGRATIONS })

export const fetchAvailableIntegrations = (options = {}) => async dispatch => {
  try {
    if(options.refresh) dispatch(fetchingAvailableIntegrations());
    const integrations = await sendGetRequest(`${BASE_URL}integrations/spec`);
    dispatch(fetchAvailableIntegrationsSuccess(integrations));
  } catch (error) {
    console.log('error fetching integrations from server', error);
    dispatch(fetchAvailableIntegrationsError(error));
  }
}

const fetchCurrentIntegrationsSuccess = integrations => ({ type: FETCH_CURRENT_INTEGRATIONS_SUCCESS, integrations });
const fetchCurrentIntegrationsError = error => ({ type: FETCH_CURRENT_INTEGRATIONS_ERROR, error });
const fetchingCurrentIntegrations = () => ({ type: FETCHING_CURRENT_INTEGRATIONS })

export const fetchCurrentIntegrations = () => async dispatch => {
  try {
    dispatch(fetchingCurrentIntegrations());
    const integrations = await sendGetRequest(`${BASE_URL}integrations`);
    dispatch(fetchCurrentIntegrationsSuccess(integrations));
  } catch (error) {
    console.log('error fetching integrations from server', error);
    dispatch(fetchCurrentIntegrationsError(error));
  }
}
    