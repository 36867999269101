import { forwardRef } from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

function WidgetWrapper({ name, icon_url, children, wrapperClassName, loading, ...props }, ref){
  return (
    <div className={`note-widget-block ${wrapperClassName || ''}`} ref={ref}>
      {icon_url && <div className='app-avatar'><img src={icon_url} /></div>}
      {!loading
        ? <div {...props}>
            {name && <div className='app-name'>{name}<div className='app-token'>App</div></div>}
            {children}
          </div>
        : <SkeletonTheme color='#e3e3e3' highlightColor='#e9e9e9'>
            <Skeleton width={500} />
            <div className='mrkdwn-side-line'>
              <div></div>
              <div style={{ width: 380 }}><Skeleton /><br/><Skeleton /></div>
            </div>
          </SkeletonTheme>
      }
    </div>
  )
}

export default forwardRef(WidgetWrapper)