import { useMemo } from "react"
import { useSelector } from "react-redux"
import DueDate from "../../Tasks/DueDate"
import { setTask } from "../../Tasks/actions"
import EditorBlock from "./Editor"

function TaskBlock({ block, dragPreview, ...props }){
  const taskId = block.payload.id
  const task = useSelector(state => state.notes.tasks[taskId])

  const converted = useMemo(() => {
    return { type: 'field', payload: { text: task?.description }, auto_focus: block.auto_focus }
  }, [task, block.auto_focus])

  return (
    <div ref={dragPreview} className={`note-task-block ${!task ? 'task-not-found' : ''}`}>
      <i className='fad fa-clipboard-check' />
      {task
        ? <>
            <EditorBlock
              {...props}
              block={converted}
              setValue={description => setTask(taskId, task, { description })}
              placeholder='Type your task here'
            />
            <div className='task-interaction-icons'>
              <i
                className={`fad fa-check-circle ${task.completed ? 'completed' : ''}`}
                onClick={() => setTask(taskId, task, { completed: !task.completed || null })}
              />
              <DueDate
                {...{ task, taskId }}
                iconProps={{ active: task.datetime, icon: 'alarm-clock' }}
                setTask={newDate => setTask(taskId, task, { datetime: newDate?.getTime() || null })}
                tooltipText={`${task.datetime ? 'Update' : 'Set'} due date`}
              />
            </div>
          </>
        : 'We could not find this task... It might have been deleted.'
      }
    </div>
  )
}

export default TaskBlock