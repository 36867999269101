import { makeStyles } from '@material-ui/core';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

const tabulatorStyles = makeStyles({
  tabStyle: ({ initial, selected }) => ({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px transparent solid',
    color: initial,

    '&:hover': {
      borderColor: initial,
    },
    '&.selected': {
      color: selected,
      borderColor: selected,
    }
  })
})

function Tabulator({ tabs, mode, modeAddress, setMode, colors = { initial: '#19270d', selected: '#155788' } }){
  const dispatch = useDispatch()
  const reduxMode = useSelector(state => {
    if(!modeAddress)
      return undefined
    let m = state;
    modeAddress.forEach(i => m = m[i])
    return m;
  })

  const { tabStyle } = tabulatorStyles(colors)

  return tabs.map(tab => {
    const type = tab.toLowerCase();
    return (
      <div
        className={`${tabStyle} ${(mode || reduxMode) === type ? 'selected' : ''}`}
        onClick={() => mode ? setMode(type) : dispatch(setMode(type))}
        key={type}
      >
        {tab}
      </div>
    )
  })
}

export default Tabulator