import { forwardRef, useState } from "react"
import './style.scss'

function SimpleHoverableIcon({ className, icon, type = 'fal', active, activeType = 'fas', activeIcon, ...props }, ref) {
  const [hovered, setHovered] = useState(false)
  const isActive = active || hovered

  return (
    <i
      className={`${isActive ? activeType : type} fa-${(isActive && activeIcon) || icon} ${className || ''}`}
      {...props}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      ref={ref}
    />
  )
}

export default forwardRef(SimpleHoverableIcon)