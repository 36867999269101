import OverviewBlock from "./OverviewBlock"
import { useDispatch, useSelector } from 'react-redux'
import NewTable from '../../../components/PlanttTable/NewTable'
import columns from './columns'
import { Fragment, useMemo, useState } from 'react'
import EntityEditor from '../../Accounts/EntityEditor'
import { fetchAccountContacts } from '../actions'
import { updateFields } from '../../Accounts/actions'
import { ContactsIcon } from '../../../icons'
import Paginator from "../../../components/PlanttTable/NewTable/Paginator"
import { OrangeButton } from "../../../components/Button"
import { useHistory } from "react-router"

function Contacts(){
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const page = useSelector(state => state.accounts.contacts.page)
  const loading = useSelector(state => state.accounts.loading.contacts)
  const total = useSelector(state => state.accounts.contacts.records_amount)

  const fullscreen = useMemo(() => ({
    columns,
    itemsAddress: ['accounts', 'contacts', 'records'],
    loading,
    tableTitle: <Fragment><div>Contacts</div><Paginator setPage={p => dispatch(fetchAccountContacts({ page: p }))} step={25} {...{ page, total }} /></Fragment>,
    onCellClick: (_, { id }) => setOpen(id)
  }), [columns, page, loading, total])

  return (
    <OverviewBlock title='Contacts' tooltipProps={{ title: 'Contacts', description: 'All the contacts available in this workspace' }} fullscreen={fullscreen}>
      <NewTable
        columns={columns}
        itemsAddress={['accounts', 'contacts', 'preview']}
        inline
        EmptyState={EmptyState}
      />
      <EditContact {...{ open, setOpen }} />
    </OverviewBlock>
  )
}

function EmptyState(){
  const history = useHistory()
  return (
    <Fragment>
      <div>
        It seems you do not have any contacts associated with this account yet...
      </div>
      <OrangeButton outline rounded onClick={() => history.push('/apps')}>Check Apps</OrangeButton>
    </Fragment>
  )
}

function EditContact({ open, setOpen }){
  const records = useSelector(state => state.accounts.contacts.records)
  const { contact, fields } = useMemo(() => {
    const contact = records.find(r => r.id === open) || {}
    const fields = { ...((open && contact?.fields) || {}) }
    return { contact, fields }
  }, [open, records])

  return (
    <EntityEditor
      open={Boolean(open)}
      setOpen={setOpen}
      title={<Fragment><ContactsIcon className='entity-editor-icon' /> Update {fields.first_name} {fields.last_name}</Fragment>}
      fieldsAddress={['global', 'fieldLists', 'contacts']}
      entity={contact}
      type='Contact'
      onSubmit={updateFields}
    />
  )
}

export default Contacts