import useAddApp from "../../../Apps/useAddApp";
import { useAppInfo } from "../../helpers";
import WidgetWrapper from "../Widget/WidgetWrapper";
import { OrangeButton } from "../../../../components/Button"

function DisabledActionBlock({ block }){
  const { spec_ref } = block?.payload || {}
  const { name, icon_url } = useAppInfo(spec_ref)
  const connectApp = useAddApp()

  return (
    <WidgetWrapper {...{ name, icon_url }}>
      <div className='disabled-action-content'>
        Connect {name} to your workspace in just a few minutes to start doing actions with it.
        <OrangeButton rounded padding='8px 12px' onClick={() => connectApp(spec_ref)}>Connect {name}</OrangeButton>
      </div>
    </WidgetWrapper>
  )
}

export default DisabledActionBlock