import { useLayoutEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useLayoutConfig } from "../../components/PlanttLayout/LayoutConfig"
import Scrollable from "../../components/Scrollable"
import Account from "../Account"
import AppsDock from "../Account/AppsDock"
import ActionModal from "../Account/partials/ActionModal"
import GistEditor from "../GistEditor"
import { getNote } from "../GistEditor/actions"
import GistLoading from "./GistLoading"
import useAuthorize from "./useAuthorize"

import PlanttIcon from '../../images/plantt.svg'
import GistActionsMenu from "../GistEditor/partials/GistActionsMenu"

function Gists(){
  const { noteId, workspaceId } = useParams()
  const dispatch = useDispatch()
  const workspace = useSelector(state => state.global.workspace)
  const defaultTemplates = useSelector(state => state.notes.defaultTemplates)
  const currentViewer = useSelector(state => state.notes.currentViewer)

  const hasGist = useSelector(state => Object.keys(state.notes.currentNote).length > 0)
  const hasAccount = useSelector(state => Boolean(state.notes.currentNote.account_id))

  const authorized = useAuthorize()
  useLayoutConfig(authorized ? { title: 'account_header', nonScrollable: true, HeaderRight: GistActionsMenu } : undefined)
  useLayoutEffect(() => {
    if(noteId && authorized)
      return dispatch(getNote())
  }, [noteId, authorized])

  if(!hasGist || (workspace && !defaultTemplates.length))
    return <GistLoading />

  return hasAccount && workspace?.id === workspaceId && workspace?.role !== 'lite'
    ? <Account />
    : (workspace
        ? <div className='account-overview'>
            <div style={{ height: '100%' }}>
              <GistEditor />
              {/*<AppsDock />*/}
              <ActionModal />
            </div>
          </div>
        : <div id='gist-loading'>
            <div id='gist-view-header'>
              <div>{currentViewer?.workspace_name}</div>
              <img src={PlanttIcon} />
            </div>
            <GistEditor />
          </div>
      )
}

export default Gists