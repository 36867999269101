import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateConnectorAuthenticationInfo, setAuthenticationStatus } from '../actions'
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator'
import { setToastMessage } from '../../App/actions'
import { trackMixpanelEvents } from '../../../helpers/mixpanel'
import { parse } from 'query-string'
import { useCurrentConnector } from '../helpers'

function Reauthorize() {
    const { currentConnector } = useCurrentConnector()

    const dispatch = useDispatch()
    const history = useHistory()
    const authenticationStatus = useSelector(state => state.connectors.authenticationStatus)
    const authenticationError = useSelector(state => state.connectors.errors.authenticationPageError)

    useEffect(() => {
        const { code } = parse(history.location.search)
        if(code) {
            localStorage.removeItem('isReauthorizingConnector')
            dispatch(updateConnectorAuthenticationInfo(currentConnector.id,{ code }))
        }
    }, [history.location.search])

    useEffect(() => {
        if(authenticationStatus === 2 || authenticationStatus === 3) {
            dispatch(setAuthenticationStatus(0))
            if(authenticationStatus === 3)
                dispatch(setToastMessage('Reauthorization succeeded!','success'))
            if (authenticationStatus === 2)
                dispatch(setToastMessage(`Reauthorization failed: ${authenticationError}`,'error'))

            trackMixpanelEvents("connectors_reauthorized_connector")
            history.replace(`/apps/view/${currentConnector.id}`)
        }
        
    }, [authenticationStatus])
    return (
        <div className="settings-padded samples-loading-status">
            <LoadingIndicator />
            <div>Finishing Reauthorization Process...</div>
        </div>
    )
}
export default Reauthorize