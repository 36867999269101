import OverviewBlock from "./OverviewBlock"
import NewTable from '../../../components/PlanttTable/NewTable'
import { Fragment, useMemo } from "react"
import { upperFirst } from "../../../helpers"
import { formatDistanceToNow } from "date-fns"
import SourceIcon from "../../../components/SourceIcon"
import Paginator from "../../../components/PlanttTable/NewTable/Paginator"
import { useDispatch, useSelector } from "react-redux"
import { fetchRelationshipActivity } from "../actions"
import { useHistory, useParams } from "react-router"
import { OrangeButton } from "../../../components/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import Truncator from "../../../components/Truncator"
import ParticipantAvatars from "../../../components/UserAvatar/ParticipantAvatars"

const columns = [
  {
    id: 'when',
    Content: ({ item }) => {
      let { timestamp, futureActivity } = useMemo(() => {
        const { timestamp } = item?.payload || {}
        if(timestamp)
          return { timestamp: new Date(timestamp * 1000), futureActivity: new Date() < timestamp }

        return {}
      }, [item])

      return timestamp && formatDistanceToNow(timestamp).replace('about ', '') + (!futureActivity ? ' ago' : '')
    }
  },
  {
    name: 'Type',
    Content: ({ item }) => {
      let { type, source } = useMemo(() => {
        const { source, type } = item?.payload || {}
        if(source || type)
          return {
            source: Object.entries(source).flat()[0],
            type: type ? upperFirst(type) : source?.split('_').map(str => upperFirst(str)).join(' '),
          }
    
        return {}
      }, [item])

      return (
        <Fragment>
          {source && <SourceIcon source={source} size={16} style={{ marginRight: 8 }} />}
          {type}
        </Fragment>
      )
    }
  },
  {
    name: 'Title',
    Content: ({ item }) => {
      const { payload } = item || {}
      const title = useMemo(() => <Truncator text={upperFirst(payload?.title || '')} maxLen={10} />, [item])

      if(payload.url)
        return (
          <a href={payload.url} target='_blank'>
            <span>{title}</span> <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
        )

      return title
    }
  },
  {
    id: 'avatars',
    Content: ({ item }) => {
      const participants = item?.payload.participants
      return participants?.length ? <ParticipantAvatars participants={participants} use='allContacts' avatarSize={24} /> : null
    }
  },
  {
    id: 'tags',
    Content: ({ item }) => {
      const tags = item?.payload.tags || []
      return (
        <Truncator
          style={{ display: 'flex', alignItems: 'flex-end' }}
          maxLen={2}
          items={tags}
          makeItem={tag => <div className='relationship-activity-tag'>{tag}</div>}
        />
      )
    }
  },
]

function RelationshipActivity(){
  const { id } = useParams()
  const dispatch = useDispatch()
  const page = useSelector(state => state.accounts.relationshipActivity.page)
  const loading = useSelector(state => state.accounts.loading.relationshipActivity)
  const total = useSelector(state => state.accounts.relationshipActivity.records_amount)

  const fullscreen = useMemo(() => ({
    columns,
    itemsAddress: ['accounts', 'relationshipActivity', 'records'],
    loading,
    tableTitle: <Fragment><div>Activity</div><Paginator setPage={p => dispatch(fetchRelationshipActivity(id, p))} {...{ page, total }} /></Fragment>
  }), [columns, page, loading, total, id])

  return (
    <OverviewBlock title='Activity' tooltipProps={{ title: 'Log', description: 'Activity from all channels connected to Plantt' }} fullscreen={fullscreen}>
      <NewTable
        columns={columns}
        itemsAddress={['accounts', 'relationshipActivity', 'preview']}
        inline
        EmptyState={EmptyState}
        getId={item => item.payload.id}
        // onSort={onSort}
      />
    </OverviewBlock>
  )
}

function EmptyState(){
  const history = useHistory()
  return (
    <Fragment>
      <div>
        There does not seem to be any activity on this account yet...
      </div>
      <OrangeButton outline rounded onClick={() => history.push('/apps')}>Check Apps</OrangeButton>
    </Fragment>
  )
}

export default RelationshipActivity