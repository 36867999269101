import Scrollable from "../../../components/Scrollable";

function ErrorState({ setHasError }){
  return (
    <Scrollable id='note-editor-content'>
      <div id='note-error-state'>
        Oops! Something went wrong. Click the refresh icon to get back to where you were:
        <i className='fas fa-redo-alt' onClick={() => setHasError(false)} />
      </div>
    </Scrollable>
  )     
}

export default ErrorState