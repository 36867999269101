import { useEffect, useState } from 'react'
import Autocomplete from '../../FormControls/Autocomplete'
import useAddressSelector from '../../../helpers/useAddressSelector'

function AppField({ item, stateAddress, setState, labelsAddress, containerEl, fieldList }){
  
  const state = useAddressSelector(stateAddress)
  const labels = useAddressSelector(labelsAddress) || {}
  const labeldFieldList = fieldList.map(FieldName => labels[FieldName] || FieldName)
  const labelsSwap = Object.entries(labels)
    .reduce((obj, [key, value]) => ({ ...obj, [value]: key }), {});
  const { index, errors } = item
  const name = labels[item.name] || item.name
  const [hasErrors, setHasErrors] = useState(false)
  useEffect(() => {
      setHasErrors(errors.appNameNotUnique)
  }, [errors])

  const onSelect = item => {
    item = fieldList.find(i => i === item) || item || ''
    const fields = [ ...state.fields ]
    fields[index] = { ...fields[index], errors: { ...fields[index].errors } }

    for(const e in fields[index].errors)
      fields[index].errors[e] = false

    for(const field of fields){
      const name = field.name || ''
      if(field.index !== index && name.length && name === labelsSwap[item]){
        fields[index].errors = { ...fields[index].errors, appNameNotUnique: true }
        break
      }
    }

    Object.assign(fields[index], {
      name:   labelsSwap[item] || item ,
      type: fields[index].type
    })

    setState({ ...state, fields })
    
  }

  const errorText = errors.planttNameEmpty ? 'Field is required' : (errors.appNameNotUnique ? 'Must be unique' : '')

  return  !item.type.isNew ? (
    <Autocomplete
      style={{ display: 'none' }}
      initialValue={name}
      inputProps={{ placeholder: 'Select field...', height: 42 }}
      items={labeldFieldList}
      // getItem={presentableFieldName}
      onSelect={(onSelect)}labels
      resetOnClickAway
      errorProps={{ hasErrors, containerEl, text: errorText }}
    />
  ) : null

}

export default AppField