import { useContext, useCallback } from 'react';
import AuthContext from '../../containers/App/AuthContext';
import { FirebaseContext } from '../Firebase';

const useLogout = () => {
  const auth = useContext(AuthContext)
  const firebase = useContext(FirebaseContext)

  const logout = useCallback(async () => {
    try {
      await firebase.auth.signOut()
      localStorage.removeItem('PlanttAccountID')
      if(window.location.hostname.includes('plantt.io'))
        window.location.replace('https://plantt.io')
      else
        window.location.reload()
    } catch(error) {
      console.error(error)
    }
  }, [auth, firebase])

  return logout
}


export default useLogout