import { formatDistanceToNow } from "date-fns"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { firebase } from "../.."

import ConfirmModal from "../../components/ConfirmModal"
import TooltipComponent from "../../components/Tooltip/new"
import RenderTags from "../GistEditor/partials/RenderTags"

import { deleteNote, saveAsTemplate, updateNoteMetadata, setNoteListFromSnapshot } from '../GistEditor/actions'
import HoverableIcon from "../../components/HoverableIcon"
import SimpleHoverableIcon from "../../components/HoverableIcon/Simple"
import Skeleton from "react-loading-skeleton"
import { useLocation } from "react-router-dom"
import AssociatedMembers from "../../components/UserAvatar/AssociatedMembers"

const skeletonItems = [new Array(4).fill(true).map((_, index) => ({ id: index, skeleton: true }))]
const groups = ['Today', 'Last 7 Days', 'Last 30 Days', 'Earlier']

function GistList({ EmptyState, ListHeader, accountId = null }){
  const location = useLocation()
  const dispatch = useDispatch()
  const workspaceId = useSelector(state => state.global.workspace.id)
  const gists = useSelector(state => {
    const { noteList } = state.notes
    return noteList.pathname === location.pathname ? noteList.items : skeletonItems
  })

  const [nowDeleting, setNowDeleting] = useState(null)
  const [loading, setLoading] = useState(false)
  const doDelete = async () => {
    setLoading(true)
    await deleteNote(nowDeleting)
    setLoading(false)
    setNowDeleting(null)
  }

  useEffect(() => {
    const gistsRef = firebase.database.ref(workspaceId).child('notes').orderByChild('account_id').equalTo(accountId)
    gistsRef.on('value', snapshot => dispatch(setNoteListFromSnapshot(location.pathname, snapshot)))

    return () => gistsRef.off()
  }, [accountId, location.pathname])

  return (
    <div className='notebook-note-block'>
      {ListHeader && <ListHeader gists={gists} />}
      {gists.map((group, index) => (
        <div key={index} className='gist-group-wrapper'>
          {group.length > 0 && <h3 className='gist-group-title'>{location.pathname === '/starred' ? 'Starred' : groups[index]}</h3>}
          {group.map(gist => <GistListItem key={gist.id} {...gist} setNowDeleting={setNowDeleting} />)}
        </div>
      ))}
      {!gists.flat().length && EmptyState && <EmptyState />}
      <ConfirmModal
        open={Boolean(nowDeleting)}
        onClose={() => setNowDeleting(null)}
        text='Are you sure you would like to delete this gist?'
        loading={loading}
        onConfirm={doDelete}
      />
    </div>
  )
}

function GistListItem({ name, created_at, updated_at, id, setNowDeleting, skeleton, ...gist }){
  const { metadata = {} } = gist
  const history = useHistory()
  const workspaceId = useSelector(state => state.global.workspace.id)

  const onItemClick = e => {
    if(!e.target.className.includes('fa-'))
      history.push(`/${workspaceId}/${id}`)
  }

  return (
    <div className='gist-list-item' onClick={onItemClick}>
      <SimpleHoverableIcon active={metadata.favorite} icon='star' onClick={() => !skeleton && updateNoteMetadata({ id, favorite: !metadata.favorite || null })} />
      <i 
        className={`fad fa-check-circle ${metadata.completed ? 'completed' : ''}`}
        onClick={() => !skeleton && updateNoteMetadata({ id, completed: !metadata.completed || null })}
      />
      <div>
        <div className={`gist-name ${metadata.completed ? 'completed' : ''}`}>
          {!skeleton
            ? name || <div className='untitled-gist'>Untitled</div>
            : <Skeleton width={300} />
          }
        </div>
        <div className='last-update'>
          {!skeleton
            ? `${formatDistanceToNow(updated_at || created_at)} ago`
            : <Skeleton width={160} />
          }
        </div>
      </div>
      {!skeleton &&
      <>
        <RenderTags gist={gist} readonly />
        <div className='avatars-wrapper'>
          {/* <AssociatedMembers memberIds={gist.collaborators} tooltipTitle='Collaborators' /> */}
        </div>
        <GistActions {...{ metadata, id, setNowDeleting }} />
      </>}
    </div>
  )
}

function GistActions({ id, metadata, setNowDeleting }){
  return (
    <div className='gist-actions'>
      <TooltipComponent
        placement='top'
        content='Save as template'
        lean={true}
        styles={{ wrapper: { maxWidth: 68, textAlign: 'center'}}}
      >
        <span onClick={() => saveAsTemplate(id)}>
          <HoverableIcon icon='file-invoice' />
        </span>
      </TooltipComponent>
      <HoverableIcon active={metadata.deleted} icon='trash' onClick={() => setNowDeleting(id)} />
    </div>
  )
}

export default GistList
