
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'
import { useMemo } from 'react'

function AppStatusBox({ connectorSpec, currentConnector }) {
    const { title, text, className } = useMemo(() => {
        const { name, status, sync_status, authentication } = currentConnector
        if(sync_status?.status === `failed` || authentication?.status !== 'authenticated')
            return {
                className: 'err',
                title: `Error syncing ${name}`,
                text: authentication.status !== 'authenticated'
                    ? `Connector authentication status is ${authentication.status}`
                    :   (sync_status?.message.length
                            ? `Error: ${sync_status.message}`
                            : `Something went wrong while syncing ${name}. Try to reconnect the app or contact us.` 
                        )
            }

        if(status === 'draft')
            return {
                className: 'draft',
                title: `Not Syncing ${name}`,
                text: `${name} is in "Draft" mode and is currently not syncing data into your workspace.`
            }

        if(connectorSpec.data_sync)
            switch(sync_status.status){
                case 'done': return {
                    className: 'synced',
                    title: `${name} Synced Succesfully`
                }
                case 'in_progress': return {
                    className: 'syncing',
                    title: `Syncing ${name}`,
                    text: `Plantt is currently syncing your data from ${name} into your workspace.`
                }
            }
        if(authentication?.status === 'authenticated')
            return {
                className: 'synced',
                title: `${name} is Authenticated`
            }
        
        return {}
    }, [currentConnector])

    return (
        <div id='app-status-box' className={className}>
                <div className="notice-first-row">
                    <InfoRoundedIcon />
                    <span className="title">{title}</span>
                </div>
                <div className="text">
                    {text}
                </div>
        </div>
    )
}
export default AppStatusBox

