export const SET_LOADING = 'plantt/Accounts/SET_LOADING'
export const SET_CURRENT_SEGMENT = 'plantt/Accounts/SET_CURRENT_SEGMENT'

export const SET_FETCH_ACCOUNTS_TIMESTAMP = 'plantt/Accounts/SET_FETCH_ACCOUNTS_TIMESTAMP'
export const FETCH_ACCOUNTS_SUCCESS = 'plantt/Accounts/FETCH_ACCOUNTS_SUCCESS'
export const FETCH_ACCOUNTS_ERROR = 'plantt/Accounts/FETCH_ACCOUNTS_ERROR'

export const CLEAR_CURRENT_ACCOUNT = 'plantt/Accounts/CLEAR_CURRENT_ACCOUNT'
export const FETCH_CURRENT_ACCOUNT_SUCCESS = 'plantt/Accounts/FETCH_CURRENT_ACCOUNT_SUCCESS'

export const FETCH_FIELD_LIST_SUCCESS = 'plantt/Accounts/FETCH_FIELD_LIST_SUCCESS'
export const FETCH_FIELD_LIST_ERROR = 'plantt/Accounts/FETCH_FIELD_LIST_ERROR'

export const FETCH_RELATIONSHIP_ACTIVITY_SUCCESS = 'plantt/Accounts/FETCH_RELATIONSHIP_ACTIVITY_SUCCESS'
export const FETCH_RELATIONSHIP_ACTIVITY_ERROR = 'plantt/Accounts/FETCH_RELATIONSHIP_ACTIVITY_ERROR'
export const FETCH_RELATIONSHIP_ACTIVITY_INITIATED = 'plantt/Accounts/FETCH_RELATIONSHIP_ACTIVITY_INITIATED'

export const SET_CONJUNCTION = 'plantt/Accounts/SET_CONJUNCTION'
export const MODIFY_FILTER = 'plantt/Accounts/MODIFY_FILTER'
export const ADD_NEW_FILTER = 'plantt/Accounts/ADD_NEW_FILTER'
export const DELETE_FILTER = 'plantt/Accounts/DELETE_FILTER'
export const CLEAR_FILTERS = 'plantt/Accounts/CLEAR_FILTERS'
export const RESET_FILTERS = 'plantt/Accounts/RESET_FILTERS'

export const FETCH_ACCOUNT_CONTACTS_SUCCESS = 'plantt/Accounts/FETCH_ACCOUNT_CONTACTS_SUCCESS'

export const PRE_MODIFY_CONTACT = 'plantt/Accounts/PRE_MODIFY_CONTACT'
export const MODIFY_CONTACT_SUCCESS = 'plantt/Accounts/MODIFY_CONTACT_SUCCESS'
export const MODIFY_CONTACT_ERROR = 'plantt/Accounts/MODIFY_CONTACT_ERROR'

export const TOGGLE_CHECKED_CONTACT = 'plantt/Accounts/TOGGLE_CHECKED_CONTACT'
export const CLEAR_CHECKED_CONTACTS = 'plantt/Accounts/CLEAR_CHECKED_CONTACTS'
export const MERGE_CHECKED_CONTACTS_SUCCESS = 'plantt/Accounts/MERGE_CHECKED_CONTACTS'
export const DELETE_CHECKED_CONTACTS_SUCCESS = 'plantt/Accounts/DELETE_CHECKED_CONTACTS'

export const PRE_SET_CSM = 'plantt/Accounts/PRE_SET_CSM'
export const SET_CSM_SUCCESS = 'plantt/Accounts/SET_CSM_SUCCESS'
export const SET_CSM_ERROR = 'plantt/Accounts/SET_CSM_ERROR'

export const FETCH_TRACKABLES_SUCCESS = 'plantt/Accounts/FETCH_TRACKABLES_SUCCESS'

export const SET_DISPLAYED_IN_DOCK = 'plantt/Accounts/SET_DISPLAYED_IN_DOCK'