import { makeStyles } from "@material-ui/core"
import { useCallback, useEffect, useState } from "react"

const useStyles = makeStyles({
  container: {
    height: 5,
    width: '100vw',
    position: 'absolute',
    top: 0,
    zIndex: 4000,
    display: 'flex',
  },
  left: {
    backgroundColor: '#3aaf4b',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10
  },
  right: {
    backgroundColor: '#3aaf4b',
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10
  }
})

function LoadingBar({ loading = true }){
  const transitionLen = 900
  const transition = `width ${transitionLen / 1000}s ease-out`
  const classes = useStyles()
  const [style, setStyle] = useState({ transition, width: 0 })
  const [containerStyle, setContainerStyle] = useState({ justifyContent: 'space-between ', backgroundColor: 'transparent', transition: 'opacity 0.3s' })

  const doAnimation = useCallback((containerStyle, style) => {
    const containerCentered = containerStyle.justifyContent === 'center'
    const justifyContent = containerCentered ? 'space-between' : 'center'
    const newStyle = { ...style, width: style.width ? 0 : '50%' }
    const newContainerStyle = {
      ...containerStyle,
      justifyContent,
      backgroundColor: containerCentered ? containerStyle.backgroundColor : '#2a8037'
    }

    requestAnimationFrame(() => {
      setContainerStyle(newContainerStyle)
      requestAnimationFrame(() => setStyle(newStyle))
    })
    return { newStyle, newContainerStyle }
  }, [])

  const [leave, setLeave] = useState(null)
  useEffect(() => {
    if(loading)
      setTimeout(() => doAnimation(containerStyle, style), transitionLen)
    else
      setLeave(setTimeout(() => setContainerStyle({ ...containerStyle, opacity: 0 }), 200))
  }, [style])

  useEffect(() => {
    if(loading){
      if(leave){
        clearTimeout(leave)
        setLeave(null)
      }
      const newContainerStyle = { ...containerStyle, opacity: 1 }
      setContainerStyle(newContainerStyle)
      doAnimation(newContainerStyle, style)
    }
  }, [loading])

  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 2200)
  //   setTimeout(() => setLoading(true), 6000)
  //   setTimeout(() => setLoading(false), 8200)
  //   setTimeout(() => setLoading(true), 12000)
  //   setTimeout(() => setLoading(false), 13200)
  // }, [])

  return (
    <div className={classes.container} style={containerStyle}>
      <div className={classes.left} style={style}></div>
      <div className={classes.right} style={style}></div>
    </div>
  )
}

export default LoadingBar