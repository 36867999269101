import mime from 'mime-types'

const types = {
  xls: { icon: 'file-spreadsheet', color: '#3aaf4b' },
  xlsx: { icon: 'file-spreadsheet', color: '#3aaf4b' },
  doc: { icon: 'file-word', color: '#155788' },
  docx: { icon: 'file-word', color: '#155788' },
  csv: { icon: 'file-csv', color: '#3aaf4b' },
  pdf: { icon: 'file-pdf', color: '#cc2121' },
  json: { icon: 'file-code', color: '#4f1588' }
}

function FileBlock({ block }){
  const { url, name, size } = block.payload

  const type = mime.extension(block.payload.type)

  return (
    <div className='note-file-wrapper'>
      <i className={`fas fa-${types[type].icon ?? 'file'}`} style={{ color: types[type].color }} />
      <div>
        {name}
        <div className='file-metadata'>
          {type.toUpperCase()} file, {Math.floor(size / 1000)}kb
        </div>
      </div>
    </div>
  )
}

export default FileBlock