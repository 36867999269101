import { useDispatch, useSelector } from 'react-redux'
import { updateCurrentNoteSelection, clearSelection, deleteMultipleBlocks } from './actions'
import { focusHiddenInput, focusAndSelect } from './helpers'
import  { blocksRef } from "./useGistHistory"
import { Editor, Transforms } from "cs-slate"

import isHotkey from "is-hotkey"
import { store } from '../..'
import { indexOf } from 'lodash'
import { ReactEditor } from 'cs-slate-react'

export const changeSelection = (direction, blocksGroups, oldSelection) => { //direction can be up or down
  const isThereMoreBlocks = () => {
    if(direction === 'up')
      return blocksOrder[upperBlockLocation] && blocksOrder[upperBlockLocation] !== 'noteTitle' ? true : false
    if(direction === 'down') {
      return blocksOrder[bootomBlockLocation] ? true : false
    }
  }
  const addItem = (currentSelection) => {
    if(isThereMoreBlocks()) {
      if(direction === 'up')
        return [{blockId:blocksOrder[upperBlockLocation],index:currentSelection.length}, ...currentSelection]
      if(direction === 'down')
        return [...oldSelection, {blockId:blocksOrder[bootomBlockLocation],index:currentSelection.length*-1}]
    }
    return currentSelection
  }


  let newSelection = [...oldSelection]
  const blocksOrder = []
  for(const group of blocksGroups) 
    for(const block of group.blocks)
      blocksOrder.push(block)

  const idsWithRealLocation = oldSelection.map(selectionItem => ({blockId:selectionItem.blockId, index:blocksOrder.indexOf(selectionItem.blockId)}))
  idsWithRealLocation.sort((a, b) => a.index - b.index)
  const upperBlockLocation = blocksOrder.indexOf(idsWithRealLocation[0].blockId) - 1
  const bootomBlockLocation = blocksOrder.indexOf(idsWithRealLocation[idsWithRealLocation.length-1].blockId) + 1

  //get the sum of all the indexes
  const reducer = (accumulator, curr) => accumulator + curr;
  const indexesSum = newSelection.map(selectionItem => selectionItem.index).reduce(reducer)
  //Add or Remove block item to selection
  if(indexesSum < 0)  //add to the selection with direction down
    if(direction === 'down') newSelection = addItem(newSelection) //Adding down
    else if(direction === 'up')  newSelection.pop()  //remove from bottom
  if(indexesSum > 0) 
    if(direction === 'down')  newSelection.shift() //remove from the top
    else if(direction === 'up') newSelection = addItem(newSelection) // adding to the top
  if(indexesSum === 0) newSelection = addItem(newSelection)


  //Finally, if we have a change, return it
  if(oldSelection.length !== newSelection.length) return newSelection
  return false
}
export const useTriggerSelectionShortcuts = () => {
  return (e, blocks) => {
    const { selection, blockOrder } = store.getState().notes
    const localBlockOrder = [...blockOrder].filter(blockId => blockId !== 'noteTitle').reverse()
    let clearCurrentSelection = false
    const copySelectionToClipboard = () => {
      const selectionBlocks = selection.map(selectionBlock => blocks[selectionBlock.blockId])
      .filter(block => block.type === 'basic')
      .map(block => block.payload.text)
      .join('\n')
      console.log(selectionBlocks)
      navigator.clipboard.writeText(selectionBlocks).then(() =>{
        console.log('content is in clipboard')
      })
    }
    if (isHotkey('Ctrl+a', e)) {
      focusHiddenInput()
      const newSelection = localBlockOrder.filter(id => id != 'noteTitle').map((blockId, index) => ({blockId, index}))
      console.log({newSelection})
      store.dispatch(updateCurrentNoteSelection(newSelection))
    }
    if(selection.length) {
      console.log('999999999')
      if(isHotkey('Esc',e))
        clearCurrentSelection = true
      if(isHotkey('ctrl',e) && (!isHotkey('ctrl+c',e) || isHotkey('ctrl+x',e) )){
        e.preventDefault()
        return
      } 
      if(isHotkey('ctrl+c',e)) {
        copySelectionToClipboard()
        clearSelection()
        return
      }
      if(isHotkey('ctrl+x',e)) {
        copySelectionToClipboard()
        deleteMultipleBlocks(selection.map(selectionBlock => selectionBlock.blockId))
        clearSelection()
        return
      }
      if(isHotkey('delete',e) || isHotkey('backspace',e)) {
        const previousBlockId = blockOrder[blockOrder.indexOf(selection[0].blockId)-1]
        const previousBlockEditor = blocksRef.current[previousBlockId]?.editor
        deleteMultipleBlocks(selection.map(selectionBlock => selectionBlock.blockId))
        if(previousBlockEditor)
          focusAndSelect(previousBlockEditor, Editor.start(previousBlockEditor, []))
        e.preventDefault()
        clearSelection()
        return
      }
      if(isHotkey(`shift+arrowDown`, e) || isHotkey(`shift+arrowUp`, e)) return

      const alternativeFocusAndSelect = (editor, point) => {
        ReactEditor.focus(editor)
        console.log({point})
        setTimeout(() => Transforms.select(editor, point))
      }
      if (isHotkey('ArrowUp', e)) {
        const firstSelectionEditor = blocksRef.current[selection[0].blockId].editor
        alternativeFocusAndSelect(firstSelectionEditor, Editor.start(firstSelectionEditor, []))
        e.preventDefault()
        clearSelection()
        return
      }
      if (isHotkey('ArrowDown', e)) {
        const lastSelectionEditor = blocksRef.current[selection[selection.length-1].blockId].editor
        alternativeFocusAndSelect(lastSelectionEditor, Editor.end(lastSelectionEditor, []))
        e.preventDefault()
        clearSelection()
        return
      }
      if (isHotkey('ArrowLeft', e)) {
        const firstSelectionEditor = blocksRef.current[selection[0].blockId].editor
        alternativeFocusAndSelect(firstSelectionEditor, Editor.start(firstSelectionEditor, []))
        e.preventDefault()
        clearSelection()
        return

      }
      if (isHotkey('ArrowRight', e)) {
        const lastSelectionEditor = blocksRef.current[selection[selection.length-1].blockId].editor
        alternativeFocusAndSelect(lastSelectionEditor, Editor.end(lastSelectionEditor, []))
        e.preventDefault()
        clearSelection()
        return
      }
      console.log('clrearing selection')

      const selectionBasicBlock = selection.find(blockItem => blockItem.index === 0)
      if(selectionBasicBlock) {
        const lastSelectionEditor = blocksRef.current[selectionBasicBlock.blockId].editor
        focusAndSelect(lastSelectionEditor, Editor.end(lastSelectionEditor, []))
      }
      clearSelection()
    }
    
  }
}

const useGistSelection = () =>  {
  const dispatch = useDispatch()
  return (e, blocksGroups, buildGroups) => {
    const { selection } = store.getState().notes
    //Handle block selection
    let newSelection = false
    if (isHotkey('Shift+ArrowUp', e) && selection.length) 
      newSelection = changeSelection('up', blocksGroups, selection)
    if(isHotkey(`shift+arrowDown`, e) && selection.length) 
      newSelection = changeSelection('down', blocksGroups, selection)
    if(newSelection) {
      dispatch(updateCurrentNoteSelection(newSelection))
      buildGroups()
    }
  }
    
}
export default useGistSelection