import { useDispatch } from 'react-redux'
import { Fragment, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { updateThirdPartyObject } from '../actions'

import useToggle from '../../../components/Toggle/useToggle'
import WidgetWrapper from './Widget/WidgetWrapper'
import { useAppInfo } from '../helpers'
import deserialize from '../../../components/TextEditor/deserialize'
import RenderNode from '../../../components/TextEditor/RenderNode'
import AutosizeInput from 'react-input-autosize'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { CircularProgress } from "@material-ui/core"
import NewTable from '../../../components/PlanttTable/NewTable'
import HoverableIcon from '../../../components/HoverableIcon'
import AutosizeTextarea from 'react-textarea-autosize';
import { APPLY_OBJECT_UPDATE } from '../constants'
import { store } from '../../..'

function TableBlock({ block, specRef, blockIndex, cardsOnly = false, RenderCard = TableCard }) {
  const { icon_url } = useAppInfo(specRef)
  const { toggleItem, contentProps, isOpen } = useToggle({ open: true, transitionLen: 500 })
  const [mode, setMode] = useState('cards')

  const changeMode = newMode => () => {
    setMode(newMode)
    if(!isOpen)
      toggleItem()
  }

  return (
    <>
      <div className={`toggle-block-title table-block-title ${isOpen ? 'block-open' : ''}`}>
        <div onClick={toggleItem}><KeyboardArrowDown /> {block.title}</div>
        {!cardsOnly &&
        <div>
          <HoverableIcon icon='table' active={mode === 'table'} onClick={changeMode('table')} />
          <HoverableIcon icon='credit-card-blank' active={mode === 'cards'} onClick={changeMode('cards')} />
        </div>}
      </div>
      <div {...contentProps}>
        <div>
          {mode === 'cards' && <CardsView {...{ block, icon_url, blockIndex, specRef, RenderCard }} />}
          {mode === 'table' && <TableView {...{ block, blockIndex, specRef }} />}
        </div>
      </div>
    </>
  )
}

function TableView(props){
  const { block, specRef, blockIndex } = props
  const columns = useMemo(() => {
    const columns = block.schema.map(({ key, name, editable }) => {
      return {
        name,
        Content: editable
          ? ({ item, index }) => <RenderEditable column={key} objectIndex={index} object={item} {...props} tableView />
          : ({ item }) => <RenderNonEditable text={item[key]} />
      }
    })
    columns.push({ id: 'external-link', Content: ({ item }) => <ExternalLink object={item} /> })
    return columns
  }, [block.schema])
  return (
    <NewTable
      className='note-table-block'
      columns={columns}
      itemsAddress={['notes', 'dockData', specRef, 'blocks', blockIndex, 'objects']}
      inline
    />
  )
}

function CardsView({ RenderCard, ...props }){
  return props.block.objects.map((object, index) => <RenderCard key={index} {...props} {...{ object, index }} />)
}

export function TableCard({ block, icon_url, name, object, index, noEdit = false, ...editableProps }){
  return (
    <WidgetWrapper {...{ name, icon_url }} className='note-table-block'>
      <div className='mrkdwn-side-line'>
        <div></div>
        <div className='widget-card-block'>
          {block.schema.map(({ key, name, editable }) => (
            <Fragment key={key}>
              <div>{name}</div>
              <div>
                {editable && !noEdit
                  ? <RenderEditable
                      column={key}
                      objectIndex={index}
                      {...{ block, object, ...editableProps }}
                    />
                  : <RenderNonEditable text={object[key]} />
                }
              </div>
            </Fragment>
          ))}
        </div>
      </div>
      <ExternalLink object={object} />
    </WidgetWrapper>
  )
}

function ExternalLink({ object }){
  if(!object.url)
    return null
  
  return <a href={object.url} target='_blank' rel='noreferrer'><i className='fad fa-external-link' /></a>
}

function RenderNonEditable({ text }) {
  const deserialized = useMemo(() => typeof text === 'string' ? deserialize(text) : text || null, [text])
  return !deserialized?.map ? null : deserialized.map((node, index) => <RenderNode key={index} {...node} />)
}

const defaultUpdateFunction = updateData => store.dispatch({ type: APPLY_OBJECT_UPDATE, updateData })

function RenderEditable({ column, block, object, tableView = false, updateFunction = defaultUpdateFunction, ...updateProps }) {
  const dispatch = useDispatch()
  const ref = useRef()
  const [value, setValue] = useState('')

  useLayoutEffect(() => {
    if(value !== object[column])
      setValue(object[column] || '')
  }, [object[column]])

  const preventSubmit = useRef(false)
  const onKeyDown = e => {
    if (e.key === 'Escape')
      preventSubmit.current = true
    if (['Enter', 'Escape'].includes(e.key)){
      e.preventDefault()
      ref.current?.blur()
    }
  }

  const [loading, setLoading] = useState(false)
  const onBlur = async () => {
    if (preventSubmit.current){
      setValue(object[column])
      return preventSubmit.current = false
    }

    setLoading(true)
    await dispatch(updateThirdPartyObject({
      object,
      changes: { [column]: value },
      edit_action: block.edit_action,
      ...updateProps
    }, updateFunction))
    setLoading(false)
  }

  const InputComponent = useMemo(() => !tableView ? AutosizeTextarea : AutosizeInput, [tableView])

  return (
    <label>
      <InputComponent disabled={!tableView && loading} onChange={e => setValue(e.target.value)} {...{ value, onBlur, onKeyDown, ref }} />
      {!tableView && (!loading ? <i className='fas fa-pencil' /> : <CircularProgress size={12} />)}
    </label>
  )
}

export default TableBlock