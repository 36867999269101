import { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  // componentDidCatch(error, errorInfo) {
  //   // You can also log the error to an error reporting service
  // }

  render() {
    if (this.state.hasError){
      const { ErrorState } = this.props
      return <ErrorState setHasError={hasError => this.setState({ hasError }) } />
    }

    return this.props.children
  }
}

export default ErrorBoundary