import { format } from "date-fns"
import { Fragment, useEffect, useLayoutEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom"
import AutosizeInput from "react-input-autosize"
import { firebase } from "../.."

import HoverableIcon from "../../components/HoverableIcon"
import { useLayoutConfig } from "../../components/PlanttLayout/LayoutConfig"
import Scrollable from "../../components/Scrollable"
import { BlueButton } from "../../components/Button"
import Hoverable from "../../components/Hoverable"
import AssociatedMembers from "../../components/UserAvatar/AssociatedMembers"
import { upperFirst } from "../../helpers"
import injectReducer from "../../utils/injectReducer"
import DueDate from "./DueDate"
import PlanttFocus from '../../images/PlanttFocus.svg'
import useTasks from "./useTasks"

import { createTask, deleteTask, setTask } from "./actions"
import { setMemberTasksFromSnapshot } from "./actions"
import reducer from './reducer'
import './style.scss'

const views = ['by-date', 'all', 'completed']

function Tasks(){
  const dispatch = useDispatch()
  const history = useHistory()
  const workspaceId = useSelector(state => state.global.workspace.id)
  const memberId = useSelector(state => state.global.currentMember.id)
  const loadingTasks = useSelector(state => state.tasks.loading.memberTasks)
  const memberTasks = useTasks()
  const { view } = useParams()
  
  useLayoutConfig({ title: 'Tasks' })
  useLayoutEffect(() => {
    if(workspaceId && memberId){
      const tasksRef = firebase.database.ref(`${workspaceId}/tasks`)
      tasksRef.on('value', snapshot => dispatch(setMemberTasksFromSnapshot(snapshot)))

      return () => tasksRef.off()
    }
  }, [workspaceId, memberId])

  const addTask = () => {
    createTask({ preset: { auto_focus: memberId } })
    if(view === 'completed')
      history.push('/tasks/all')
  }

  return (
    <Scrollable className='account-overview tasks-display' id='note-editor-content'>
      <div className='notebook-note-block tasks-menu'>
        <BlueButton padding='4px 8px' outline onClick={addTask}>
          <i className='fal fa-plus' /> Add Task
        </BlueButton>
        <div id='task-views'>
          View: {views.map((v, index) => (
            <Fragment key={v}>
              {index > 0 && '|'}
              <Link className={view === v ? 'active' : ''} to={`/tasks/${v}`}>
                {v.split('-').map(upperFirst).join(' ')}
              </Link>
            </Fragment>
          ))}
        </div>
      </div>
      {memberTasks.map(({ date, tasks }) => (
        <div className='notebook-note-block' key={date || 'noDate'}>
          {date && <h3>{date !== 'noDate' ? format(new Date(date), 'd MMMM yyyy') : 'No Due Date'}</h3>}
          {tasks.map(task => <RenderTask key={task.id} {...{ date, task }} />)}
        </div>
      ))}
      {!loadingTasks && !memberTasks.length &&
      <div id='page-empty-state'>
        <h2>{view !== 'completed' ? 'You have no tasks to do!' : 'No completed tasks to see here!'}</h2>
        <h3 id='enjoy-your-day'>Enjoy your day or <span onClick={addTask}>add a task</span></h3>
        <img src={PlanttFocus} />
      </div>}
    </Scrollable>
  )
}

function RenderTask({ task = {}, date }){
  const relatedAccounts = useSelector(state => state.tasks.relatedAccounts)
  const inputRef = useRef()
  useEffect(() => {
    if(inputRef.current && task.auto_focus)
      inputRef.current.input?.focus()
  }, [task.auto_focus, inputRef.current])

  const account = relatedAccounts[task.account_id]
  const origin = account && `/account/${account.id}/${task.ref.note}#${task.ref.block}`

  const onItemClick = e => {
    if(e.target === e.currentTarget || ['task-assignees-wrapper', 'assignee-avatars'].includes(e.target.className))
      inputRef.current?.input?.focus()
  }

  const addAssignee = id => setTask(task.id, task, { assignees: [...(task.assignees || []), id] })
  const removeAssignee = id => setTask(task.id, task, { assignees: task.assignees.filter(assignee => assignee !== id) })

  return (
    <div className='gist-list-item' key={task.id} onClick={onItemClick}>
      <div className='task-list-left'>
        <i
          className={`fad fa-check-circle ${task.completed ? 'completed' : ''}`}
          onClick={() => setTask(task.id, task, { completed: !task.completed || null })}
        />
        <div>
          <AutosizeInput
            value={task.description}
            onChange={e => setTask(task.id, task, { description: e.target.value })}
            placeholder='Untitled task'
            style={task.completed ? { textDecoration: 'line-through' } : undefined}
            onFocus={() => task.auto_focus ? setTask(task.id, task, { auto_focus: null }) : null}
            ref={inputRef}
          />
          {!date && task.datetime && <div className='task-duedate'>Due {format(new Date(task.datetime), 'MMMM do yyyy')}</div>}
        </div>
      </div>
      <div className='task-list-right'>
        {account && <Link to={origin} className='task-account-token'>{account?.fields.name}</Link>}
        <TaskAssignes
          assigneeIds={task.assignees || []}
          addAssignee={addAssignee}
          removeAssignee={removeAssignee}
        />
        <DueDate
          task={task}
          iconProps={{ active: task.datetime, icon: 'alarm-clock' }}
          setTask={newDate => setTask(task.id, task, { datetime: newDate?.getTime() || null })}
          MenuTop={DueDateTop}
          tooltipText={`${task.datetime ? 'Update' : 'Set'} due date`}
        />
        <HoverableIcon icon='trash' onClick={() => deleteTask(task)} />
      </div>
    </div>
  )
}

function DueDateTop({ task, setTask }){
  return task.datetime ? (
    <Hoverable className='due-date-top' onClick={() => setTask()}>
      Remove Due Date
      <div className='date-display'>Currently {format(new Date(task.datetime), 'LLL d')}</div>
    </Hoverable>
  ) : null
}

function TaskAssignes({assigneeIds, addAssignee, removeAssignee}) {
  const workspaceMembers = useSelector(state => state.global.workspaceMembers)
  const members = useMemo(() => {
    return assigneeIds.map(a => workspaceMembers.find(m => m.id === a)).filter(a => typeof a !== 'undefined')
  }, [assigneeIds, workspaceMembers])

  return (<AssociatedMembers
    members={members}
    tooltipTitle='Assignees'
    onAdd={addAssignee}
    onRemove={removeAssignee}
  />)
}

export default injectReducer({ key: 'tasks', reducer })(Tasks)