import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useLocation } from "react-router"
import { useDispatch, useSelector } from "react-redux"

import useAddressSelector from "../../../helpers/useAddressSelector"
import Scrollable from "../../Scrollable"
import TableContext from "./TableContext"
import RenderCell from "./RenderCell"
import injectReducer from '../../../utils/injectReducer'
import reducer, { setHoveredRow } from './reducer'
import Checkbox from "../../FormControls/Checkbox"
import RenderHeader from "./RenderHeader"

import './style.scss'

const noop = () => {}

const useItems = ({ itemsAddress, loading, limit }) => {
  const items = useAddressSelector(itemsAddress)
  const loader = useMemo(() => {
    return new Array(isNaN(loading) || typeof loading === 'boolean' ? 50 : loading)
      .fill(true)
      .map((_, index) => ({ id: index, skeleton: true }))
  }, [loading])
  
  if(!loading){
    if(limit)
      return items.slice(0, limit)
    return items
  }

  return loader
}

const defaultGetId = (item, index) => item.id ?? index

function NewTable({
  columns,
  itemsAddress,
  sticky,
  classes = {},
  onCellClick,
  headerActions,
  expansionActions = {},
  tableTitle,
  footer,
  loading,
  rightFloat,
  getId = defaultGetId,
  isHighlighted = noop,
  inline = false,
  limit,
  EmptyState,
  checkboxProps,
  rowHeight = 56,
  ...props
}){
  const dispatch = useDispatch()
  const items = useItems({ itemsAddress, loading, limit })

  const contextValue = useMemo(() => {
    return { classes, onCellClick, rowHeight, headerActions, expansionActions, getId, isHighlighted }
  }, [classes, onCellClick, rowHeight, headerActions, expansionActions, getId, isHighlighted])

  const [contentHeight, setContentHeight] = useState('100%')
  const titleRef = useRef()
  const footerRef = useRef()
  useEffect(() => {
      setContentHeight(`calc(100% - ${titleRef.current?.offsetHeight || 0}px - ${footerRef.current?.offsetHeight || 0}px)`)
  }, [titleRef.current, footerRef.current])

  const location = useLocation()
  const ref = useRef()
  useEffect(() => {
    if(ref.current)
      ref.current.scrollTop = 0
  }, [location, loading])

  return (
    <TableContext.Provider value={contextValue}>
      <div {...props} className={`new-table ${!inline ? 'card' : ''} ${classes.table || ''} ${props.className || ''}`}>
        {tableTitle && <div className='new-table-title' ref={titleRef}>{tableTitle}</div>}
        <Scrollable
          className='new-table-content'
          onMouseLeave={() => dispatch(setHoveredRow(null))}
          style={{ height: contentHeight }}
          ref={ref}
        >
          {checkboxProps && <CheckboxColumn {...{ items, checkboxProps }} />}
          {sticky && <StickyColumn {...{ items, sticky }} hasCheckbox={Boolean(checkboxProps)} />}
          {columns.map((column, colIndex) => (
            <div key={column.id || column.name} className='new-table-column'>
              <RenderHeader column={column} />
              {items.map((item, index) => (
                <RenderCell key={getId(item, index)} {...{ item, index, column }} />
              ))}
              {expansionActions.onAddRow && <AddRow index={colIndex} />}
            </div>
          ))}
          <div className={`new-table-filler ${rightFloat ? 'right-float' : ''}`}>
            <RenderHeader column={rightFloat} filler />
            {items.map((item, index) => (
              <RenderCell filler column={rightFloat || undefined} key={getId(item, index)} item={item} />
            ))}
            {expansionActions.onAddRow && <AddRow />}
          </div>
        </Scrollable>
        {!items.length 
          ? EmptyState && <div className='new-table-empty-state'><EmptyState /></div>
          : footer && <div className='new-table-footer' ref={footerRef}>{footer}</div>
        }
      </div>
    </TableContext.Provider>
  )
}

const checkboxWidth = 44

function StickyColumn({ items, sticky, hasCheckbox }) {
  const { classes, getId } = useContext(TableContext)
  return (
    <div className={`new-table-sticky ${classes.sticky || ''}`} style={{ left: hasCheckbox ? checkboxWidth + 1 : 0 }}>
      <RenderHeader column={sticky} />
      {items.map((item, index) => <RenderCell key={getId(item, index)} column={sticky} item={item} />)}
    </div>
  )
}

function CheckboxColumn({ items, checkboxProps: { isChecked, onClick } }) {
  const { classes, getId } = useContext(TableContext)

  const cellStyle = { padding: '12px 4px' }
  return (
    <div className={`new-table-sticky ${classes.sticky || ''}`} style={{ left: 0, width: checkboxWidth }}>
      <RenderHeader style={cellStyle} />
      {items.map((item, index) => (
        <RenderCell key={getId(item, index)} style={cellStyle} column={{ width: checkboxWidth }} item={item}>
          <Checkbox onClick={() => onClick(item)} checked={isChecked(item)} style={{ marginTop: isChecked(item) ? -1 : undefined }} />
        </RenderCell>
      ))}
    </div>
  )
}

function AddRow({ index }){
  const dispatch = useDispatch()
  const isHovered = useSelector(state => state.tableState.hoveredRow === 'add_row')
  const { expansionActions: { onAddRow }, rowHeight } = useContext(TableContext)
  return (
    <div
      className={`new-table-cell add-row ${isHovered ? 'is-hovered' : ''}`}
      style={{ height: rowHeight }}
      onMouseEnter={() => dispatch(setHoveredRow('add_row'))}
      onMouseLeave={() => dispatch(setHoveredRow(null))}
      onClick={onAddRow}
    >
      {index === 0 &&
      <div><i className='fal fa-plus' />{isHovered && 'Add Row'}</div>}  
    </div>
  )
}

export default injectReducer({ key: 'tableState', reducer })(NewTable);