import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGeneralSettings } from '../actions';

import { useLayoutConfig } from '../../../components/PlanttLayout/LayoutConfig';
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';


import { SettingsItems, SettingsTitle, FormAlert } from './GeneralElems';

const GeneralStyle = makeStyles(() => ({
  row: {
    paddingLeft: 23,
    paddingRight: 23,
  },
  teamTitle: {
    fontFamily: 'Helvetica',
    fontSize: 18,
    color: '#19270d'
  }
}));

function General() {
  useLayoutConfig({ title: 'Settings / General' })

  const generalStyle = GeneralStyle();
  const settings = useSelector(state => state.setting.generalSettings);

  const [open, setOpen] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGeneralSettings());
  }, []);

  const renderSettings = () => {
    return settings.map((section, index) => {
      let path = `${index}`;
      let isOpen = open === section.label;
      
      return section.items.length ? (
        <Grid item xs={12} className='generalSettings' key={section.label}>
          <SettingsTitle 
            title={section.label}
            description={section.description}
            open={isOpen}
            onClick={() => setOpen(open !== section.label ? section.label : null)}
          />
          { isOpen
            ? <SettingsItems
              items={section.items}
              path={path}
            />
            : null 
          }
          { index !== settings.length-1 ? <Divider /> : null }
        </Grid>
      ) : null;
    });
  }

  return (
    <Fragment>
      {settings !== null
        ? (
          <div>
            <form>
              <div className="setting-section">
                <Grid container>
                  <Grid container className={`${generalStyle.row} mb-3`}>
                    <Grid item xs={6} className={generalStyle.teamTitle}>General Settings</Grid>
                  </Grid>
                </Grid>
                <Grid container>{renderSettings()}</Grid>
              </div>
            </form>
            <FormAlert />
          </div>
        )
        : <LoadingIndicator />
      }
    </Fragment>
  )
}


export default General;
