import { addMinutes } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { useLayoutEffect } from 'react'
import { Close } from '@material-ui/icons'
import Analyzing from '../../images/discover/Analyzing.svg'
import { toggleShowGoogleBanner } from '../../containers/App/actions'

function GoogleSyncBanner(){
  const dispatch = useDispatch()
  const { connectors, onboardingStatuses, showGoogleBanner: showBanner } = useSelector(state => state.global)

  useLayoutEffect(() => {
    const connector = connectors.find(c => c.spec_ref === 'google_cloud')
    if(
      !localStorage.getItem('hideGoogleSyncBanner')
      && onboardingStatuses.sync_google
      && connector
      && new Date(connector.created_at) > addMinutes(new Date(), -30)
    )
      dispatch(toggleShowGoogleBanner(true))
  }, [connectors, onboardingStatuses])

  const close = () => {
    localStorage.setItem('hideGoogleSyncBanner', true)
    dispatch(toggleShowGoogleBanner(false))
  }

  return showBanner ? (
    <div id='syncing-google-banner' style={{ height: showBanner ? 60 : 0 }}>
      We're syncing your workspace with Google Calendar. This might take a few minutes.
      <img src={Analyzing} />
      <Close onClick={close} />
    </div>
  ) : null
}

export default GoogleSyncBanner