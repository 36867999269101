import Draggable from 'react-draggable'
import { useEffect, useState } from 'react'
import { getMinutes, getSeconds } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

import HoverableIcon from '../../../components/HoverableIcon/Simple'
import { applyUpdates, getNoteInfo, setNoteTimer } from '../actions'
import { useSelector } from 'react-redux'
import { createPortal } from 'react-dom'


const useStyles = makeStyles(() => ({
  root: { position: 'relative', display: 'flex', justifyContent: 'center' },
  bottom: { color: '#ebeff2' },
  top: { color: '#155788', position: 'absolute', left: 0 },
  stem: { width: 6, height: 16, backgroundColor: '#ebeff2' },
  head: { width: 32, height: 8, backgroundColor: '#ebeff2', borderRadius: '20px' },
  clicker: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: -24,
  },
  close: {
    position: 'absolute',
    right: 4,
    top: 0,
    display: 'none',
    color: '#979797',
    zIndex: 10,
  },
  time: {
    position: 'absolute',
    top: 6,
    paddingTop: 40,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 124,
    width: 124,
    backgroundColor: '#fff',
    borderRadius: '50%',
    color: '#155788',
    zIndex: 3,
    fontSize: 32,

    '& input': {
      border: 'none',
      borderBottom: '1px solid transparent',
      font: 'inherit',
      color: '#155788',
      width: 38,
      lineHeight: 1,
      letterSpacing: -2,
      backgroundColor: 'transparent',
      textAlign: 'center',
      '&:focus, &:hover:not(:disabled)': {
        outline: 'none',
        borderColor: '#979797'
      },
      '&:disabled': { cursor: 'initial' }
    }
  }
}))

function Timer(){
  const gist = useSelector(state => state.notes.currentNote || {})
  if(!gist.timer)
    return null

  return createPortal(
    <Draggable handle='.stopwatch-clicker'>
      <div className='gist-timer' style={{ position: 'absolute', top: 100, right: 80, zIndex: 3000 }}>
        <FacebookCircularProgress timer={gist.timer} />
      </div>
    </Draggable>,
    document.querySelector('body')
  )
}

const addZero = n => n >= 10 ? n : `0${n}`
function FacebookCircularProgress({ timer }) {
  const classes = useStyles()

  const [passed, setPassed] = useState(0)

  const setTimer = component => e => {
    let { value } = e.target
    while(value.startsWith('0') && value.length > 1)
      value = value.slice(1)

    value = value.length > 2 && value.startsWith(timer[component])
      ? value.slice(value.length - 2)
      : value.slice(0, 2)

    if(['minutes', 'seconds'].includes(component) && value > 59)
      value = 59

    const { notePath } = getNoteInfo()
    applyUpdates(`${notePath}/timer/${component}`, 'set', addZero(value))
  }

  const total = ((timer.minutes * 60) + parseInt(timer.seconds)) * 1000
  const remaining = total - passed
  const remainingAsDate = new Date(remaining)
  const minutes = !timer.start ? timer.minutes : addZero(getMinutes(remainingAsDate))
  const seconds = !timer.start ? timer.seconds : addZero(getSeconds(remainingAsDate))

  const [blink, setBlink] = useState(null)
  const handleBlink = remaining => {
    if(timer.start && !timer.paused && !blink && remaining < 10000)
      setBlink(blink => blink || Math.ceil(remaining / 2000))
    else if(blink)
      setBlink(null)
  }

  useEffect(() => {
    handleBlink(remaining)
    if(timer.start && !timer.paused){
      const counter = setInterval(() => {
        const passed = Math.round((Date.now() - timer.start) / 1000) * 1000
        if(total - passed < 0)
          clearInterval(counter)
        else
          setPassed(passed)
        
        handleBlink(total - passed)
      }, 1000)
      return () => clearInterval(counter)
    }
  }, [timer.start, timer.paused])

  const stopTimer = () => {
    setNoteTimer({ start: null, paused: null })
    setPassed(0)
  }
  const setTimerTimes = () => {
    const changes = !timer.start ? { start: Date.now() } : { paused: !timer.paused ? Date.now() : null }
    if(timer.paused)
      changes.start = Date.now() - passed  // The start date is changed when pause is released to make sure "passed" doesn't change
    
    setNoteTimer(changes)
  }

  const circularProps = { variant: 'determinate', size: 136, thickness: 2 }

  return (
    <div className={classes.root}>
      <HoverableIcon
        icon='times-circle'
        className={`${classes.close} close-icon`}
        onClick={() => applyUpdates(`${getNoteInfo().notePath}/timer`, 'remove')}
      />
      <div className={`${classes.clicker} stopwatch-clicker`}>
        <div className={classes.head} />
        <div className={classes.stem} />
      </div>
      <CircularProgress {...circularProps} className={classes.bottom} value={100} />
      <div
        className={`${classes.time} ${remaining < 10000 ? 'last-ten' : ''}`}
        style={blink ? { animation: 'last-ten 2s ease-in-out 0s', animationIterationCount: blink } : undefined }
      >
        <div>
          <input disabled={timer.start} type='number' value={minutes} onChange={setTimer('minutes')} />
          :
          <input disabled={timer.start} type='number' value={seconds} onChange={setTimer('seconds')} />
        </div>
        <div>
          <HoverableIcon icon={timer.start && !timer.paused ? 'pause' : 'play'} onClick={setTimerTimes} />
          {timer.start && <HoverableIcon icon='stop' onClick={stopTimer} />}
        </div>
      </div>
      <CircularProgress {...circularProps} className={classes.top} value={remaining / (total / 100)} />
    </div>
  )
}

export default Timer