import { prepareFields, importDataFactory } from '../../components/PlanttTable/ImportTable/helpers'
import {
  FETCH_CONNECTORS_SPECS_LIST_SUCCSESS,
  START_ADDING_CONNECTOR,
  SET_IMPORT_DATA,
  SET_IMPORT_DATA_FIELDS,
  SET_AUTHENTICATION_STATUS,
  SET_OAUTH2_PROCESS_STATUS,
  SET_AUTHENTICATION_PAGE_ERROR,
  FETCH_CONNECTOR_SAMPLES_SUCCESS,
  UPDATE_LOADING,
  UPDATE_MAPPING_TABLE_FIELDS,
  FETCH_ACCOUNT_FIELDS_SUCCESS,
  RESET_MAPPING_TABLE_DATA,
  SET_ALL_MAPPING_FIELDS_TO_READ_ONLY,
  } from './constants'
  import { produce, original } from "immer"

  // The initial state of the Reducer
  export const initialState = {
    premiumApps: ['intercom', 'zoom', 'mixpanel'],
    inAddingConnectorSpec: {},
    inAddingConnectorInstance: {},
    authenticationStatus:0,
    mappingTableData: importDataFactory(),
    connectorSamples: [],
    connectorFields: [],
    connectorSamplesLabels: {},
    loadings: {
      mappingOutput:false,
      samples:0,
    },
    errors: {
      authenticationPageError : ''
    },
    oauth2ProcessStatus: 0,
    accountFields: []
  };


  
  const automationReducer = produce((draft, action) => {
    
    switch (action.type) {
      case START_ADDING_CONNECTOR: {
        draft.inAddingConnectorSpec = action.connectorSpec
        return
      }

      
      case SET_IMPORT_DATA_FIELDS: {
        const origin = original(draft)
        /*
        if(action.mode === 'adding'){
            //Build default mapping fields, them add the others.
            const mappedFields = Object.entries(action.defaultMappingFields).map(([key,value],index) => ({
              index,
              name: key,
              planttName: value,
              trackable: false,
              type: { is: 'string', readonly: true },
              errors: { planttNameEmpty: false, planttNameNotUnique: false }
            }))
            //const normalFields = prepareFields(action.data.filter(fieldName => !Object.keys(action.defaultMappingFields).includes(fieldName)), mappedFields.length)

            const allFields = mappedFields.concat(prepareFields(origin.accountFields.filter(field => !Object.values(action.defaultMappingFields).includes(field.key.toLowerCase())), mappedFields.length))
            

            )
            draft.mappingTableData.fields = allFields
        }else{
          
        }
        */
       //build dtat structure for mapping table.
       //First, add mapped fields. then, all others
       //action.data = array of fields from External app

       console.log({ action })

        const mappedFields = prepareFields(origin.accountFields.filter(field => Object.values(action.currentConnector.identity_mapping).includes(field.key.toLowerCase())),action.currentConnector.identity_mapping, 0)
        const allFields = mappedFields.concat(prepareFields(origin.accountFields.filter(field => !Object.values(action.currentConnector.identity_mapping).includes(field.key.toLowerCase())),action.currentConnector.identity_mapping, mappedFields.length))
        // allFields.push ({
        //   index: allFields.length,
        //   type: {isNew:true},
        //   errors: {}
        // })
        draft.mappingTableData.fields = allFields

        return
      }
      case SET_IMPORT_DATA: {
        draft.mappingTableData = action.data
        return
      }
      case SET_ALL_MAPPING_FIELDS_TO_READ_ONLY: {
        draft.mappingTableData.fields.forEach((item, index) => { 
          draft.mappingTableData.fields[index].type.readonly = true          
        })
        return
      }
      case SET_AUTHENTICATION_STATUS: {
        draft.authenticationStatus = action.status
        return
      }
      case SET_OAUTH2_PROCESS_STATUS: {
        draft.oauth2ProcessStatus = action.status
        return
      }
      case SET_AUTHENTICATION_PAGE_ERROR: {
        draft.errors.authenticationPageError = action.text
        return
      }
      case FETCH_CONNECTOR_SAMPLES_SUCCESS: {
        draft.connectorSamples = action.samples.records
        draft.connectorFields = action.samples.fields
        draft.connectorSamplesLabels = action.samples.labels
        return
      }
      case UPDATE_LOADING: {
        draft.loadings[action.key] = action.status
        return
      }
      case UPDATE_MAPPING_TABLE_FIELDS: {
        action.updatedFields.forEach(index => {
          draft.mappingTableData.fields[index].errors.planttNameEmpty = true
        })
        return
      }
      case RESET_MAPPING_TABLE_DATA: {
        draft.mappingTableData = importDataFactory()
        draft.accountFields = []
        draft.connectorSamplesLabels = {}
        draft.connectorSamples = []
        draft.connectorFields = []
        draft.connectorSamplesLabels = {}
        return
      }
      case FETCH_ACCOUNT_FIELDS_SUCCESS: {
        draft.accountFields = action.fields
        return
      }
      default: {
        return
      }
    }
  }, initialState)
  
  export default automationReducer;
  