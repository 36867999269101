
import { useState } from "react"
import Scrollable from "../Scrollable"
import Tooltip from "../Tooltip/new"
import UserAvatar from "../UserAvatar"
import HoverableIcon from "../HoverableIcon"
import OwnerSelector from "../OwnerSelector"
import './style.scss'

function AssociatedMembers({ members, tooltipTitle = null, onAdd, onRemove }){
  return (
    <Tooltip
      content={<TooltipContent {...{ members, tooltipTitle, onAdd, onRemove }} />}
      placement='bottom'
      theme={{ backgroundColor: '#fff', borderColor: '#ddd', color: 'inherit' }}
      interactive
      styles={{ wrapper: { maxWidth: 'none' }}}
      enterDelay={400}
      enterNextDelay={400}
    >
      <div className='associated-members-avatars'>
        {members.map(({ id, name, email, profile_image }) => (
          <UserAvatar key={id} size={24} name={name || email} url={profile_image} />
        ))}
        {!members.length && <UserAvatar size={24} />}
      </div>
    </Tooltip>
  )
}

function TooltipContent({ members, tooltipTitle, onAdd, onRemove }){
  const [count, setCount] = useState(1)
  const addMember = ({ id }) => {
    onAdd(id)
    setCount(count + 1)
  }

  return (
    <Scrollable className='associated-members-tooltip'>
      {tooltipTitle && <h1>{tooltipTitle}</h1>}
      {members.map(({ id, name, email, profile_image }) => (
        <div className='associated-member' key={id}>
          <div><UserAvatar size={24} name={name || email} url={profile_image} /> {name || email}</div>
          {onRemove && <HoverableIcon icon='minus-circle' onClick={() => onRemove(id)} />}
        </div>
      ))}
      {onAdd &&
      <OwnerSelector
        key={count}
        className={`add-member ${members.length ? 'has-members' : ''}`}
        setCSM={addMember}
        placeholder='Add an Assignee'
        sizes={{ font: 14, avatar: 24 }}
      />}
    </Scrollable>
  )
}

export default AssociatedMembers