import React, { Fragment, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { addConnector, fixAuthFields, updateConnectorAuthenticationInfoNotOauth, setAuthenticationPageError, setOauth2ProcessStatus } from '../actions'
import AuthField from './AuthField'
import TopProgressBar from '../../../components/TopProgressBar'
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded'
import CircularProgress from '@material-ui/core/CircularProgress'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded'
import { handleOauthRedirect } from './HandleOauthRedirect'
function Authenticate({ connectorSpec }) {
    const authenticationStatus = useSelector(state => state.connectors.authenticationStatus)
    const oauth2ProcessStatus = useSelector(state => state.connectors.oauth2ProcessStatus)
    const dispatch = useDispatch()
    const history = useHistory()
    const [manualyActivateOauth2, setManualyActivateOauth2] = useState(false)
    useEffect(() => {
        dispatch(setAuthenticationPageError(''))
    }, [])
    useEffect(() => {
        if(connectorSpec?.newInstanceId) {
            if(connectorSpec.authentication.type === 'oauth2' && (oauth2ProcessStatus === 1 || (oauth2ProcessStatus === 0 && manualyActivateOauth2))) {
                handleOauthRedirect(connectorSpec, connectorSpec?.newInstanceId, authFieldsValues)
            }
        }
    }, [connectorSpec, manualyActivateOauth2])
    useEffect(() => {
        if(authenticationStatus === 3) {
            //Done with authentication
            localStorage.removeItem('inAddingConnectorIndex')
            history.push(`/apps/add/activate/${connectorSpec.newInstanceId}`)
        }
        if(authenticationStatus === 2) {
            //move to error page
            history.push(`/apps/add/authenticationError`)
        }
    }, [authenticationStatus])

    const startOauthProcess = () => {
        if(!connectorSpec?.newInstanceId) 
            dispatch(addConnector(connectorSpec))
        else 
            setManualyActivateOauth2(true)
    }

    const moveForward = () => {
        setnextClicked(true)
        let allFieldsFilled = true
        Object.values(authFieldsValues).forEach(field => {
            if(field.value === '' && field.required && !field.disabled ) allFieldsFilled = false
        })
        if(allFieldsFilled) {
            const authFieldsValuesFixed  = handleAuthFields(authFieldsValues)
            setauthFieldsValues(authFieldsValuesFixed)
            if(!connectorSpec?.newInstanceId) 
                dispatch(addConnector(connectorSpec, authFieldsValuesFixed))   
            else {
                //Handle zendek exception
                if(connectorSpec.id === 'zendesk')
                    setManualyActivateOauth2(true)
                else
                    dispatch(updateConnectorAuthenticationInfoNotOauth(connectorSpec.newInstanceId, fixAuthFields(authFieldsValuesFixed)))
            }
        }
    }
    const handleAuthFields = (fields) => {
        if(connectorSpec.id === 'google_sheet') {
            const tempValue = `${fields.spreadsheet_id.value}`
            const regex = new RegExp("/spreadsheets/d/([a-zA-Z0-9-_]+)").exec(tempValue)
            if(regex) {
                console.log(`changed spreadsheet_id to:`, regex[1])
                fields.spreadsheet_id.value = regex[1]
            }
            const regex2 = new RegExp("[#&]gid=([0-9]+)").exec(tempValue)
            if(regex2) {
                fields.sheet_id.value = regex2[1]
            }
            else {
                console.log('no wheet id',tempValue)
            }
        }
        return fields
    }
    const createAuthFieldsInitialValue = (fields) => {
        const stateObject = {}
        Object.values(fields).forEach(field => {
            stateObject[field.key] ={
                value: '',
                required: field.required,
                disabled: field.disabled
            }
        })
        return stateObject
    }
    const [authFieldsValues, setauthFieldsValues] = useState(createAuthFieldsInitialValue(connectorSpec?.authentication?.auth_fields))
    const handleFieldChange = (key, value) => setauthFieldsValues({ ...authFieldsValues, [key]: { ...authFieldsValues[key], value } })
    const [nextClicked, setnextClicked] = useState(false)

    return(
        <Fragment>
            <TopProgressBar progress="33" />
            <div className="add-connector-stage-container">
                <div className="page-title">Authenticate</div>
                {connectorSpec?.authentication?.type === 'oauth2' && connectorSpec?.id !== 'zendesk' &&
                    <div className="authenticate-block">
                        <div className="block-header">
                            <img src={`${connectorSpec.icon_url}`} className="connector-logo"/>
                            <span className="connector-name">
                                {connectorSpec.name}
                            </span>
                        </div>
                        <div className="logos">
                            <div className="circle-box">
                                <img src="https://firebasestorage.googleapis.com/v0/b/plantt-ui-icons/o/plantt.png?alt=media" className="logo-icon" />
                            </div>
                            <div className="green-dots">
                                <span className="dot"><FiberManualRecordRoundedIcon fontSize="inherit" /></span>
                                <span className="dot"><FiberManualRecordRoundedIcon fontSize="inherit" /></span>
                                <span className="dot2"><FiberManualRecordRoundedIcon fontSize="inherit" /></span>
                            </div>
                            <div className="circle-box">
                            <img src={`${connectorSpec.icon_url}`} className="logo-icon" />
                            </div>
                        </div>
                        <div className="go-button-container">
                            <div className="dark-blue-button pointer" onClick={startOauthProcess}>
                                Authorize  {connectorSpec.name}
                            </div>
                        </div>
                    </div>
                }
                {(connectorSpec?.authentication?.type === 'other' || connectorSpec?.id === 'zendesk') &&
                    <div className="authenticate-block">
                        <div className="block-header">
                            <img src={`${connectorSpec.icon_url}`} className="connector-logo"/>
                            <span className="connector-name">
                                {connectorSpec.name} 
                            </span>
                        </div>
                        <div className="connector-fields">
                            {Object.values(connectorSpec?.authentication?.auth_fields).map((field, index)=>(
                                <AuthField {...{ field, moveForward, handleFieldChange, authFieldsValues, nextClicked }}  key={`key${index}`} />
                            ))}                                
                        </div>
                        <div className="authenticate-buttons">

                            <div className="dark-blue-button pointer" onClick={moveForward}>
                                {authenticationStatus === 1  && 
                                <div className="inside-button-flex">
                                    Working... 
                                    <CircularProgress color="inherit" size={14} className="working" />
                                </div>
                                }
                                {authenticationStatus !== 1  &&  
                                <div className="inside-button-flex">
                                    Next  <ArrowForwardIosRoundedIcon color="inherit" className="next-arrow" />
                                </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Fragment>
    )
}

export default Authenticate