/* eslint-disable react-hooks/rules-of-hooks */

import { useMemo } from "react"
import { TO_DO_ITEM } from "../../constants"
import ItemizedBlockField from "./Field"
import { setValue } from "./helpers"
import Checkbox from "../../../../components/FormControls/Checkbox"
import { setCheckListTask, removeCheckListTask } from "../../actions"
import Hoverable from "../../../../components/Hoverable"
import { format, setHours, setMinutes } from "date-fns"
import { useSelector } from "react-redux"
import DueDate from "../../../Tasks/DueDate"

function CheckListBlock({ readonly } = {}){
  return ({ dragPreview, ...props }) => {
    const { fields } = props.block.payload
    const { completed, fieldCount } = useMemo(() => {
      let completed = fields.filter(f => f.is_done).length
      let fieldCount = fields.length
      const lastField = fields[fieldCount - 1]

      if(!lastField.label.length)
        fieldCount--
      if(lastField.is_done)
        completed--

      return { completed, fieldCount }
    }, [fields])

    return (
        <div className='note-check-list-block'>
          <div ref={dragPreview} className='note-itemized-block check-list'>
            {fields.map((item, index) => {
              const isLast = index === fields.length - 1
              return !isLast || !readonly ? (
                <ItemizedBlockField
                  key={item.id}
                  type={TO_DO_ITEM}
                  placeholder={isLast ? 'Check list item' : undefined}
                  {...{ isLast, ItemLeft, ItemRight, readonly, ...props, ...item }}
                />
              ) : null
            })}
            <div className='check-list-progress'>
              <div style={{ width: completed ? `calc(100% / ${fieldCount} * ${completed})` : 0 }} />
            </div>
          </div>
          <div className='check-list-completed'>COMPLETED {completed}/{fieldCount}</div>
        </div>
    )
  }
}

function ItemLeft({ is_done, isLast, readonly, ...props }){
  return !isLast
    ? <Checkbox
        checked={is_done}
        style={{ transform: is_done ? 'translateY(-1px)' : undefined, marginRight: !is_done ? 2 : undefined, cursor: !readonly ? 'pointer' : 'auto' }}
        onClick={!readonly ? () => setValue('is_done', props)(!is_done) : undefined}
      />
    : <i className='fal fa-plus' />
}

function ItemRight({ isLast, blockId, task_id, readonly }){
  const task = useSelector(state => state.notes.tasks[task_id])

  const setTask = date => {
    if(date === 'delete')
      return removeCheckListTask(blockId)

    if(date)
      date = setHours(setMinutes(date, 0), 8)
    setCheckListTask(blockId, date)
  }

  return !isLast && !readonly ? (
    <DueDate
      iconProps={{ active: task, icon: 'clipboard-check' }}
      MenuTop={DueDateTop}
      tooltipText={task ? 'Update the task assigned to this item' : 'Assign a task to this item'}
      {...{ task, setTask }}
      simpleIcon
    />
  ) : null
}

function DueDateTop({ task, setTask }){
  return task
    ? <Hoverable className='due-date-top' onClick={() => setTask('delete')}>
        Delete Task
        {task.datetime && <div className='date-display'>{format(new Date(task.datetime), 'LLL d, h:mm a')}</div>}
      </Hoverable>
    : <Hoverable className='due-date-top' onClick={() => setTask()}>Create Task</Hoverable>
}

export default CheckListBlock