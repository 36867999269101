import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import InstallComponent from './InstallComponent';
import useIntegrationHandler from '../useIntegrationHandler';

function Install({ beforeRedirect, afterNonOAuth }) {
    const { integrationName } = useParams()
    const [userParams, setUserParams] = useState({})
    const {
        installIntegration,
        loading,
        integrationSpec
    } = useIntegrationHandler({ name: integrationName, userParams, beforeRedirect, afterNonOAuth })
    
    return <InstallComponent
        specs={integrationSpec}
        title={`Connect Plantt with your ${integrationSpec?.name || ''} Account`}
        buttonText="Continue"
        params={userParams}
        setParams={setUserParams}
        moveOn={installIntegration}
        loading={loading}
    />
}

export default Install