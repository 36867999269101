import { parse, stringify } from "query-string"
import { useMemo } from "react"
import { useLocation } from "react-router"
import history from "../../utils/history"

export const getSearch = () => {
  try {
    return parse(atob(history.location.search.slice(1)))
  }
  catch (error) {
    return ''
  }

}
export const useSearch = () => {
  const { search } = useLocation()
  return useMemo(() => getSearch(), [search])
}

export const getCurrentSegment = () => getSearch().segment || 'All Accounts'
export const useCurrentSegment = () => {
  const { search } = useLocation()
  return useMemo(() => getCurrentSegment(), [search])
}

export const applyQueryParams = changes => {
  const search = getSearch()
  const queryParams = { ...search }
  let hasChanges = false
  for(const key in changes){
    const value = changes[key]
    if(value?.length || !isNaN(value) || (search[key] && search[key].length) || typeof value === 'undefined'){
      hasChanges = true
      if(value?.length || !isNaN(value))
        queryParams[key] = value
      else
        delete queryParams[key]
    }
  }

  if(hasChanges)
    history.push(`${history.location.pathname}?${btoa(stringify(queryParams))}`)
}

export const groupPath = group => `/accounts?${btoa(`segment=${group}`)}`