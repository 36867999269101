import { compose } from 'redux';
import injectReducer from '../../utils/injectReducer';

import reducer from './reducer';

import SettingsPage from './SettingsPage';

const withReducer = injectReducer({ key: 'setting', reducer });

export default compose(withReducer)(SettingsPage);
