import { Popper } from "@material-ui/core";
import { useEffect, useRef, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import ConfirmModal from "../../../components/ConfirmModal";
import Hoverable from "../../../components/Hoverable";
import HoverableIcon from "../../../components/HoverableIcon";
import AssociatedMembers from "../../../components/UserAvatar/AssociatedMembers";
import { deleteNote, saveAsTemplate, setNoteTimer } from "../actions";
import GistShare from './GistShare';
import GistTalk from "./GistTalk";
import Timer from './Timer';

function GistActionsMenu({ containerRef }){
  const readonly = useSelector(state => state.notes.currentViewer.permissions === 'read')
  const location = useLocation()
  const noteId = useMemo(() => {
    const split = location.pathname.split('/')
    return split[split.length - 1]
  }, [location.pathname])

  const [deleting, setDeleting] = useState(false)
  const [openMenu, setOpenMenu] = useState(null)
  const menuRef = useRef()
  const gamifyRef = useRef()
  const gistTimer = useSelector(state => state.notes.currentNote?.timer || null)

  useEffect(() => {
    if(openMenu){
      const closeMenu = e => {
        if(
          openMenu !== 'share'
          && !e.target.classList.contains('actions-bar-button')
          && !e.target.closest('.actions-bar-button')
        )
          setOpenMenu(null)
      }
      window.addEventListener('click', closeMenu)
      return () => window.removeEventListener('click', closeMenu)
    }
  }, [openMenu])

  return (
    <div id='gist-actions-wrapper'>
      <Timer />
      <GistTalk />
      <GistCollaborators />
      {!readonly
        ? <>
            <GistShare {...{ openMenu, setOpenMenu }} />
            <div className='actions-bar-button'> {/*onClick={() => setOpenMenu('gamify')}>*/}
              {/* <HoverableIcon icon='gamepad-alt' size={36} ref={gamifyRef} /> */}
              <HoverableIcon icon='stopwatch' size={36} onMouseDown={() => !gistTimer && setNoteTimer({ minutes: '00', seconds: '00' })} />
            </div>
            <div className='actions-bar-button' onClick={() => setOpenMenu('actions')}>
              <HoverableIcon type='far' activeType='far' icon='ellipsis-h' ref={menuRef} size={36} />
            </div>
            {['actions', 'gamify'].includes(openMenu) &&
            <Popper
              id='gist-actions-menu'
              // container={containerRef.current}
              open={true}
              anchorEl={(openMenu === 'gamify' ? gamifyRef : menuRef).current}
              placement='bottom-end'
            >
              <div className='card'>
                {openMenu === 'gamify'
                  ? <>
                      <Hoverable onMouseDown={() => !gistTimer && setNoteTimer({ minutes: '00', seconds: '00' })}>
                        <i className='fal fa-stopwatch' /> Timer
                      </Hoverable>
                      <Hoverable>
                        <i className='fal fa-glass-cheers' /> Celebrate
                      </Hoverable>
                      <Hoverable>
                        <i className='fal fa-bell' /> Bell
                      </Hoverable>
                    </>
                  : <>
                      <Hoverable onMouseDown={() => setDeleting(true)}>
                        <i className='fal fa-trash' /> Delete
                      </Hoverable>
                      <Hoverable onMouseDown={() => saveAsTemplate(noteId)}>
                        <i className='fal fa-file-invoice' /> Save as Template
                      </Hoverable>
                    </>
                }
              </div>
            </Popper>}
            <ConfirmModal
              open={deleting}
              onClose={() => setDeleting(false)}
              text='Are you sure you would like to delete this gist?'
              onConfirm={() => deleteNote()}
            />
          </>
        : <div style={{ padding: '0 4px' }} />}
    </div>
  )
}

function GistCollaborators() {
  const activeCollaborators = useSelector(state => state.notes.activeCollaborators)
  const collaborators = useSelector(state => state.notes.currentNote.collaborators || {})
  const members = useMemo(() => {
      return Object.entries(collaborators)
        .filter(([id]) => activeCollaborators.includes(id))
        .map(([id, { email, name, avatar_url }]) => ({ id, profile_image: avatar_url, name, email }))
  }, [collaborators, activeCollaborators])

  return <AssociatedMembers members={members} tooltipTitle='Collaborators' />
}

export default GistActionsMenu