
import { store } from "../../.."
import { ClickAwayListener, Popper } from "@material-ui/core"
import { useRef, useState } from "react"
import Hoverable from "../../../components/Hoverable"
import { clearSelection, deleteBlock, getBlockOffspring, updateCurrentNoteSelection, setIsDragging } from "../actions"
import { EmojiPicker } from "./EmojiDisplay"

function BlockLeft({ block, drag, blockId, buildGroups }){
  const ref = useRef()
  const heading = parseInt(block?.payload?.heading ?? 0)
  const style = heading
    ? { transform: `translateY(${heading === 1 ? 32 : heading === 2 ? 20 : 17}px)` }
    : undefined

  const handleDragStartMouseDown = (e) => {
    e.stopPropagation() //avoid collistion of react dnd & vselect
    const selectionList = store.getState().notes.selection.map(selectionBlock => selectionBlock.blockId)
    if(!selectionList.includes(blockId)) {
      buildGroups()
      clearSelection()
    }
  }

  const handleMouseEnter = () => {
    const { currentNote: { blocks }, selection } = store.getState().notes
    const children = getBlockOffspring(blocks, blockId)
    if(!children.length || selection.length)
      return
 
    children.unshift(blockId)
    const newSelection = []
    store.getState().notes.blockOrder.forEach(blockId => {
      if(children.includes(blockId))
        newSelection.push({ blockId, index: newSelection.length })
    })
    store.dispatch(updateCurrentNoteSelection(newSelection))
    buildGroups()
  }

  const handleMouseLeave = () => {
    const { currentNote: { blocks }, selection } = store.getState().notes
    const children = getBlockOffspring(blocks, blockId)
    for(const item of selection)
      if(!children.includes(item.blockId) && item.blockId !== blockId)
        return
        
    store.dispatch(updateCurrentNoteSelection([]))
  }

  const [open, setOpen] = useState(false)
    
  return (
    <div className='note-block-left' style={style} ref={ref}>
      <EmojiPicker blockId={blockId} />
      <div ref={drag} className='block-drag-handle' onMouseUp={() => setOpen(true)} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} onMouseDown={handleDragStartMouseDown}>
        <i className='fas fa-grip-vertical' />
      </div>
      {open &&
      <Popper className='block-menu-popper' anchorEl={ref.current} open={true} placement='bottom-end'>
        <ClickAwayListener onClickAway={() => setOpen(false)} >
          <div className='card'>
            <Hoverable onClick={() => deleteBlock(blockId, block.type === 'task')}>
              <i className='fas fa-trash' /> Delete {block.type !=='task' ? 'block' : 'task'}
            </Hoverable>
          </div>
        </ClickAwayListener>
      </Popper>}
    </div>
  )
}

export default BlockLeft