export const CLEAR_NOTES_AND_TASKS = 'plantt/Gists/CLEAR_NOTES_AND_TASKS'

export const SET_FOCUSED_EDITOR = 'plantt/Gists/SET_FOCUSED_EDITOR'
export const SET_CURRENT_NOTE_FROM_SNAPSHOT = 'plantt/Gists/SET_CURRENT_NOTE_FROM_SNAPSHOT'
export const SET_NOTE_LIST_FROM_SNAPSHOT = 'plantt/Gists/SET_NOTE_LIST_FROM_SNAPSHOT'
export const SET_TEMPLATES_FROM_SNAPSHOT = 'plantt/Gists/SET_TEMPLATES_FROM_SNAPSHOT'
export const SET_TASKS_FROM_SNAPSHOT = 'plantt/Gists/SET_TASKS_FROM_SNAPSHOT'

export const COMMIT_FIREBASE_UPDATE = 'plantt/Gists/COMMIT_FIREBASE_UPDATE'
export const COMMIT_FIREBASE_REMOVE = 'plantt/Gists/COMMIT_FIREBASE_REMOVE'
export const COMMIT_FIREBASE_SET = 'plantt/Gists/COMMIT_FIREBASE_SET'

export const SET_DYNAMIC_VALUES = 'plantt/Gists/SET_DYNAMIC_VALUES'

export const SET_CURRENT_TEMPLATE = 'plantt/Gists/SET_CURRENT_TEMPLATE'

export const SET_EXISTING_TAGS = 'plantt/Gists/SET_EXISTING_TAGS'
export const ADD_TO_EXISTING_TAGS = 'plantt/Gists/ADD_TO_EXISTING_TAGS'

export const SET_DOCK_DATA = 'plantt/Gists/SET_DOCK_DATA'
export const CLEAR_DOCK_DATA = 'plantt/Gists/CLEAR_DOCK_DATA'
export const APPLY_OBJECT_UPDATE = 'plantt/Gists/APPLY_OBJECT_UPDATE'

export const FETCH_DEFAULT_TEMPLATES_SUCCESS = 'plantt/Gists/FETCH_DEFAULT_TEMPLATES_SUCCESS'
export const SET_ACTION_IN_MODAL = 'plantt/Gists/SET_ACTION_IN_MODAL'
export const UPDATE_CURRENT_NOTE_SELECTION = 'plantt/Gists/UPDATE_CURRENT_NOTE_SELECTION'

export const SET_CURRENT_VIEWER = 'plantt/Gists/SET_CURRENT_VIEWER'
export const SET_IN_OVERLAY = 'plantt/Gists/SET_IN_OVERLAY'

export const SET_IS_DRAGGING = 'plantt/Gists/SET_IS_DRAGGING'
export const SET_NOTE_CARET_POSITION = 'plantt/Gists/SET_NOTE_CARET_POSITION'
export const SET_ACTIVE_COLLABORATORS = 'plantt/Gists/SET_ACTIVE_COLLABORATORS'



//Dropzone types
export const GIST_BLOCK = 'dropzone/GIST_BLOCK'
export const BASIC_BLOCK = 'dropzone/BASIC_BLOCK'
export const DOCK_WIDGET = 'dropzone/DOCK_WIDGET'
export const TO_DO_ITEM = 'dropzone/TO_DO_ITEM'
export const METRIC = 'dropzone/METRIC'
export const PLANTT_FIELD = 'dropzone/PLANTT_FIELD'
export const INTENDATION_WIDTH = 20