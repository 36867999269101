/*
 * SettingsConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const HOOK = 'plantt/ConversationPage/HOOK';
export const HOOK_SUCCESS = 'plantt/ConversationPage/HOOK_SUCCESS';
export const HOOK_ERROR = 'plantt/ConversationPage/HOOK_ERROR';
export const FETCH_ACCOUNT_INVITE_SUCCESS = 'plantt/SettingPage/FETCH_ACCOUNT_INVITE_SUCCESS';
export const FETCH_ACCOUNT_TEAM_SUCCESS = 'plantt/SettingPage/FETCH_ACCOUNT_TEAM_SUCCESS';
export const SET_ACCOUNT_INVITE_SUCCESS = 'plantt/SettingPage/SET_ACCOUNT_INVITE_SUCCESS';
export const SET_INVITE_DIALOG_OPEN = 'plantt/SettingPage/SET_INVITE_DIALOG_OPEN';

export const FETCH_API_KEY = 'plantt/SettingPage/FETCH_API_KEY';
export const FETCH_API_KEY_SUCCESS = 'plantt/SettingPage/FETCH_API_KEY_SUCCESS';
export const FETCH_API_KEY_ERROR = 'plantt/SettingPage/FETCH_API_KEY_ERROR';
export const POST_API_KEY = 'plantt/SettingPage/POST_API_KEY';
export const POST_API_KEY_SUCCESS = 'plantt/SettingPage/POST_API_KEY_SUCCESS';
export const POST_API_KEY_ERROR = 'plantt/SettingPage/POST_API_KEY_ERROR';
export const DELETE_API_KEY = 'plantt/SettingPage/DELETE_API_KEY';
export const DELETE_API_KEY_SUCCESS = 'plantt/SettingPage/DELETE_API_KEY_SUCCESS';
export const DELETE_API_KEY_ERROR = 'plantt/SettingPage/DELETE_API_KEY_ERROR';

export const FETCH_TEAM = 'plantt/SettingPage/FETCH_TEAM'
export const FETCH_TEAM_SUCCESS = 'plantt/SettingPage/FETCH_TEAM_SUCCESS'

export const FETCH_SETTINGS_SUCCESS = 'plantt/SettingPage/FETCH_SETTINGS_SUCCESS'
export const FETCH_SETTINGS_ERROR = 'plantt/SettingPage/FETCH_SETTINGS_ERROR'
export const UPDATING_SETTINGS = 'plantt/SettingPage/UPDATING_SETTINGS'
export const UPDATING_SETTINGS_DISMISSED = 'plantt/SettingPage/UPDATING_SETTINGS_DISMISSED'
export const UPDATE_SETTINGS_SUCCESS = 'plantt/SettingPage/UPDATE_SETTINGS_SUCCESS'
export const UPDATE_SETTINGS_ERROR = 'plantt/SettingPage/UPDATE_SETTINGS_ERROR'
export const SET_SETTINGS_PROPERTY_BY_PATH = 'plantt/SettingPage/SET_SETTINGS_PROPERTY_BY_PATH'
export const FETCH_INVOICES_SUCCESS = 'plantt/SettingPage/FETCH_INVOICES_SUCCESS'
export const RESET_CHECKOUT_PLAN = 'plantt/SettingPage/RESET_CHECKOUT_PLAN'
export const SET_CHECKOUT_PLAN = 'plantt/SettingPage/SET_CHECKOUT_PLAN'
export const SET_SUBSCRIPTION_ID = 'plantt/SettingPage/SET_SUBSCRIPTION_ID'

export const SET_CHECKOUT_TOKEN = 'plantt/SettingPage/SET_CHECKOUT_TOKEN'
export const REPLACE_CREDIT_CARD_STATUS = 'plantt/SettingPage/REPLACE_CREDIT_CARD_STATUS'
export const PAYMENT_MEHTOD_DATA = 'plantt/SettingPage/PAYMENT_MEHTOD_DATA'



