import { ReactEditor } from "cs-slate-react"
import { Transforms } from "cs-slate"
import { Fragment, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { createBlock, getFieldDefault } from "../actions"
import InteractionMenu from "."
import { planttActions, basicActions } from "./hardcodedActions"

function ActionCreator({ id, selection, isTitle = false, setInteractionMenu, interaction, ...props }){
  const allActions = useSelector(state => state.global.allActions)
  const [search, setSearch] = useState('')

  const items = useMemo(() => {
    const actions = allActions.filter(action => action.user_trigger_enable)
    const inSearch = r => (r.name || r.type).toLowerCase().includes(search.toLowerCase()) || r.searchTerms?.includes(search.toLowerCase())
    const available = planttActions.concat(actions).filter(r => (!r.spec_ref || r.enabled) && inSearch(r))
    const all = actions.filter(r => !r.enabled && inSearch(r))
    const basic = basicActions.filter(inSearch)

    let prepared = basic.length ? [{ section: 'Basic Blocks' }].concat(basic) : []
    if(available.length)
      prepared = prepared.concat([{ section: 'Available Actions'}], available)
    if(all.length)
      prepared = prepared.concat([{ section: 'All Actions'}], all)
    return prepared
  }, [allActions, search])

  const resetInteractionMenu = () => setInteractionMenu({ anchor: null })

  const interact = (event, hovered) => {
    event.preventDefault()
    const action = items[hovered]
    if(action.getBlock){
      const { options = {}, ...newBlock } = action.getBlock()
      createBlock(id, newBlock, options)
    }else{
      const values = {}
      action.input.forEach(field => values[field.key] = getFieldDefault(field))
      const newBlock = action.enabled
        ? { type: 'action', payload: { action_id: action.id, values, dynamicValues: {} } }
        : { type: 'disabled_action', payload: { action_id: action.id, spec_ref: action.spec_ref } }
      createBlock(id, newBlock, { autoFocus: action.enabled })
    }

    const { editor } = props
    if(editor && !isTitle){
      ReactEditor.focus(editor)
      setTimeout(() => {
        Transforms.delete(editor, { at: selection.anchor, reverse: true })
        resetInteractionMenu()
      })
    }else{
      resetInteractionMenu()
    }
  }

  return (
    <InteractionMenu
      searchProps={{ placeholder: 'Search for an action', icon: 'search', noMatch: 'No actions match your search' }}
      {...{ search, setSearch, items, resetInteractionMenu, interact, ...props }}
      RenderItem={ActionCreatorItem}
      open={interaction === 'actions'}
    />
  )
}


function ActionCreatorItem({ item, itemRef, ...props }) {
  const connector = useSelector(state => !item.section && state.global.connectorSpecs.find(connector => connector.id === item.spec_ref))

  if(item.section)
    return <div className='action-creator-section'>{item.section}</div>

  return (
    <div
      className='interaction-menu-item is-action-menu'
      ref={itemRef}
      {...props}
    >
      {!item.icon_name
        ? <Fragment>
            {connector ? <img src={connector.icon_url} style={{ width: connector.id === 'salesforce' ? 24 : 16 }} /> : <div></div>}
            <div>{item.name}</div>
          </Fragment>
        : <Fragment><i className={`fal fa-${item.icon_name}`} /> {item.name || item.type}</Fragment>
      }
    </div>
  )
}

export default ActionCreator