import { Modal } from '@material-ui/core'
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import Autocomplete from '../../../components/FormControls/Autocomplete'
import DebouncedInput from '../../../components/FormControls/DebouncedInput'
import Scrollable from '../../../components/Scrollable'
import { fetchDock, setActionInModal, setBlock } from '../../GistEditor/actions'
import ActionForm from '../../GistEditor/Blocks/Action/ActionForm'

const initialPayload = (action, init) => ({ values: {}, dynamicValues: {}, action_id: action?.id, ...init })

const actionSelector = createSelector(
  state => {
    const { notes: { actionInModal }, global: { allActions, connectorSpecs } } = state
    return { actionInModal, allActions, appSpecs: connectorSpecs }
  },
  ({ actionInModal, allActions, appSpecs }) => {
    allActions = allActions.filter(a => a.enabled).map(a => ({ ...a, spec: appSpecs.find(app => app.id === a.spec_ref) }))
    const action = allActions.find(action => action.id === actionInModal?.id)

    return { allActions, actionInModal, action }
  }
)

function ActionModal(){
  const dispatch = useDispatch()
  const ref = useRef()
  const { allActions, actionInModal, action } = useSelector(actionSelector)
  const isConfig = actionInModal?.isConfig
  const { payload: blockPayload } = useSelector(({ notes: { currentNote, actionInModal }}) => currentNote?.blocks?.[actionInModal?.blockId] || {})
  const { blockId } = actionInModal || {}

  const [payload, setPayload] = useState(initialPayload(action))
  useLayoutEffect(() => {
    if(action)
      setPayload(initialPayload(action, blockPayload?.config))
  }, [action, blockPayload])

  const close = () => dispatch(setActionInModal(null))

  const onSuccess = async () => {
    dispatch(fetchDock({ specRef: action?.spec_ref }, true))
    close()
  }

  const onActionSelect = item => {
    setBlock(blockId, { payload: { ...blockPayload, action_id: item.id || '', spec_ref: item.spec_ref || null } })
    dispatch(setActionInModal({ ...actionInModal, id: item.id }))
  }

  const submitOverride = () => {
    const config = { ...payload }
    delete payload.action_id
    setBlock(blockId, { payload: { ...blockPayload, config } })
    close()
  }

  return (
    <Modal open={Boolean(action || isConfig)} onClose={close} id='dock-action-modal' ref={ref} disableEnforceFocus>
      <div className='card'>
        <h1>
          {isConfig
            ? <><i className='fal fa-cogs' /> Thread Button Config</>
            : <><img src={action?.spec?.icon_url} /> {action?.spec?.name} - {action?.name}</>
          }
        </h1>
        <Scrollable>
          {isConfig &&
          <div className='note-action-form' style={{ borderBottom: '1px dashed #ddd' }}>
            <label>
              Button Name
              <DebouncedInput initialValue={blockPayload.name} setValue={name => setBlock(blockId, { payload: { ...blockPayload, name } })} />
            </label>
            <label>
              Button Action
              <Autocomplete
                initialValue={action}
                inputProps={{ placeholder: 'Select an action' }}
                items={allActions}
                getItem={item => <><img src={item.spec?.icon_url} style={{ width: 16, marginRight: 12, marginLeft: -2 }} />{item.name}</>}
                onSelect={onActionSelect}
                filterBy='name'
                icon={action?.spec?.icon_url ? <img src={action.spec.icon_url} style={{ width: 14, marginRight: 8 }} /> : null}
              />
            </label>
          </div>}
          {action &&
          <ActionForm
            onSuccess={onSuccess}
            {...{ action, payload, setPayload }}
            popperContainer={ref}
            submitOverride={isConfig ? submitOverride : undefined}
          />}
        </Scrollable>
      </div>
    </Modal>
  )
}

export default ActionModal