import React from 'react'
import { Helmet } from 'react-helmet';
import '../Onboarding/Chatbot/style.scss';
import Tree from '../../images/OnBoarding/tree.svg'
import { OrangeButton } from  '../../components/Button';
import useCalendly from '../../helpers/useCalendly';

function TrialExpired() {
    const { openCalendly } = useCalendly()

    return (
        <div>
            <Helmet><title>Trial Expired</title></Helmet>
            <div className="width-limit">
                <div className="first-title">Your trial has expired</div>
                <div className="second-title" >Contact your account manager to start your subscription.</div>
                <div className="tree"><img src={Tree} /></div>
                <OrangeButton
                    rounded
                    onClick={openCalendly}
                    className='onboarding-button'
                >Set a call with us</OrangeButton>
            </div>
        </div>
    )
}
export default TrialExpired