import { makeStyles } from "@material-ui/core"
import { forwardRef, useMemo } from "react"

const useStyles = makeStyles({
  token: ({ minWidth, centered }) => ({
    backgroundColor: 'rgba(21, 87, 136, 0.2)',
    padding: '4px 12px 4px 8px',
    borderRadius: 100,
    // marginLeft: 40,
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth,
    margin: centered ? '0 auto' : null
  }),
  emoji: {
    fontSize: 14,
    marginRight: 8,
    transform: 'translateY(1px)'
  }
})

function RelationshipScoreToken({ score, renderScore, classes, showWhenZero, minWidth, centered, ...props }, ref){
  const defaultClasses = useStyles({ minWidth, centered })
  const computedClasses = useMemo(() => ({ ...defaultClasses, ...classes }), [defaultClasses, classes])

  const emoji = useMemo(() => {
    switch(true){
      case score < 3.5:
        return '🙁'
      case score >= 3.5 && score < 5.5:
        return '😐'
      case score >= 5.5 && score < 8.5:
        return '🙂'
      case score >= 8.5:
        return '🏆'
      default:
        return ''
    }
  }, [score])
  return score || showWhenZero ? (
    <div {...props} className={`${computedClasses.token} ${props.className || ''}`} ref={ref}>
      <span className={computedClasses.emoji}>{emoji}</span>
      {renderScore || score}
    </div>
  ) : null
}

export default forwardRef(RelationshipScoreToken)
