import { parse } from "query-string";
import { firebase } from "../..";
import history from "../../utils/history";

export const finalizeSignIn = async (email = window.localStorage.getItem('emailForSignIn')) => {
  if(!firebase.auth.isSignInWithEmailLink(window.location.href))
    return

  const { redirect } = parse(window.location.search)
  
  try {
    if(!email)
      await firebase.auth.signInWithEmailLink('fake@fake.com', window.location.href)
    
    await firebase.auth.signInWithEmailLink(email, window.location.href)
    window.localStorage.removeItem('emailForSignIn')
    window.location = redirect || '/'
  } catch(error) {
    if(error.code === 'auth/invalid-action-code')
      return history.replace('/login' + (redirect ? `?redirect=${redirect}` : ''))
    if(!email)
      return 'no_email'
  }
}