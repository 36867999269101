import { useSelector } from "react-redux"

export const useAddressSelector = address => {
  return useSelector(state => {
    if(!address)
      return null
    
    for(const item of address){
      if(state && typeof state === 'object')
        state = state[item]
      else
        break
    }
      
    return state
  })
}

export default useAddressSelector