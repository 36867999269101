
import {
    START_ADDING_CONNECTOR,
    SET_IMPORT_DATA,
    SET_IMPORT_DATA_FIELDS,
    SET_AUTHENTICATION_STATUS,
    SET_OAUTH2_PROCESS_STATUS,
    SET_AUTHENTICATION_PAGE_ERROR,
    FETCH_CONNECTOR_SAMPLES_SUCCESS,
    DELETE_CONNECTOR,
    UPDATE_LOADING,
    UPDATE_MAPPING_TABLE_FIELDS,
    FETCH_ACCOUNT_FIELDS_SUCCESS,
    FETCH_ACCOUNT_FIELDS_ERROR,
    RESET_MAPPING_TABLE_DATA,
    SET_ALL_MAPPING_FIELDS_TO_READ_ONLY,
    UPDATE_CONNECTOR
} from './constants'
import { setRedirectUri } from './AddApp/HandleOauthRedirect'
import { NEW_sendPutRequest, sendGetRequest, NEW_sendGetRequest, sendPutRequest, sendPostRequest, sendDeleteRequest } from '../../apis/api-utils'
import { BASE_URL2 } from '../../apis/constant'
import { fetchConnectors, setToastMessage } from '../App/actions'
import history from '../../utils/history'
import { fetchFields } from '../../components/PlanttTable/ImportTable/helpers'
import { trackMixpanelEvents } from '../../helpers/mixpanel'
export const deleteConnectorFromRedux = (connectorId) => ({ type: DELETE_CONNECTOR, connectorId })
export const deleteConnector = (connectorId) => async (dispatch, getState) => {
  try {
  await sendDeleteRequest(`${BASE_URL2}connector/${connectorId}`)
  dispatch(deleteConnectorFromRedux(connectorId))
  } catch (error) {
    console.error('Error deleting app', error);
    setToastMessage(`Error deleting app`,'error')  }
}
export const fixAuthFields = (authFields) => {
  const readyAuthFields = {}
  Object.entries(authFields).forEach(([key,value]) => {
    readyAuthFields[key] = value.value
  })
  return readyAuthFields
}
export const addConnector = (connectorSpec, authFields = {}) => async (dispatch, getState) => {
  const readyAuthFields = fixAuthFields(authFields)
  if (connectorSpec.authentication.type === 'oauth2') dispatch(setOauth2ProcessStatus(1))
  dispatch(setAuthenticationStatus(1))
  //Handle zendesk execption
  const regex = new RegExp(`/(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})zendesk\.com`);
  const zendeskSubdomain = regex.test(readyAuthFields.zendesk_subdomain) ? readyAuthFields.zendesk_subdomain.match(regex)[1].slice(1) : readyAuthFields.zendesk_subdomain
  const handledAuthFields = connectorSpec.id === 'zendesk' ?{...readyAuthFields,...{zendesk_subdomain:zendeskSubdomain}} : readyAuthFields
  const zendeskException = connectorSpec.id === 'zendesk' ? handledAuthFields : {}

  try {
    const newConnectorInstance = {
      account_id: getState().global.workspace.id,
      name: connectorSpec.name,
      spec_ref: connectorSpec.id,
      authentication: {
        status: "unauthenticated",
        auth_config: zendeskException
      },
      configuration: {},
      identity_mapping:connectorSpec.default_identity_mapping,
      sync_status: {
        status: 'in_progress',
        message: "",
        total_new: 0
      },
      status: connectorSpec.data_sync ? "draft" : "active",
    }
    const newConnector = await sendPostRequest(`${BASE_URL2}connector`,{},JSON.stringify(newConnectorInstance))
    if(newConnector) {
      dispatch(updateCurrentConnector(newConnector))
      const inAddingConnectorSpec = getState().connectors.inAddingConnectorSpec
      dispatch(startAddingConnector({...inAddingConnectorSpec,...{newInstanceId:newConnector.id}}))
      if(connectorSpec.authentication.type === 'other') {
        console.log(`@@@`,handledAuthFields)  
        dispatch(updateConnectorAuthenticationInfoNotOauth(newConnector.id, handledAuthFields))
      }

    }
    else {
      //Error in adding connector
    }
  } catch (error) {
    console.error('Error fetching App specs from server', error);
    setToastMessage(`Error fetching App specs from server`,'error')  }
}

export const updateConnector = connector => ({ type: UPDATE_CONNECTOR, connector })
export const startAddingConnector = connectorSpec  => ({ type: START_ADDING_CONNECTOR, connectorSpec })

export const setImportData = data => ({ type: SET_IMPORT_DATA, data })
export const setImportDataFields = data => ({ type: SET_IMPORT_DATA_FIELDS, ...data })
export const setAuthenticationStatus = status => ({ type: SET_AUTHENTICATION_STATUS, status })
export const setOauth2ProcessStatus = status => ({ type: SET_OAUTH2_PROCESS_STATUS, status })


const applyAuthenticationUpdate = connector => async dispatch => {
  const updatedConnector = await NEW_sendPutRequest(`${BASE_URL2}connector/${connector.id}`, {}, JSON.stringify(connector))
  if(!updatedConnector.ok)
    return console.error('Error with authentication update', updatedConnector.text)

  console.log({updatedConnector})

  dispatch(updateConnector(updatedConnector.text))
  dispatch(setAuthenticationStatus(3))
  dispatch(setAuthenticationPageError(''))
}

export const updateConnectorAuthenticationInfo = (connectorId, authInfo, isReauthorizing = false) => async (dispatch, getState) => {
  try {
    dispatch(setAuthenticationStatus(1))
    const currentConnector = await sendGetRequest(`${BASE_URL2}connector/${connectorId}`)
    const currentSpec = getState().global.connectorSpecs.find(spec => spec.id === currentConnector.spec_ref)
    const auth = await NEW_sendPutRequest(`${BASE_URL2}connector/${connectorId}/authorize`, {}, JSON.stringify({...authInfo, redirect_uri:setRedirectUri(currentSpec)}))
    if(auth?.ok) {
        dispatch(applyAuthenticationUpdate({
          ...currentConnector,
          status:'active',
          authentication:{ ...currentConnector.authentication, status: 'authenticated' }
        }))
    }
    else {
      if(!isReauthorizing)
        dispatch(startAddingConnector({...currentSpec,...{newInstanceId:connectorId}}))

      dispatch(setAuthenticationPageError(auth.text))
      dispatch(setAuthenticationStatus(2))
    }
  } catch (error) {
    console.error('Error updating app authentication info', error);
    setToastMessage(`Error updating app authentication info`,'error')  }
}
export const setAuthenticationPageError = text => ({ type: SET_AUTHENTICATION_PAGE_ERROR, text })
export const updateConnectorAuthenticationInfoNotOauth = (connectorId, authInfo) => async (dispatch, getState) => {
  try {
    dispatch(setAuthenticationStatus(1))
    const currentConnector = await sendGetRequest(`${BASE_URL2}connector/${connectorId}`)
    const currentSpec = getState().global.connectorSpecs.find(spec => spec.id === currentConnector.spec_ref)
    const newAuthentication = {...currentConnector.authentication, auth_config:authInfo}
    const update =  await NEW_sendPutRequest(`${BASE_URL2}connector/${connectorId}`, {}, JSON.stringify( {...currentConnector,authentication:newAuthentication}))
    const auth = await NEW_sendPutRequest(`${BASE_URL2}connector/${connectorId}/authorize`, {}, JSON.stringify(authInfo))

    if(auth?.ok) {
      trackMixpanelEvents('connector_connected')
      dispatch(applyAuthenticationUpdate({
        ...update.text,
        status: currentSpec.data_sync ? 'active' : update.text.status,
        authentication: { ...newAuthentication, status: 'authenticated' }
      }))
    }
    else {
      dispatch(setAuthenticationPageError(auth.text))
      dispatch(setAuthenticationStatus(2))
    }
  } catch (error) {
    console.error('Error updating app authentication info', error)
    setToastMessage(`Error updating app authentication info`,'error')
  }
}

export const fetchConnectorLabels = connectorId => async dispatch => {
  try {
    const samples = await NEW_sendGetRequest(`${BASE_URL2}connector/${connectorId}/samples`)
    if(!samples.ok)
      throw new Error(samples.text)
    
    dispatch(fetchConnectorSamplesSuccess(samples.text))
  }catch(error){
    console.error('Failed to fetch labels:', error)
  }
}
export const resetMappingTable = () => ({type: RESET_MAPPING_TABLE_DATA})
export const fetchConnectorSamplesSuccess = samples => ({ type: FETCH_CONNECTOR_SAMPLES_SUCCESS, samples })
let mySet = new Set()

export const getFieldsFromSamples = (samples) => {
    samples.forEach(sample => {
      Object.entries(sample).forEach(([key,value]) => {
        if(value && typeof(value) === "object") {
          Object.entries(value).forEach(([level2Key,level2Value]) => {
            if(level2Value && typeof(level2Value) === "object") {
              Object.keys(level2Key).forEach(level3Key => {
                mySet.add(`${key}.${level2Key}.${level3Key}`)
              })
            }
            else
              mySet.add(`${key}.${level2Key}`)
          })
        } else
          mySet.add(key)
      })
    })
  return  Array.from(mySet)
}

export const fetchConnectorSamples = (connectorId, connectorSpecId) => async (dispatch, getState) => {
  const connector = getState().global.connectors.find(c => c.id === connectorId)

  try {
      const samples = await NEW_sendGetRequest(`${BASE_URL2}connector/${connectorId}/samples`)
      const connectorSpec = getState().global.connectorSpecs.find(spec => spec.id === connectorSpecId)
      if(samples.ok) {
        dispatch(setImportDataFields({
          data: getFieldsFromSamples(samples.text.records),
          defaultMappingFields: connectorSpec.default_identity_mapping,
          labelsObject: samples.text.labels,
          currentConnector: connector
        }))
        if(samples.text.fields) {
          samples.text.fields = samples.text.fields.map(field => field.id)
        }
        else {
          samples.text.fields = getFieldsFromSamples(samples.text.records)
        }
        dispatch(fetchConnectorSamplesSuccess(samples.text))
      }else if(samples.text.includes('no authentication')) {
        dispatch(setToastMessage(`There was an issue with ${connector?.name}'s authorization. Please reauthorize your connector and try again.`, 'error'))
        history.push(`/apps/view/${connectorId}`)
      }else {
        dispatch(setToastMessage(`We had some trouble getting samples from ${connector?.name}. Please try again.`, 'error'))
      }
      dispatch(updateLoading('samples', 1))
  } catch (error) {
    console.error('Error fetching samples from server', error);
    setToastMessage(`Error fetching samples from server`,'error')  }
}

const validateMapping = (dispatch, getState) => {
  const fields = getState().connectors.mappingTableData.fields
  let updatedFields = []
  let hasErrors = false
  Object.values(fields).forEach((field, index) => {
    const { errors } = field
    for(const error in errors){
      if(errors[error]){
        hasErrors = true
        break
      }
    }
  })
  return !hasErrors
}

export const updateLoading = (key, status) => ({ type: UPDATE_LOADING, key, status })
export const setAllMappingFieldsToReadOnly = () => ({type: SET_ALL_MAPPING_FIELDS_TO_READ_ONLY})
export const updateConnectorMapping = (connectorId) => async (dispatch, getState) => {
  if(!validateMapping(dispatch, getState)) {
    dispatch(setToastMessage(`Can't update Connector's mapping when having errors.`,'error'))
    return
  }
  try {
      dispatch(updateLoading('mappingOutput',1))
      const identity_mapping = {}
      const fields = getState().connectors.mappingTableData.fields
      const fieldsToPut = []
      
      fields.forEach(({ planttName, name, type, trackable }) => {
        if(!name || name === '')
          return
        const planttNameVar = getState().connectors.accountFields.find(field => field.label === planttName)
        identity_mapping[name] = planttNameVar ? planttNameVar.key : planttName.toLowerCase().replace(' ', '_')
        if(!type.readonly)
          fieldsToPut.push({ key: planttName.toLowerCase().replace(' ', '_'), type: type.is, label: planttName, track_changes: trackable })
      })

      const response = await NEW_sendPutRequest(`${BASE_URL2}connector/${connectorId}/mapping`, {}, JSON.stringify({ identity_mapping, fields: fieldsToPut }))
      if(response.ok) {
        dispatch(setAllMappingFieldsToReadOnly())
        const { spec_ref, status } = getState().global.connectors.find(c => c.id === connectorId) || {}
        if(status !== 'active') {
          const connectorSpec = getState().global.connectorSpecs.find(spec => spec.id === spec_ref)
          dispatch(setToastMessage(`Good mapping job! In order to start getting your ${connectorSpec?.name} data into plantt, you need to turn on syncing right here.`,'info'))
        }
        dispatch(updateLoading('mappingOutput', 2))
        dispatch(updateCurrentConnector(response.text))
        dispatch(setToastMessage('Your changes were saved successfully', 'success'))
      } else {
        throw new Error(response.text)
      }
      
    } catch (error) {
      console.error('Error updating connector mapping:', error);
      dispatch(setToastMessage(`We had some trouble saving your changes. Please try again`,'error'))
      dispatch(updateLoading('mappingOutput',3))
    }
}

export const updateMappingTableFields = (updatedFields) => ({ type: UPDATE_MAPPING_TABLE_FIELDS, updatedFields })

export const updateCurrentConnector = (updatedConnector) => async (dispatch) => {
  try {
    const update = await NEW_sendPutRequest(`${BASE_URL2}connector/${updatedConnector.id}`, {}, JSON.stringify(updatedConnector))
    if(!update.ok)
      throw new Error(update.text)

    dispatch(updateConnector(update.text))
  } catch (error) {
    console.error('Error updating app', error);
    setToastMessage(`Something went wrong with your update. Please try again`,'error')
    return { error }
  }
}

const fetchAccountFieldsSuccess = fields => ({ type: FETCH_ACCOUNT_FIELDS_SUCCESS, fields })
const fetchAccountFieldsError = error => ({ type: FETCH_ACCOUNT_FIELDS_ERROR, error })
export const fetchAccountFields = () => async dispatch => {
  try {
    const list = await fetchFields()
    dispatch(fetchAccountFieldsSuccess(list))
  } catch(error) {
    console.error('Failed to fetch identity fields:', error)
    dispatch(fetchAccountFieldsError(error))
  }
}