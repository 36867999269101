import { Editor, Element as SlateElement, Transforms } from 'cs-slate'
import formats from "./formats"
import LinkModal from "./LinkModal"

const LIST_TYPES = formats.filter(({ type, list }) => type === 'block' && list).map(({ format }) => format)

function ToolbarFormats({ Start, End, hideLink, hideLists, overrides, ...props }){
  return (
    <div className='note-editor-toolbar-formats'>
      {Start && <Start {...props} />}
      {!hideLink && <LinkModal {...props} />}
      {formats
        .filter(f => !hideLists || !f.list)
        .map(format => format.Icon ? <ToggleButton key={format.format} editor={props.editor} {...{ overrides, ...format }} /> : null)
      }
      {End && <End {...props} />}
    </div>
  )
}

export const isActive = {
  block: (editor, format) => {
    try {
      const [match] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format
      })
    
      return !!match
    } catch(error) {
      return false
    }
  },
  mark: (editor, format) => {
    try {
      const marks = Editor.marks(editor)
      return marks ? marks[format] === true || typeof marks[format] === 'number' : false
    } catch(error) {
      return false
    }
  }
}

export const toggle = {
  block: (editor, format, active) => {
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
      match: n => LIST_TYPES.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && n.type),
      split: true
    })
    const newProperties = { type: active ? 'paragraph' : isList ? 'list-item' : format }
    Transforms.setNodes(editor, newProperties)

    if (!active && isList) {
      const block = { type: format, children: [] }
      Transforms.wrapNodes(editor, block)
    }
  },
  mark: (editor, format, active) => {
    if (active)
      Editor.removeMark(editor, format)
    else
      Editor.addMark(editor, format, true)
  }
}

function ToggleButton({ editor, type, format, Icon, overrides }) {
  const active = typeof overrides[format]?.active !== 'undefined' ? overrides[format].active : isActive[type](editor, format)
  
  return (
    <button
      onMouseDown={event => {
        event.preventDefault()
        if(overrides[format]?.click)
          return overrides[format].click()
        toggle[type](editor, format, active)
      }}
      className={`format-button ${active ? 'is-active' : ''}`}
    >
      <Icon />
    </button>
  )
}

export default ToolbarFormats