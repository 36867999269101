import {
  PRE_UPDATE_ACCOUNT_FIELDS,
  PRE_UPDATE_CONTACT_FIELDS,
  UPDATE_ACCOUNT_FIELDS_ERROR,
  UPDATE_CONTACT_FIELDS_ERROR
} from '../Accounts/constants'

import {
  SET_LOADING,
  FETCH_CURRENT_ACCOUNT_SUCCESS,
  CLEAR_CURRENT_ACCOUNT,
  FETCH_RELATIONSHIP_ACTIVITY_SUCCESS,
  FETCH_RELATIONSHIP_ACTIVITY_INITIATED,
  FETCH_ACCOUNT_CONTACTS_SUCCESS,
  FETCH_RELATIONSHIP_ACTIVITY_ERROR,
  PRE_MODIFY_CONTACT,
  MODIFY_CONTACT_ERROR,
  MODIFY_CONTACT_SUCCESS,
  TOGGLE_CHECKED_CONTACT,
  MERGE_CHECKED_CONTACTS_SUCCESS,
  CLEAR_CHECKED_CONTACTS,
  DELETE_CHECKED_CONTACTS_SUCCESS,
  PRE_SET_CSM,
  SET_CSM_SUCCESS,
  SET_CSM_ERROR,
  FETCH_TRACKABLES_SUCCESS,
  SET_DISPLAYED_IN_DOCK,
  SET_ACTION_IN_MODAL,
} from './constants'

export const initialState = () => ({
  currentAccount: {},
  contacts: {
    records: [],
    preview: [],
    next_url: null,
    records_amount: 0,
    checked: [],
    page: 1
  },
  relationshipActivity: {
    fetchTimestamp: null,
    records: [],
    preview: [],
    next_url: null,
    records_amount: 0,
    page: 1
  },
  trackables: [],
  loading: {
    contacts: true,
    nextContacts: false,
    currentAccount: true,
    relationshipActivity: true,
    nextActivity: false,
    watchers: true
  },
  displayedInDock: null,
})

function accountsReducer(state = initialState(), action) {
  const newLoading = loading => ({ loading: { ...state.loading, ...loading } })

  switch(action.type){
    case SET_LOADING: {
      return { ...state, ...newLoading(action.loading) }
    }
    case CLEAR_CURRENT_ACCOUNT: {
      const newState = initialState()
      newState.loading.currentAccount = true
      return newState
    }
    case FETCH_RELATIONSHIP_ACTIVITY_INITIATED: {
      const newState = { ...state, relationshipActivity: { ...state.relationshipActivity, fetchTimestamp: action.timestamp }, ...newLoading({ relationshipActivity: true }) }
      if(action.page !== 'initial')
        newState.relationshipActivity.page = action.page
      return newState
    }
    case FETCH_RELATIONSHIP_ACTIVITY_SUCCESS: {
      if(action.timestamp !== state.relationshipActivity.fetchTimestamp)
        return state

      if(action.page === 'initial')
        action.relationshipActivity.preview = action.relationshipActivity.records.slice(0, 4)

      return {
        ...state,
        relationshipActivity: { ...state.relationshipActivity, ...action.relationshipActivity },
        ...newLoading({ relationshipActivity: false, nextActivity: false })
      }
    }
    case FETCH_RELATIONSHIP_ACTIVITY_ERROR: {
      return {
        ...state,
        relationshipActivity: { ...state.relationshipActivity },
        ...newLoading({ relationshipActivity: false })
      }
    }
    case FETCH_CURRENT_ACCOUNT_SUCCESS: {
      return {
        ...state,
        currentAccount: action.account,
        ...newLoading({ currentAccount: false })
      }
    }
    case PRE_UPDATE_ACCOUNT_FIELDS:
    case UPDATE_ACCOUNT_FIELDS_ERROR: {
      const currentAccount = { ...state.currentAccount, ...action.account }
      return { ...state, currentAccount }
    }
    case PRE_UPDATE_CONTACT_FIELDS:
    case UPDATE_CONTACT_FIELDS_ERROR: {
      const records = [ ...state.contacts.records ]
      const recordIndex = records.findIndex(contact => contact.id === action.contact.id)
      records[recordIndex] = { ...records[recordIndex], ...action.contact }

      return { ...state, contacts: { ...state.contacts, records } }
    }
    case FETCH_ACCOUNT_CONTACTS_SUCCESS: {
      if(action.page === 'initial')
        action.contacts.preview = action.contacts.records.slice(0, 4)
      else
        action.contacts.page = action.page

      return { ...state, contacts: { ...state.contacts, ...action.contacts }, ...newLoading({ contacts: false }) }
    }
    case MODIFY_CONTACT_SUCCESS:
    case MODIFY_CONTACT_ERROR:
    case PRE_MODIFY_CONTACT: {
      const records = [ ...state.contacts.records ]
      const recordIndex = records.findIndex(record => record.id === action.contact.id)
      records[recordIndex] = action.contact
      return { ...state, contacts: { ...state.contacts, records } }
    }

    case TOGGLE_CHECKED_CONTACT: {
      const checked = [ ...state.contacts.checked ]
      const index = checked.findIndex(id => id === action.id)
      if(index >= 0)
        checked.splice(index, 1)
      else
        checked.push(action.id)

      return { ...state, contacts: { ...state.contacts, checked } }
    }
    
    case MERGE_CHECKED_CONTACTS_SUCCESS: {
      let { checked, records } = state.contacts
      records = records.filter(record => !checked.includes(record.id) || record.id === action.primary)
      const primaryIndex = records.findIndex(record => record.id === action.primary)
      records[primaryIndex] = action.contact
      
      return { ...state, contacts: { ...state.contacts, checked: [], records } }
    }

    case DELETE_CHECKED_CONTACTS_SUCCESS: {
      let { checked, records } = state.contacts
      records = records.filter(record => !checked.includes(record.id))
      return { ...state, contacts: { ...state.contacts, checked: [], records } }
    }

    case CLEAR_CHECKED_CONTACTS: {
      return { ...state, contacts: { ...state.contacts, checked: [] } }
    }

    case PRE_SET_CSM:
    case SET_CSM_SUCCESS:
    case SET_CSM_ERROR: {
      return { ...state, currentAccount: action.account }
    }

    case FETCH_TRACKABLES_SUCCESS: {
      return { ...state, trackables: action.trackables.map((t, index) => ({ ...t, index })) }
    }

    case SET_DISPLAYED_IN_DOCK: {
      return { ...state, displayedInDock: action.app }
    }
      
    default:
      return state;
    }
}

export default accountsReducer