import { formatDistanceToNow } from "date-fns"
import { useCallback, useMemo } from "react"
import Skeleton from "react-loading-skeleton"
import ParticipantAvatars from "../../../components/UserAvatar/ParticipantAvatars"
import Favicon from '../../../images/plantt.svg'

function MomentHeadline({ skeleton, metadata = {}, type, id }){
  const { time, participants, title } = metadata
  const timeInfo = useMemo(() => {
    if(!time)
      return null
    
    const date = new Date(time * 1000)
    let t = formatDistanceToNow(date)
    return { string: t[0].toUpperCase() + t.slice(1), future: date > new Date() }
  }, [time])

  const AvatarsEmpty = useCallback(({ accounts, emails }) => {
    if(type === 'plantt_moment' || (!accounts.length && emails.length))
      return <img src={Favicon} className='plantt-moment-icon' />
    else
      return null
  }, [type])

  return (
    <div className='moment-headline'>
      {!skeleton
        ? <ParticipantAvatars {...{ participants, id }} EmptyState={AvatarsEmpty} />
        : <Skeleton circle={true} width={32} height={32} className='avatar-skeleton' />
      }
      <div className='headline-text'>
        <div>{!skeleton ? title : <Skeleton width={200} />}</div>
        {timeInfo &&
        <div className='moment-time'>
          {!skeleton ? `${timeInfo.future ? 'In ' : ''}${timeInfo.string}
          ${!timeInfo.future ? ' ago' : ''}` : <Skeleton width={60} height={12} />}
        </div>}
      </div>
    </div>
  )
}

export default MomentHeadline