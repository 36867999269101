import { useEffect, useMemo, useRef, useState } from 'react'
import { fetchDynamicList } from '../../actions';
import Autocomplete from '../../../../components/FormControls/Autocomplete';
import Select from '../../../../components/FormControls/Select';

export function DynamicDropdown({ action, field, setValue, value, values, blockRef, popperContainer }){
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)

  const search = useRef({ timeout: null, term: value?.text || '' })
  const fetchList = async () => {
    setLoading(true)
    const newList = await fetchDynamicList(values, search.current.term, action, field)
    if(!newList.error){
      if(!Array.isArray(newList))
        setList(Object.keys(newList).map(v => ({ value: v, text: newList[v] })))
      else
        setList(newList.map(({ type, items }) => {
          const asArray = Object.keys(items).map(v => ({ value: v, text: items[v], type, indent: true }))
          return [{ text: type.toUpperCase(), isSectionHeader: true }].concat(asArray)
        }).flat())
    }
    setLoading(false)
  }

  const onInput = async v => {
    if(search.current.timeout)
      clearTimeout(search.current.timeout)
    
    Object.assign(search.current, { term: v, timeout: setTimeout(fetchList, 500) })
  }

  useEffect(fetchList, [])

  return(
    <Autocomplete
      initialValue={value}
      inputProps={{ placeholder: 'Search or select from the dropdown' }}
      items={list}
      getItem={item => item.text || null}
      filterBy='text'
      onSelect={value => setValue(field, value || {})}
      onInput={onInput}
      resetOnClickAway='initial'
      popperProps={{ container: popperContainer?.current || blockRef.current }}
      loading={loading}
      // noFiltering
    />
  )
}

export function StaticDropdown({ value, setValue, field, blockRef, popperContainer }){
  const rawItems = field.dropdown_settings.items
  const items = useMemo(() => rawItems.map(item => item.key), [field])

  return (
    <Select
      initialValue={value}
      onSelect={({ selectedItem }) => setValue(field, selectedItem)}
      items={items}
      getItem={item => rawItems.find(i => i.key === item)?.label}
      popperProps={{ container: popperContainer?.current || blockRef.current }}
    />
  )
}