import { Switch, Route,useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import SettingsPage from '../SettingsPage';

import MyProfile from '../MyProfile';
import Apps from '../Apps';
import NotFoundPage from '../NotFoundPage/Loadable';
import Workspaces from '../Workspaces'
import TrialExpired from './TrialExpired';
import PlanttLayout from '../../components/PlanttLayout';
import ManageTemplate from '../GistEditor/ManageTemplate';
import { useBillingStatus } from '../../helpers';

import Tasks from '../Tasks'
import Gists from '../Gists'
import ChatbotLoadable from './ChatbotLoadable'
import CustomerSuccessLoadable from './CustomerSuccessLoadable';
import Integrations from '../Integrations';

function Sefi() {
    const location = useLocation()
    const workspace = useSelector(state => state.global.workspace)
    const role = workspace?.role
    const isAdmin = useSelector(state => state.global.currentMember?.type === 'admin')

    const { isExpired } = useBillingStatus()
    const showExpired = !isAdmin && isExpired && (!location.pathname.startsWith('/settings') || location.pathname.startsWith('/settings/te')) && location.pathname !== '/workspaces'

    return (
      <div className="app-wrapper">
        {workspace &&
        <PlanttLayout>
          {!showExpired
            ? <Switch>
                <Route exact path={`/:workspaceId(.{36})/:noteId`} component={Gists} />
                {role === 'lite' && <Route exact path="/" component={Gists} />}
                <Route path="/workspaces" component={Workspaces} />
                <Route path="/settings/templates/:templateId" component={ManageTemplate} />
                <Route path="/settings/:page/:plan?" component={ SettingsPage} />
                <Route exact path="/my-profile" component={ MyProfile} />

                <Route path="/apps" component={Apps} />
                <Route path="/tasks/:view?" component={Tasks} />
                <Route path={["/threads", "/starred"]} component={Gists} />

                <Route path="/inte" component={Integrations} />

                <Route path="">
                  {role === 'chat_bot' && <ChatbotLoadable />}
                  {['beta', 'customer_success'].includes(role) && <CustomerSuccessLoadable />}
                  {role === 'lite' && <NotFoundPage />}
                </Route>
              </Switch>
            : <TrialExpired />}
      </PlanttLayout>}
    </div>
  )   
}

export default Sefi