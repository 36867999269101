import firebase from 'firebase/app';
import {
  SET_FULL_ACCOUNT_DATA,
  SET_APP_DIMENSIONS,
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
  SET_LOGIN_ERROR,
  SET_GLOBAL_LOADING,
  SET_WORKSPACE_MEMBERS,
  FETCH_ONBOARDING_STATUSES_SUCCESS,
  FETCH_CONNECTORS_SUCCESS,
  FETCH_CONNECTOR_SPECS_SUCCESS,
  FETCH_SIGNALS_SUCCESS,
  SET_SEGMENTS,
  FETCH_ALL_FIELDS_SUCCESS,
  FETCH_ALL_FIELDS_ERROR,
  FETCH_ACCOUNTS_SUCCESS,
  TOGGLE_SHOW_GOOGLE_BANNER,
  UPDATE_MEMBER,
  FETCH_WATCHERS_SUCCESS,
  FETCH_ACTIONS_SUCCESS,
} from './constants';

import { NEW_sendGetRequest, NEW_sendPutRequest, sendGetRequest } from '../../apis/api-utils';
import { BASE_URL, BASE_URL2, GET_ACCOUNTS_URL, GET_USER_PROFILE } from '../../apis/constant';
import { Close } from '@material-ui/icons';
import { fetchFields } from '../../components/PlanttTable/ImportTable/helpers';
import { store } from '../..'
import { fetchGistTemplates } from '../GistEditor/actions';
import { parse } from 'query-string';
import history from '../../utils/history';

export const setGlobalLoading = payload => ({ type: SET_GLOBAL_LOADING, payload })
export const setLoginError = errorId => ({ type: SET_LOGIN_ERROR, errorId })
export const setFullAccountData = (account, additionalData) => ({ type: SET_FULL_ACCOUNT_DATA, account, additionalData })
export const toggleShowGoogleBanner = payload => ({ type: TOGGLE_SHOW_GOOGLE_BANNER, payload })
const setWorkspaceMembers = members => ({ type: SET_WORKSPACE_MEMBERS, members })

const bootIntercom = (account = { metadata: {} }, currentMember, statuses) => {
  const user = firebase.auth().currentUser
  window.Intercom('boot', {
    hide_default_launcher: true,
    app_id: 'wdoiy2sm',
    email: user.email,
    created_at: user.metadata.creationTime,
    name: user.displayName,
    user_id: user.uid,
    phone: account.metadata.phone || '',
    plantt_signup_at: new Date(currentMember.created_at).getTime() / 1000,
    company: { company_id: account.id, name: account.name, ...account.metadata, role: account.role, created_at: account.created_at },
    ...statuses,
    sync_google: undefined
  })
}

// onAuthChange
export const getAccount = () => async (dispatch, getState) => {
  if(localStorage.getItem('addingAccountFromGoogle') !== '1')
    try {
      let PlanttAccountID = localStorage.getItem('PlanttAccountID');
      let userProfile = await NEW_sendGetRequest(GET_USER_PROFILE)
      if(!userProfile.ok){
        dispatch(setGlobalLoading({ user: false }))
        history.replace(`/register?redirect=${history.location.pathname}`)
        throw new Error(userProfile.text)
      }

      userProfile = userProfile.text
      
      if(!PlanttAccountID || PlanttAccountID === 'undefined') {
        PlanttAccountID = userProfile.default_account_id;
        localStorage.setItem('PlanttAccountID', PlanttAccountID);
      }

      const [members, availableWorkspaces] = await Promise.all([
        NEW_sendGetRequest(`${BASE_URL2}members`),
        sendGetRequest(GET_ACCOUNTS_URL),
        dispatch(fetchConnectorSpecs()),
        dispatch(fetchConnectors()),
        dispatch(fetchOnboardingStatuses())
      ])

      if(members.ok)
        dispatch(setWorkspaceMembers(members.text))

      const notFound = str => str.includes('not found')
      let account
      if(Array.isArray(availableWorkspaces)){
        account = availableWorkspaces.find(account => account.id === PlanttAccountID)
        account.role = localStorage.getItem(`role@${PlanttAccountID}`) || account.role
        const additionalData = { currentMember: userProfile, availableWorkspaces }
        //Update mixpanel user profile properties

        dispatch(setFullAccountData(account, additionalData))
      }else if(typeof PlanttAccountID === 'undefined' && notFound(userProfile) && notFound(availableWorkspaces)) {
        //Someone is logged in with firebase, but he has no Account in Plantt.
        //this can happen if he logged in with Google (maybe in more scenrions in the future)
        //So, Create an account for this guy, in Plantt!
      }else{
        console.warn('Account not found:', availableWorkspaces)
        console.warn('PlanttAccountID:', PlanttAccountID)
      }
      //Handle mixPanel identification
      const currentUser = firebase.auth().currentUser
      window.mixpanel.identify(currentUser.uid)
      const defaultAccount = availableWorkspaces.find(workspace => workspace.id === userProfile.default_account_id)
      window.mixpanel.people.set({
        'Email': currentUser.email, //from firebase
        'Name' : currentUser.displayName,
        'plantt_signup_at': new Date(userProfile.created_at).toISOString(),
        // 'plantt_workspace_id': defaultAccount.id,
        'Role' : defaultAccount.role
      })
      const onboardingStatuses = getState().global.onboardingStatuses
      bootIntercom(account, userProfile, onboardingStatuses)
      dispatch(fetchSignals())
      dispatch(fetchAllFields())
      dispatch(fetchSegments())
      dispatch(fetchAccounts())
      dispatch(fetchGistTemplates(account.id))
      dispatch(fetchWatchers())
      dispatch(fetchActions())
    } catch (err) {
      console.error('Error in getAccount Function:', err)
    }
}

const fetchOnboardingStatusesSuccess = statuses => ({ type: FETCH_ONBOARDING_STATUSES_SUCCESS, statuses })
export const fetchOnboardingStatuses = () => async dispatch => {
  try {
    const statuses = await NEW_sendGetRequest(`${BASE_URL}onboarding/statuses`)
    if(!statuses.ok)
      throw new Error(statuses.text)
    else {
      window.mixpanel.people.set(statuses.text)
      dispatch(fetchOnboardingStatusesSuccess(statuses.text))
    }
  } catch(error) {
    console.error('Failed to fetch onboarding statuses:', error)
  }
}

const fetchSignalsSuccess = signals => ({ type: FETCH_SIGNALS_SUCCESS, signals })
export const fetchSignals = () => async dispatch => {
  try {
    const signals = await NEW_sendGetRequest(`${BASE_URL2}signals`)
    if(!signals.ok)
      throw new Error(signals.text)
    else
      dispatch(fetchSignalsSuccess(signals.text))
  } catch(error) {
    console.error('Failed to fetch signals:', error)
  }
}

var fetchSegmentsCount = 0
export const setSegments = segments => ({ type: SET_SEGMENTS, segments })
export const fetchSegments = () => async dispatch => {
  fetchSegmentsCount++
  try {
    const segments = await NEW_sendGetRequest(`${BASE_URL2}segments`)

    if(!segments.ok)
      throw new Error(segments.text)
    
    let ordered = segments.text.filter(({ name }) => name !== 'All Accounts')
    ordered.unshift(segments.text.find(({ name }) => name === 'All Accounts'))
    ordered = ordered.filter(i => i)//TEMP
    dispatch(setSegments(ordered))
  } catch(error) {
    console.error('Failed to fetch segments:', error)
    dispatch(setGlobalLoading({ segments: 'error' }))
    if(fetchSegmentsCount < 3)
      setTimeout(() => dispatch(fetchSegments()), 7000)
    else
      dispatch(setToastMessage('Something went wrong while fetching your groups. Please refresh the page', 'error'))
  }
}

export const fetchConnectorsSuccess = connectors => ({ type: FETCH_CONNECTORS_SUCCESS, connectors })
export const fetchConnectors = () => async dispatch => {
  try {
    const connectors = await NEW_sendGetRequest(`${BASE_URL2}connectors`)
    if(!connectors.ok)
      throw new Error(connectors.text)
      
    dispatch(fetchConnectorsSuccess(connectors.text))
  } catch (error) {
    console.error('Error fetching App list from server', error)
    setToastMessage(`Error fetching App list from server`,'error')
  }
}

const fetchConnectorSpecsSuccess = connectors => ({ type: FETCH_CONNECTOR_SPECS_SUCCESS, connectors })
export const fetchConnectorSpecs = () => async dispatch => {
  try {
    const connectors = await NEW_sendGetRequest(`${BASE_URL2}connectors-spec`)
    if(!connectors.ok)
      throw new Error(connectors.text)

    //TEMP
    connectors.text.forEach(c => c.is_premium = ['intercom', 'zoom', 'mixpanel'].includes(c.id))

    dispatch(fetchConnectorSpecsSuccess(connectors.text)) 
  } catch(error) {
    console.error('Failed to fetch connector specS:', error)
  }
}

const fetchAllFieldsSuccess = fieldLists => ({ type: FETCH_ALL_FIELDS_SUCCESS, fieldLists })
const fetchAllFieldsError = error => ({ type: FETCH_ALL_FIELDS_ERROR, error })
export const fetchAllFields = () => async dispatch => {
  try {
    let fetched = await Promise.all([
      fetchFields('identities'),
      fetchFields('contacts')
    ])
    const [accounts, contacts] = fetched
    dispatch(fetchAllFieldsSuccess({ contacts, accounts }))
  } catch(error) {
    console.error('Failed to fetch account fields:', error)
    dispatch(fetchAllFieldsError(error))
  }
}

export const closeSnackbar = key => ({ type: CLOSE_SNACKBAR, dismissAll: !key, key }) // dismiss all if no key has been defined key,
export const removeSnackbar = key => ({ type: REMOVE_SNACKBAR, key })

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key;
  notification = { ...notification, key: key || new Date().getTime() + Math.random() }

  return { type: ENQUEUE_SNACKBAR, notification }
}

export const setToastMessage = (message, toastType = 'default') => async (dispatch) => {
  dispatch(enqueueSnackbar({
    message,
    options: {
      key: new Date().getTime() + Math.random(),
      variant: toastType,
      action: key => (
        <Close className="pointer" onClick={() => dispatch(closeSnackbar(key))} />
      ),
    }
   }))
}

export const accountsSearch = async search_term => {
  try {
    const accounts = await NEW_sendGetRequest(`${BASE_URL2}identities`, {}, { search_term })
    if(!accounts.ok)
      throw new Error(accounts.text)

    return accounts.text.records
  } catch(error) {
    console.error('Failed to search for accounts:', error)
    return { error }
  }
}

const fetchAccountsSuccess = records => ({ type: FETCH_ACCOUNTS_SUCCESS, records })
export const fetchAccounts = () => async (dispatch, getState) => {
  const getAccounts = async queryParams => {
    const fetched = await NEW_sendGetRequest(`${BASE_URL2}identities`, {}, queryParams)
    if(!fetched.ok)
      throw new Error(fetched.text)
    return fetched.text
  }

  try {
    const myAccounts = await getAccounts({ take: 15, mine: true })
    let records = myAccounts.records || []
   
    if(myAccounts.records.length < 15) {
      const accounts = await getAccounts({ take: 15 - myAccounts.records.length })
      const filtered = accounts.records.filter(account => !records.find(a => a.id === account.id))
      records = records.concat(filtered)
    }
    
    records = records.map(record => ({ identity: record }))
    
    dispatch(fetchAccountsSuccess(records))
  } catch(error) {
    console.error('Failed to fetch accounts on app start:', error)
  }
}

export const updateMember = (member, updates) => async dispatch => {
  const newMember = { ...member, ...updates }

  //TEMP - this should be handled by the backend
  newMember.starred_identities = member.starred_identities.map(({ identity_id }) => identity_id)

  try {
    const response = await NEW_sendPutRequest(`${BASE_URL2}member/${member.id}`, {}, JSON.stringify(newMember))
    if(!response.ok)
      throw new Error(response.text)
      
    dispatch({ type: UPDATE_MEMBER, member: { ...response.text, starred_identities: member.starred_identities } })
  } catch(error) {
    console.error('Failed to update member:', error)
  }
}

const fetchWatchersSuccess = watchers => ({ type: FETCH_WATCHERS_SUCCESS, watchers })

export const fetchWatchers = () => async dispatch => {
  try {
    const watchers = await NEW_sendGetRequest(`${BASE_URL2}watchers`)
    if(watchers.ok)
      dispatch(fetchWatchersSuccess(watchers.text))
  } catch(error) {
    console.error('Failed to fetch watchers: ', error)
  }
}

const setAppDimensions = () => {
  const width = window.innerWidth
  const height = window.innerHeight
  const dimensions = { compactDisplay: width <= 1024, isPortrait: height > width, width, height }
  return ({ type: SET_APP_DIMENSIONS, dimensions })
}

export const setResizeListener = () => {
  let resizeTimeout
  store.dispatch(setAppDimensions())
  window.addEventListener('resize', () => {
    clearTimeout(resizeTimeout)
    resizeTimeout = setTimeout(() => store.dispatch(setAppDimensions()), 250);
  })
}

const fetchActionsSuccess = actions => ({ type: FETCH_ACTIONS_SUCCESS, actions })
export const fetchActions = () => async (dispatch, getState) => {
  if(getState().global.allActions.length)
    return
  try {
    const actions = await NEW_sendGetRequest(`${BASE_URL2}actions`)
    if(!actions.ok)
      throw new Error(actions.text)

    dispatch(fetchActionsSuccess(actions.text))
  } catch(error) {
    console.error(error)
  }
}