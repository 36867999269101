import { makeStyles } from "@material-ui/core"
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from "@material-ui/icons"
import { useLayoutEffect, useRef, useState } from "react"

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    borderRadius: 3,
    border: '1px solid #ddd',
    padding: '2px 4px',
    width: 32,
    fontSize: 12,
    textAlign: 'center',
    '&:focus': {
      outline: 'none',
      borderColor: '#155788'
    }
  },
  arrow: {
    fontSize: 18,
    color: '#aaa',
    '&:hover:not([disabled])': {
      transform: 'scale(1.1)',
      color: '#155788',
      cursor: 'pointer',
    },
    '&[disabled]': {
      cursor: 'auto',
      color: '#ddd'
    }
  }
})

function Paginator({ page, setPage, total, step = 50 }){
  const classes = useStyles()
  const [value, setValue] = useState(page)

  const shift = dir => {
    setPage(page + dir)
    setValue(value + dir)
  }

  useLayoutEffect(() => {
    if(value !== page)
      setValue(page)
  }, [page])

  const ref = useRef()
  const onKeyDown = e => {
    if(e.key === 'Enter')
      ref.current?.blur()
  }

  return (
    <div className={classes.container}>
      <KeyboardArrowLeftRounded disabled={value === 1} className={classes.arrow} onClick={value !== 1 ? () => shift(-1) : null} />
      <input
        {...{ value, onKeyDown }}
        onChange={e => setValue(e.target.value)}
        onBlur={e => setPage(parseInt(e.target.value))}
        className={classes.input}
        ref={ref}
      />
      <KeyboardArrowRightRounded disabled={total < value * step} className={classes.arrow} onClick={total > value * step ? () => shift(1) : null} />
    </div>
  )
}

export default Paginator