import {
  FETCH_RELATIONSHIP_ACTIVITY_SUCCESS,
  FETCH_RELATIONSHIP_ACTIVITY_ERROR,
  FETCH_RELATIONSHIP_ACTIVITY_INITIATED
} from '../constants'

import { NEW_sendGetRequest } from '../../../apis/api-utils'
import { BASE_URL2 } from '../../../apis/constant'

const prepareActivity = activity => {
  const records = []
  activity.records.forEach(item => {
    if(item.type !== 'signal_event')
      return records.push(item)

    const interaction =records.findIndex(i => i.payload.id === item.payload.source.interaction)
    if(interaction >= 0)
      records[interaction].signal_events = [
        ...(records[interaction].signal_events || []),
        item
      ]
  })

  return records
}

const fetchRelationshipActivitySuccess = fetched => ({ type: FETCH_RELATIONSHIP_ACTIVITY_SUCCESS, ...fetched })
const fetchRelationshipActivityError = error => ({ type: FETCH_RELATIONSHIP_ACTIVITY_ERROR, error })
const fetchRelationshipActivityInitiated = (timestamp, page) => ({ type: FETCH_RELATIONSHIP_ACTIVITY_INITIATED, timestamp, page })

const fetchRelationshipActivity = (id, page) => async dispatch => {
  try {
    const timestamp = new Date()
    
    dispatch(fetchRelationshipActivityInitiated(timestamp, page))
    const activity = await NEW_sendGetRequest(`${BASE_URL2}identity/${id}/relationship/activity`, {}, page !== 'initial' ? { page } : {})

    if(!activity.ok)
      throw new Error(activity.text)

    dispatch(fetchRelationshipActivitySuccess({ 
      relationshipActivity: { ...activity.text.meta, records: prepareActivity(activity.text) },
      timestamp,
      page
    }))
  } catch(error) {
    console.error('Failed to fetch relationship activity:', error)
    dispatch(fetchRelationshipActivityError(error))
  }
}

export default fetchRelationshipActivity