import { Editor, Transforms } from "cs-slate"
import { ReactEditor } from "cs-slate-react"
import { useRef } from "react"
import { useDispatch } from "react-redux"
import { store } from "../.."
import { handleHistoryShortcuts } from "../../components/TextEditor/withHistory"
import { applyUpdates, getNoteInfo, setFocusedEditor } from "./actions"
import { useSelector } from "react-redux"

export const gistHistory = { records: [], position: -1, stopChange: false, timeout: null }

export const blocksRef = { current: {} }

export const recordHistory = note => {
  const { focusedEditor } = store.getState().notes

  if(!gistHistory.stopChange){
    if(gistHistory.timeout)
      clearTimeout(gistHistory.timeout)
    if(gistHistory.position < gistHistory.records.length - 1)
      gistHistory.records = gistHistory.records.slice(0, gistHistory.position + 1)

    gistHistory.timeout = setTimeout(() => {
      gistHistory.records.push({ note, focused: focusedEditor, selection: blocksRef.current[focusedEditor]?.editor.selection })
      gistHistory.position++
    }, 300)
  }
}

const useGistHistory = () => {
  const dispatch = useDispatch()
  const doAction = dir => {
    const currentPosition = gistHistory.position
    Object.assign(gistHistory, { position: gistHistory.position + dir, stopChange: true })
    let { position, records } = gistHistory
    const current = { ...records[currentPosition] }
    current.editor = blocksRef.current[current.focused]?.editor
    const next = { ...records[position] }
    next.editor = blocksRef.current[next.focused]?.editor

    if(current.editor)
      ReactEditor.deselect(current.editor)

    if(next.editor)
      setTimeout(() => {
        const { notePath } = getNoteInfo()
        if(current.focused !== next.focused)
        dispatch(setFocusedEditor(next.focused))
        applyUpdates(`${notePath}`, 'set', next.note)
        setTimeout(() => {
          const editor = blocksRef.current[next.focused]?.editor
          Transforms.select(editor, Editor.start(editor, []))
          Transforms.setSelection(editor, next.selection)
          ReactEditor.focus(editor)
          setTimeout(() => gistHistory.stopChange = false)
        })
      })
  }


  const history = useRef(gistHistory)
  const gistKeyDown = (e, blocks) => {
    handleHistoryShortcuts(e, history, doAction)
    //handleSelectionChange(e, selection, blocksGroups, blocks)

     
  } 

  return { gistKeyDown }
}

export default useGistHistory