import { COMMIT_FIREBASE_SET } from "../GistEditor/constants"
import {
  FETCH_TASK_RELATED_ACCOUNTS_SUCCESS,
  SET_MEMBER_TASKS_FROM_SNAPSHOT
} from "./constants"

export const initialState = () => ({
 memberTasks: [],
 loading: { memberTasks: true },
 relatedAccounts: {}
})

function tasksReducer(state = initialState(), action) {
  switch(action.type){
    case SET_MEMBER_TASKS_FROM_SNAPSHOT: {
      const newState =  { ...state, memberTasks: action.tasks }
      if(state.loading.memberTasks)
        newState.loading = { ...newState.loading, memberTasks: false }
      return newState
    }
    case FETCH_TASK_RELATED_ACCOUNTS_SUCCESS: {
      return { ...state, relatedAccounts: Object.assign(Object.fromEntries(action.accounts.map(account => [account.id, account])), state.relatedAccounts) }
    }
    // case COMMIT_FIREBASE_SET: {
    //   const { obj, key } = getByPath()
    //   if(typeof key === 'undefined')
    //     return
        
    //   if(action.value !== null)
    //     obj[key] = action.value
    //   else
    //     delete obj[key]
    //   return
    // }
    default:
      return state
    }
}

export default tasksReducer