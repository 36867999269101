import { CircularProgress } from "@material-ui/core"
import { Fragment, useLayoutEffect, useRef, useState } from "react"
import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { updateFields } from "../../Accounts/actions"
import { computeFieldValue } from "../../../helpers"
import { useDrag } from "react-dnd"
import { PLANTT_FIELD } from "../../GistEditor/constants"
import Trackables from "../partials/Trackables"
import Contacts from "../partials/Contacts"
import RelationshipActivity from "../partials/RelationshipActivity"
import OverviewBlock from "../partials/OverviewBlock"

function Plantt(){
  const fieldList = useSelector(state => state.global.fieldLists.accounts)
  const account = useSelector(state => state.accounts.currentAccount)

  const fields = useMemo(() => {
    if(!account || !fieldList)
      return null

    const initialValues = account?.fields || {}
    return Object.keys(initialValues).filter(key => key[0] !== '_').map(key => {
      const field = fieldList.find(f => f.key === key) || { key: key, label: key }
      return { ...field, initialValue: initialValues[key] }
    })
  }, [account, fieldList])

  return (
    <div id='plantt-app-dock'>
      <OverviewBlock style={{ marginTop: -40 }} title='Metadata' tooltipProps={{ title: 'Metadata', description: 'View and modify account metadata' }}>
        <div id='account-metadata'>
          {fields?.map(field => <RenderField key={field.key} {...{ field, account }} />)}
        </div>
      </OverviewBlock>
      <div style={{ padding: 2 }}></div>
      {/* This is a trick to get padding when scrolling... */}
    </div>
  )
}

function RenderField({ field, account }){
  const [{ isDragging }, drag, dragPreview] = useDrag({
    item: { type: PLANTT_FIELD, id: field.key },
    collect: monitor => ({ isDragging: !!monitor.isDragging() })
  })

  const dispatch = useDispatch()
  const { label, initialValue } = field
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(initialValue || '')
  const [isFocused, setIsFocused] = useState(false)

  useLayoutEffect(() => {
    if(value !== initialValue)
      setValue(initialValue)
  }, [initialValue])

  const escaped = useRef(false)
  const onBlur = async () => {
    if(escaped.current)
      return escaped.current = false

    setIsFocused(false)
    if(value === initialValue)
      return

    setLoading(true)
    await dispatch(updateFields('account', account, { [field.key]: value }))
    setLoading(false)
  }

  const ref = useRef()
  const onKeyDown = e => {
    if(e.key === 'Escape'){
      escaped.current = true
      setValue(initialValue)
    }
    if(['Enter', 'Escape'].includes(e.key))
      ref.current?.blur()
  }

  const computedValue = !isFocused ? computeFieldValue(field.type, value) : value

  return (
    <div className='plantt-input'>
      <label ref={dragPreview}>
        <span ref={drag}>{label}</span>
        <input
          onKeyDown={onKeyDown}
          onFocus={() => setIsFocused(true)}
          value={computedValue}
          onChange={e => setValue(e.target.value)}
          onBlur={onBlur}
          disabled={loading}
          type={isFocused && ['currency', 'number'].includes(field.type) ? 'number' : 'input'}
          ref={ref}
        />
      </label>
      {loading &&
      <div>
        <CircularProgress size={12} color='inherit' />
      </div>}
    </div>
  )
}

export default Plantt