import { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Contacts from "./Contacts"
import Trackables from "./Trackables"
import RelationshipActivity from "./RelationshipActivity"
import ActionButton from "./ActionButton"
import { RenderBlock, RenderError } from "../AppsDock/DockContent"
import { fetchDock } from "../../GistEditor/actions"
import WidgetWrapper from "../../GistEditor/Blocks/Widget/WidgetWrapper"

import HoverableIcon from "../../../components/HoverableIcon"
import { Popper, ClickAwayListener } from "@material-ui/core"
import Hoverable from "../../../components/Hoverable"
import { dockEmpty } from "../helpers"

function Overview({ availableApps, currentApp }){
  const dispatch = useDispatch()
  const dockData = useSelector(state => state.notes.dockData)

  const { app, spec } = currentApp || {}
  const appData = dockData[spec.id]

        return (
          <div className='overview-section'>
            {appData && !appData.loading && (!appData.error && !dockEmpty(appData)
              ? appData.blocks.map((block, index) => (
                  <div className='notebook-note-block' key={spec.id + index} style={{ padding: 0 }}>
                    <RenderBlock key={index} block={block} isTop blockIndex={index} specRef={spec.id} />
                  </div>
                ))
              : <RenderError
                  appName={spec.name}
                  doFetch={() => dispatch(fetchDock({ appId: app.id, specRef: spec.id }))}
                  {...appData}
                />
            )}
            {appData?.loading && <WidgetWrapper {...spec} loading={true} />}
            {spec.id === 'plantt' &&
            <>
              <Trackables />
              <Contacts />
              <RelationshipActivity />
            </>}
          </div>
        )
}

export function OverviewSection({ id, icon_url, name, children, actions }){
  const largeIcon = ['salesforce', 'zendesk'].includes(id)
  const size = largeIcon ? 28 : 20 // Necessary because salesforce icon proportions are different from most icons...

  const [show, setShow] = useState(true)
  return (
    <>
      <div className='overview-section-title' id={`overview-${id}`}>
        <div>
          <img src={icon_url} style={{ width: size, height: size, transform: largeIcon ? 'translateX(-4px)' : undefined }} />
          <div>{name}</div>
        </div>
        <div>
          {actions?.length > 0 && <RenderActions {...{ actions, id }} />}
          <i className={`far ${show ? 'fa-eye-slash' : 'fa-eye'}`}  onClick={() => setShow(!show)} />
        </div>
      </div>
      {show &&
      <div className='overview-section-content'>
        {children}
      </div>}
    </>
  )
}

function RenderActions({ actions, id }){
  const [open, setOpen] = useState(false)
  const ref = useRef()

  return (
    <>
      <HoverableIcon icon='bolt' ref={ref} onClick={() => setOpen(true)} />
      <Popper open={open} anchorEl={ref.current} placement='bottom-start'>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div className='app-actions-menu card'>
            {actions.map(action => (
              <ActionButton key={action.id} specRef={id} onModalOpen={() => setOpen(false)} {...{ RenderButton, ...action }} />
            ))}
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

const RenderButton = (props) => <Hoverable {...props} />

export default Overview