import { getNoteInfo, isNew } from "../actions"
import { createPollBlock, createMetricField, checkListField } from "../Blocks/Itemized/helpers"
import { idGen } from "../../../helpers"
import { createTask } from "../../Tasks/actions"
import { v4 as uuidv4 } from 'uuid'

const mockBarPayload = () => ({ title: { text: 'Traffic Over the Last Two Quarters (x1000)', ...isNew() }, data: [['1.2.2020', 22], ['2.2.2020', 34], ['3.2.2020', 28], ['4.2.2020', 24], ['5.2.2020', 32], ['7.2.2020', 55], ['8.2.2020', 70]], labels_are_dates: true })
const mockPiePayload = () => ({ title: { text: 'Usage by Time of Day', ...isNew() }, data: [['Morning', 22], ['Noon', 34], ['Afteroon', 70]] })

const createTaskBlock = () => {
  const { blocks, noteId, accountId } = getNoteInfo()
  let blockId
  while(!blockId || (blocks && blocks[blockId]))
    blockId = idGen()

  const taskId = createTask({ accountId, ref: { note: noteId, block: blockId } })
  return { type: 'task', payload: { id: taskId }, options: { newId: blockId } }
}

const createTableBlock = () => {
  const schema = new Array(2).fill(true).map((_, index) => ({ key: uuidv4(), name: `Column ${index + 1}`, type: 'text' }))
  return {
    type: 'editable-table',
    payload: {
      schema,
      objects: new Array(3).fill(true).map(() => Object.fromEntries(schema.map(({ key }) => [key, ''])))
    }
  }
}

const headings = ['Heading 1', 'Heading 2', 'Heading 3'].map(type => ({ type, icon_name: 'heading', searchTerms: [type.replace('Heading ', 'h')] }))
export const basicActions = headings.concat([{ type: 'Text', icon_name: 'text' }, { type: 'Check List', icon_name: 'list-ul' }])
  .map(action => {
    action.basic = true
    const heading = action.type.includes('Heading') ? action.type.replace('Heading ', '') : null
    action.getBlock = action.type !== 'Check List'
      ? () => ({ type: 'basic', payload: { text: '', heading } })
      : () => ({ type: 'to-do-list', payload: { fields: [checkListField(isNew())] }, options: { autoFocus: false } })
    return action
  })

export const planttActions = [
    { name: 'Create a Task', icon_name: 'bell-on', getBlock: createTaskBlock },
    // { name: 'Create a Table', icon_name: 'table', getBlock: createTableBlock },
    // { type: 'Create a Poll', icon_name: 'poll-people', getBlock: () => createPollBlock() },
    // {
    //   type: 'Make a Button',
    //   icon_name: 'mouse',
    //   getBlock: () => ({ type: 'button', payload: { name: '', action_id: '', config: {} } })
    // },
    {
      type: 'Upload a File',
      icon_name: 'upload',
      getBlock: () => ({ type: 'file_upload', options: { autoFocus: false } })
    },
    // {
    //   type: 'Add Metrics',
    //   icon_name: 'tachometer-alt',
    //   getBlock: () => ({ type: 'metrics', payload: { fields: [createMetricField()] } })
    // },
    // {
    //   type: 'Create a Bar Chart',
    //   icon_name: 'chart-bar',
    //   getBlock: () => ({ type: 'bar-chart', payload: { title: { text: '', ...isNew() }, data: [] }, payload: mockBarPayload() })
    // },
    // {
    //   type: 'Create a Pie Chart',
    //   icon_name: 'chart-pie',
    //   getBlock: () => ({ type: 'pie-chart', payload: { title: { text: '', ...isNew() }, data: [] }, payload: mockPiePayload() })
    // },
]