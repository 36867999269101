import { Fragment, useContext, useRef, useState } from "react"
import { useDispatch } from "react-redux"

import Hoverable from "../../Hoverable"
import TableContext from "./TableContext"
import { upperFirst } from "../../../helpers"
import { setHoveredRow } from './reducer'

import { ClickAwayListener, Popper } from "@material-ui/core"
import { FilterListRounded } from "@material-ui/icons"


function RenderHeader({ column, style, filler = false }){
  const dispatch = useDispatch()
  const { headerActions, expansionActions: { onAddColumn } } = useContext(TableContext)
  const { onClick, onSort, onEditField } = headerActions || {}

  const ref = useRef()
  const [open, setOpen] = useState(false)
  const onClickAway = e => {
    const iconElem = e.target.classList.contains('filter-icon') ? e.target : e.target.closest('.filter-icon')
    if(!iconElem || iconElem.getAttribute('data-column-id') !== column.id)
      setOpen(false)
  }

  const sort = options => {
    onSort(column.id, options)
    setOpen(false)
  }

  const edit = () => {
    onEditField(column)
    setOpen(false)
  }

  return (
    <div
      className={`new-table-column-header ${column?.noPadding ? 'no-padding' : ''} ${column && onClick ? 'is-clickable' : ''}`} 
      style={{ width: column?.width, ...style }}
      onMouseEnter={() => dispatch(setHoveredRow(null))}
      onClick={e => {
        if(!column) return
        if(!e.target.classList.contains('new-table-column-header'))
          e.target = e.target.closest('.new-table-column-header')
        onClick(e, column)
      }}
    >
      <div>
        {column?.icon && <i className={`column-icon far fa-${column.icon}`} />}
        {column && upperFirst(column.name || '')}
      </div>
      {filler && onAddColumn &&
      <i className='fal fa-plus add-column' onClick={onAddColumn} />}
      {column && headerActions && (!column.noFilter || !column.noEdit) && (onSort || onEditField) &&
      <>
        <FilterListRounded
          className={`filter-icon ${!column.id ? 'no-margin' : ''} ${open ? 'no-hide' : ''}`}
          onClick={() => setOpen(true)}
          ref={ref}
          data-column-id={column.id}
        />
        <Popper
          anchorEl={ref.current}
          open={open}
          className='new-table-column-filter card'
          placement='bottom-start'
        >
          <ClickAwayListener onClickAway={onClickAway}>
            <div>
              {onSort && !column.noFilter &&
              <Fragment>
                <Hoverable id='sort-ascending' onClick={() => sort({ dir: 'asc' })}>
                  Sort <div>A <i className='fal fa-long-arrow-right' /> Z</div>
                </Hoverable>
                <Hoverable id='sort-descending' onClick={() => sort({ dir: 'desc' })}>
                  Sort <div>Z <i className='fal fa-long-arrow-right' /> A</div>
                </Hoverable>
              </Fragment>}
              {onEditField && !column.noEdit &&
              <Hoverable id='edit-field' onClick={edit}>
                Edit Field
              </Hoverable>}
            </div>
          </ClickAwayListener>
        </Popper>
      </>}
    </div>
  )
}

export default RenderHeader