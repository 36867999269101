import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import Authenticate from './Authenticate'
import AuthenticationError from './AuthenticationError'
import Authenticate2 from './Authenticate2'
import Activate from './Activate'

import isEmpty from 'lodash/isEmpty'
import { useLayoutConfig } from '../../../components/PlanttLayout/LayoutConfig'

const connectorSpecSelector = createSelector(
    state => {
        const { inAddingConnectorSpec } = state.connectors
        const { connectorSpecs } = state.global
        return { inAddingConnectorSpec, connectorSpecs }
    },
    ({ inAddingConnectorSpec, connectorSpecs }) => {
        const index = localStorage.getItem('inAddingConnectorIndex')
        const connectorSpec = isEmpty(inAddingConnectorSpec) !== true
            ? inAddingConnectorSpec
            : connectorSpecs[index]

        return { connectorSpec, connectorSpecs }
    }
)

function AddApp() {
    useLayoutConfig({ title: `Apps / Add App`})

    const { connectorSpec, connectorSpecs } = useSelector(connectorSpecSelector)

    return (
        <Switch>
            <Route exact path='/apps/add/authenticate'>
                {isEmpty(connectorSpec) != true && <Authenticate connectorSpec={connectorSpec} />}
            </Route>
            <Route exact path='/apps/add/authenticationError'>
                 {isEmpty(connectorSpec) != true  && <AuthenticationError connectorSpec={connectorSpec} />}
            </Route>
            <Route exact path='/apps/add/authenticate2/'>
                {connectorSpec &&  <Authenticate2 connectorSpecs={connectorSpecs} /> }
            </Route>
            <Route exact path='/apps/add/activate/:connectorId' component={Activate} />
        </Switch>
    )
}
export default AddApp