import { ReactEditor } from "cs-slate-react"
import { Transforms } from "cs-slate"
import { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { updateNoteTag } from "../actions"
import InteractionMenu from "."
import { store } from "../../.."

function TagMenu({ id, selection, isTitle = false, setInteractionMenu, interaction, ...props }){
  const tags = useSelector(state => state.notes.existingTags || [])
  const [search, setSearch] = useState('')

  const items = useMemo(() => {
    const { metadata } = store.getState().notes.currentNote || {}
    const filtered = tags.filter(tag => {
      return tag.toLowerCase().includes(search.toLowerCase()) && !metadata?.tags?.includes(tag)
    })
    if(search.length && !filtered.includes(search))
      filtered.unshift(search)
    return filtered
  }, [tags, search])

  const resetInteractionMenu = () => setInteractionMenu({ anchor: null })

  const interact = (event, hovered) => {
    event.preventDefault()
    const tag = items[hovered]
    updateNoteTag(tag)

    const { editor } = props
    if(editor){
      ReactEditor.focus(editor)
      setTimeout(() => {
        Transforms.delete(editor, { at: selection.anchor, reverse: true })
        resetInteractionMenu()
      })
    }else{
      resetInteractionMenu()
    }
  }

  return (
    <InteractionMenu
      searchProps={{ placeholder: 'Type a tag or choose from the list', icon: 'tag' }}
      resetHoverIndex={search.length ? 0 : null}
      {...{ search, setSearch, items, resetInteractionMenu, interact, ...props }}
      RenderItem={TagMenuItem}
      open={interaction === 'tags'}
    />
  )
}


function TagMenuItem({ item, itemRef, ...props }) {
  return (
    <div
      className='interaction-menu-item'
      ref={itemRef}
      {...props}
    >
      <i className='fad fa-hashtag' />
      {item}
    </div>
  )
}

export default TagMenu