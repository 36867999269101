export const FETCH_MOMENTS_SUCCESS = 'plantt/Moments/FETCH_MOMENTS_SUCCESS'
export const INITIATE_FETCH_MOMENTS = 'plantt/Moments/INITIATE_FETCH_MOMENTS'

export const PRE_MODIFY_MOMENT_METADATA = 'plantt/Moments/PRE_MODIFY_MOMENT_METADATA'
export const START_REMOVE_MOMENT = 'plantt/Moments/START_REMOVE_MOMENT'
export const END_REMOVE_MOMENT = 'plantt/Moments/END_REMOVE_MOMENT'
export const MODIFY_MOMENT_METADATA_ERROR = 'plantt/Moments/MODIFY_MOMENT_METADATA_ERROR'

export const PRE_APPLY_ACTION = 'plantt/Moments/PRE_APPLY_ACTION'
export const APPLY_ACTION_ERROR = 'plantt/Moments/APPLY_ACTION_ERROR'

export const MODIFY_MOMENT_NOTES_SUCCESS = 'plantt/Moments/MODIFY_MOMENT_NOTES_SUCCESS'
export const SET_MODIFYING_NOTE = 'plantt/Moments/SET_MODIFYING_NOTE'

export const FETCH_NEW_MOMENTS_SUCCESS = 'plantt/Moments/FETCH_NEW_MOMENTS_SUCCESS'
export const APPLY_PREPEND_QUEUE = 'plantt/Moments/APPLY_PREPEND_QUEUE'

export const UPDATE_NOTE_STATE = 'plantt/Moments/UPDATE_NOTE_STATE'
export const CLEAR_NOTE_STATE = 'plantt/Moments/CLEAR_NOTE_STATE'

export const FETCH_MEMBER_STATUS_BAR_SUCCESS = 'plantt/Moments/FETCH_MEMBER_STATUS_BAR_SUCCESS'
