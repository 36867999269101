import { forwardRef, Fragment, useEffect, useMemo, useRef, useState } from "react"
import FieldError from "./FieldError"

function DebouncedInput({ textarea = false, setValue, initialValue, error, className, debounce = 500, ...props }, ref){
  const internalRef = useRef()
  if(!ref)
    ref = internalRef

  const [state, setState] = useState(initialValue || '')
  const Elem = useMemo(() => textarea ? 'textarea' : 'input', [textarea])

  useEffect(() => {
    if(state !== initialValue)
      setState(initialValue || '')
  }, [initialValue])

  const timeout = useRef()
  useEffect(() => {
    if(debounce !== 'blur' && state !== initialValue){
      if(timeout.current)
        clearTimeout(timeout.current)

      timeout.current = setTimeout(() => setValue(state), debounce)
    }
  }, [state])

  const hasErrors = Boolean(error)
  const classes = useMemo(() => {
    const classes = []
    if(className)
      classes.push(className)
    if(hasErrors)
      classes.push('has-errors')
    return classes
  })

  return (
    <Fragment>
      <Elem
        value={state}
        onChange={e => setState(e.target.value)}
        onBlur={debounce === 'blur' ? () => setValue(state) : undefined}
        ref={ref}
        className={classes.length ? classes.join(' ') : undefined}
        autoComplete='off'
        {...props}
      />
      <FieldError anchorEl={ref.current} containerEl={ref.current?.parentNode} hasErrors={hasErrors}>{error}</FieldError>
    </Fragment>
  )
}

export default forwardRef(DebouncedInput)