import { useMemo } from "react"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { store } from "../.."
import PlanttIcon from '../../images/plantt.svg'

export const getContactName = contact => !contact.fields?.first_name ? (contact.fields?.name || '') : `${contact.fields.first_name} ${contact.fields.last_name}`

export const getAvailableApps = () => {
  const { global } = store.getState()
  return prepareAvailableApps(global)
}

const prepareAvailableApps = ({ connectors, connectorSpecs }) => {
  const available = connectorSpecs
    .filter(spec => spec.dock_enabled)
    .map(spec => {
      const app = connectors.find(a => a.spec_ref === spec.id)
      return app ? { app, spec } : null
    })
    .filter(app => app !== null)
  available.unshift({ spec: { id: 'plantt', icon_url: PlanttIcon, name: 'Plantt' } })
  return available
}

export const useAvailableApps = () => {
  const connectors = useSelector(state => state.global.connectors)
  const connectorSpecs = useSelector(state => state.global.connectorSpecs)

  return useMemo(() => prepareAvailableApps({ connectors, connectorSpecs }), [connectors, connectorSpecs])
}

export const dockEmpty = appData => {
  if(!appData.blocks?.length)
    return true

  return appData.blocks.length === 1 && appData.blocks[0].type === 'toggle' && !appData.blocks[0].blocks.length
}