import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { sendAccountInvite } from '../actions';
import FieldError from '../../../components/FormControls/FieldError';
import { OrangeButton } from '../../../components/Button';
import { trackMixpanelEvents } from '../../../helpers/mixpanel'
const AddTeamMemberDialogStyle = makeStyles(() => ({
  dialogContent: {
    paddingBottom: 32,
    paddingLeft: 55,
    paddingRight: 55
  },
  addButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  btnPrimary: {
    borderRadius: 3,
    border: 'solid 1px #fd6c4b',
    backgroundColor: '#fd6c4b',
    fontFamily: 'Helvetica',
    fontSize: 14,
    color: '#ffffff',
    padding: 10,
    minHeight: 41,
    minWidth: 130,
    maxHeight: 41,
    '&:disabled': {
      color: '#fd6c4b',
    }
  },
  fieldsContainer: {
    marginBottom: 36
  },
  subHeader: {
    paddingTop: 18,
    fontSize: 18,
    fontWeight: 'normal',
    textAlign: 'center',
  }
}));

function CustomTextField({ state, changeState, hasErrors, label, id, errorText, ...props }){
  const ref = useRef()
  return (
    <label ref={ref}>
      {label}
      <input value={state[id]} onChange={changeState(id)} {...props} />
      {errorText && <FieldError hasErrors={hasErrors} containerEl={ref.current} anchorEl={ref.current}>{errorText}</FieldError>}
    </label>
  )
}

const initial = { email: '', name: '' }
function AddTeamMemberDialog({ open, onClose }) {
  const dispatch = useDispatch();
  const addTeamMemberDialogStyle = AddTeamMemberDialogStyle();

  const [state, setState] = useState(initial)
  const [errors, setErrors] = useState({ email: false, noEmail: false })
  const [loading, setLoading] = useState(false)

  const onSubmit = async e => {
    e.preventDefault()
    if(!state.email.length)
      setErrors({ ...errors, noEmail: true })
      
    setLoading(true)
    
    const response = await dispatch(sendAccountInvite(state.email, state.name))
    if(response?.accountExists)
      setErrors({ ...errors, email: true })
    setLoading(false)
    
    if(response?.success) {
      trackMixpanelEvents('member_invited_to_workspace')
      setState(initial)
    }
  }

  const changeState = id => e => {
    setState({ ...state, [id]: e.target.value })
    const newErrors = { ...errors, [id]: false }
    if(id === 'email')
      newErrors.noEmail = false
    setErrors(newErrors)
  }

  return (
    <Dialog
      fullWidth maxWidth="sm"
      open={open}
      aria-labelledby="Add new team member"
      onClose={onClose}
    >
      <DialogTitle id="form-dialog-title">
        <div className="dialog-header">
          <div className="subHeader">Invite a member to the team</div>
        </div>
      </DialogTitle>
      <DialogContent className={addTeamMemberDialogStyle.dialogContent}>
        <form
          id='add-team-member'
          onSubmit={onSubmit}
        >
          <CustomTextField
            id='email'
            type='email'
            label='Email Address'
            errorText={errors.noEmail ? 'Please enter an email address' : 'Already registered with Plantt'}
            hasErrors={errors.email || errors.noEmail}
            {...{ state, changeState }}
          />
          <CustomTextField
            id='name'
            label='Name'
            {...{ state, changeState }}
          />
          <OrangeButton loading={loading}>Add</OrangeButton>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default AddTeamMemberDialog;
