import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import OnboardingItem from "../partials/OnboardingItem"
import { inviteMembers, setInviteSuccess, toggleEmailListItem } from "../actions"
import EmailBox from '../../../components/FormControls/EmailBox'

import { PersonAdd } from "@material-ui/icons"
import { CircularProgress } from "@material-ui/core"
import { BlueButton } from "../../../components/Button"
import { trackMixpanelEvents } from '../../../helpers/mixpanel'


function InviteTeam(){
  const dispatch = useDispatch()
  const [error, setError] = useState(false)
  
  const emails = useSelector(state => state.onboarding.teamEmails)
  const open = useSelector(state => state.onboarding.openItem)
  const inviteSuccess = useSelector(state => state.onboarding.inviteSuccess)

  const [loading, setLoading] = useState(false)
  const invite = async () => {
    if(!emails.length)
      return
    
    setLoading(true)
    await dispatch(inviteMembers())
    trackMixpanelEvents("onboarding_sent_invite")
    setLoading(false)
  }

  const toggle = email => dispatch(toggleEmailListItem(email))

  return (
    <OnboardingItem
      id='invite_members'
      Icon={PersonAdd}
      title='Invite Team Members'
      minutes={1}
    >
      <div className='content-title'>Invite your team members</div>
      <ContentText />
      {inviteSuccess === null
        ? <div className='connect-text'>Enter email addresses</div>
        : (inviteSuccess === true
            ? <div className='success-text'>Team members invited successfully!</div>
            : <div className='error-text'>Something went wrong...</div>
          )
      }
      <div className='handle-invites'>
        <EmailBox
          displayed={open}
          onRemove={toggle}
          onSave={toggle}
          onFocus={() => dispatch(setInviteSuccess(null))}
          {...{ error, setError, emails }}
        />
        <BlueButton className='onboarding-button' onClick={invite}>
          {!loading ? 'Invite' : <CircularProgress thickness={4} size={14} color='inherit' />}
        </BlueButton>
      </div>
    </OnboardingItem>
  )
}

export function ContentText(){
  return (
    <div className='content-text'>
      Keep your team synchronized with their data and yours,
      let them setup their own workspace, and take advantage of all that Plantt has got to offer.
    </div>
  )
}

export default InviteTeam