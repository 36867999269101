import React from 'react';
import './style.scss';

const LoadingIndicator = props => (
  <div className={`loading-indicator ${props.standalone ? 'standalone' : ''}`} style={props.style || {}}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default LoadingIndicator;
