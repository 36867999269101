import { Route, Switch  } from 'react-router-dom'
import GeneralSettings from './GeneralSettings'
import Reauthorize from './Reaouthorize'

import { useLayoutConfig } from '../../../components/PlanttLayout/LayoutConfig'
import LoadingIndicator from '../../../components/LoadingIndicator'
import { useCurrentConnector } from '../helpers'

function ViewApp () {
  const { currentConnector, connectorSpec } = useCurrentConnector()

  useLayoutConfig({ title: `Apps${currentConnector ? ` / ${currentConnector.name}` : connectorSpec ? ` / ${connectorSpec.name}` : ''}` })

  return (
      <div className="connector-page">
        <div className="settings-tabs">
          {connectorSpec
            ? <Switch>
                <Route exact path={"/apps/view/:connectorId/:section?"} component={GeneralSettings}/>
                <Route exact path={"/apps/view/:connectorId/reauthorize"} component={Reauthorize} />
              </Switch>
            : <div className='loading-container'> <LoadingIndicator /></div>
          }
        </div>
      </div>
  )
}
export default ViewApp