import serialize from "../../../../components/TextEditor/serialize"
import { setBlock, isNew } from "../../actions"
import { getNestedBlockInfo, mergeNodesOnRemove } from "../../helpers"
import { idGen } from "../../../../helpers"
import { store } from "../../../.."

export const isItemizedBlock = block => ['to-do-list', 'poll'].includes(block.type)

export const newField = (settings = {}) => ({ label: '', is_done: false, id: idGen(), ...settings })

export const addFieldAfter = (path, label) => {
  let { blockId, block, fields, fieldIndex } = getNestedBlockInfo(path)
  if(isItemizedBlock(block) && fieldIndex < fields.length - 1){
    fields = [ ...fields ]
    fields.splice(fieldIndex + 1, 0, newField({ label, ...isNew() }))
    setBlock(blockId, { payload: { ...block.payload, fields } })
  }
}

export const removeField = ({ editor, id, nextBlockId, nextEditor, direction }) => {
  let { blockId, fieldIndex: currentField, fields, block } = getNestedBlockInfo(id)
  let { childId: nextChild, fieldIndex: nextField } = getNestedBlockInfo(nextBlockId)

  if(!isItemizedBlock(block))
    return

  const lastIndex = fields.length - 1
  if(!nextChild || currentField === lastIndex || nextField === lastIndex)
    return true

  fields = [ ...fields ]
  const removedField = direction > 0 ? nextField : currentField
  const receivingField = direction > 0 ? currentField : nextField

  const { mergeResult } = mergeNodesOnRemove({
    editor,
    nextEditor,
    removedText: fields[removedField].label,
    receivingText: fields[receivingField].label
  })
  if(mergeResult)
    fields[receivingField] = { ...fields[receivingField], label: mergeResult }

  fields.splice(removedField, 1)
  setBlock(blockId, { payload: { ...block.payload, fields } })
}

export const setValue = (key, { block, blockId, id }, compensate = true) => value => {
  const { payload } = block
  const fields = [ ...payload.fields ]
  const newPayload = { ...payload, fields }
  const fieldIndex = fields.findIndex(f => f.id === id)

  const changes = { [key]: value }
  if(key === 'label')
    Object.assign(changes, {
      label: typeof value !== 'string' ? serialize(value) : value,
      lock: { at: Date.now(), by: store.getState().firebase.auth.uid }
    })
  fields[fieldIndex] = { ...fields[fieldIndex], ...changes }

  if(compensate && fields[fields.length - 1].label.length > 0){
    fields[fieldIndex] = { ...fields[fieldIndex], ...isNew() }
    fields.push(newField())
  }else{
    delete fields[fieldIndex].auto_focus
  }

  setBlock(blockId, { payload: newPayload })
}

export const createPollBlock = ()  => ({
  type: 'poll',
  payload: {
    question: { text: '', ...isNew() },
    fields: new Array(3).fill(true).map(() => ({ label: '', is_done: false, id: idGen() }))
  },
  options: { autoFocus: false }
})

export const createMetricField = () => ({ label: '', value: '', id: idGen(), ...isNew() })

export const checkListField = payload => ({ label: '', is_done: false, id: idGen(), ...payload })