import Select from '../../FormControls/Select'
import useAddressSelector from '../../../helpers/useAddressSelector'

const types = {
  string: 'String',
  number: 'Number',
  boolean: 'Boolean',
  currency: 'Currency'
}

function FieldType({ item, stateAddress, setState }){
  const state = useAddressSelector(stateAddress)
  const { index, type, action, planttName } = item

  const setType = ({ selectedItem }) => {
    if(selectedItem === type.is)
      return
      
    const fields = [ ...state.fields ]
    fields[index] = { ...fields[index], type: { is: selectedItem, readonly: false } }
    setState({ ...state, fields })
  }

  return  !item.type.isNew ? (
    <Select
      initialValue={type.is || 'string'}
      onSelect={setType}
      disabled={type.readonly}
      popperProps={{ className: 'import-select' }}
      items={Object.keys(types)}
      getItem={itm => types[itm]}
    />
  ) : null
}

export default FieldType