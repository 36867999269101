import { Modal } from '@material-ui/core'
import { Fragment, useLayoutEffect, useMemo, useRef } from 'react'
import { ReactEditor } from 'cs-slate-react'
import { Editor, Transforms } from 'cs-slate'
import { BlueButton } from '../Button'
import { Link } from '@material-ui/icons'

function LinkModal({ editor, linkModal, setLinkModal }){
  const open = useMemo(() => Boolean(linkModal), [linkModal])

  const close = callback => {
    ReactEditor.focus(editor)
    setLinkModal(null)

    setTimeout(() => {
      Transforms.setSelection(editor, linkModal.selection)
      if(typeof callback === 'function') callback()
    })
  }

  const addLink = e => {
    e.preventDefault()
    close()
    if(!linkModal.text.length)
      return
    
    close(() => {
      if(linkModal.path)
        Transforms.removeNodes(editor, { at: linkModal.path })
      Transforms.insertNodes(editor, [
        { type: 'link', href: linkModal.href, children: [{ text: linkModal.text }] },
        { text: '' }
      ])
    })
  }

  const textRef = useRef()
  const hrefRef = useRef()
  useLayoutEffect(() => {
    if(open){
      let { text, href, path } = linkModal
      if(!path)
        text = Editor.string(editor, editor.selection)

      setLinkModal({ selection: editor.selection, text, href, path })
      setTimeout(() => {
        (path ? hrefRef : textRef)?.current.focus()
      }, 0)
    }
  }, [open])

  const buttonMouseDown = e => {
    e.preventDefault()
    setLinkModal({ selection: null, text: '', href: '' })
  }

  return (
    <Fragment>
      <button onMouseDown={buttonMouseDown} className='format-button'><Link /></button>
      {open &&
      <Modal
        open={true}
        id='add-link-modal'
        onClose={() => setLinkModal(false)}
      >
        <div className='card'>
          <form onSubmit={addLink}>
            <h1>Edit Link</h1>
            <label>
              Text to display:
              <input value={linkModal.text} onChange={e => setLinkModal({ ...linkModal, text: e.target.value })} ref={textRef} />
            </label>
            <label>
              Link to:
              <input value={linkModal.href} onChange={e => setLinkModal({ ...linkModal, href: e.target.value })} ref={hrefRef} />
            </label>
            <div className='button-container'>
              <BlueButton outline type='button' onClick={close}>Cancel</BlueButton>
              <BlueButton>OK</BlueButton>
            </div>
          </form>
        </div>
      </Modal>}
    </Fragment>
  )
}

export default LinkModal