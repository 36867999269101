import MakeBlock from './MakeBlock';
import MakeReadOnly from './MakeReadOnly';
import  {useMemo, useRef } from 'react'

function MakeBlocksGroup({ group: { blocks, selection }, readonly, ...props}){
    const Maker = useMemo(() => !readonly ? MakeBlock : MakeReadOnly, [readonly])
    const groupRef = useRef()

    return (
      <>
        <div ref={groupRef} className="note-block-group">
          {blocks.map(blockId => (
            <Maker
              key={blockId}
              blockId={blockId}
              selection={selection}
              firstBlockIdInGroup={selection ? blocks[0] : undefined}
              lastBlockIdInGroup={selection ? blocks[blocks.length - 1] : undefined}
              {...props}
              groupRef={selection ? groupRef : undefined}
            />
          ))}
        </div>
      </>
    )
  }
export default MakeBlocksGroup